@import "src/include";

#docTradeMyTransactionSaleList{
	.mytransaction{
		&_section{
			width: 880px;
			margin: 0 auto;
			&_title{
				font-family: NanumSquareOTF_acB;
				font-size: 22px;
				color: #343434;
				margin-bottom: 47px;
			}
			&_table{
				&_thead{
					.transaction-title{
						text-align: left;
						padding-left: 26px;
					}
					.price-title{
						.style{
							font-family: NanumSquareOTF_acR;
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}