#MobileUserManage {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 0;
    padding-bottom: 31px;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #ffffff;
    z-index: 9999;

    .header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 60px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

        button {
            position: absolute;
            left: 20px;
            background-size: contain;
        }

        h2 {
            font-size: 16px;
            font-family: NanumSquareOTF_acEB;
            line-height: 22px;
            color: #443018;
        }
    }

    .personal_link {
        margin-bottom: 24px;
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        background-image: linear-gradient(to right, #ff7a00, var(--color-Web-Orange));

        input {
            width: 180px;
            font-size: 10px;
            font-family: NanumSquareOTF_acEB;
            line-height: 27px;
            color: #ffffff;
            border: none;
            background-color: transparent;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 130px;
            height: 27px;
            font-size: 12px;
            font-family: NanumSquareOTF_acB;
            line-height: 18px;
            color: #ffffff;
            border: 1px solid #ffffff;
            background-color: transparent;
        }
    }

    .account_area {
        margin: auto;
        width: 90%;

        .area_title {
            margin-bottom: 12px;
            font-size: 20px;
            font-family: NanumSquareOTF_acEB;
            line-height: 26px;
            color: #443018;
        }

        ul {
            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 12px 16px;
                min-height: 68px;
                border-radius: 4px;
                border: solid 1px #d8d8d8;


                .info {
                    width: 75%;

                    h2 {
                        font-size: 16px;
                        font-family: NanumSquareOTF_acEB;
                        line-height: 22px;
                        color: #606060;
                    }

                    p {
                        font-size: 14px;
                        line-height: 20px;
                        color: #959595;
                        word-break: break-word;
                    }
                }
            }

            li+li {
                margin-top: 9px;
            }
        }
    }

    .account_area+.account_area {
        margin-top: 26px;
    }
}
