@import "../../../include";

#CyberCompanyAdded {
    @import "Common.scss";

    .page_title {
        font-size: 24px;
        font-family: NanumSquareOTF_acEB;
        margin-bottom: 10px;
    }

    .count {
        margin-bottom: 25px;
        padding: 0 15px;
        font-size: 32px;
        font-family: NanumSquareOTF_acB;
        line-height: 30px;
        color: #00a545;
        text-align: right;
    }

    .tabs {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            width: 157px;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            line-height: 24px;
            text-align: center;
        }

        li.active {
            color: #00a545;
        }

        li:first-child {
            border-right: 1px solid #979797;
        }
    }

    .wrap_list {
        margin: 24px auto 0 auto;
        padding: 0 15px;
        min-width: 333px;

        ul {
            margin-top: 33px;
            border-top: 2px solid #00a545;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 49.5px;
                border-bottom: 1px solid #d8d8d8;

                .info {
                    display: flex;
                    align-items: center;
                    column-gap: 6px;
                }

                p {
                    font-size: 12px;
                }

                p.member {
                    display: inline-block;
                    padding: 0 7px;
                    font-size: 12px;
                    line-height: 24px;
                    color: #ffffff;
                    text-align: center;
                    @include border-radius(15px);
                    background-color: #00a545;
                }
            }
        }
    }

    .wrap_table {
        margin: 24px auto 0 auto;
    }
}