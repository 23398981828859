@import "src/include";

#PopupAnnualFeeCalc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 780px;
    height: 370px;
    padding: 35px 29px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    z-index: 2;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            font-size: 22px;
            font-family: NanumSquareOTF_acB;
            color: #343434;
        }

        .btns {
            display: flex;
            align-items: center;

            .btn_select {
                margin-right: 30px;
                width: 120px;
                line-height: 32px;
                font-size: 16px;
                text-align: center;
                color: #ffffff;
                @include border-radius(4px);
                background-color: var(--color-Web-Orange);
            }
        }
    }

    .desc {
        margin-top: 7px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #606060;

        i {
            margin-right: 7px;
        }
    }

    .type {
        margin-top: 27px;

        .row {
            margin-bottom: 27px;
            display: flex;

            .title {
                margin-right: 15px;
                min-width: 56px;
                line-height: 16px;
                font-size: 14px;
                font-family: NanumSquareOTF_acB;
            }

            .checks {
                display: flex;
                flex-flow: wrap;

                .checkbox {
                    justify-content: flex-start;
                    width: 162px;

                    .checkmark {
                        min-width: 20px;
                        min-height: 20px;
                    }

                    .value {
                        font-size: 12px;
                    }
                }
            }

            .checks.line {

                .checkbox:last-child {
                    margin-top: 15px;

                    .value {
                        white-space: pre;
                    }
                }
            }
        }
    }
}
