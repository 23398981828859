#AdminAnnualManage {
  margin: auto;
  width: 1280px;
  .annual_wrapper {
    min-height: 402px;
    background-color: rgba(240,240,240,0.2);
    padding: 28px 50px 50px 40px;
    position: relative;
    h2 {
      font-family: Pretendard_extraBold;
      font-size: 30px;
      letter-spacing: -1px;
      color: #1d334e;
      margin-bottom: 35px;
    }
    .header_btn {
      position: absolute;
      top: 40px;
      right: 50px;
      button {
        width: 153px;
        height: 40px;
        border-radius: 4px;
        background-color: #4593f5;
        color: #fff;
        margin-right: 10px;
      }
    }
    .annual_user_list {
      .list_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: Pretendard_bold;
        margin-bottom: 10px;
        p {
          font-size: 22px;
          color: #343434;
        }
        button {
          width: 140px;
          height: 40px;
          border-radius: 4px;
          background-color: #4593f5;
          font-size: 14px;
          color: #fff;
          font-family: Pretendard_bold;
        }
      }
      .annual_list_grid {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(4, 1fr);
        .annual_list_grid_item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 293px;
          height: 100px;
          border-radius: 5px;
          box-shadow: 0 2px 3px 1px #d8d8d8;
          background-color: #fff;
          padding: 0 11px 0 21px;
          p {
            font-family: Pretendard_extraBold;
            font-size: 20px;
            color: #4593f5;
             span {
               font-family: Pretendard_bold;
               font-size: 14px;
               letter-spacing: -0.25px;
               color: #606060;
               margin-left: 4px;
             }
          }
        }
      }
    }
  }
  .annual_search_wrapper {
    margin: 42px 0;
    .search_box {
      padding-left: 50px;
      display: flex;
      align-items: center;
      p {
        font-family: Pretendard_bold;
        font-size: 22px;
        color: #343434;
      }
      .search_input {
        margin-left: 60px;
        width: 688px;
        height: 50px;
        border-radius: 5px;
        border: solid 0.5px #d8d8d8;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 0 18px;
        input[type="text"] {
          font-family: Pretendard_bold;
          font-size: 16px;
          color: #343434;
          width: 610px;
          height: 24px;
          border: none;
          outline: none;
        }
      }
      i {
        cursor: pointer;
      }
    }
  }
}
