@import "src/include";

#MarketInfoMainCart {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 15px 0;
  width: 100vw;
  height: 350px;
  border: solid 1px #f0f0f0;
  background-color: #ffffff;
  z-index: 1;

  .MarketCart {

    &_header {
      margin: auto;
      padding-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      border-bottom: 1px solid #e7e7e7;

      h2 {
        font-size: 22px;
        font-family: NanumSquareOTF_acB;
        color: #343434;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 132px;
        height: 32px;
        font-size: 16px;
        color: #ffffff;
        border-radius: 4px;
        background-color: #4593f5;
      }
    }

    &_body {
      margin: 15px auto 0 auto;
      width: 80%;

      &_header {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          font-size: 14px;
          font-family: NanumSquareOTF_acEB;
          color: #1d334e;
        }

        .inner {
          display: flex;
          align-items: center;
          column-gap: 10px;

          p {
            display: flex;
            align-items: center;
            column-gap: 5px;

            i {
              min-width: 24px;
            }

            span {
              font-size: 12px;
              color: #606060;
            }
          }

          button {
            width: 132px;
            height: 32px;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            color: #ffffff;
            border-radius: 4px;
            background-color: #1bb3f3;
          }
        }
      }
    }

    @media screen and (max-width: 850px) {
      &_header {
        h2 {
          font-size: 16px;
        }

        a {
          width: 90px;
          font-size: 14px;
        }
      }

      &_body {
        h2 {
          display: none;
        }
      }
    }

  }

  ul {
    padding-bottom: 10px;
    display: flex;
    column-gap: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}