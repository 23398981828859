.table {
  & tr{
    border-bottom: 1px solid #C7C9CC !important;
  }
  .edit-buttons {
    display: flex;
    gap: 10px;

    & > button {
      color: white;
      padding: 8px;
      border-radius: 8px;
      &:first-child {
        background-color: #23d300;
      }
      &:last-child {
        background-color: #ff5151;
      }
    }
  }
}
