#AnnualFeeMailing {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1024px;
  height: 640px;
  border-radius: 15px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px #e7e7e7;
  background-color: #fff;
  z-index: 10;
  overflow-y: auto;

  > h2 {
    height: 62px;
    background-color: #00c6be;
    border-radius: 15px 15px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 28px;
    letter-spacing: -0.56px;
    color: #fff;
    > .icon_exit_gray {
      position: absolute;
      right: 30px;
      cursor: pointer;
    }
  }

  > header {
    padding: 45px 35px;
    .section_header {
      > div:nth-child(1) {
        display: flex;
        justify-content: space-between;
        p {
          font-size: 22px;
        }
        .btn_wrapper {
          display: flex;
          gap: 30px;
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            padding: 9px 19px;
            font-size: 16px;
            letter-spacing: -0.4px;
            i {
              margin-right: 14.5px;
            }
          }
          .btn_reply {
            color: #4f5ef7;
            background-color: #e8eaff;
          }
          .btn_mail {
            color: #4f5ef7;
            border: solid 1px #4f5ef7;
            background-color: #fff;
          }
          .btn_edit, .btn_save {
            color: #FFFFFF;
            background-color: #4f5ef7;
          }
          .btn_cancel {
            border: solid 0.5px #acacac;
            background-color: #fff;
            color: #acacac;
          }
        }
      }
      > div:nth-child(2) {
          margin-top: 10px;
          font-size: 16px;
      }
    }
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 45px 35px;
    .field {
      display: flex;
      gap: 15px;
      margin-bottom: 40px;
      position: relative;
      width: 829px;
      border-radius: 15px;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
      border: solid 0.5px #e7e7e7;
      background-color: #fff;
      padding: 14.8px 19.5px;
      flex-direction: column;
      table {
        border-radius: 8px;
        border: solid 0.5px #4f5ef7;
        background-color: #fff;
        border-collapse: separate;
        thead {
          border: solid 0.5px #4f5ef7;
          background-color: #e8edfd;
          tr {
            border-bottom: solid 0.5px #4f5ef7;
          }
        }
        tbody {
          tr td {
            border-bottom: solid 0.5px #c3c3c3;
          }
        }
      }

      .rows {
        display: flex;
        .row {
          display: flex;
          flex: 1;
          justify-content: space-between;
          align-items: center;
          min-height: 45px;
          font-size: 14px;
          border-bottom: solid 1px #e7e7e7;
          .title {
            display: flex;
            align-items: center;
            white-space: pre;
            font-family: NanumSquareOTF_acB;
            font-size: 15px;
          }
          select {
            width: 100px;
          }
          > p {
            max-width: 150px;
            word-break: break-word;
            //overflow: hidden;
            //text-overflow: ellipsis;
            //display: -webkit-box;
            //-webkit-line-clamp: 2;
            //-webkit-box-orient: vertical;
          }

          input {
            width: 150px;
            height: 30px;
            border: 1px solid #d8d8d8;
            border-radius: 4px;
            font-size: 14px;
            color: black;
            padding: 12px 0px 12px 10px;
          }
          .btns {
            display: flex;
            align-items: center;
          }
        }
        .color_red {
          color: #ec0700;
        }
        .row.no_data {
          border-bottom: none;
        }

        .row.colspan2 {
          justify-content: space-between;
          width: 580px;

          .title {
            width: 100px;
          }

          p {
            width: 450px;
            max-width: 450px;
          }
        }

        .row.colspan3 {
          width: 800px;
          p {
            width: 860px;
            max-width: 860px;
          }
          input.long {
            width: 720px !important;
          }
        }

        .row+.row {
          margin-left: 15px;
        }
      }
      > h5 {
        font-size: 20px;
        letter-spacing: -0.5px;
        position: absolute;
        top: 18px;
        right: 850px;
        display: flex;
        align-items: center;
        white-space: pre;
      }
    }
  }
}
