.fourth {
  .rnd_plan {
    padding-left: inherit;
    > div {
      padding-left: 24px;
      padding-right: 15px;
    }
  }
  input[type="text"] {
    width: 365px;
  }
  .react-datepicker__input-container {
    input {
      background-image: url("../../../../../assets/images/businessPlan/icon-calendar.svg");
      background-position: right 10px center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      width: 125px;
      height: 42px;
      border-radius: 4px;
      border: 1px solid #d8d8d8;
      padding: 5px;
    }
    span {
      font-size: 14px;
      color: #959595;
      letter-spacing: inherit;
    }
  }
  select {
    width: calc(100% - 5px);
    border: solid 1px #d8d8d8;
    padding-left: 5px;
    background-color: #fff;
    height: 42px;
    font-size: inherit;
    border-radius: 4px;
  }
  .plan_grid_items {
    margin-top: 5px;
    margin-left: 40px;
    p {
      width: 80px;
      padding-left: inherit;
      span {
        padding: inherit;
        font-size: inherit;
        color: inherit;
      }
    }
  }
  .plan_text {
    button {
      margin: 0 0 0 10px;
    }
  }
  .personal_info_wrapper {
    > p {
      padding-left: 16px;
    }
  }
  .company_info_wrapper {
    margin-top: 9px;
    padding: 11px 0 15px 0;
    background-color: rgba(240,240,240,0.2);
    > p {
      padding-left: 16px;
    }
    .plan_grid_item:last-child {
      grid-column: 1 / span 2;
      input[type="text"] {
        width: 845px;
      }
    }
  }
  table {
    thead {
      background-color: #f0f0f0;
    }
    th {
      color: #343434;
    }
    tbody {
      td {
        input[type="text"] {
          width: calc(100% - 5px);
        }
      }
    }
  }
  .extra_text {
    color: #343434;
  }
  .academic_background_table {
    input[type="text"] {
      width: 135px;
    }
    .react-datepicker__input-container {
      input {
        width: 125px;
      }
    }
    .school_name {
      input {
        width: 180px;
      }
    }
    .final_degree_thesis_name {
      td {
        font-family: NanumSquareOTF_acB;
        letter-spacing: -0.25px;
        color: #343434;
        font-size: 14px;
      }
      input {
        width: 750px;
      }
    }
  }
  .career_table {
    input[type="text"] {
      width: 197px;
    }
    .react-datepicker__input-container {
      input {
        width: 125px;
      }
    }
  }
  .performance_table {
    thead tr{
        height: 30px;
    }
    tbody {
      tr:nth-child(odd) {
        border: none;
      }
    }
  }
  .result_ones_thesis_table {
    tbody {
      input[type="text"] {
        width: calc(100% - 5px);
      }
    }
  }
  .researcher_table {
    tbody {
      input[type="text"] {
        width: calc(100% - 5px);
      }
    }
  }
  .organization_name {
    background-color: rgba(107, 211, 255, 0.2);
    width: 1010px;
    display: flex;
    align-items: center;
    padding: 5px 0;
    p {
      font-family: NanumSquareOTF_acB;
      font-size: 14px;
      min-width: 70px;
    }
    input[type="text"] {
      width: 390px;
      margin-left: 21px;
      margin-right: 50px;
    }
    input[type="text"]:nth-of-type(2) {
      margin-left: 0;
      width: 241px;
    }
    button {
      margin-left: 50px;
    }
  }
  .organization_items {
    padding: 11px 0 11px 41px;
    .blue_color_text {
      margin-left: 21px;
    }
    .organization_grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 30px;
      row-gap: 5px;
      margin-left: 55px;
      margin-top: 5px;
      div {
        display: flex;
        align-items: center;
        p {
          min-width: 71px;
          font-family: NanumSquareOTF_acB;
          font-size: 14px;
          color: #343434;
        }
      }
    }
  }
  .manager_grid {
    div:nth-child(3),div:last-child {
      grid-column: 1 / span 2;
    }
  }
  .person_charge_grid {
    div:nth-child(1), div:nth-child(2), div:last-child {
      grid-column: 1 / span 2;
    }
  }
  .key_performance_table {
    border-bottom: 1px solid #f0f0f0;
    tbody {
      tr:nth-child(odd) {
        border: none;
      }
    }
  }
  .extra_info_text {
    font-family: NanumSquareOTF_acR;
    font-size: 12px;
    line-height: 1.75;
    color: #606060;
  }
  .general_conditions_table {
    thead {
      input[type="text"] {
        margin: 7px 0;
        width: calc(100% - 5px);
      }
    }
    td,th {
      border-left: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
    }
    td:first-child {
      border-left: 0;
    }
    select {
      width: 85px;
      height: 42px;
      border-radius: 4px;
      border: solid 1px #d8d8d8;
      padding-left: 5px;
      background-color: #fff;
      font-size: inherit;
      margin-right: 5px;
    }
    span {
      font-family: NanumSquareOTF_acR;
      font-size: 14px;
      color: #343434;
    }
  }
}
