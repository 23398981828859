#SignUpCheck {
    margin: auto;
    padding-top: 47px;
    width: 880px;

    .header {
        margin-bottom: 56px;
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 22px;
            font-family: NanumSquareOTF_acEB;
        }

        ul {
            display: flex;
            align-items: center;

            li {
                width: 111.9px;
                line-height: 30px;
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
                text-align: center;
                color: rgba(0, 0, 0, 0.87);
                border-radius: 5px;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
                background-color: #ffffff;
                cursor: pointer;
            }

            li+li {
                margin-left: 11px;
            }
        }
    }

    .wrap_table_title {
        margin-bottom: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .btn_download {
        margin-left: auto;
        display: block;
        width: 150px;
        height: 32px;
        font-size: 16px;
        font-family: NanumSquareOTF_acB;
        color: #ffffff;
        background-color: var(--color-Web-Orange);
    }

    .table_title {
        font-size: 20px;
        font-family: NanumSquareOTF_acB;
    }

    .btn_delete {
        width: 60px;
        height: 24px;
        color: #ffffff;
        background-color: #e92f2c;
    }
}
