@import "src/include";

#TradePost {
    margin: auto;
    padding-top: 82px;
    width: 880px;

    @media screen and (max-width: 850px) {
        position: fixed;
        top: 0;
        left: 0;
        padding: 60px 0;
        width: 100vw;
        height: 100vh;
        background-color: #ffffff;
        overflow-y: scroll;
        z-index: 2;
    }

    .pc {
        .header {
            margin-bottom: 15px;
            padding-bottom: 7px;
            border-bottom: solid 1px #e7e7e7;

            p {
                font-size: 22px;
                font-family: NanumSquareOTF_acB;
                line-height: 30px;
            }
        }

        .trade_item_add {

            .head {
                margin-bottom: 7px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 32px;

                h2 {
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    line-height: 16px;
                }

                button {
                    width: 132px;
                    height: 32px;
                    font-size: 16px;
                    line-height: 32px;
                    color: #ffffff;
                    background-color: var(--color-Web-Orange);
                }
            }

            table {
                th:last-child,
                tr:last-child {
                    width: 10%;
                }
            }
        }

        > .wrap {
            margin: 30px 0;
            display: flex;
            align-items: flex-end;
            column-gap: 20px;

            .image {
                width: 283px;

                .inner {
                    margin-bottom: 16px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        margin-bottom: 8px;
                        font-size: 14px;
                        font-family: NanumSquareOTF_acEB;
                    }
                }

                .btn_camera {
                    position: relative;
                    min-width: 283px;
                    width: 283px;
                    min-height: 143px;
                    height: 143px;
                    border: solid 1px #e7e7e7;
                    background-color: #ffffff;

                    p {
                        padding-top: 60px;
                        font-size: 14px;
                        text-align: center;
                        color: #959595;
                    }

                    .icon_badge_del {
                        position: absolute;
                        top: -10px;
                        right: -12px;
                    }

                    img {
                        width: 283px;
                        height: 141px;
                        object-fit: contain;
                        border: solid 1px #e7e7e7;
                    }
                }
            }

            .info {
                width: 100%;

                .wrap {
                    display: flex;
                    column-gap: 17px;

                    .row {
                        width: 50%;
                    }
                }

                .wrap.half {
                    .row:last-child {
                        padding: 4px 0 0 0;
                        border: none;
                    }
                }

                .row {
                    padding: 13px 0;
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                    height: 46px;
                    border-bottom: 1px solid #e7e7e7;

                    h2 {
                        width: 105px;
                        font-size: 14px;
                        font-family: NanumSquareOTF_acB;
                        line-height: 16px;
                    }

                    p {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.87);
                    }

                    input {
                        padding: 0 10px;
                        width: 185px;
                        height: 30px;
                        font-size: 14px;
                        border: 1px solid #e7e7e7;
                    }
                }

                .row.space-between {
                    justify-content: space-between;
                }
            }
        }

        > .comment_area {
            margin: 15px 0;

            h2 {
                margin-bottom: 15px;
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
            }

            textarea {
                padding: 12px 15px;
                width: 100%;
                height: 246px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.87);
                border: 1px solid #e7e7e7;
                background-color: #ffffff;
                resize: none;
            }
        }

        > .classification_area {
            padding: 15px 0;
            border-bottom: 1px solid #e7e7e7;

            h2 {
                margin-bottom: 15px;
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
            }

            p {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.87);
            }
        }

        > .product_area {
            padding: 15px 0;
            border-bottom: 1px solid #e7e7e7;

            h2 {
                margin-bottom: 15px;
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
            }

            .item {

                .title {
                    margin-bottom: 12px;
                    font-size: 14px;
                    color: var(--color-Web-Orange);
                }

                .value {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 10px;
                    row-gap: 10px;
                    font-size: 14px;
                    line-height: 2;
                    white-space: break-spaces;

                    span {
                        padding: 0 10px;
                        border: 1px solid var(--color-Web-Orange);
                        @include border-radius(16px);
                        background-color: white;
                    }
                }
            }

            .item+.item {
                margin-top: 30px;
            }
        }

        .classification_area,
        .product_area {

            > p,
            .list {
                padding: 15px;
                background-color: rgba(216, 216, 216, 0.1);
            }
        }
    }

    .mo {
        .header {
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 60px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
            background-color: #ffffff;
            z-index: 2;

            h2 {
                font-size: 16px;
                font-family: NanumSquareOTF_acEB;
                color: #443018;
            }

            button {
                position: absolute;
                left: 16px;
                background-size: contain;
            }
        }

        table{
            width: 90%;
            margin: 0 auto;
        }

        .trade_item_add {
            padding-top: 24px;

            .head {
                margin-bottom: 13px;
                padding: 0 5%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h2 {
                    font-size: 14px;
                    font-family: NanumSquareOTF_acEB;
                    line-height: 20px;
                    color: #443018;
                }

                button {
                    width: 92px;
                    height: 26px;
                    font-size: 12px;
                    font-family: NanumSquareOTF_acEB;
                    color: #ffffff;
                    border-radius: 4px;
                    background-color: var(--color-Web-Orange);
                }
            }
            table {

                .not_styled {
                    color: #747474;
                }

                th:last-child,
                td:last-child {
                    width: 15%;
                }

                td:nth-child(2) {
                    font-family: NanumSquareOTF_acB;
                    color: #606060;
                    text-decoration: underline;
                }

                td {
                    button {
                        margin-left: 5px;
                    }
                }
            }
        }

        .trade_item_image {
            margin-top: 32px;
            padding: 0 5%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .inner {

                h2 {
                    margin-bottom: 5px;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acEB;
                    line-height: 20px;
                    color: #443018;
                }

                p {
                    font-size: 14px;
                    line-height: 20px;
                    color: #959595;
                }
            }

            .images {
                display: flex;

                .btn_camera {
                    position: relative;
                    width: 45px;
                    height: 45px;
                    border-radius: 3px;
                    border: 2px solid #d8d8d8;
                    background-color: #ffffff;

                    .icon_badge_del {
                        position: absolute;
                        top: -10px;
                        right: -12px;
                    }

                    img {
                        width: 41px;
                        height: 41px;
                        object-fit: contain;
                    }
                }

                .btn_camera+.btn_camera {
                    margin-left: 20px;
                }
            }
        }

        .trade_item_class {
            p {
                font-size: 12px;
                font-family: NanumSquareOTF_acB;
                line-height: 20px;
                color: #606060;

                span {
                    text-decoration: underline;
                }

                span+span {
                    margin-left: 6px;
                }
            }
        }

        .trade_item_class,
        .trade_item_comment {
            margin-top: 32px;
        }

        .trade_item_price {
            margin-top: 25px;

            .wrap_price {
                display: flex;
                align-items: center;

                input {
                    margin-right: 7px;
                    width: 160px;
                }

                .checkbox {
                    column-gap: 5px;
                    .value {
                        font-size: 12px;
                        color: #606060;
                    }
                }
            }
        }

        .trade_item_class,
        .trade_item_comment,
        .trade_item_price {
            padding: 0 5%;

            > h2 {
                margin-bottom: 5px;
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
                color: #443018;
            }
        }

        input {
            padding: 10px 7px;
            width: 214px;
            height: 38px;
        }

        textarea {
            padding: 10px 9px;
            width: 100%;
            height: 130px;
            resize: none;
        }

        input,
        textarea {
            font-size: 14px;
            line-height: 20px;
            color: #443018;
            border-radius: 3px;
            border: solid 2px #d8d8d8;
        }

        input::placeholder,
        textarea::placeholder {
            color: #959595;
        }
    }

    .btn_sell_add {
        margin: 30px auto 0 auto;
        display: block;
        width: 206px;
        height: 42px;
        font-size: 16px;
        font-family: NanumSquareOTF_acEB;
        color: #ffffff;
        @include border-radius(21px);
        background-color: var(--color-Web-Orange);


        @media screen and (min-width: 850px) {
            @include border-radius(0px);
        }
    }
}