@import "src/include";

#Header {
    width: 100%;
    height: 53px;

    > .wrap_header {
        display: flex;
        width: 100%;
        height: 100%;
        max-width: 1280px;
        margin: auto;
        justify-content: space-between;
        .header_left {
            display: flex;
            align-items: center;
            .oval {
                border-radius: 50%;
                width: 24px;
                height: 24px;
                margin-left: 20px;
            }
            .homeBtn {
                .oval {
                    background-color: black;
                }
            }
            .groupwareBtn {
                display: flex;
                align-items: center;
               .oval {
                   background-color: yellowgreen;
               }
                p {
                    margin-left: 5px;
                    color: yellowgreen;
                }
            }
        }
        .wrap_header_utill {
            display: inline-flex;
            justify-content: flex-end;
            align-items: center;
        }

        .user_tab {
            .header_user_tab_ul {
                display: flex;
                align-items: center;
                height: 53px;
                li {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    font-family: NanumSquareOTF_acB;
                    font-size: 12px;
                    letter-spacing: -0.21px;
                    height: 53px;
                    color: #343434;
                    padding: 5px;
                    a {
                        padding-right: 10px;
                        display: flex;
                        align-items: center;
                        color: #343434;
                        border-right: solid 1px #f0f0f0;
                        height: 18px;
                        span {
                            margin-left: 8px;
                            display: inline-block;
                            color: #606060;
                        }
                    }
                }
            }
        }
        .toolbar-wrap {
            padding-left: 20px;
            height: 100%;
        }
        .toolbar {
            position: relative;
            display: inline-flex;
            width: 100%;
            height: 100%;
            &_btn {
                position: relative;
                font-family: NanumSquareOTF_acB;
                font-size: 11px;
                color: #4593f5;
                background-color: transparent;
                padding-right: 28px;
                &.on {
                    + .toolbar_box {
                        display: block;
                    }
                }
            }
            &_box {
                display: none;
                position: absolute;
                width: 150px;
                height: 75px;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                padding: 5px;
                background-color: #fff;
                border-radius: 8px;
                border: 1px solid #d8d8d8;
                z-index: 3;
                &_list {
                    display: block;
                    width: 100%;
                    height: 50%;
                }
                &_link {
                    display: inline-block;
                    font-family: NanumSquareOTF_acB;
                    font-size: 12px;
                    color: #747474;
                    width: 100%;
                    height: 100%;
                    line-height: 31.5px;
                    text-align: center;
                    background-color: transparent;
                    &:hover {
                        color: #4593f5;
                    }
                }
            }
        }
    }

    .wrap.mo {
        position: fixed;
        padding: 0 20px;
        width: 100vw;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        z-index: 1000;
        background-color: #ffffff;

        .menu_top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 60px;

            .btnDel{
                width: 24px;
                height: 24px;
                background: url(../../assets/images/common/icon_menu_exit_mobile.svg) no-repeat 50% 50%;
                background-size: cover;
                text-indent: -9999px;
            }

            .prevDel{
                width: 24px;
                height: 24px;
                background: url(../../assets/images/common/icon_menu_arrow_mobile.svg) no-repeat 50% 50%;
                background-size: cover;
                text-indent: -9999px;
            }

            &_title{
                position: absolute;
                font-family: NanumSquareOTF_acEB;
                font-size: 16px;
                color: #1d334e;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }

            .btns {
                display: flex;
                align-items: center;

                .btn_change {
                    margin-right: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100px;
                    height: 28px;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    @include border-radius(20px);
                    color: #ffffff;
                }

                .btn_ipnow {
                    background-color: var(--color-Web-Orange);
                }

                .btn_findbiz {
                    background-color: #6bd3ff;
                }

                .icon_m_bell,
                .icon_m_bell_on {
                    width: 24px;
                    height: 24px;
                }

                .icon_exit,
                .icon_m_menu {
                    margin-left: 12px;
                }

                .icon_m_login {
                    margin-right: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 61px;
                    height: 28px;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acEB;
                    color: #ffffff;
                    border-radius: 14px;
                    background-color: var(--color-Piction-Blue);
                }
            }
        }
    }

    .more_menu {
        position: fixed;
        top: 60px;
        left: 0;
        width: 100%;
        z-index: 999;
        transition: 1s;
        pointer-events: none;

        .dimmed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            opacity: 0.3;
            background-color: #443018;
            pointer-events: auto;
        }

        .menu_animation {
            left: 25vw !important;
        }

        .menus {
            position: relative;
            top: 0;
            left: 100vw;
            padding-bottom: 100px;
            min-width: 284px;
            width: 75vw;
            height: 100vh;
            overflow-y: scroll;
            background-color: #ffffff;
            z-index: 2;
            transition: left 300ms ease-in-out;
            pointer-events: auto;

            .myinfo {
                padding: 23px 20px 18px 17px;
                height: 105px;
                background-image: linear-gradient(
                    to right,
                    #ff7a00,
                    var(--color-Web-Orange)
                );

                .access_account {
                    margin-bottom: 12px;
                    display: flex;
                    align-items: center;

                    h2 {
                        margin-right: 16px;
                        min-width: 95px;
                        max-width: calc(70% - 16px);
                        @include text-ellipsis(1);
                        font-size: 20px;
                        font-family: NanumSquareOTF_acEB;
                        line-height: 26px;
                        color: #ffffff;
                    }

                    p {
                        font-size: 14px;
                        font-family: NanumSquareOTF_acB;
                        color: #ffffff;
                    }
                }

                .btns {
                    display: flex;

                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 26px;
                        font-size: 12px;
                        font-family: NanumSquareOTF_acEB;
                        border-radius: 13px;
                    }

                    .btn_mypage {
                        margin-right: 12px;
                        width: 89px;
                        color: #ff8a00;
                        background-color: #ffffff;
                    }

                    .btn_auth {
                        width: 67px;
                        color: #ffffff;
                        border: 2px solid #ffffff;
                    }
                }
            }

            .m_item {
                padding: 0 6vw;

                > a,
                > p {
                    padding: 13.5px 0 9.5px 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 47.5px;
                    font-size: 18px;
                    font-family: NanumSquareOTF_acEB;
                    color: #5f5f5f;
                    border-bottom: 1px solid #c9c9c9;
                }

                ul {
                    padding: 13.5px 15px;
                    border-bottom: 1px solid #c9c9c9;

                    li {
                        a {
                            display: block;
                            width: 100%;
                            font-size: 16px;
                            font-family: NanumSquareOTF_acEB;
                            color: #959595;
                        }
                    }

                    li + li {
                        margin-top: 14px;
                    }
                }
            }
        }

        .wrap_agree {
            margin-top: 24px;
            padding: 0 6vw;
            display: flex;
            width: 75vw;
            justify-content: center;

            a {
                display: block;
                width: 50%;
                font-size: 12px;
                color: #5f5f5f;
                text-align: center;
            }

            a + a {
                border-left: 1px solid #5f5f5f;
            }
        }
    }

    .m_logo1 {
        width: 80px;
        height: 30px;
        background: url("../../assets/images/common/logo_ipnow.svg") no-repeat;
        background-size: contain;
        text-indent: -9999px;
    }
    .m_logo2 {
        width: 80px;
        height: 30px;
        background: url("../../assets/images/common/logo_findBiz.svg") no-repeat;
        background-size: contain;
        text-indent: -9999px;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }
}

@media screen and (max-width: 850px) {
    #Header {
        position: fixed;
        top: 0;
        left: 0;
        height: 60px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
        z-index: 2;
    }
}
