@import "src/include";

#InvestmentRequestDetail {
    margin: auto;
    padding-top: 42px;
    width: 880px;

    .title {
        color: #343434;
    }

    .extra_bold {
        font-family: NanumSquareOTF_acEB !important;
    }

    > .header {
        padding-bottom: 25px;

        .title {
            margin-bottom: 20px;
            font-size: 22px;
            font-family: NanumSquareOTF_acB;
        }

        .content {
            margin-bottom: 9px;
            display: flex;
            justify-content: space-between;
            line-height: 21px;

            ul {
                li {
                    font-size: 16px;
                }
            }

            .btns {

                .btn_update {
                    margin-right: 8px;
                    width: 64px;
                    height: 32px;
                    font-size: 16px;
                    color: #ffffff;
                    background-color: #96c719;
                }

                .btn_submit {
                    width: 120px;
                    height: 32px;
                    font-size: 16px;
                    color: #ffffff;
                    background-color: #e92f2c;
                }

                .btn_save {
                    margin-right: 8px;
                    width: 64px;
                    height: 32px;
                    font-size: 16px;
                    color: #ffffff;
                    background-color: #19c77c;
                }

                .btn_cancle {
                    margin-right: 8px;
                    width: 64px;
                    height: 32px;
                    font-size: 16px;
                    color: #ffffff;
                    background-color: #96c719;
                }
            }
        }

        .state {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 115px;
            height: 24px;
            font-size: 12px;
            color: #ffffff;
            border-radius: 16px;
            background-color: #989898;
        }

        .state.red {
            background-color: #e92f2c;
        }

        .state.green {
            background-color: #00ae02;
        }
    }

    .field {

        > .title {
            margin-bottom: 8px;
            font-size: 22px;
            font-family: NanumSquareOTF_acB;
        }

        > .desc {
            margin-bottom: 16px;
            padding: 15px 49px 15px 50px;
            overflow-y: scroll;
            width: 880px;
            height: 272px;
            font-size: 14px;
            line-height: 1.36;
            letter-spacing: 0.25px;
            border: solid 1px #e7e7e7;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }

        textarea {
            margin-bottom: 16px;
            padding: 15px;
            width: 880px;
            height: 272px;
            font-size: 14px;
            line-height: 1.36;
            letter-spacing: 0.25px;
            border: solid 2px #000000;
            border-radius: 4px;
            resize: none;
        }

        .table_list {

            .header {
                margin-bottom: 6px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 30px;

                .title {
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                }

                .btn_add {
                    margin-left: 8px;
                    width: 120px;
                    height: 32px;
                    font-size: 16px;
                    color: #ffffff;
                    @include border-radius(4px);
                    background-color: #4593f5;
                }

            }
        }
    }

    .wrap_list {

        table {
            tbody {

                tr {
                    border-left: 1px solid #e7e7e7;
                    border-right: 1px solid #e7e7e7;

                    td {
                        border-left: 1px solid #e7e7e7;

                        .btn_remove {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    td.btn_url {
                        cursor: pointer;
                    }

                    td.no_border {
                        border-left: none;
                    }

                    td.border_right {
                        border-right: 1px solid #e7e7e7;
                    }
                }
            }
        }

        .rowspan {
            border: 1px solid #e7e7e7;

            div {
                min-height: 30px;
            }
            div:first-child {
                color: var(--color-Web-Orange);
            }
        }
    }

    @import "../../common/css/rows";

    .btn_remove {
        width: 24px;
        height: 24px;
    }

    .wrap_comment {
        margin: 47px auto 30px auto;
        padding: 11px 20px 21px;
        @include border-radius(20px);

        .title {
            margin-bottom: 5px;
            line-height: 30px;
            font-size: 16px;
            font-family: NanumSquareOTF_acEB;
            color: #443018;
        }

        .comment {
            font-size: 14px;
            color: #343434;
        }
    }

    .wrap_comment.approval {
        background-color: rgba(255, 166, 0, 0.1);
    }

    .wrap_comment.reject {
        background-color: rgba(233, 47, 44, 0.1);
    }

    .wrap_comment {
        margin-top: 47px;
        margin-bottom: 30px;

        .title {
            margin-bottom: 5px;
            line-height: 30px;
            font-size: 14px;
            font-family: NanumSquareOTF_acEB;
            color: #343434;
        }

        .comment {
            padding: 20px 49px 18px 50px;
            width: 880px;
            height: 127px;
            font-size: 14px;
            color: #343434;
        }
    }

    .wrap_agree {
        margin-bottom: 36px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 32px;

        p {
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
        }
    }

    .wrap_btns {
        display: flex;
        justify-content: center;
        align-items: center;

        .btn_approval, .btn_unable {
            width: 132px;
            line-height: 32px;
            font-size: 16px;
            color: #ffffff;
            background-color: #4593f5;
        }

        .btn_unable {
            margin-left: 13px;
            background-color: #e92f2c;
        }
    }

    textarea {
        padding: 20px 49px 18px 50px;
        width: 880px;
        height: 127px;
        border: solid 1px #d8d8d8;
        resize: none;
    }

    .checkbox {
        .checkmark {
            margin: 0;
        }
    }

    *::-webkit-scrollbar {
        display: none;
    }

    .circle {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 24px;
        border-radius: 16px;
        pointer-events: none;
    }
}

#InvestmentRequestDetail.disable_upload {

    button.icon_upload {
        display: none;
        pointer-events: none;
    }
}
