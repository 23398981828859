@import "src/include";

#PopupPatentInfo {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 49px 59px;
    width: 1028px;
    max-height: 710px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    z-index: 2;

    .header {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .inner {
            display: flex;
            flex-direction: column;
            row-gap: 7px;

            h2 {
                font-size: 22px;
                font-family: NanumSquareOTF_acB;
                color: #343434;
            }

            p {
                display: flex;
                align-items: center;
                column-gap: 7px;
                font-size: 12px;
                color: #606060;
            }
        }

        .btns {
            display: flex;
            align-items: center;

            .btn_register {
                width: 120px;
                line-height: 32px;
                font-size: 16px;
                text-align: center;
                color: #ffffff;
                @include border-radius(4px);
                background-color: var(--color-Web-Orange);
            }

            button+button {
                margin-left: 20px;
            }
        }
    }

    .checkbox {
        .checkmark {
            margin-left: 0 !important;
        }
    }
}
