dialog#modal {
  &::backdrop {
    background: rgba(0 0 0 / 0.2);
  }
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  overflow: hidden;
}

.modal-content {
  overflow-y: auto; /* Enable vertical scrolling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #c1c1c1 #f0f0f0; /* Thumb color, Track color for Firefox */
}

/* Webkit Browsers (Chrome, Safari, Edge) */
.modal-content::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.modal-content::-webkit-scrollbar-track {
  background: #f0f0f0; /* Track color, similar to Safari */
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: #c1c1c1; /* Thumb color, similar to Safari */
  border-radius: 10px; /* Rounded edges for a smooth look */
  border: 2px solid #f0f0f0; /* Padding around the thumb */
}

.modal-patent-manage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 300px;
  gap: 40px;
  > p {
    margin-top: 12px;
  }
}

.modal-patent-manage div {
  display: flex;
  gap: 12px;
}

.modal-patent-manage button {
  padding: 6px 12px;
  border: none;
  border-radius: 8px;
  background-color: #4a90e2;
  color: white;
}

.modal-inventor-search {
  padding: 30px;
  width: 850px;
}
.modal-signup-terms {
  padding: 30px;
}
