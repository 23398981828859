#MailTypePop {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 330px;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  border-radius: 15px;

  .title {
    background-color: #00c6be;
    color: #fff;
    text-align: center;
    padding: 14px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 22px;

    i {
      position: absolute;
      right: 14px;
      top: 14px;
    }
  }

  .cont {
    padding: 30px;

    >ul>li {
      margin-bottom: 10px;

      input[type="radio"] {
        margin-right: 4px;
      }
    }
  }

  .btns {
    margin-top: 40px;
    text-align: right;

    button {
      width: 120px;
      text-align: center;
      padding: 8px 12px;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      border-radius: 10px;
      margin-left: 10px;
      font-size: 16px;
    }

    .bg {
      color: #fff;
      background-color: #00c6be;
    }
  }
}