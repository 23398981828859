@import "src/include";
#docTradeSampleRegisterIndividual{
	width: 880px;
	margin: 0 auto;
	.individual-register{
		&_table-section{
			margin-bottom: 40px;
			}
		&_table-top{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
			.title{
				height: 24px;
				line-height: 24px;
				width: calc(100% - 321px);
				font-family: NanumSquareOTF_acB;
				font-size: 14px;
				letter-spacing: -.25px;
				}
			.attention-wrap{
				display: flex;
				justify-content: start;
				align-items: center;
				width: 321px;
				height: 32px;
				position: relative;

                .btn-attention{
					display: flex;
					justify-content: center;
					align-items: center;
                    width: calc(100% - 132px);
                    height: 100%;
					line-height: 32px;
					background-color: transparent;
                }

				.icon_attention{
					width: 24px;
					height: 24px;
					background-size: cover;
				}
				.attention{
					width: 155px;
					height: 32px;
					line-height: 32px;
					font-size: 12px;
					color: #606060;
					text-align: center;
				}
				.sampleFileAdd{
					width: 132px;
					height: 32px;
					line-height: 32px;
					text-align: center;
					font-size: 16px;
					border-radius: 4px;
					background-color: var(--color-Web-Orange);
					color: #fff;
				}
			}
		}
		&_table{
			margin-bottom: 5px;
			tr th:first-child, tr td:first-child{
				padding-left: 50px;
				text-align-last: left;
			}
			tr td{
				height: 50px;
				font-family: NanumSquareOTF_acB;
				a{
					display: inline-block;
					width: 24px;
					height: 24px;
				}
				&:last-child{
					position: relative;
					.icon_badge_del{
						position: absolute;
						display: inline-block;
						width: 24px;
						height: 24px;
						top: 50%;
						left: 50%;
						transform: translateX(-50%) translateY(-50%);
					}
				}
			}
		}
		&_table-attention-wrap{
			text-align: right;
			height: 24px;
		}
		&_table-attention{
			display: inline-block;
			height: 24px;

            button{
				display: flex;
				justify-content: end;
				align-items: center;
                width: 100%;
                height: 100%;
				background-color: #ffffff;
            }
			.icon_attention{
				width: 24px;
				height: 24px;
				margin-right: 2px;
			}
			.text{
				height: 100%;
				line-height: 24px;
				font-size: 12px;
				color: #606060;
				margin-right: 10px;
			}
		}
		&_information-section{
			margin-bottom: 20px;
		}
		&_information-top{
			margin-bottom: 16.5px;
			.title{
				height: 24px;
				line-height: 24px;
				font-family: NanumSquareOTF_acB;
				font-size: 14px;
				letter-spacing: -.25px;
			}
		}
		&_information{
			display: flex;
			.information{
				width: 100%;
				height: 143px;
				tr{
					height: calc(100% / 3);
				}
				th{
					font-family: NanumSquareOTF_acB;
					font-size: 14px;
					text-align: left;
					letter-spacing: -.25px;
					border-bottom: 1px solid #e7e7e7;
					padding-left: 5px;
				}
				td{
					border-bottom: 1px solid #e7e7e7;
					padding: 0 9px;
					.informationTitle{
						width: 100%;
						height: 30px;
						padding: 0 9px;
						font-size: 14px;
						color: #343434;
						border: .5px solid #d8d8d8;
						background-color: transparent;
					}
					&.borderNone{
						border-bottom: none;
					}
					.dataClassSel{
						width: 100%;
						height: 30px;
						font-size: 14px;
						color: rgba(0, 0, 0, 0.87);
						border: .5px solid #d8d8d8;
						padding-left: 9px;
					}
					.payment-input:checked{
						& ~ .paymentLabel{
							&::before{
								background: url(../../../assets/images/common/icon-radio-checked.svg) no-repeat 50% 50%;
								background-size: cover;
							}
						}
						& ~ .priceNumInput {
							opacity: 1;
							pointer-events: auto;
						}
					}
					.paymentLabel{
						position: relative;
						padding-left: 30px;
						cursor: pointer;
						&::before{
							position: absolute;
							content: '';
							width: 24px;
							height: 24px;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
							background: url(../../../assets/images/common/icon-radio.svg) no-repeat 50% 50%;
							background-size: cover;
						}
						span{
							font-size: 14px;
							color: rgba(0, 0, 0, 0.87);
							em{
								font-size: 12px;
								color: #606060;
							}
						}
					}
					.priceNumInput{
						width: 210px;
						height: 30px;
						margin-left: 16px;
						padding: 0 9px;
						font-size: 14px;
						color: #343434;
						border: 0.5px solid #d8d8d8;
						background-color: transparent;
						opacity: 0.2;
						pointer-events: none;
					}
				}
			}
		}
		&_introduce-section{
			margin-bottom: 45.5px;
		}
		&_introduce-top{
			display: flex;
			justify-content: start;
			align-items: center;
			height: 24px;
			line-height: 24px;
			margin-bottom: 12px;
			.title{
				height: 24px;
				font-family: NanumSquareOTF_acB;
				font-size: 14px;
				letter-spacing: -.25px;
				padding-right: 25px;
			}
			.text{
				height: 24px;
				line-height: 24px;
				font-size: 12px;
				color: #343434;
				letter-spacing: 0;
			}
		}
		&_introduce-textarea{
			width: 100%;
			height: 246px;
			resize: none;
			padding: 16.5px 15px;
			font-size: 14px;
			color: #343434;
			border: .5px solid #d8d8d8;
			margin-bottom: 25px;
			& + .attention{
				font-size: 14px;
				color: #606060;
				padding-left: 15px;
			}
		}
	}
}