#MobileAddPopup {
    position: fixed;
    top: 0;
    left: 0;
    padding: 18px 5% 0 5%;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    z-index: 1000;

    .icon_exit {
        margin-left: auto;
        margin-bottom: 18px;
        display: block;
    }

    .page_title {
        margin-bottom: 32px;
        font-size: 20px;
        font-family: NanumSquareOTF_acEB;
        line-height: 26px;
        color: #443018;
    }

    .list {
        border-radius: 4px;
        border: solid 2px #d8d8d8;

        .list_header {
            padding: 0 16px;
            display: flex;
            align-items: center;
            height: 44px;

            p {
                margin-left: 16px;
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
                line-height: 20px;
                color: #443018;
            }
        }

        .list_content {
            padding: 0 16px;
            max-height: 300px;
            overflow-x: hidden;
            overflow-y: scroll;
            background-color: #f8f8f8;

            li {
                padding: 8px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-height: 50px;

                p {
                    font-size: 12px;
                    font-family: NanumSquareOTF_acB;
                    line-height: 18px;
                    color: #464646;
                }

                > p.state {
                    width: 10%;
                }

                div {
                    margin-left: 16px;
                    padding-right: 8px;
                    width: 90%;
                    word-break: break-all;

                    h2 {
                        font-size: 12px;
                        font-family: NanumSquareOTF_acB;
                        line-height: 18px;
                        color: #464646;
                    }

                    p {
                        font-size: 12px;
                        line-height: 18px;
                        color: #959595;
                    }
                }
            }

            li+li {
                border-top: 1px solid #d8d8d8;
            }
        }
    }

    .btn {
        margin-top: 50px;
    }
}
