/* design */
$mainColor-1: #f0ab00;
$mainColor-2: #c7e5f6;
$mainColor-3: #FF9946;
$fontColor-1: #575756;
$fontColor-2: #57330e;
$fontColor-3: #6c6c6c;
$fontColor-4: #242424;
$fontColor-5: #3f3f3f;
$fontColor-6: #057aba;
$fontColor-7: #1e344f;
$bgColor-1: rgba(81, 188, 255, 0.05);
$bgColor-2: rgba(253, 236, 203, 0.5);

#AdminMailingTemplate {
    width: 100%;
    max-width: 630px;
    font: 14px/1.4 NanumSquareOTF_acR, Malgun Gothic, Arial, sans-serif;
    color: #000;

    /* common */
    .box-style {
        position: relative;
        background-color: #f7f7f7;
        border-radius: 20px;
        padding: 46px 20px 0;
        &::after {
            position: absolute;
            content: "";
            width: 62px;
            height: 12px;
            background: url(../../../assets/images/mailling/ico_box_1.svg) no-repeat 50% 50%;
            background-size: cover;
            top: 20px;
            left: 24px;
        }
    }

    .mail {
		&.ipnowPage{
			.total-only{
				display: none;
			}
			.ipnow-only{
				display: block;
			}
		}
        &_section-first {
            position: relative;
            &::after {
                position: absolute;
                content: "";
                width: 100%;
                height: 500px;
                top: 0;
                left: 0;
                background-color: $bgColor-2;
                z-index: -1;
            }
            &_top {
                position: absolute;
                width: 220px;
                height: 32px;
                line-height: 32px;
                border-radius: 16px;
                top: 20px;
                right: 30px;
                background-color: #f68002;
                color: #fff;
                font-family: NanumSquareOTF_acEB;
                font-size: 17.5px;
                text-align: center;
                padding-top: 2px;
            }
            &_sub-title {
                display: block;
                padding-top: 88px;
                text-align: center;
                font-family: NanumSquareOTF_acB;
                font-size: 20px;
                color: $fontColor-2;
            }
            &_title {
                margin: 0 auto 10px;
                padding: 40px 40px 0;
                text-align: center;
                font-family: NanumSquareOTF_acB;
                font-size: 27px;
                color: $fontColor-1;
                line-height: 1.2;
                .style-font {
                    &_b {
                        font-family: NanumSquareOTF_acB;
                        font-size: 34px;
                        color: $mainColor-1;
                    }
                    &_r {
                        font-size: 25px;
                    }
                }
            }
            &_text {
                font-family: NanumSquareOTF_acB;
                font-size: 14px;
                color: #626261;
				text-align: left;
                margin-bottom: 20px;
                padding: 0 32px;
				em{
					font-size: 14px;
				}
				&.text-center{
					text-align: center;
				}
				.style{
					color: $mainColor-3;
				}
				.ipnow-only{
					display: none;
				}
            }
            &_box-first {
                width: 566px;
                margin: 0 auto 24px;
                padding-bottom: 10px;
                &_title {
                    font-family: NanumSquareOTF_acB;
                    font-size: 23px;
                    color: $fontColor-2;
                    margin-bottom: 6px;
					max-height: 64.38px;
                    overflow: hidden;
					text-overflow: ellipsis;
					white-space: initial;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					word-break: keep-all;
                }
                &_sub-title {
                    font-family: NanumSquareOTF_acB;
                    font-size: 14px;
                    color: $fontColor-3;
                    text-align: right;
                    padding-bottom: 6px;
                }
                &_img-wrap {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    width: 505px;
                    margin: 0 auto 6px;
                    .mail_section-first_box-first_img {
                        position: relative;
                        display: block;
                        width: 50%;
                        em {
                            position: absolute;
                        }
                        &:nth-of-type(1) {
                            width: calc(50% - 8px);
                            margin-left: 3px;
                            margin-bottom: 8px;
                            .total {
                                top: 106px;
                                left: 81px;
                                font-family: NanumSquareOTF_acEB;
                                font-size: 20px;
                                color: #fff;
                                transform: translateX(-50%);
                            }
                            .register {
                                top: 81px;
                                right: 23px;
                                font-family: NanumSquareOTF_acEB;
                                font-size: 12px;
                                color: #ff9a2a;
                            }
                            .apply {
                                top: 113px;
                                right: 23px;
                                font-family: NanumSquareOTF_acEB;
                                font-size: 11px;
                                color: #ff9a2a;
                            }
                            .renounce {
                                top: 143px;
                                right: 23px;
                                font-family: NanumSquareOTF_acEB;
                                font-size: 11px;
                                color: #ffffff;
                            }
                        }
                        &:nth-of-type(2) {
                            width: calc(50% - 8px);
                            margin-right: 3px;
                            margin-bottom: 8px;
                            .total {
                                top: 106px;
                                left: 81px;
                                font-family: NanumSquareOTF_acEB;
                                font-size: 20px;
                                color: #fff;
                                transform: translateX(-50%);
                            }
                            .register {
                                top: 81px;
                                right: 23px;
                                font-family: NanumSquareOTF_acEB;
                                font-size: 12px;
                                color: #ff9a2a;
                            }
                            .apply {
                                top: 113px;
                                right: 23px;
                                font-family: NanumSquareOTF_acEB;
                                font-size: 11px;
                                color: #ff9a2a;
                            }
                            .renounce {
                                top: 143px;
                                right: 23px;
                                font-family: NanumSquareOTF_acEB;
                                font-size: 11px;
                                color: #ffffff;
                            }
                        }
                        &:nth-of-type(3) {
                            width: calc(50% - 2.5px);
                            font-family: NanumSquareOTF_acB;
                            color: #000;
                            font-size: 11px;
                            .year {
                                &_1 {
                                    left: 41px;
                                    bottom: 20px;
                                    transform: translateX(-50%);
                                }
                                &_2 {
                                    left: 98px;
                                    bottom: 20px;
                                    transform: translateX(-50%);
                                }
                                &_3 {
                                    left: 155px;
                                    bottom: 20px;
                                    transform: translateX(-50%);
                                }
                                &_4 {
                                    left: 214px;
                                    bottom: 20px;
                                    transform: translateX(-50%);
                                }
                            }
                            .val {
                                &_1 {
                                    left: 41px;
                                    bottom: 52px;
                                    transform: translateX(-50%);
                                }
                                &_2 {
                                    left: 98px;
                                    bottom: 105px;
                                    transform: translateX(-50%);
                                }
                                &_3 {
                                    left: 155px;
                                    bottom: 151px;
                                    transform: translateX(-50%);
                                }
                                &_4 {
                                    left: 214px;
                                    bottom: 112px;
                                    transform: translateX(-50%);
                                    color: #fff;
                                }
                            }
                        }
                        &:nth-of-type(4) {
                            width: calc(50% - 2.5px);
                            font-family: NanumSquareOTF_acB;
                            color: #000;
                            font-size: 12px;
                            .year {
                                &_1 {
                                    left: 41px;
                                    bottom: 20px;
                                    transform: translateX(-50%);
                                }
                                &_2 {
                                    left: 85px;
                                    bottom: 20px;
                                    transform: translateX(-50%);
                                }
                                &_3 {
                                    left: 129px;
                                    bottom: 20px;
                                    transform: translateX(-50%);
                                }
                                &_4 {
                                    left: 173px;
                                    bottom: 20px;
                                    transform: translateX(-50%);
                                }
                                &_5 {
                                    left: 217px;
                                    bottom: 20px;
                                    transform: translateX(-50%);
                                }
                            }
                            .val {
                                &_1 {
                                    &_1 {
                                        left: 41px;
                                        bottom: 147px;
                                        transform: translateX(-50%);
                                    }
                                    &_2 {
                                        left: 41px;
                                        bottom: 89px;
                                        transform: translateX(-50%);
                                    }
                                }
                                &_2 {
                                    &_1 {
                                        left: 85px;
                                        bottom: 71px;
                                        transform: translateX(-50%);
                                    }
                                    &_2 {
                                        left: 85px;
                                        bottom: 41px;
                                        transform: translateX(-50%);
                                    }
                                }
                                &_3 {
                                    &_1 {
                                        left: 129px;
                                        bottom: 62px;
                                        transform: translateX(-50%);
                                    }
                                    &_2 {
                                        left: 129px;
                                        bottom: 34px;
                                        transform: translateX(-50%);
                                    }
                                }
                                &_4 {
                                    &_1 {
                                        left: 173px;
                                        bottom: 107px;
                                        transform: translateX(-50%);
                                    }
                                    &_2 {
                                        left: 173px;
                                        bottom: 61px;
                                        transform: translateX(-50%);
                                    }
                                }
                                &_5 {
                                    &_1 {
                                        left: 217px;
                                        bottom: 137px;
                                        transform: translateX(-50%);
                                    }
                                    &_2 {
                                        left: 217px;
                                        bottom: 78px;
                                        transform: translateX(-50%);
                                    }
                                }
                            }
                        }
                    }
                }
                &_subtext {
                    width: 505px;
                    margin: 0 auto;
                    text-align: right;
                    font-family: NanumSquareOTF_acB;
                    font-size: 14px;
                    color: $fontColor-3;
                }
            }
            &_box-second {
                width: 566px;
                height: 375px;
                margin: 0 auto 30px;
                &_title {
                    font-family: NanumSquareOTF_acB;
                    font-size: 23px;
                    color: $fontColor-2;
                    height: 34px;
                    line-height: 34px;
                    text-align: center;
                    margin-bottom: 14px;
                    .style-font {
                        position: relative;
                        font-family: NanumSquareOTF_acB;
                        font-size: 18px;
                        color: $fontColor-4;
                    }
                    em[class^="ico"] {
                        position: relative;
                        display: inline-block;
                        width: 34px;
                        height: 34px;
                        border-radius: 50%;
                        font-size: 14px;
                        margin-left: 5px;
                        bottom: 2px;
                    }
                    .ico {
                        &_ss {
                            background-color: #00bfdb;
                            color: #fff;
                        }
                        &_s {
                            background-color: #0076f3;
                            color: #fff;
                        }
                        &_a {
                            background-color: #4b85d5;
                            color: #fff;
                        }
                        &_b {
                            background-color: #ffc553;
                            color: #000;
                        }
                        &_c {
                            background-color: #ff9946;
                            color: #000;
                        }
                        &_d {
                            background-color: #ff5552;
                            color: #fff;
                        }
                        &_e {
                            background-color: #000;
                            color: #fff;
                        }
                        &_n {
                            background-color: #fff;
                            color: #878787;
                            border: 1px solid #878787;
                        }
                    }
                }
                &_img {
                    width: 500px;
                    margin: 0 auto 10px;
                    img {
                        width: 100%;
                    }
                }
                &_subtext {
                    width: 500px;
                    margin: 0 auto;
                    text-align: right;
                    font-family: NanumSquareOTF_acB;
                    font-size: 14px;
                    color: $fontColor-3;
                }
            }
            &_text-box {
                &_text {
                    font-family: NanumSquareOTF_acB;
                    font-size: 25px;
                    color: $fontColor-1;
                    text-align: center;
                    margin-bottom: 29px;
                }
                &_btn {
                    display: block;
                    width: 304px;
                    height: 72px;
                    background: url(../../../assets/images/mailling/btn_img_1.png) no-repeat 50% 50%;
                    margin: 0 auto;
                }
            }
        }
        &_section_second {
            position: relative;
            margin-top: 34px;
            &::after {
                position: absolute;
                content: "";
                width: 100%;
                height: 378px;
                top: 0;
                left: 0;
                background-color: #fff4e3;
                z-index: -2;
            }
            &_text {
                padding: 42px 33px 0;
                margin: 0 auto 30px;
            }
            &_sub-title {
                font-family: NanumSquareOTF_acB;
                font-size: 20px;
                color: $fontColor-1;
                text-align: center;
                margin-bottom: 16px;
            }
            &_title {
                font-family: NanumSquareOTF_acEB;
                font-size: 52px;
                color: $fontColor-2;
                text-align: center;
                margin-bottom: 16px;
                .dot {
                    position: relative;
                    color: $mainColor-1;
                    &::after {
                        position: absolute;
                        content: "";
                        width: 8px;
                        height: 8px;
                        top: -10px;
                        left: 50%;
                        transform: translateX(-50%);
                        border-radius: 50%;
                        background-color: $mainColor-1;
                    }
                }
                &_logo {
                    display: inline-block;
                    width: 155px;
                }
            }
            &_box-title {
                display: block;
                width: 550px;
                height: 33px;
                line-height: 33px;
                border-radius: 5px;
                margin: 0 auto;
                font-family: SCDream4;
                font-size: 21px;
                color: $fontColor-2;
                text-align: center;
                .style-font {
                    font-family: SCDream6;
                    font-size: 24px;
                }
            }
            &_box {
                position: relative;
                width: 490px;
                height: 276px;
                margin: 0 auto;
                &::before {
                    position: absolute;
                    content: "";
                    width: 167px;
                    height: 234px;
                    bottom: -50px;
                    right: -70px;
                    background: url(../../../assets/images/mailling/bg_img_1.svg) no-repeat 50% 50%;
                    background-size: cover;
                }
                &_title {
                    font-family: SCDream6;
                    font-size: 40px;
                    color: $fontColor-2;
                    letter-spacing: -5px;
                    text-align: center;
                    margin-bottom: 16px;
                    .style-font {
                        position: relative;
                        top: 5px;
                        display: inline-block;
                        width: 211px;
                        height: 39px;
                        background: url(../../../assets/images/mailling/text_img_1.png) no-repeat 50% 50%;
                        background-size: cover;
                    }
                }
                &_list-wrap {
                    display: block;
                    width: 427px;
                    height: 134px;
                    background-color: #fff;
                    margin: 0 auto;
                    padding: 15px 11px;
                }
                &_list {
                    margin-bottom: 10px;
                    color: $fontColor-5;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    .style-font {
                        &_smallb {
                            font-family: NanumSquareOTF_acB;
                            font-size: 10px;
                        }
                        &_b {
                            font-family: NanumSquareOTF_acB;
                            font-size: 12px;
                        }
                        &_eb {
                            font-family: NanumSquareOTF_acEB;
                        }
                    }
                }
            }
            &_list-wrap {
                position: relative;
                width: 100%;
                padding: 32px 40px 20px;
            }
            &_list {
                position: relative;
                font-family: NanumSquareOTF_acB;
                font-size: 16px;
                color: $fontColor-3;
                margin-bottom: 10px;
                padding-left: 25px;
                &::after {
                    position: absolute;
                    content: "";
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    background-color: #82c7b3;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
                .style-font {
                    font-family: NanumSquareOTF_acEB;
                    font-size: 18px;
                    color: $fontColor-5;
                }
            }
        }
        &_section_third {
            &_title {
                &_text {
                    font-family: NanumSquareOTF_acEB;
                    font-size: 33px;
                    color: $fontColor-1;
                    text-align: center;
                    padding: 30px 0 30px;
                    background-color: rgba(255, 233, 199, 0.4);
                    margin-bottom: 30px;
                    .style-font {
                        &_1 {
                            font-size: 43px;
                            color: $mainColor-1;
                        }
                        &_2 {
                            font-size: 33px;
                            color: $fontColor-7;
                        }
                    }
                }
                &_title-wrap {
                    position: relative;
                    text-align: center;
                    &::before {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 799px;
                        background: url(../../../assets/images/mailling/bg_img_3.svg) no-repeat 50% 50%;
                        background-size: cover;
                        bottom: -18px;
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: -1;
                    }
                }
                &_img {
                    display: block;
                    width: 250px;
                    margin: 0 auto 16px;
                }
                &_subtitle {
                    font-family: NanumSquareOTF_acB;
                    font-size: 34px;
                    color: $fontColor-1;
                }
                &_humImg{
                    position: relative;
                    top: 27px;
                }
            }
            &_content {
                width: 100%;
                height: 968px;
                background: url(../../../assets/images/mailling/bg_img_4.svg) no-repeat 50% 50%;
                background-size: cover;
                margin-top: 18px;
                padding: 30px 50px 0;
                &_title {
                    font-family: NanumSquareOTF_acB;
                    font-size: 25px;
                    color: $fontColor-1;
                    text-align: center;
                    > .style-font {
                        display: block;
                        font-family: SCDream6;
                        font-size: 29px;
                        color: $fontColor-6;
                    }
                    &_link {
                        display: block;
                        width: 360px;
                        height: 60px;
                        line-height: 60px;
                        border-radius: 30px;
                        background-color: $mainColor-2;
                        color: $fontColor-5;
                        margin: 10px auto 30px;
                        font-family: NanumSquareOTF_acR;
						font-size: 23px;
                        > .style-font {
                            font-family: NanumSquareOTF_acEB;
                            color: $fontColor-6;
                        }
                    }
                }
                &_img {
                    width: 100%;
                    margin-bottom: 30px;
                    img {
                        width: 100%;
                    }
                }
                &_text {
                    &_sub {
                        font-family: NanumSquareOTF_acB;
                        font-size: 14px;
                        color: $fontColor-3;
                        text-align: center;
                        margin-bottom: 10px;
                        .style-font {
                            font-family: NanumSquareOTF_acEB;
                            font-size: 17px;
                            color: $fontColor-7;
                        }
                    }
                    &_title {
                        font-family: NanumSquareOTF_acB;
                        font-size: 17px;
                        color: $fontColor-3;
                        text-align: center;
                        margin-bottom: 20px;
                        .style-font {
                            font-family: NanumSquareOTF_acEB;
                            font-size: 20px;
                            color: $fontColor-7;
                        }
                    }
                }
                &_box-list {
                    position: relative;
                    width: 517px;
                    height: 147px;
                    margin: 0 auto;
                    background-color: #fff;
                    border-radius: 29px;
                    &_img {
                        position: absolute;
                        width: 458px;
						height: 79px;
                        margin: 0 auto;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        img {
                            width: 100%;
							height: 100%;
							object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}