.ManageUserTable {
  position: relative;

  .company_code {
    position: absolute;
    top: -10px;
    right: 0;
    display: flex;
    gap: 8px;
    align-items: center;
    z-index: 2;

    h2 {
      margin-right: 10px;
      font-size: 14px;
      font-family: NanumSquareOTF_acB;
    }

    input {
      padding: 0 8px;
      width: 200px;
      height: 40px;
      font-size: 12px;
      border: solid 1px rgba(0, 0, 0, 0.15);
      background-color: #ffffff;
    }

    button {
      width: 80px;
      height: 40px;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      background-color: #4593f5;
    }
  }

  .code {
    position: absolute;
    opacity: 0;
  }

  .btn_link {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 32px;
    color: #ffffff;
    background-color: #e92f2c;
    cursor: pointer;
    z-index: 2;
  }

  .btn_control {
    position: absolute;
    top: 0;
    right: 0;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px !important;
      height: 32px !important;
      z-index: 1000;
    }
  }

  .title {
    position: relative;
    display: inline-block;
    font-size: 22px;
    font-family: NanumSquareOTF_acEB;
  }

  .require {
    position: absolute;
    top: 0;
    left: 70px;
    padding: 0 12px;
    min-width: 106px;
    line-height: 24px;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    border-radius: 16px;
    background-color: #00ae02;
  }

  table {
    margin-top: 12px;
    width: 100%;

    tbody {
      tr {
        height: 52px;
        td {
          input {
            padding: 0 16px;
            width: 80%;
            height: 45px;
            font-size: 12px;
            border: solid 1px rgba(0, 0, 0, 0.56);
          }

          select {
            padding: 0 16px;
            width: 80%;
            height: 45px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .not_styled {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 880px;
    width: 880px;
    height: 62px;
    border-bottom: 1px solid #e7e7e7;
  }

  .btns {
    width: 10%;

    button {
      width: 24px;
      height: 24px;
      vertical-align: middle;
    }

    button:nth-child(1) {
      margin-right: 10px;
    }
  }
}
