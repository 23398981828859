#PopupUpDownload {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 35px 45px;
    width: 780px;
    max-height: 735px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    z-index: 9;

    .icon_exit {
        position: absolute;
        top: 35px;
        right: 45px;
    }

    > div {

        .wrap_table::-webkit-scrollbar {
            display: none;
        }

        .wrap_table {
            overflow-x: hidden;
            overflow-y: scroll;
            min-height: 150px;
            max-height: 260px;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            .btn_delete {
                color: var(--color-Alizarin-Crimson);
                background-color: transparent;
            }
        }
    }
    .header {
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 22px;
            font-family: NanumSquareOTF_acB;
            color: #343434;
        }

        .btns {
            display: flex;
            align-items: center;

            .btn_attach {
                margin-left: 8px;
                margin-right: 40px;
                background-color: #96c719;
            }
        }
    }

    .btn_upload, .btn_attach {
        width: 120px;
        height: 32px;
        font-size: 16px;
        color: white;
        background-color: var(--color-Alizarin-Crimson);
    }

    .btn_upload {
        margin: 50px auto 0 auto;
        display: block;
    }
}
