#PopupPatentApprovalApply {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-black-opacity-05);
  z-index: 3;

  .popup {
    padding: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 650px;
    min-height: 300px;
    border: 1px solid var(--color-Alto);
    background-color: #ffffff;
  }

  .head {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 20px;
      font-family: NanumSquareOTF_acEB;
    }
  }

  .wrap_table {
    margin-top: 30px;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  table {
    td {
      font-size: 16px;
    }
  }

  .add_approval_tale {
    caption {
      margin-bottom: 20px;
      font-size: 16px;
      font-family: NanumSquareOTF_acB;
    }

    td {
      word-break: keep-all;
      border: 1px solid var(--color-Alto);
    }
  }

  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;

    .btn_instruction {
      background-color: var(--color-Niagara);
    }

    .btn_delete {
      background-color: var(--color-Alizarin-Crimson);
    }

    .btn_instruction,
    .btn_delete {
      width: 121px;
      height: 30px;
      font-family: NanumSquareOTF_acB;
      color: #ffffff;
    }

    .btn_success {
      color: var(--color-Cornflower-Blue);
    }

    .btn_fail {
      color: var(--color-Red);
    }

    .btn_success,
    .btn_fail {
      font-family: NanumSquareOTF_acB;
      background-color: transparent;
    }
  }
}