#IntroChartContainer {
    @import "../../../include";

    .chartform-wrap {
        display: flex;
        flex-wrap: wrap;
        max-width: 980px;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        column-gap: 40px;
        row-gap: 32px;

        .chartform {
            position: relative;
            width: 100%;
            max-width: 465px;
            height: 320px;
            background-color: #fff;

            &_title {
                margin-bottom: 30px;
                font-size: 22px;
                font-family: NanumSquareOTF_acB;
                color: #343434;
            }

            &_btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 87px;
                height: 30px;
                font-size: 12px;
                font-family: NanumSquareOTF_acB;
                color: #606060;
                border-radius: 9px;
                background-color: #eff3fb;
            }

            .legend {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: baseline;
                flex-direction: column;
                row-gap: 8px;
                font-size: 12px;
                font-family: NanumSquareOTF_acB;
                line-height: 18px;
                color: #606060;
                pointer-events: none;

                .legend_data {
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                }

                .circle {
                    width: 10px;
                    height: 10px;
                    @include border-radius(8px);
                }

                .total {
                    background-color: #83cfff;
                }

                .organized {
                    background-color: #2d99ff;
                }
            }

            &.doughnut {
                .legend {
                    top: 80px;
                    left: 0;
                }

                .total_amount {
                    position: absolute;
                    top: 45%;
                    right: 0;
                    width: 280px;
                    text-align: center;

                    h2 {
                        width: 100%;
                        font-size: 16px;
                        font-family: NanumSquareOTF_acEB;
                        line-height: 26px;
                        color: #443018;
                        pointer-events: none;
                    }

                    p {
                        font-size: 12px;
                        font-family: NanumSquareOTF_acB;
                        line-height: 20px;
                        color: #606060;
                    }
                }

                canvas {
                    margin-left: auto;
                }
            }
        }
    }
}