#RelativeFileListView {
  margin: auto;
  padding: 30px 0px;
  width: 880px;

  .header {
    margin-bottom: 30px;

    .title {
      margin-bottom: 13px;
      font-size: 22px;
    }

    .patent_info {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);
      word-break: break-word;

      .flag {
        margin-left: 10px;
      }
    }
  }

  table {
    th:nth-child(2),
    tr:nth-child(2) {
      width: 10%;
    }

    th:nth-child(3),
    tr:nth-child(3) {
      width: 20%;
    }
  }

  #RelativeFileList + #RelativeFileList {
    margin-top: 70.5px;
  }
}
