#CandidatePop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 어두운 배경 */
  z-index: 999; /* 다른 요소 위에 배치 */
  .patent_content {
    z-index: 999;
    width: 1400px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    border-radius: 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: start;
    .patent_name {
      font-size: 20px !important;
      font-weight: bolder !important;
    }
    .left {
      width: 50%;
      float: left;
      padding-top: 30px;

      .category {
        display: flex;
        gap: 10px;
        button:not(.link) {
          padding: 8px 12px;
          border-radius: 20px;
          border: 1px solid #4e7ce9;
          background: transparent;
          color: #4e7ce9;
          margin-right: 6px;
          &.on {
            background: #4e7ce9;
            color: #fff;
          }
        }
      }
    }
    .right {
      width: 50%;
      float: right;
      padding-top: 30px;

      .right_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }

    .left_content {
      padding: 16px 0px;
      font-size: 14px; // 일반적인 본문 크기 (보통 14px~16px)
      line-height: 1.6; // 줄 간격 (행간) – 가독성 확보
      color: #333; // 검정보다 약간 연한 계열 (너무 진하면 피로감)
      white-space: pre-line; // 개행 문자(\n) 있으면 반영, 없으면 무시
      word-break: keep-all; // 한국어 단어 중간에 줄바꿈 방지
      margin-top: 12px;
      border-top: solid 1px #d8d8d8;
    }

    button {
      &.mypage_button {
        padding: 6px 14px;
        border-radius: 6px;
        background: #4e7ce9;
        color: #fff;
        margin-right: 6px;
        margin-bottom: 10px;
      }
    }

    #pagination {
      .item {
        &.end,
        &.start {
          width: 36px;
        }
        &:hover {
          background-color: #4e7ce9;
          border-radius: 100%;
        }
        .link {
          border: none;
          background-color: transparent;
        }
        &.on {
          .link {
            background-color: #4e7ce9;
            border-radius: 100%;
          }
        }
      }
    }

    button {
      background-color: transparent;
    }

    strong {
      display: block;
    }
    .close {
      position: absolute;
      right: 30px;
      top: 30px;
      background: transparent;
    }
    .more {
      background: transparent;
      color: #0078f1;
      border: 1px solid #0078f1;
      padding: 4px 12px;
      border-radius: 20px;
    }
    .tbl_box {
      max-height: none;
      tbody {
        > tr {
          &:hover {
            cursor: pointer;
            background-color: rgba(107, 211, 255, 0.2);
          }
        }
      }
    }
    .grade {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 22px;
      border-radius: 8px;
    }
  }
}
