@import "src/include";

#PatentView {
  margin: 30px auto;
  width: 80%;
  min-width: 1300px;

  @import "../common/css/ListView";

  .border_blue {
    border-bottom: solid 1px var(--color-Niagara);
  }

  .circle {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 24px;
    border-radius: 16px;
    pointer-events: none;
  }
}
