#BillingHeader {
  border-bottom: 1px solid #343434;
   > * {
    padding: 10px 0;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 20px;
      span {
        font-size: 16px;
        color: grey;
        margin-left: 5px;
      }
    }
  }
}
