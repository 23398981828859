@import "src/include";

#GradeCompany {
    $green: #80ceb7;
    $blue: #5b90df;

    .pc {
        .header {
            margin: 41px auto 53px auto;
            position: relative;
            width: 880px;

            h2 {
                margin-bottom: 10px;
                font-size: 24px;
                font-family: NanumSquareOTF_acEB;
            }

            .area_box {
                padding: 13px 12px;
                width: 100%;
                max-width: 880px;
                height: 130px;
                @include border-radius(20px);
                background-color: rgba(241, 241, 241, 0.3);

                img {
                    width: 100%;
                }
            }

            ul {
                margin-top: 20px;
                display: flex;
                align-items: center;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 120px;
                    height: 36px;
                    font-size: 16px;
                    line-height: 16px;
                    @include border-radius(5px);
                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
                    background-color: #ffffff;
                    cursor: pointer;

                    .circle {
                        margin-right: 10px;
                    }
                }

                li:nth-child(1).active {
                    border: 3px solid var(--color-Web-Orange);
                }

                li:nth-child(2).active {
                    border: 3px solid $green;
                }

                li:nth-child(3).active {
                    border: 3px solid $blue;
                }

                li+li {
                    margin-left: 10px;
                }
            }

            .cur_grade {
                position: absolute;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;

                p {
                    margin-left: 10px;
                    font-size: 16px;
                    color: rgba(0,0,0,0.87);
                }
            }
        }

        .chart {
            margin: auto;
            position: relative;
            width: 880px;
            height: 263px;
            text-align: center;

            .distribution {
                position: absolute;
                left: 0;
                bottom: 0;
            }

            .line {
                position: absolute;
                left: 0;
                bottom: 0;
            }

            .active_chart {
                position: absolute;
                left: 0;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: baseline;
                width: 880px;

                img {
                    opacity: 0;
                }

                img+img {
                    margin-left: 20px;
                }
            }

            .wrap_circle {
                position: absolute;
                left: 0;
                bottom: 0;
                display: flex;
                align-items: baseline;
                width: 880px;

                .circle {
                    opacity: 0;
                }

                .circle+.circle {
                    margin-left: 42.5px;
                }
            }

            .wrap_circle.orange {
                bottom: 10%;
            }

            .wrap_circle.green {
                bottom: 20%;
            }

        }

        .chart:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -15px;
            width: 100%;
            height: 2px;
            border-bottom: 2px dashed #e7e7e7;
        }

        .table_grade {
            margin: 38px auto 0 auto;
            display: flex;
            align-items: center;
            width: 1035px;

            .data {
                position: relative;
                min-width: 35px;
                opacity: 0.3;

                h2 {
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    line-height: 34px;
                    color: rgba(0, 0, 0, 0.87);
                    text-align: center;
                }

                p {
                    margin-top: 5px;
                    font-size: 14px;
                    line-height: 27px;
                    color: rgba(0, 0, 0, 0.87);
                    text-align: center;
                }

                div[class^="grade"] {
                    margin: auto;
                }
            }

            .data.active {

                .border_left_circle {
                    position: absolute;
                    top: -28px;
                    left: -5px;
                    width: 7px;
                    height: 7px;
                    @include border-radius(50%);
                    background-color: var(--color-Web-Orange);
                }

                .border_right_circle {
                    position: absolute;
                    top: -28px;
                    right: -5px;
                    width: 7px;
                    height: 7px;
                    @include border-radius(50%);
                    background-color: var(--color-Web-Orange);
                }
            }

            .data.active:not(.not):before {
                content: "";
                position: absolute;
                top: -25px;
                width: 100%;
                height: 0;
            }

            .data:first-child {
                opacity: 1;
            }

            .data:first-child,
            .data:last-child {
                h2,
                p {
                    width: 50px;
                }
            }

            .data:nth-child(2) {
                margin-left: 10px;
            }

            .data+.data {
                margin-left: 19px;
            }
        }

        .table_grade.orange {

            .data.active:not(.not):before {
                border-bottom: 2px dashed var(--color-Web-Orange);
            }

            .border_left_circle,
            .border_right_circle {
                background-color: var(--color-Web-Orange) !important;
            }
        }

        .table_grade.green {

            .data.active:not(.not):before {
               border-bottom: 2px dashed $green;
            }

            .border_left_circle,
            .border_right_circle {
                background-color: $green !important;
            }
        }

        .table_grade.blue {

            .data.active:not(.not):before {
                border-bottom: 2px dashed $blue;
            }

            .border_left_circle,
            .border_right_circle {
                background-color: $blue !important;
            }
        }

        .wrap {
            margin: 46px auto 0 auto;
            width: 880px;

            h2 {
                margin-bottom: 6px;
                font-size: 22px;
                font-family: NanumSquareOTF_acB;
                line-height: 30px;
                color: #000000;
            }

            p {
                font-size: 14px;
                line-height: 16px;
            }

            .content {
                margin-top: 14px;
                padding: 15px;
                width: 880px;
                max-height: 272px;
                font-size: 14px;
                line-height: 19px;
                word-break: break-all;
                border: solid 1px #e7e7e7;
            }
        }

        div[class^="grade"] {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            font-size: 14px;
            @include border-radius(50%);
        }

        .circle {
            width: 12px;
            height: 12px;
            @include border-radius(50%);
        }

        .circle.orange {
            background-color: var(--color-Web-Orange);
        }

        .circle.blue {
            background-color: $blue;
        }

        .circle.green {
            background-color: $green;
        }

    }

    .mo {
        padding-top: 60px;
        background-color: #ffffff;
        z-index: 2;

        .header {
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 60px;
            background-color: #ffffff;
            z-index: 2;

            h2 {
                font-size: 16px;
                font-family: NanumSquareOTF_acEB;
                color: #443018;
            }

            button {
                position: absolute;
                left: 16px;
                background-size: contain;
            }
        }

        .wrap_head {
            padding: 20px 20px 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                font-size: 18px;
                font-family: NanumSquareOTF_acEB;
                line-height: 24px;
                color: #443018;
            }

            select {
                padding: 0 10px;
                width: 88px;
                height: 26px;
                font-size: 12px;
                font-family: NanumSquareOTF_acB;
                color: #747474;
                border-radius: 4px;
                border: 1px solid #d8d8d8
            }
        }

        canvas {
            margin: 20px auto;
        }

        .alert_msg.not_login {
            margin: 8px auto 0 auto;
            align-items: center;
        }

        .alert_msg {
            margin: 0 auto 60px auto;
            display: flex;
            width: 90%;

            i {
                margin-right: 6px;
            }

            p {
                font-size: 12px;
                font-family: NanumSquareOTF_acB;
                line-height: 18px;
                color: #959595;
            }
        }
    }
}
