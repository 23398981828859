#IpStatisticPage {
  max-width: 1600px;
  margin: 0 auto;
  padding: 40px 0;
  position: relative;
  .tab-titles {
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    right: 100%;
    top: 200px;
    z-index: 999;
    > button {
      width: 120px;
      height: 40px;
      text-align: center;
      color: #fff;
      margin-bottom: 16px;
      background-color: rgba(44, 89, 133, 0.6);
      &.active {
        background-color: rgba(44, 89, 133);
      }
    }
  }
  .tab-content {
    min-height: 300px;
    padding: 30px;
    background-color: #fafafa;
    border: 1px solid #d7d7d7;
  }
}
