#MarketInfoPopupDetail {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 35px;
    width: 80vw;
    height: 80vh;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    z-index: 999;
    overflow-x: hidden;
    overflow-y: scroll;

    .header {
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;

        h2 {
            width: 90%;
            font-size: 22px;
            font-family: NanumSquareOTF_acB;
            color: #343434;
        }
    }

    img {
        width: 100%;
        max-height: calc(80vh - 40px - 70px - 15px);
        object-fit: contain;
    }

    .content {
        font-size: 14px;
        line-height: 18px;
        color: #343434;
    }
}