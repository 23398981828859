#PatentMemo, #TradeMarkMemo {
    margin: auto;
    padding-top: 67px;
    width: 880px;

    .header {
        margin-bottom: 47px;
        position: relative;

        .title {
            margin-bottom: 13px;
            font-size: 22px;
        }

        .patent_info {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.87);
            word-break: break-word;

            .flag {
                margin-left: 10px;
            }
        }

        .btns {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;

            button {
                width: 64px;
                font-size: 16px;
                line-height: 32px;
                color: #ffffff;
                background-color: #96c719;
            }

            .btn_save {
                background-color: #19c77c;
            }

            button+button {
                margin-left: 8px;
            }
        }
    }

    .memo {

        p {
            margin-bottom: 12px;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            line-height: 24px;
        }

        textarea {
            padding: 27px 50px;
            width: 880px;
            height: 403px;
            font-size: 14px;
            line-height: 1.29;
            color: rgb(0,0,0,0.87);
            border: 1px solid #0095ae;
            resize: none;
        }

        .content {
            padding: 22px 0;
            font-size: 14px;
            color: rgba(0,0,0,0.87);
            border-top: 1px solid #e7e7e7;
            border-bottom: 1px solid #e7e7e7;
        }
    }
}
