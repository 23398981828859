$grayColor: #d9d9d9;

.checkpoint-form {
  width: 600px;
  min-height: 560px;
  padding: 26px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  font-weight: 700;
  font-size: 1.7rem;
  position: relative;

  & > h2 {
    font-size: 1.8rem;
    margin: 20px 0;
  }

  & > div {
    display: flex;
    justify-content: center;
    width: 80%;

    & > label {
      min-width: 120px;
    }

    &:last-child {
      justify-content: flex-end;
      margin-top: auto;
      button {
        background-color: transparent;
        font-size: 1.4rem;
      }
    }

    textarea {
      max-width: 300px;
      padding: 10px;
      resize: vertical;
    }
  }

  .close-btn {
    position: absolute;
    background-color: transparent;
    top: 16px;
    right: 16px;
  }

  .select-div {
    flex-grow: 1;
    max-width: 300px;
    background-color: $grayColor;

    & > button {
      background-color: $grayColor;
      width: 100%;
      color: black;
      border-radius: 0;
      font-size: 1.1rem;
    }

    & > ul {
      color: #000;
      width: auto;
      width: 100%;
      z-index: 5;
      max-height: 200px; 
      overflow-y: auto;

      li {
        border: 1px solid black;
        background-color: $grayColor;

        &:hover {
          background-color: #4593f5;
        }
      }
    }
  }
}
