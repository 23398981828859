#NationListPopUp {
  position: fixed;
  width: 780px;
  height: 500px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 20px;

    & > div {
      position: relative;
      min-width: 250px;

      input[type="search"] {
        padding: 10px;
        border: 1px solid #d8d8d8;
        width: 100%;
        border-radius: 4px;
      }

      ul {
        position: absolute;
        top: 103%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 200px;
        overflow: auto;
        padding: 12px 0;

        li {
          cursor: pointer;
          padding: 10px 5px;

          &:hover {
            box-shadow: 0 2px #e6e0df;
          }
        }
      }

      ::-webkit-scrollbar {
        width: 6px;
      }

      ::-webkit-scrollbar-thumb {
        background: #4593f5;
        border-radius: 25px;
      }
    }

    .icon_exit {
      margin-left: auto;
    }
  }

  > .nation_list_wrapper {
    overflow-y: scroll;
    height: 400px;
    ul {
      width: 100%;
      li {
        width: 100%;
        cursor: pointer;
        border: 1px solid #d8d8d8;
        > p {
          display: flex;
          align-items: center;
          height: 40px;
        }
        span {
          min-width: 40px;
        }
      }
      .nation_list_second {
        padding-left: 20px;
        border: 1px solid #d8d8d8;
      }
      .nation_list_second:hover {
        background-color: rgb(255, 254, 196);
      }
    }
  }
}
