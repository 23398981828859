@import "src/include";

#BusinessDescriptionMain{
    .pc{
        .businessDescriptionMain{
            width: 950px;
            margin: 0 auto;
            padding: 40px 0;
            &_box-wrap{
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 880px;
                height: 110px;
                margin: 0 auto 45px;
                padding: 10px 15px;
                background-color: #f1f1f1;
                border-radius: 20px;
            }
            &_box{
                width: calc(100% - 123px);
                height: 100%;
                position: relative;
                padding: 20px 0 20px 160px;
                &::after{
                    position: absolute;
                    content: '';
                    width: 61px;
                    height: 51px;
                    top: 50%;
                    left: 46px;
                    transform: translateY(-50%);
                    background: url(../../../assets/images/businessDescription/ico_businessDescriptionMain_box.svg)no-repeat 50% 50%;
                    background-size: cover;
                }
                &_title{
                    font-family: NanumSquareOTF_acEB;
                    font-size: 18px;
                    color: #343434;
                    margin-bottom: 10px;
                }
                &_sub{
                    font-family: NanumSquareOTF_acB;
                    font-size: 14px;
                    color: #606060;
                }
                &_btn{
                    width: 123px;
                    height: 24px;
                    text-indent: -9999px;
                    background: url(../../../assets/images/common/icon_tutorial.png) no-repeat 50% 50%;
                    background-size: cover;
                }
            }
        }
    }
    .mo{
        .businessDescriptionMain{
			width: 100vw;
			overflow: hidden;
			&_top{
				width: 100%;
				height: 122px;
				margin: 60px 0 32px;
				padding-top: 32px;
				text-align: center;
				background-image: linear-gradient(to top, rgba(129,202,233,.2), rgba(128,206,183,.2));
			}
			&_title{
				font-family: NanumSquareOTF_acEB;
				font-size: 20px;
				color: #1d334e;
				margin-bottom: 12px;
			}
			&_subtitle{
				font-family: NanumSquareOTF_acB;
				font-size: 14px;
				color: #606060;
			}
			&_content{
				&_title{
					font-family: NanumSquareOTF_acEB;
					margin: 0 20px 20px;
				}
			}
		}
    }

    .contentPhotoList_list_box_date,
    .contentPhotoList_list_box_day {
        display: none;
    }
}