#SaveButton {
  button {
    width: 97px;
    height: 40px;
    background-color: #4593f5;
    text-align: center;
    font-size: 14px;
    color: white;
    font-family: NanumSquareOTF_acB;
    border-radius: 5px;
    margin-right: 35px;
    cursor: pointer;
  }
}
