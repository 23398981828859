#IntroFindBiz {
    @import "src/mixin";
    margin: auto;
    max-width: 1280px;
    position: relative;
    .dataVoucherPopUp_mo {
        width: 100%;
        height: 100vh;
        background-color: rgba(52, 52, 52, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        > div {
            position: fixed;
            z-index: 100;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 280px;
            height: 360px;
            background: url("../../../assets/images/intro/intro-datavoucher-mo.png");
            i {
                position: absolute;
                right: 20px;
                top: 20px;
            }
            > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                padding-bottom: 50px;
                p:nth-child(1) {
                    color: #fff;
                    font-family: Pretendard_extraBold;
                    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                    font-size: 16px;
                }
                p:nth-child(2) {
                    text-align: center;
                    font-size: 39px;
                    color: #70fff2;
                    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                    font-family: GmarketSansTTF_bold;
                    letter-spacing: -1.56px;
                }
                p:nth-child(3) {
                    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                    color: #fff;
                    font-size: 28px;
                    font-family: GmarketSansTTF_bold;
                }
            }
            button {
                width: 122.6px;
                height: 29px;
                box-shadow: 0 -1px 9px 0 #70fff3;
                border-radius: 17px;
                background-color: #70fff3;
                font-family: GmarketSansTTF_bold;
                font-size: 14px;
                color: #185efe;
                position: absolute;
                bottom: 24px;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .dataVoucherPopUp_pc {
        width: 1280px;
        height: 100px;
        background: url("../../../assets/images/intro/intro-datavoucher-pc.png");
        position: relative;
        padding: 13px 0 0 88px;
        > div {
            p:nth-child(1) {
                text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                font-size: 18px;
                color: #fff;
                font-family: Pretendard_extraBold;
            }
            p:nth-child(2) {
                display: flex;
                align-items: center;
                margin-top: 10px;
                text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                font-size: 39px;
                letter-spacing: -1.56px;
                color: #70fff2;
                font-family: GmarketSansTTF_bold;
                button {
                    width: 173.3px;
                    height: 41px;
                    box-shadow: 0 -1px 9px 0 #70fff3;
                    margin-left: 20px;
                    border-radius: 21px;
                    background-color: #70fff3;
                    font-family: GmarketSansTTF_bold;
                    font-size: 23px;
                    letter-spacing: -1.64px;
                    color: #185efe;
                    margin-bottom: 5px;
                }
            }
        }
        i {
            position: absolute;
            top: 11px;
            right: 11px;
        }
    }
    @include mobile {
        padding-bottom: 30px;
    }
    .mobile_view {
        padding: 17px 0 5px 0;
        .bg_v2 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 25px;
        }
        .show_dashboard {
            position: absolute;
            top: 60px;
            left: 0;
            z-index: 10;
            img {
                width: 100%;
                object-fit: cover;
            }
        }
        .btn_change {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: 40px;
            background-color: var(--color-Web-Orange-opacity-01);

            p {
                font-size: 13px;
                font-family: NanumSquareOTF_acEB;
                color: var(--color-Web-Orange);
            }
        }

        &_title {
            margin: 0 0 5vh 0;
            font-size: 1.8em;
            text-align: center;

            span {
                font-family: NanumSquareOTF_acB;
                color: var(--color-Tarawera);

                &.bold {
                    font-family: NanumSquareOTF_acEB;
                    font-size: 30px;
                }
            }
        }

        .hash_list {
            margin: 30px auto 10px auto;
            display: flex;
            align-items: center;
            column-gap: 5px;
            width: 90%;

            li {
                display: inline-block;
                justify-content: center;
                align-items: center;
                padding: 7px 12px 7px 12px;
                font-size: 12px;
                font-family: NanumSquareOTF_acEB;
                border-radius: 14px;
            }

            li:nth-child(odd) {
                color: #073E55;
                background-color: rgba(84, 183, 224, 0.3);
            }

            li:nth-child(even) {
                color: #073E55;
                background: rgba(84, 183, 224, 0.15);
            }
        }

        .search_area_mo {
            margin: auto;
            width: 90%;

            input {
                padding: 0 15px;
                width: 100%;
                height: 40px;
                font-size: 14px;
                font-family: NanumSquareOTF_acB;
                border: none;
                border-radius: 20px;
                box-shadow: 0 2px 8px 0 var(--color-Alto);
                background-color: #fff;

                &::placeholder {
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                }
            }

            button {
                position: absolute;
                top: 6px;
                right: 15px;
            }
        }

        .btn_go_task {
            margin: 13.5vh 5% 0 auto;
            display: flex;
            align-items: center;
            width: 89px;
            font-size: 12px;
            font-family: NanumSquareOTF_acEB;
            color: var(--color-Piction-Blue);

            p {
                width: 65px;
            }
        }
    }

    .title {
        margin-top: 35px;
        font-size: 35px;
        line-height: 38px;
        font-family: NanumSquareOTF_acEB;
        color: var(--color-Mine_Shaft);
        text-align: center;

        @include mobile {
            font-size: 24px;
        }

        span {
            color: var(--color-Cloud-Burst);
        }
    }

    .mobile_banner {
        img {
            width: 100%;
        }
    }

    .count_list {
        padding: 0 5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        background-color: rgba(216, 216, 216, 0.15);

        @include mobile {
            padding: 0;
            flex-direction: column;
            justify-content: center;
            align-items: initial;
            row-gap: 10px;
            height: 66px;
        }

        .slick-slider {
            width: 100%;
        }

        .count_item {
            @include mobile {
                padding: 0 30px;
                display: flex !important;
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: center;
            }

            h2 {
                margin-bottom: 5px;
                font-size: 30px;
                font-family: NanumSquareOTF_acEB;
                color: #1d334e;
                text-align: center;
                @include mobile {
                    margin-bottom: 0;
                    font-size: 20px;
                }
            }

            p {
                font-size: 16px;
                font-family: NanumSquareOTF_acB;
                color: #343434;
                text-align: center;

                @include mobile {
                    text-align: left;
                }
            }
        }
    }

    .category_list {
        padding: 35px 0 80px 0;

        @include mobile {
            margin-bottom: 20px;
            padding: 20px 15px 0 15px;
            justify-content: left;
        }

        .category_item {
            img {
                @include mobile {
                    width: 87px;
                }
            }
        }
    }

    .task_process {
        margin-top: 80px;
        padding: 25px 70px 30px 70px;
        background-color: rgba(233, 249, 255, 0.5);

        @include mobile {
            margin-top: 0;
            padding: 25px 0 30px 0;
        }

        .process_title {
            font-size: 35px;
            color: #1d334e;

            @include mobile {
                margin-bottom: 25px;
                padding: 0 5%;
                font-size: 16px;
                line-height: 24px;

                span {
                    font-size: 18px;
                    font-family: NanumSquareOTF_acB;
                }
            }
        }

        .tab_list {
            display: flex;
            align-items: center;

            @include mobile {
                overflow-x: scroll;
                overflow-y: hidden;

            }

            .tab_item {
                display: flex;
                align-items: center;
                column-gap: 5px;
                width: 225px;
                height: 60px;
                border-bottom: 4px solid #ffffff;
                cursor: pointer;

                @include mobile {
                    padding-left: 10px;
                    min-width: 31vw;
                }

                &.active {
                    border-bottom: 4px solid #4593f5;

                    .circle {
                        align-items: center;
                        color: #ffffff;
                        background-color: #4593f5;
                    }

                    p {
                        font-family: NanumSquareOTF_acEB;
                    }
                }

                .circle {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 24px;
                    min-height: 24px;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acEB;
                    color: #4593f5;
                    border: 2px solid #4593f5;
                    border-radius: 50%;
                    background-color: #ffffff;
                }

                p {
                    font-size: 16px;
                    font-family: NanumSquareOTF_acB;
                    color: #343434;
                    word-break: keep-all;

                    @include mobile {
                        font-size: 12px;
                    }
                }
            }
        }

        .box {
            margin-top: 15px;
            padding: 25px;
            height: 310px;
            border-radius: 25px;
            box-shadow: 0 2px 3px 1px #d8d8d8;
            background-color: #fff;

            @include mobile {
                margin: 15px 5% 0 5%;
                height: auto;
            }

            .step {
                margin-bottom: 15px;
                font-size: 16px;
            }

            .desc {
                font-size: 22px;
                line-height: 1.73;

                @include mobile {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            .more_desc {
                margin-top: 25px;
                padding-top: 25px;
                border-top: 1px solid #f0f0f0;

                @include mobile {
                    margin-top: 15px;
                    padding-top: 15px;
                }

                p {
                    font-size: 14px;

                    @include mobile {
                        font-size: 12px;
                        line-height: 16px;
                    };
                }
            }

            p {
                span {
                    color: #4593f5;
                }
            }
        }
    }

    .task_review {
        padding-top: 60px;
        @include mobile {
            padding: 20px 0 30px 0;
        }
        .title {
            text-align: center;
            font-family: NanumSquareOTF_acR;
            span {
                font-family: NanumSquareOTF_acEB;
                color: #1d334e;
            }
            @include mobile {
                text-align: center;
            }
        }

        .review_list {
            margin-top: 5%;
            @include mobile {
                padding: 0 5%;
            }
            @include desktop {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 16px;
            }

            .slick-list {
                border-radius: 25px;
                box-shadow: 0 2px 3px 1px #d8d8d8;

            }
        }

        .review_item {
            padding: 30px;
            min-width: 300px;
            height: 370px;
            border-radius: 25px;
            box-shadow: 0 2px 3px 1px #d8d8d8;
            background-color: #fff;
            .tab_name {
                line-height: 24px;
                font-size: 16px;
                font-family: NanumSquareOTF_acEB;
                color: #4593f5;
                border-radius: 14px;
                span {
                    background-color: #FFFBDA;
                }

            }

            .review_content {
                padding-top: 10px;
                height: 70%;
                font-size: 16px;
                font-family: NanumSquareOTF_acB;
                line-height: 29px;
                color: #343434;
                display: flex;
                flex-direction: column;
                img {
                    width: 18px;
                    height: 28px;
                }
                p {
                    padding-left: 2px;
                }

                p > span {
                    font-weight: bold;
                    color: #073e55;
                    font-size: 18px;
                    font-family: NanumSquareOTF_acEB;
                }
            }

            .reviewer_info {
                padding: 5px 0;
                display: flex;
                border-top: 1px solid #d8d8d8;
                height: 30%;
                .inner {
                    margin: 12px 8px 0 0;
                    .name {
                        font-size: 16px;
                        color: #1d334e;
                        font-family: NanumSquareOTF_acEB;
                        line-height: 1.38;
                        letter-spacing: 0.5px;
                    }

                    .grade {
                        margin-top: 7px;
                        line-height: 1.14;
                        letter-spacing: 0.5px;
                        line-height: 18px;
                        color: rgba(0, 0, 0, 0.87);
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .btn_go_findbiz {
        margin: 30px auto 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 900px;
        height: 50px;
        font-size: 16px;
        font-family: NanumSquareOTF_acB;
        color: #ffffff;
        border-radius: 4px;
        background-color: var(--color-Piction-Blue);

        span {
            display: block;
            width: 135px;
            text-align: center;
        }

        @include mobile {
            width: 90%;
            font-size: 14px;
        }
    }

    .task_mou {
        margin-top: 60px;

        @include mobile {
            margin-top: 35px;
        }

        .mou_list {
            padding-top: 60px;
            display: grid;
            grid-template-columns: repeat(4, minmax(150px, 1fr));
            grid-gap: 3rem;

            @include mobile {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                padding: 20px;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                column-gap: 10px;
                row-gap: 10px;
            }

            .mou_item {
                display: flex;
                justify-content: center;

                img {
                    display: block;
                    max-width: 100%;
                    width: 150px;
                    height: 75px;
                    object-fit: scale-down;
                }

                @include mobile {
                }
            }
        }
    }

    .MuiTabs-root {
        margin: 20px 0;
        @include mobile {
            margin-bottom: 15px;
        }

        .MuiTabs-indicator {
            background-color: var(--color-Piction-Blue);
        }
    }

    .MuiTabs-flexContainer {
        border-bottom: 1px solid var(--color-Gallery);

        .MuiButtonBase-root {
            padding: 0;
            width: 200px;
            font-size: 16px;

            &.Mui-selected {
                font-family: NanumSquareOTF_acEB !important;
                color: var(--color-Piction-Blue);
            }

            @include mobile {
                min-width: 90px;
                width: 25%;
                font-size: 12px;
            }
        }
    }

    .wrap_banner {
        font-size: 0;
        margin-top: 30px;
        img {
            max-width: 1280px;
            height: 100%;
        }

        .slick-dots {
            bottom: 10px;

            @include mobile {
                bottom: 5px;
            }

            li.slick-active button:before {
                opacity: 1;
                color: #ffe0c8;
            }

            li button:before {
                opacity: 0.4;
                font-size: 12px;
                color: #ffe0c8;
            }
        }
    }

    .slick-dots {
        li {
            margin: 0;
        }

        li.slick-active button:before {
            opacity: 0.9;
            color: var(--color-Dodger-Blue);
        }

        li button:before {
            opacity: 0.2;
            font-size: 12px;
            color: var(--color-Dodger-Blue);
        }
    }

    #TaskTable {
        margin-top: 25px;

        @include mobile {
            margin-top: 0;

            tbody {
                td.day {
                    p {
                        padding: 0 10px;
                        display: inline-block;
                        width: 80px;
                        line-height: 21px;
                        font-size: 12px;
                        font-family: NanumSquareOTF_acEB;
                        color: #ffffff;
                        text-align: center;
                        border-radius: 14px;
                        background-color: var(--color-Piction-Blue);
                    }
                }
            }
        }

        th:first-child,
        td:first-child,
        th:nth-child(9),
        td:nth-child(9),
        th:nth-child(10),
        td:nth-child(10) {
            display: none;
        }

        thead {
            background-color: #f0f0f0;

            th {
                color: var(--color-Mine_Shaft) !important;
            }

            button {
                display: none;
            }
        }
    }

    .wrap_search {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 15px;

        @include mobile {
            margin-top: 0;
        }
    }

    .search_area_pc {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 746px;
        height: 140px;
        background-color: var(--color-Malibu-opacity-015);

        .wrap {
            margin-bottom: 12px;
            display: flex;
            justify-content: space-between;
            width: 700px;

            .hash_list {
                display: flex;
                align-items: center;
                column-gap: 4px;

                li {
                    display: inline-block;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    padding: 7px 12px 7px 12px;
                    font-family: NanumSquareOTF_acEB;
                    border-radius: 14px;
                    color: #ffffff;
                    background-color: var(--color-Malibu);
                    cursor: pointer;
                }
            }

            .btn_notify {
                display: flex;
                align-items: center;
                column-gap: 5px;

                img {
                    width: 27px;
                    height: 24px;
                }

                p {
                    font-size: 18px;
                    font-family: NanumSquareOTF_acEB;
                    color: var(--color-Cloud-Burst);
                }
            }
        }

        .search_inner {
            input {
                padding: 0 40px 0 20px;
                width: 700px;
                height: 50px;
                font-family: NanumSquareOTF_acB;
                border: none;
                border-radius: 5px;
                background-color: #fff;

                @include mobile {
                    width: 90vw;
                    height: 40px;
                    font-size: 14px;
                }
            }

            input:focus {
                box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
            }

            input::placeholder {
                font-family: NanumSquareOTF_acR;
                color: var(--color-Mine_Shaft);
            }

            button {
                position: absolute;
                top: 12px;
                right: 20px;

                @include mobile {
                    top: 7px;
                    right: 10px;
                }
            }
        }
    }

    .banner {
        .link_img {
            @include mobile {
                width: 100vw;
            }
        }
    }
}
