#MarketInfoSideBar {
  width: 220px;

  ul {
    display: flex;
    flex-direction: column;
  }
  ul > li {
    width: 220px;
    height: 49px;
    display: flex;
    align-items: center;
    font-family: NanumSquareOTF_acB;
    font-size: 16px;
    color: #343434;
    cursor: pointer;
    padding-left: 23px;
  }
}
