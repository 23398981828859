#InventorMember {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  gap: 20px;

  /* 각 열의 너비를 설정 */
  table th:nth-child(1),
  table td:nth-child(1) {
    width: 5%; /* 순번 */
  }

  table th:nth-child(2),
  table td:nth-child(2) {
    width: 5%; /* 이름 */
  }

  table th:nth-child(3),
  table td:nth-child(3) {
    width: 5%; /* 영문명 */
  }

  table th:nth-child(4),
  table td:nth-child(4) {
    width: 5%; /* 사번 */
  }
  table th:nth-child(5),
  table td:nth-child(5) {
    width: 7%; /* 생년월일 */
  }
  table th:nth-child(6),
  table td:nth-child(6) {
    width: 7%; /* 직급 */
    padding: 7px;
    white-space: nowrap; /* 줄 바꿈 방지 */
    overflow: hidden; /* 넘치는 내용 숨기기 */
    text-overflow: ellipsis; /* 넘치는 내용 ...으로 표시 */
  }

  table th:nth-child(7),
  table td:nth-child(7) {
    width: 10%; /* 전화 */
    padding: 7px;
    white-space: nowrap; /* 줄 바꿈 방지 */
    overflow: hidden; /* 넘치는 내용 숨기기 */
    text-overflow: ellipsis; /* 넘치는 내용 ...으로 표시 */
  }

  table th:nth-child(8),
  table td:nth-child(8) {
    width: 15%; /* 이메일 */
  }

  table th:nth-child(9),
  table td:nth-child(9) {
    width: 7%; /* 지사명 */
  }
  table th:nth-child(10),
  table td:nth-child(10) {
    width: 15%; /* 주소 */
  }
  table th:nth-child(11),
  table td:nth-child(11) {
    width: 5%; /* 상태 */
  }

  /* 버튼 컨테이너 (수직 정렬) */
  .btn-group {
    display: flex;
    flex-direction: row; /* 버튼을 세로 정렬 */
    align-items: center; /* 중앙 정렬 */
    justify-content: center;
    gap: 4px; /* 버튼 사이 간격 */
  }

  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 6px;
    border: 1px solid;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .btn-outline-dark {
    color: #343434;
    border-color: #343434;
    background-color: white;
  }

  .btn-outline-dark:hover {
    background-color: #343434;
    color: white;
  }

  /* 계정 연결 버튼 (파란색) */
  .btn-outline-primary {
    color: #007bff;
    border-color: #007bff;
    background-color: white;
  }

  .btn-outline-primary:hover {
    background-color: #007bff;
    color: white;
  }

  /* 계정 관리 버튼 (빨간색) */
  .btn-outline-danger {
    color: #28a745;
    border-color: #28a745;
    background-color: white;
  }

  .btn-outline-danger:hover {
    background-color: #28a745;
    color: white;
  }

  .inventor_wrapper {
    width: calc(100% - 150px);

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      div {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      p {
        font-size: 24px;
      }
      button {
        width: 95px;
        font-size: 14px;
        color: white;
        background-color: #0984e3;
        padding: 8px 12px;
        border-radius: 4px;
      }
      .search_bar {
        display: flex;
        height: 40px;
        align-items: center;
        gap: 10px;

        label {
          font-size: 14px;
          font-weight: bold;
          color: #333;
        }

        select {
          padding: 8px 20px 8px 10px;
          font-size: 14px;
          border: 1px solid #ccc;
          border-radius: 5px;
          outline: none;
          height: 100%;
          -webkit-appearance: auto; /* 화살표 없애기 for chrome*/
          -moz-appearance: auto; /* 화살표 없애기 for firefox*/
          appearance: auto; /* 화살표 없애기 공통*/
          background: none;
        }
        button {
          width: 50px;
          height: 100%;
        }

        input {
          padding: 8px 10px;
          font-size: 14px;
          width: 240px;
          border: 1px solid #ccc;
          border-radius: 5px;
          outline: none;
        }
      }
    }
    .table_wrapper {
      input[type="text"] {
        height: 100%;
        width: 90%;
      }
      select {
        height: 100%;
        width: 90%;
        border: 1px solid #d8d8d8;
        border-radius: 4px;
        font-size: 14px;
        color: black;
        padding: 12px 0px 12px 10px;
      }
    }
  }
}
