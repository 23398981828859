#RnDText {
  display: flex;
  justify-content: space-between;
  height: 75px;
  margin-top: 22px;
  font-family: NanumSquareOTF_acB;
  p:nth-child(1) {
    font-size: 22px;
  }
  p:nth-child(2) {
    font-size: 14px;
    margin-top: 10px;
  }
}
