#AdminAnnualFeeList {
  margin: auto;

  padding: 45px 40px 0 40px;
  .list_header {
    font-family: NanumSquareOTF_acB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    p {
      font-size: 22px;
      color: #343434;
      select {
        width: 80px;
        font-size: 22px;
      }
    };
    button {
      padding: 10px 20px;
      height: 40px;
      border-radius: 4px;
      background-color: #4593f5;
      color: #fff;
      margin-right: 10px;
    }
  }
  .button_wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
    padding: 0 10px;
    gap: 10px;
    > div {
      display: flex;
      gap: 10px;
    }
    button {
      height: 40px;
      border-radius: 4px;
      background-color: #636e72;
      color: #fff;
      padding: 10px 20px;
    }
  }
  .select_box_wrapper {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    .select_box_right {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    .search_wrapper {
      display: flex;
      align-items: center;
      width: 423px;
      height: 51px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      border-radius: 30px;
      padding: 0 10px;
      justify-content: space-between;
      select {
        width: 25%;
        padding: 0 10px;
        color: #343434;
        letter-spacing: -0.09px;
        font-family: Pretendard_regular;
      }
      input[type="text"] {
        width: 65%;
        border: none;
      }
      i {
        margin-left: 10px;
      }
    }
  }
  .select_wrapper {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 0 10px;
    margin-bottom: 20px;
    select:nth-of-type(1) {
      width: 80px;
    }
    select:nth-of-type(2) {
      width: 60px;
    }
  }
  .annual_change_view {
    width: 100%;
    display: flex;
    font-family: NanumSquareOTF_acB;
    font-size: 16px;
    color: #343434;
    margin-bottom: 24px;
    > p {
      width: 50%;
      text-align: center;
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 15px;
      cursor: pointer;
    };
  }

  .annual_list_table {
    th {
      background-color: #f0f0f0;
      font-family: NanumSquareOTF_acB;
      font-size: 14px;
      color: #343434;
      height: 46px;
      position: relative;
      .select_text {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .annual_list, .nation_list {
        position: absolute;
        background-color: #fff;
        color: #000;
        border: 1px solid #0095ae;
        z-index: 2;
        > li {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          border-bottom: 1px solid #0095ae;
          cursor: pointer;
        }
      }
      .annual_list {
        width: 120px;
      }
      .nation_list {
        width: 80px;
      }
    };
    //th:nth-child(2), td:nth-child(2) {
    //  text-align: left;
    //}
    .react-datepicker {
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translate(-50%);
    }
    .flex_td {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .button_review {
      width: 46px;
      height: 18px;
      background-color: rgba(255, 166, 39, 0.2);
      border-radius: 5px;
      font-family: NanumSquareOTF_acB;
      font-size: 10px;
      letter-spacing: 0.71px;
      color: #343434;
      z-index: 100;
    }
    .annual_receipt {
      p {
        font-family: NanumSquareOTF_acEB;
        font-size: 12px;
        letter-spacing: -0.4px;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 4px;
      }
      button {
        width: 74px;
        height: 18px;
        border-radius: 5px;
        background-color: rgba(240,240,240,0.8);
        font-family: NanumSquareOTF_acB;
        font-size: 10px;
        letter-spacing: 0.71px;
        color: #343434;
      }
    }
    .billing_button {
      button {
        width: 74px;
        height: 30px;
        border-radius: 5px;
        font-family: NanumSquareOTF_acB;
        font-size: 12px;
      }
    }
  }
}
