@import "../../../include";

#EducationMain {
    margin: auto;
    padding-top: 42px;
    width: 880px;

    .page_title {
        margin-bottom: 32px;
        font-size: 22px;
        font-family: NanumSquareOTF_acEB;
    }

    .wrap {
        display: flex;
        align-items: center;

        > div {
            .head {
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .more {
                    display: flex;
                    align-items: center;
                    font-size: 11px;
                    text-align: right;

                    p {
                        margin-right: 15px;
                    }

                    i {
                        background-size: contain;
                    }
                }
            }

            .item {

                p {
                    width: 100%;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    @include text-ellipsis(1);
                }

                img {
                    margin-bottom: 10px;
                    object-fit: contain;
                    border: 1px solid #e7e7e7;
                }
            }
        }

        .area_ppt {
            width: 100%;

            .list {
                display: flex;
                flex-wrap: wrap;
                column-gap: 20px;
                row-gap: 20px;

                .item {
                    width: 280px;
                    height: 209px;

                    img {
                        width: 100%;
                        height: 169px;
                    }
                }
            }
        }
    }

    .btn_more {
        margin: auto;
        display: block;
        width: 190px;
        height: 40px;
        font-size: 16px;
        font-family: NanumSquareOTF_acEB;
        color: #747474;
        text-align: center;
        @include border-radius(20px);
        border: solid 2px #959595;
        background-color: #ffffff;
    }
}