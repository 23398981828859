#AdminMarketSurvey {
  margin: auto;
  padding-top: 40px;
  width: 880px;

  .page_title {
    margin-bottom: 40px;
    height: 40px;
    font-size: 24px;
    font-family: NanumSquareOTF_acB;
    color: #443018;
    border-bottom: 1px solid #ddd;
  }

  .articles {
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }
  .field {
    position: relative;
    padding: 16px 0;
    display: flex;
    column-gap: 14px;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    .unreleased {
      width: 200px;
    }
    span {
      padding-right: 14px;
      display: flex;
      align-items: center;
      min-width: 180px;
      width: 180px;
      max-width: 180px;
      font-family: NanumSquareOTF_acB;
      border-right: 1px solid #ddd;
    }

    img {
      width: 100%;
    }

    input,
    select,
    textarea {
      font-size: 14px;
    }

    input[type="checkbox"] {
      width: 16px;
      height: 16px;
    }
    input[type="checkbox"]:disabled {
      background-color: #0075FF;
    }
    textarea {
      width: 100%;
      min-height: 500px;
      resize: none;
    }

    textarea,
    input[type="text"],
    select {
      padding: 10px;
      width: 100%;
      border: 1px solid #ddd;
    }

    select {
      min-width: 135px;
      width: 135px;
      height: 38px;
    }

    .icon_plus {
      min-width: 24px;
    }

    p {
      line-height: 38px;
    }
  }

  .btn_register,
  .btn_list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 48px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
  }

  .wrap_btns {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
  }

  .btn_register {
    background-color: #4593f5;
  }

  .btn_list {
    background-color: #96c719;
  }
}
