#KoreaMap {
  position: relative;
  display: inline-block;
  .chart_info {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 14px;
    color: #000;
    > strong {
      display: block;
    }
    > span {
      display: block;
      font-size: 12px;
    }
  }
  .legend {
    position: absolute;
    right: 0;
    bottom: 20px;
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      .ball {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 12px;
        margin-right: 4px;
      }
      .text {
        display: inline-block;
        font-size: 14px;
      }
    }
  }
  .region {
    &:hover {
      z-index: 99;
    }
    canvas {
      position: relative;
      z-index: 99;
    }
  }
}
