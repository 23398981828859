#MarketInfoHeader {
  margin: 0 auto;
  width: 1280px;
  .tutorial-box {
    display: flex;
    column-gap: 70px;
    width: 1280px;
    height: 180px;
    background-image: url("../../../assets/images/common/bg-marketInfo.svg");
    background-color: rgba(107, 211, 255, 0.14);
    .wrap_text {
      padding: 29px 0 44px 45px;
      h2 {
        font-family: NanumSquareOTF_acEB;
        font-size: 30px;
        color: #1d334e;
      }
      p {
        margin-top: 13px;
        font-family: NanumSquareOTF_acB;
        font-size: 16px;
        color: #606060;
      }
      span:nth-child(1) {
        font-family: NanumSquareOTF_acB;
        font-size: 16px;
        color: #1d334e;
      }
      span {
        font-family: NanumSquareOTF_acEB;
        font-size: 30px;
        color: #4593f5;
      }
    }
  }
  .category_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: solid 1px #f0f0f0;
    height: 47.5px;
    .links {
      display: flex;
      align-items: center;
      padding-right: 30px;
      a {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding-right: 20px;
        font-family: NanumSquareOTF_acR;
        span {
          padding-right: 8px;
        }
        i {
          width: 18px;
          height: 18px;
        }
      }
      .btn_my {
        color: #4593f5;
      }
      .btn_write {
        color: #343434;
      }
    }
    p {
      padding-left: 28px;
      display: flex;
      align-items: center;
      font-family: NanumSquareOTF_acB;
      font-size: 12px;
      line-height: 2;
      color: #343434;
    }
  }
}
