#ManageMyPoint {
  margin: auto;
  padding-top: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  column-gap: 20px;
  width: 1280px;

  .area_box {
    margin: 0 auto 24px auto;
    padding: 26px 0 25px 45px;
    width: 880px;
    height: 140px;
    border-radius: 20px;
    background-color: rgba(241, 241, 241, 0.3);
  }
  #docTradeMyTransaction {
    .mytransaction_section-wrap {
      width: calc(100% - 120px);
    }
  }

  #docTradeMyTransactionUsage {
    width: calc(100% - 150px);
    .usage_content_title {
      font-family: NanumSquareOTF_acEB;
    }
  }
}
