#MobileErrorPopup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    .dimmed {
        width: 100vw;
        height: 100vh;
        background-color: rgba(62, 62, 62, 0.5);
    }

    .popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 8.8% 12.5%;
        width: 90vw;
        height: 50vw;
        background-color: #ffffff;
        text-align: center;

        h2 {
            font-size: 20px;
            font-family: NanumSquareOTF_acEB;
            color: #443018;
        }

        .admin_email {
            margin: 4px 0 10px 0;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            color: #959595;
        }

        .desc {
            margin-bottom: 12px;
            font-size: 12px;
            font-family: NanumSquareOTF_acB;
            color: #606060;
            white-space: break-spaces;
        }
    }
}
