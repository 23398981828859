@import "src/include";

#docTradeMyTransactionUsage{
	.usage{
		&_content{
			&_title-wrap{
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				height: 24px;
				line-height: 24px;
				margin-bottom: 30px;
			}
			&_title{
				width: calc(100% - 487px);
				font-family: NanumSquareOTF_acB;
				font-size: 22px;
				color: #343434;
			}
			&_info{
				position: relative;
				width: 487px;
				font-size: 12px;
				color: #606060;
				padding-left: 24px;
				&::after{
					position: absolute;
					content: '';
					width: 24px;
					height: 24px;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					background: url(../../../assets/images/common/icon-badge-information-gray.svg) no-repeat 50% 50%;
				}
			}
			&_table{
				&_content{
					margin-bottom: 10px;
					.style{
						text-align: left;
						padding-left: 26px;
					}
				}
			}
		}
	}
}