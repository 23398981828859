#ResearchTechPatentList {
  margin: auto;
  padding-top: 40px;
  width: 1280px;

  .wrap_category {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .category {
      display: flex;
      align-items: center;
      column-gap: 10px;
      font-size: 22px;
      font-family: NanumSquareOTF_acB;
      color: #343434;
    }
    .button_link {
      display: flex;
    }
    .download_button {
      width: 160px;
      height: 40px;
      padding: 8px 16px;
      border-radius: 4px;
      border: solid 1px #d8d8d8;
      background-color: #fff;
      margin-right: 20px;
      text-align: center;
      cursor: pointer;
      span {
        cursor: pointer;
        background-color: #fff;
        color: #343434;
        font-family: NanumSquareOTF_acB;
        font-size: 16px;
      }
      img {
        padding-right: 5px;
        width: 24px;
        height: 24px;
      }
    }
    .link {
      padding: 0 0 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 40px;
      border-radius: 9px;
      background-color: #eff3fb;

      span {
        display: block;
        width: 87px;
        font-size: 12px;
        font-family: NanumSquareOTF_acB;
        color: #606060;
      }
    }
  }

  .wrap_table {
    margin-top: 40px;
    height: 400px;
    overflow-y: scroll;
  }

  table {

    thead {
      background-color: var(--color-Gallery);

      th {
        color: var(--color-Mine_Shaft);
      }
    }

    th:nth-child(2),
    td:nth-child(2) {
      padding-left: 20px;
      text-align: left;
    }
  }

  .btns {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;

    .btn_prev {
      width: 86px;
      height: 40px;
      font-size: 14px;
      font-family: NanumSquareOTF_acB;
      border-radius: 4px;
      border: solid 1px var(--color-Alto);
      background-color: #ffffff;
    }

    .btn_next {
      padding: 0 10px;
      height: 40px;
      font-size: 14px;
      font-family: NanumSquareOTF_acB;
      color: #ffffff;
      border-radius: 4px;
      background-color: var(--color-Cornflower-Blue);
    }
  }
}
