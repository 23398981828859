@import "src/include";
#HomeInvestor {
    padding-top: 15px;

    h2 {
        font-size: 22px;
        font-family: NanumSquareOTF_acEB;
        color: #443018;
    }

    .area_title {
        margin-bottom: 14px;
    }

    .wrap_head {

        .desc {
            margin-bottom: 49px;
            font-size: 16px;
            line-height: 1.3;
            color: rgba(0, 0, 0, 0.87);
            text-align: center;
        }

        .area_box {
            margin: 13px 0 36px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 235px;
            border-radius: 20px;
            background-color: rgba(241, 241, 241, 0.3);
        }
    }

    .table_title {
        margin-bottom: 27px;
    }

    .area_title,
    .table_title {
        padding-left: 15px;
    }

    > .wrap_list {
        max-height: 350px;
    }

    table {
        th:first-child,
        td:first-child,
        th:nth-child(4),
        td:nth-child(4) {
            width: 5%;
        }

        th:last-child,
        td:last-child {
            width: 8%;
        }
    }

    .table-box-wrap {
        margin-bottom: 24px;
    }

    #SearchInterested {
        padding-top: 10px;
        width: 100%;
        height: 100px;
        text-align: center;
        background-color: rgba(6, 175, 255, 0.05);

        .page_title {
            display: none;
        }

        .search_form {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        input {
            padding: 0 17px;
            width: 560px;
            font-size: 14px;
            border: none;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background-color: #ffffff;
        }

        input::placeholder {
            color: #606060;
        }

        button {
            width: 110px;
            font-size: 18px;
            font-family: NanumSquareOTF_acB;
            color: #ffffff;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            background-color: var(--color-Web-Orange);
            text-align: center;
        }
    }

    .keyword_list {
        position: absolute;
        top: 100%;
        width: 680px;
        max-height: 445px;
        overflow-x: hidden;
        overflow-y: scroll;
        border-left: solid 1px #e7e7e7;
        border-right: solid 1px #e7e7e7;
        border-bottom: solid 1px #e7e7e7;
        background-color: #ffffff;
        z-index: 9;

        li {
            margin-top: 3px;
            padding-bottom: 3px;
            width: 100%;
            text-align: left;
            background-color: #ffffff;
            cursor: pointer;

            div {
                margin: 0 15px;
                padding: 3px 0;
                display: flex;
                border-bottom: solid 1px #e7e7e7;

                h2, p {
                    padding: 4px 0;
                    display: flex;
                    align-items: center;
                    min-height: 32px;
                }

                h2 {
                    margin: 0;
                    padding-right: 8px;
                    min-width: 150px;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                }

                p {
                    width: 500px;
                    font-size: 12px;
                }
            }
        }

        li:first-child {
            padding-top: 7px;
            border-top: solid 1px #e7e7e7;
        }

        li:last-child {
            padding-bottom: 7px;
            border-bottom: solid 1px #e7e7e7;
        }

        .active:hover div {
            background-color: rgba(255, 166, 0, 0.1);
        }

        .active:hover h2 {
            color: var(--color-Web-Orange);
        }
    }

    @media screen and (max-width: 850px){
        @import "src/App";

        padding-top: 0;

        .wrap_head {
            padding: 40px 20px 60px;
            width: 100%;
            height: 154px;
            background-image: linear-gradient(to right, #ff7a00, var(--color-Web-Orange));

            .title {
                margin-bottom: 8px;
                font-size: 20px;
                font-family: NanumSquareOTF_acEB;
                line-height: 26px;
                color: #ffffff;
            }

            .desc {
                font-size: 14px;
                line-height: 20px;
                color: #ffffff;
            }
        }

        .list {
            margin: auto;
            position: relative;
            top: -25px;
            width: 90%;

            .item {
                border: 1px solid #d8d8d8;
                @include border-radius(4px);
                background-color: #ffffff;

                .head {
                    padding: 16px;
                    display: flex;
                    align-items: center;
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

                    > p {
                        margin-right: 8px;
                        width: 88px;
                        font-size: 20px;
                        font-family: NanumSquareOTF_acEB;
                        color: #606060;
                    }

                    .head_more {
                        margin-right: 8px;
                        width: 80%;

                        h2 {
                            margin-bottom: 2px;
                            font-size: 14px;
                            font-family: NanumSquareOTF_acEB;
                            color: #606060;
                            @include text-ellipsis(2);
                        }
                    }
                }

                .content {
                    padding: 0 16px;
                    height: 0;
                    background-color: #f0f0f0;
                    overflow: hidden;
                    transition-duration: 0.5s;

                    .data {
                        display: flex;

                        .title {
                            margin-right: 8px;
                            width: 80px;
                            font-size: 12px;
                            line-height: 18px;
                            color: #747474;
                        }

                        .value {
                            font-size: 12px;
                            font-family: NanumSquareOTF_acEB;
                            line-height: 18px;
                            color: #606060;
                        }
                    }

                    .data+.data {
                        margin-top: 2px;
                    }
                }

                .content.active {
                    padding: 16px;
                    height: 110px;
                }
            }

            .item+.item {
                margin-top: 16px;
            }
        }

        .btn {
            margin: 0 auto 42px auto;
            display: block;
            width: 320px;
            height: 40px;
            font-size: 14px;
            font-family: NanumSquareOTF_acEB;
            color: #ffffff;
            @include border-radius(4px);
            background-color: var(--color-Web-Orange);
        }
    }
}
