#DistributionPop {
  z-index: 999;
  width: 1400px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  border-radius: 30px;
  box-shadow: 0 2px 4px 0 #d8d8d8;
  .close {
    position: absolute;
    right: 30px;
    top: 30px;
    background: transparent;
  }
  .more {
    background: transparent;
    color: #0078f1;
    border: 1px solid #0078f1;
    padding: 4px 12px;
    border-radius: 20px;
  }
  .tbl_box {
    max-height: none;
    tbody {
      > tr {
        &:hover {
          cursor: pointer;
          background-color: rgba(107, 211, 255, 0.2);
        }
      }
    }
  }
  .grade {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 22px;
    border-radius: 8px;
  }
}

.distribution-dimmed-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 검정색 배경에 50% 투명도 */
  z-index: 998; /* DistributionPop의 z-index보다 낮게 설정 */
}
