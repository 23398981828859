#ResponseStatus {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  border-radius: 15px;

  .title {
    background-color: #00c6be;
    color: #fff;
    text-align: center;
    padding: 14px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 22px;

    i {
      position: absolute;
      right: 14px;
      top: 14px;
    }
  }

  .select_box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    i {
      width: 28px !important;
      height: 28px !important;
      background-size: contain;
    }
    select {
      width: 80px;
    }
  }

  .cont {
    padding: 20px;
    max-height: 400px;
    overflow-y: auto;

    table {
      width: 100%;

      th,
      td {
        text-align: center;
        padding: 8px 0;
        border-bottom: 1px solid #c3c3c3;
      }

      td {
        font-size: 14px;
        position: relative;

        >span {
          width: 60px;
          display: inline-block;
          padding: 4px;
          border-radius: 4px;
        }
      }

      .yes {
        color: #4f5ef7;
        background-color: #e8edfd;
      }

      .no {
        color: #ec0700;
        background-color: #ffe2dd;
      }

      .etc {
        color: #a8543d;
        background-color: #fff7d2;
      }

      .no_res {
        color: #707070;
        background-color: #f2f2f2;
      }
    }
  }

  .show_comment {
    position: absolute;
    width: 200px;
    min-height: 100px;
    border-radius: 5px;
    text-align: left;
    background-color: white;
    padding: 5px;
    overflow-y: auto;
    word-break: break-all;
    z-index: 10;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    border: 1px solid black;
    > i {
      display: block;
      margin: 0 0 0 auto;
      cursor: pointer;
    }
    > p {
      padding: 5px;
    }
  }

  .btns {
    margin-top: 40px;
    text-align: right;

    button {
      width: 120px;
      text-align: center;
      padding: 8px 12px;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      border-radius: 10px;
      margin-left: 10px;
      font-size: 16px;
    }

    .bg {
      color: #fff;
      background-color: #00c6be;
    }
  }
}
