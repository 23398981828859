#MarketInfoEdit {
    margin: auto;
    padding-top: 85px;
    width: 880px;

    .header {
        margin-bottom: 32px;
        padding-bottom: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e7e7e7;

        h2 {
            font-size: 22px;
            font-family: NanumSquareOTF_acB;
            color: #343434;
        }

        .category {
            display: flex;
            align-items: center;
            column-gap: 18px;

            li {
                display: flex;
                align-items: center;
                column-gap: 12px;

                h2,
                p {
                    font-size: 14px;
                    color: #343434;
                }

                h2 {
                    width: 50px;
                    font-family: NanumSquareOTF_acB;
                }

                p {
                    padding: 0 9px;
                    width: 185px;
                    height: 24px;
                    line-height: 24px;
                    border: 1px solid #d8d8d8;
                }
            }
        }
    }

    .step {
        margin-bottom: 20px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        background-color: rgba(240, 240, 240, 0.5);

        h2 {
            font-size: 14px;
            font-family: NanumSquareOTF_acEB;
            color: var(--color-Niagara);
        }
    }

    .step1 {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        column-gap: 10px;

        h2 {
            width: 58px;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            color: #343434;
        }

        input {
            padding: 0 9px;
            width: 812px;
            height: 32px;
            font-size: 14px;
            color: #343434;
            border: 1px solid #d8d8d8;
        }
    }

    .step2 {
        margin-bottom: 20px;
        &_header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &_title {
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
                color: var(--color-Niagara);
            }

            &_info {
                display: flex;
                align-items: center;
                column-gap: 15px;

                p {
                    display: flex;
                    align-items: center;
                    column-gap: 5px ;
                    font-size: 12px;
                    color: #606060;
                }

                .btn_delete {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 64px;
                    height: 24px;
                    font-size: 12px;
                    font-family: NanumSquareOTF_acEB;
                    color: #ffffff;
                    border-radius: 4px;
                    background-color: #e92f2c;
                }
            }
        }

        &_body_head {
            margin: 12px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .desc_info {
                display: flex;
                align-items: center;
                column-gap: 4px;

                h2 {
                    min-width: 56px;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    color: #343434;
                }

                p {
                    font-size: 12px;
                    color: #343434;
                }
            }

            .txt_info {
                display: flex;
                align-items: center;

                h2 {
                    width: 120px;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    color: #343434;
                    text-align: right;
                }

                p {
                    width: 85px;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    color: #343434;
                    text-align: right;

                    span {
                        color: #1bb3f3;
                    }
                }
            }
        }

        .image_attach_list {
            padding: 15px;
            border: 1px solid #d8d8d8;

            .item {
                position: relative;
                width: 240px;
                height: 169px;
                border: 1px solid #d8d8d8;
                border-radius: 8px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .icon_badge_del {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                }

                &.get_image {
                    padding-top: 60px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    cursor: pointer;

                    p {
                        margin-top: 20px;
                        font-size: 14px;
                        font-family: NanumSquareOTF_acEB;
                        color: var(--color-Niagara);
                    }
                }
            }
        }

        textarea {
            padding: 15px;
            width: 100%;
            min-height: 246px;
            font-size: 14px;
            color: #343434;
            border: 1px solid #d8d8d8;
            resize: none;
        }
    }

    .btns {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;

        button {
            display: flex;
            align-items: center;
            height: 32px;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            color: #ffffff;
            border-radius: 4px;
            background-color: #4593f5;

            &.btn_image {
                width: 160px;
            }

            &.btn_txt {
                width: 140px;
            }

            i {
                width: 34px !important;
                height: 34px !important;
                background-size: contain;
            }
        }
    }

    .btn_save {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 32px;
        font-size: 16px;
        font-family: NanumSquareOTF_acEB;
        color: #ffffff;
        border-radius: 4px;
        background-color: #19c77c;
    }
}