#CompetitorEmployeePop {
  width: 1200px;
  padding: 30px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 9999;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  .tit {
    margin-bottom: 10px;
    > strong {
      display: block;
      font-size: 24px;
    }
  }
  .popup_close {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  .total {
    margin-bottom: 80px;
  }
}
