@import "src/include";

#SignUpAlertAddSuccess {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 22px;
    width: 520px;
    height: 240px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;

    .icon_bulb_check {
        margin: 0 auto 20px auto;
        display: block;
        width: 65px;
        height: 65px;
    }

    h2 {
        margin-bottom: 10px;
        font-size: 22px;
        font-family: NanumSquareOTF_acB;
        text-align: center;
        color: #343434;
    }

    .noti {
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 5px;
        font-size: 12px;
        font-family: NanumSquareOTF_acB;
        line-height: 30px;
        color: #606060;

        span {
            color: #443018;
        }
    }

    .btn_confirm {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 120px;
        height: 32px;
        font-size: 16px;
        color: #ffffff;
        @include border-radius(4px);
        background-color: var(--color-Alizarin-Crimson);
    }
}
