#MobileBottomGNB {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-radius: 25px 25px 0 0;
    box-shadow: 2px -6px 4px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    z-index: 2;

    .link_signup {
        position: absolute;
        top: -46px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 340px;
        height: 36px;
        font-size: 14px;
        color: #ffffff;
        border-radius: 22.5px;
        background-color: rgba(52, 52, 52, 0.9);

        span {
            color: var(--color-Piction-Blue);
        }
    }

    ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        text-align: center;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 70px;

            a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                row-gap: 5px;
                width: 100%;
                height: 70px;
                -webkit-tap-highlight-color: transparent; /* for removing the highlight */
            }

            p {
                font-size: 12px;
                font-family: NanumSquareOTF_acB;
                line-height: 18px;
                color: var(--color-Scorpion);
            }
        }
    }

    i {
        width: 24px;
        height: 24px;
    }

    .wrap_icon {
        position: relative;

        .icon_service {
            width: 21px;
            height: 28px;
            background-image: url("../../../assets/images/common/icon-bottom-gnb-service.svg");
        }

        //&.on {
        //    .icon_service {
        //        width: 24px;
        //        height: 25px;
        //        background-image: url("../../../assets/images/common/icon-bottom-gnb-service-on.svg");
        //    }
        //
        //    .icon_service_start {
        //        display: none;
        //    }
        //}
    }

    .icon_service_start {
        position: absolute;
        top: 8px;
        right: -2px;
        width: 10px;
        height: 10px;
        background-image: url("../../../assets/images/common/icon-bottom-gnb-service-star.svg");
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-name: rotate;
        animation-direction: alternate;
    }

    .icon_home {
        background-image: url("../../../assets/images/common/icon-bottom-gnb-home.svg");

        &.on {
            background-image: url("../../../assets/images/common/icon-bottom-gnb-home-on.svg");
        }
    }

    .icon_more {
        background-image: url("../../../assets/images/common/icon-bottom-gnb-more.svg");

        &.on {
            background-image: url("../../../assets/images/common/icon-bottom-gnb-more-on.svg");
        }
    }

    @keyframes rotate {
        0%, 10% {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        90%, 100% {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg) scale(1.5);
        }
    }
}