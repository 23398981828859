#ReferencePopup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  top: -5px;
  left: 0;
  margin: inherit;
  .popup {
    width: 910px;
    z-index: 3;
    border-radius: 5px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #e7e7e7;
    background-color: #fff;
  }
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 22px 24px 17px 24px;
    p {
      margin-top: 25px;
      font-family: NanumSquareOTF_acB;
      font-size: 24px;
      letter-spacing: 0.5px;
      color: #1d334e;
    };
  }
  .search {
    width: 100%;
    border-bottom: solid 1px #f0f0f0;
    .search_input {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      width: 100%;
      input[type="text"] {
        width: 760px;
      }
      button {
        width: 97px;
        height: 40px;
        font-family: NanumSquareOTF_acB;
        font-size: 14px;
        color: #fff;
        background-color: #4593f5;
        border-radius: 4px;
        text-align: center;
        margin-left: 13px;
      }
    }
    .search_result {
      width: 100%;
      padding: 0px 44px 9px 44px;
      display: flex;
      div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: auto;
        font-family: NanumSquareOTF_acEB;
        font-size: 12px;
        color: #4593f5;
        padding: 3px 7px 3px 12px;
        margin-left: 5px;
        border-radius: 14px;
        i {
          margin-left: 5px;
        }
      }
      .exist_word {
        background-color: rgba(240, 240, 240, 0.4);
      }
      .new_word {
        background-color: rgba(107, 211, 255, 0.2);
      }
    }
  }
}
