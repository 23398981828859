.toggle-slide-container {
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #e9efff;
    padding: 20px;
  }
  .title {
    display: block;
    font-size: 20px;
  }
}
