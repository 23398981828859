#MobileService {
    padding-top: var(--mobile-header-height);
    height: var(--mobile-min-content-height);
    background-color: var(--color-Gallery-opacity-02);

    .link_list {
        padding: 20px 5%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;

        a {
            padding: 15px 5px 0 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 10px;
            height: 120px;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            color: var(--color-Mine_Shaft);
            text-align: center;
            word-break: keep-all;
            word-wrap: normal;
            border-radius: 8px;
            box-shadow: 0 2px 3px 1px var(--color-Alto-opacity-03);
            background-color: #ffffff;

            p {
                height: 32px;
            }
        }
    }
}
