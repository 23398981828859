.items-in-table{
    padding-left: 45px;
    position: relative;
}
.items-in-table::after,
.items-in-table::before{
    content: '';
    position: absolute;
    background-color: black;
    top: 50%;
    transform: translateY(-50%);
}
.items-in-table::after{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    left: 44px;
}
.items-in-table::before{
    width: 7px;
    height: 1.5px;
    left: 29px;
}
.dash-up{
    position: absolute;
    background-color: black;
    width: 1.5px;
    height: 55px;
    bottom: 50%;
    left: 29px;
}
.dash-up-0{
    height: 25px;
}
