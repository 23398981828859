#IntroServiceIntro {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0;
  > img {
    width: 800px;
  }
  > div {
    width: 800px;
  }
  .qna {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px 50px;
    gap: 20px;
    > h5 {
      font-size: 30px;
      color: #EE7326;
    }
    > .qna_item {
      display: flex;
      align-items: center;
      gap: 20px;
      p:nth-of-type(1) {
        min-width: 140px;
      }
    }
  }
  .signup_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 80%;
      height: 50px;
      border-radius: 5px;
      font-size: 20px;
      color: white;
      background: linear-gradient(to right, rgb(255, 122, 0) 32%, rgb(255, 166, 0));
    }
  }
}
