#AdminAnnualFeeCompare {
  display: block;
  margin: 0 auto;
  width: 90vw;
  padding: 20px 0;
  header {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    > div {
      display: flex;
      align-items: center;
      gap: 25px;
    }

    .tab_setting_wrapper {
      position: relative;
    }

    .tab_wrapper {
      border-radius: 13px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px #f6f5f4;
      background-color: #fff;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      position: absolute;
      right: 0;
      top: 65px;
      gap: 10px 0;
      padding: 22px 13.3px;
      label {
        min-width: 140px;
        span {
          margin-left: 6px;
          font-family: Pretendard_regular;
          font-size: 14px;
        }
        input[type="checkbox"]:checked {
          accent-color: #5762e3 !important;
        }
      }
    }
    button {
      padding: 18px 25px;
      border-radius: 31px;
      background-image: linear-gradient(245deg, #ebf8f4 54%, #ebf6ff 2%);
      display: flex;
      align-items: center;
      font-family: Pretendard_bold;
      font-size: 1rem;
      color: #0078f1;
      letter-spacing: -0.11px;
      gap: 10px;
    }

    .search_wrapper {
      display: flex;
      align-items: center;
      width: 423px;
      height: 51px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      border-radius: 30px;
      padding: 0 10px;
      justify-content: space-between;
      select {
        width: 25%;
        padding: 0 10px;
        color: #343434;
        letter-spacing: -0.09px;
        font-family: Pretendard_regular;
      }
      input[type="text"] {
        width: 65%;
        border: none;
      }
      i {
        margin-left: 10px;
      }
    }

    .select_count {
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      width: 141.9px;
      height: 33px;
      padding: 0 10px;
    }
    /* The switch - the box around the slider */
    .switch {
      position: relative;
      display: inline-block;
      width: 31px;
      height: 13px;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      width: 17px;
      height: 17px;
      left: 2px;
      bottom: -2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: #2196F3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(12px);
      -ms-transform: translateX(12px);
      transform: translateX(12px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 17px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
  section {
    margin-top: 30px;
    .data_wrapper {
      display: flex;
      @media screen and (max-width: 1600px) {
        flex-direction: column;
        gap: 20px;
      }
    }
    .compare_data {
      width: 50%;
      @media screen and (max-width: 1600px) {
        width: 90vw;
      }
      .upload_wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        min-height: 40px;
        .filebox {
          display: flex;
          gap: 0 10px;
          .upload-name {
            height: 40px;
            padding: 0 10px;
            border: 1px solid #dddddd;
            color: #999999;
          }
          .text {
            color: red;
            font-size: 14px;
          }
          label {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 100px;
            padding: 10px 20px;
            color: #fff;
            background-color: black;
            cursor: pointer;
            height: 40px;
            margin-left: 10px;
            border-radius: 5px;
          }
          .none {
            visibility: hidden;
          }
          input[type="file"] {
            position: absolute;
            width: 0;
            height: 0;
            padding: 0;
            overflow: hidden;
            border: 0;
          }
        }
        .util_wrapper {
          display: flex;
          justify-content: space-between;
          gap: 20px;
        }
        select {
          width: 120px;
          padding: 0 5px;
          border: 1px solid lightgrey;
          border-radius: 5px;
          font-family: Pretendard_bold;
          color: #343434;
        }
        .export_data {
          width: 150px;
          font-size: 18px;
          color: white;
          background-color: #ffa600;
          border-radius: 5px;
        }
        .export_data:disabled {
          background-color: lightgrey;
        }
        .btn_wrapper {
          display: flex;
          gap: 10px;
          button {
            display: flex;
            align-items: center;
            padding: 5px 10px;
            border-radius: 10px;
            background-image: linear-gradient(245deg, #ebf8f4 54%, #ebf6ff 2%);
            font-family: Pretendard_bold;
            color: #0078f1;
            i {
              margin-left: 5px;
            }
          }
        }
      }
      &_header {
        height: 60px;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #f3f7ff;
        margin-bottom: 30px;
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        > p {
          display: flex;
          justify-content: space-between;
          color: #a5a5a5;
          font-family: Pretendard_regular;
          > p {
            display: flex;
          }
          .color_blue {
            color: #0078f1;
            font-size: 18px;
            font-family: Pretendard_bold;
          }
          .count {
            display: block;
            min-width: 80px;
            text-align: right;
          }
        }
      }
      table {
        thead th {
          font-size: 13px;
        }
      }
    }
    .my-shaded-effect {
      color: #cdcdcd;
    }
  }

  .select_wrapper {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 0 10px;
    margin : 0 0 20px 35px;
    select:nth-of-type(1) {
        width: 80px;
    }
    select:nth-of-type(2) {
        width: 60px;
    }
  }

  .ag-header-cell.text-center {
    .ag-header-cell-label {
      justify-content: center;
    }
  }
}
