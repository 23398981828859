#ResearchTechEdit {
  margin: auto;
  padding-top: 85px;
  width: 880px;

  .header {
    margin-bottom: 32px;
    padding-bottom: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;

    h2 {
      font-size: 22px;
      font-family: NanumSquareOTF_acB;
      color: #343434;
    }

    .category {
      display: flex;
      align-items: center;
      column-gap: 18px;

      li {
        display: flex;
        align-items: center;
        column-gap: 12px;

        h2,
        p {
          font-size: 14px;
          color: #343434;
        }

        h2 {
          width: 50px;
          font-family: NanumSquareOTF_acB;
        }

        p {
          padding: 0 9px;
          width: 185px;
          height: 24px;
          line-height: 24px;
          border: 1px solid #d8d8d8;
        }
      }
    }
  }

  .step {
    margin-bottom: 20px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: rgba(240, 240, 240, 0.5);

    h2 {
      font-size: 14px;
      font-family: NanumSquareOTF_acEB;
      color: var(--color-Cornflower-Blue);
    }
  }

  .step1 {
    margin-bottom: 20px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    column-gap: 10px;

    h2 {
      width: 58px;
      font-size: 14px;
      font-family: NanumSquareOTF_acB;
      color: #343434;
    }

    input {
      padding: 0 9px;
      width: 812px;
      height: 32px;
      font-size: 14px;
      color: #343434;
      border: 1px solid #d8d8d8;
    }
  }

  .step2 {
    margin-bottom: 20px;
  }

  .graph_item+.graph_item {
    margin-top: 30px;
  }

  .semi_title {
    margin-bottom: 10px;
    padding-left: 30px;
    font-size: 14px;
    font-family: NanumSquareOTF_acEB;
    color: var(--color-Cornflower-Blue);
  }

  .description_area {
    margin-bottom: 10px;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .description_title {
      width: 60px;
      font-size: 14px;
      font-family: NanumSquareOTF_acB;
    }

    .cur_letter_count {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        width: 120px;
        font-size: 14px;
        font-family: NanumSquareOTF_acB;
      }

      p {
        font-size: 14px;
        font-family: NanumSquareOTF_acB;

        span {
          color: var(--color-Dodger-Blue);
        }
      }
    }
  }

  .content_area {
    display: flex;
    align-items: center;
    column-gap: 7px;

    .graph {
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 375px;
      height: 360px;
      border: 1px solid var(--color-Alto);

      h2 {
        font-size: 14px;
        font-family: NanumSquareOTF_acB;
        color: var(--color-Scorpion);
      }
    }

    textarea {
      padding: 15px;
      width: 540px;
      height: 360px;
      font-size: 14px;
      line-height: 1.3;
      border: 1px solid var(--color-Alto);
      resize: none;
      word-break: keep-all;
    }
  }

  table {
    margin-bottom: 20px;

    thead {
      background-color: var(--color-Gallery);

      th {
        color: var(--color-Mine_Shaft);
      }
    }
    th:nth-child(3),
    td:nth-child(3) {
      padding-left: 15px;
      text-align: left;
    }
  }

  .btn_save {
    margin: 70px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 32px;
    font-size: 16px;
    font-family: NanumSquareOTF_acEB;
    color: #ffffff;
    border-radius: 4px;
    background-color: #19c77c;
  }

  canvas {
    background-color: #ffffff;
  }
}