#AdminTaskPosting {
    margin: auto;
    padding-top: 42px;
    width: 880px;

    .header {
        margin-bottom: 28px;
        display: flex;
        justify-content: space-between;

        h2 {
          font-size: 22px;
        }

        .btns {
            display: flex;

            > button {
                width: 132px;
                height: 32px;
                font-size: 16px;
                color: #ffffff;
                text-align: center;
            }

            .btn_cancle {
                background-color: #96c719;
            }

            .btn_delete {
                margin-left: 8px;
                background-color: #e92f2c;
            }

            .btn_copy {
                background-color: #00a545;
            }

            .btn_write {
                margin-left: 8px;
                background-color: #4593f5;
            }
        }
    }

    .title {
        margin-bottom: 12px;
        display: flex;
        width: 79%;

        h2 {
            min-width: 100px;
            width: 100px;
            line-height: 30px;
            font-family: NanumSquareOTF_acB;
        }

        > p {
            min-width: 240px;
            line-height: 30px;

            span {
                display: flex;
                align-items: center;
            }
        }

        input[type="text"] {
            width: 786px;
        }
    }

    .wrap_title {
        display: flex;
        align-items: center;
        .title {
            width: auto;
        }

        .semi {
            width: 35px;
        }

        .value {
            width: 240px;
        }

        input[type='text'] {
            width: 300px !important;
            padding: 5px;
        }
        input[type="checkbox"] {
            width: 60px;
            height: 20px;
        }
        .react-datetime-picker__wrapper {
            width: 300px;
        }
    }

    .wrap_select {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 12px;

        label {
            min-width: 92px;
            font-size: 14px;

            input {
                margin-right: 8px;
            }
        }
    }

    .file {
        margin-top: 41px;
        display: flex;
        align-items: center;

        h2 {
            margin-right: 20px;
            width: 100px;
            line-height: 30px;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
        }
    }

    .input {

        h2 {
            margin-bottom: 8px;
            font-family: NanumSquareOTF_acB;
        }
    }

    input {
        height: 30px;
    }

    input[type="text"], textarea {
        border: solid 0.5px rgba(0, 0, 0, 0.56);
    }

    .btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 16px;
    }

    .btn_add {
        display: block;
        width: 100px;
        height: 32px;
        font-size: 16px;
        font-family: NanumSquareOTF_acB;
        color: #ffffff;
        border-radius: 4px;
        background-color: #05ac9e;
    }

    .btn_remove {
        width: 100px;
        height: 32px;
        font-size: 16px;
        font-family: NanumSquareOTF_acB;
        color: #ffffff;
        border-radius: 4px;
        background-color: #e92f2c;
    }

    textarea {
        padding: 14px;
        width: 100%;
        height: 711px;
        resize: none;
    }

    .sun-editor-editable * {
        font-size: 13px !important;
    }
}
