#IntroTaskSummary {
  width: 1276px;
  height: 582px;
  border-radius: 30px;
  box-shadow: 0 2px 4px 0 #d8d8d8;
  background-color: #fff;
  padding: 0 10.5px 0 0;
  display: flex;
  > div:first-child {
    width: 230px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    box-shadow: 0 2px 4px 0 #d8d8d8;
    background-color: #fff;
    .category_search {
      display: flex;
      align-items: center;
      margin: 24px auto 0 auto;
      width: 200px;
      height: 50px;
      border-radius: 15px;
      box-shadow: 1px 2px 5px 0 #d8d8d8;
      border-style: solid;
      border-width: 3px;
      border-image-source: linear-gradient(to left,
              #00dd77,  #0078f1);
      border-image-slice: 1;
      background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to left, #0d7, #0078f1);
      background-origin: border-box;
      background-clip: content-box, border-box;
      > input[type="text"] {
        border:none;
        height: 20px;
        width: 150px;
      }
    }
    .category_tab {
      display: flex;
      justify-content: center;
      gap: 5px;
      margin-top: 11px;
      > p {
        border-radius: 14px;
        background-color: rgba(0, 205, 144, 0.2);
        font-size: 14px;
        padding: 6px 8px;
        color: #0078f1;
      }
      > .active {
        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.5);
        background-color: #0078f1;
        color: #FFFFFF;
      }
    }
    > ul {
      padding: 15px 0 15px 25px;
      height: 450px;
      overflow-y: scroll;
      overflow-x: hidden;
      > li {
        width: 220px;
        height: 49px;
        color: #343434;
        cursor: pointer;
      }
      .active {
        color: #0078f1;
      }
    }
  }
  > div:last-child {
    padding: 20px 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px 10px;
    .korea_item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 330px;
      height: 170px;
      border: none !important;
      background-size: cover;
      background-repeat: no-repeat;

      &.type_1 {
        background: url("../../../assets/images/intro/bg-korean-main-type-1.svg");
        background-size: cover;
      }

      &.type_2 {
        background: url("../../../assets/images/intro/bg-korean-main-type-2.svg") no-repeat center center;
        background-size: cover;
      }

      p {
        margin-bottom: 5px;
        font-size: 18px;
        font-family: NanumSquareOTF_acB;
        text-align: center;
      }

      h2 {
        margin-bottom: 10px;
        max-width: 350px;
        font-size: 24px;
        font-family: NanumSquareOTF_acEB;
        color: #1d334e;
        text-align: center;
        word-break: keep-all;
        white-space: pre-wrap;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 88px;
        height: 30px;
        color: var(--color-Cornflower-Blue);
        border-radius: 14px;
        background-color: var(--color-Malibu-opacity-02);
      }
    }
  }
}
