#InventorList {
  width: 1280px;
  margin: 45px auto;
  > .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    h2 {
      font-size: 22px;
    }
    button {
      width: 150px;
      height: 32px;
      background-color: #e92f2c;
      border-radius: 4px;
      font-size: 16px;
      color: #FFFFFF;
      margin-right: 10px;
    }
  }
  .inventor_search_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin: 20px 0;
    border-radius: 5px;
    border: solid 0.5px #d8d8d8;
    background-color: #fff;
    width: 400px;
    input[type="text"] {
      border: none;
      width: 90%;
    }
    i {
      cursor: pointer;
    }
  }
  .inventor_text {
    margin: 20px 0 10px 0;
    font-size: 22px;
  }
  .inventor_list {
    thead {
      background-color: black;
      tr th{
        color: #FFFFFF;
      }
    }
    tbody tr:hover {
      background-color: rgba(197, 223, 248, 0.5);
      cursor: pointer;
    }
    tbody {
      .inventor_child_table {
        width: 1280px;
        thead {
          background-color: rgba(240, 240, 240, 0.2);
          th {
            color: #343434;
          }
        }
      }
      input[type="text"] {
        width: 90%;
      }
      .inventor_info {
        background-color: rgba(240, 240, 240, 0.2);
      }
      td p {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn_add {
        padding: 5px 4px;
        font-size: 10px;
        color: #343434;
        background-color: rgba(255, 166, 39, 0.2);
      }
      .btn_edit {
        font-size: 12px;
        color: #fff;
        border-radius: 8px;
        background-color: #96c818;
        padding: 4px 12px;
      }
      .btn_save {
        border-radius: 8px;
        background-color: #05ac9e;
        padding: 4px 12px;
        font-size: 12px;
        color: #fff;
        margin-right: 5px;
      }
      .btn_cancel {
        border-radius: 9px;
        background-color: #e92f2c;
        padding: 4px 12px;
        font-size: 12px;
        color: #fff;
      }
    }
  }
}
