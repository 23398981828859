@import "../../../include";

#CyberCompanyAdd {
    @import "Common.scss";
    min-height: calc(100vh - 45px);

    .page_title{
        margin-bottom: 10px;
    }

    .desc {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 52px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        border: none;
    }

    .wrap_search {
        margin: 7px 0 16px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        input {
            padding: 0 11px;
            width: 268px;
            height: 52px;
            font-size: 12px;
            border: 1px solid #d8d8d8;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        .icon_search_white {
            width: 52px;
            height: 52px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            background: url(../../../assets/images/cyber/icon-search-white-cyber.svg) no-repeat;
            background-color: #00a545;
            background-position: center;
        }
    }

    .notify {
        display: flex;
        width: 90%;
        margin: auto;
        padding-bottom: 5px;
        justify-content: start;
        align-items: center;

        h2 {
            width: 52px;
            font-size: 14px;
            font-family: NanumSquareOTF_acEB;
            color: #343434;
        }

        p {
            width: calc(100% - 52px);
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #606060;
            word-break: keep-all;
            span{
                width: calc(100% - 20px);
                padding-left: 3px;
                line-height: 1.2;
            }
        }
    }

    .enroll{
        display: flex;
        width: 90%;
        margin: 0 auto 10px;
        justify-content: flex-end;
        align-items: center;
        .btn_manual_add {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 92px;
            height: 32px;
            font-size: 12px;
            @include border-radius(5px);
            border: 1px solid #00a545;
            background-color: #ffffff;
            cursor: pointer;
        }
    }

    .wrap_add {
        margin: auto;
        width: 90%;
        border-top: 2px solid #00a545;

        ul {
            height: 159px;
            overflow-y: scroll;

            li {
                padding: 0 8px;
                display: flex;
                align-items: center;
                column-gap: 4px;
                height: 53px;
                font-size: 12px;
                border-bottom: 1px solid #e8e8e8;
            }
        }
    }

    .wrap_btn {
        margin: 10px 0;
        width: 100%;

        button {
            margin: 8px auto 0 auto;
        }
    }

    .alert {
        margin: auto;
        display: flex;
        column-gap: 4px;
        width: 90%;
        font-size: 12px;
        line-height: 18px;
        color: #000;
    }
}