.fifth {
  .rnd_plan {
    padding-left: inherit;

    > div {
      padding-left: 24px;
      padding-right: 15px;
    }
  }
  table {
    td {
      font-family: NanumSquareOTF_acR;
      font-size: 14px;
      color: #343434;
    }
  }
  .plan_text {
    padding-bottom: 5px;
  }
  input[type="text"]::placeholder {
    font-family: NanumSquareOTF_acR;
    font-size: 14px;
    color: #959595;
  }
  table {
    thead {
      background-color: #f0f0f0;
    }
    th {
      color: #343434;
    }
    tbody {
      td {
        input[type="text"] {
          width: calc(100% - 5px);
        }
      }
    }
  }
  .rnd_cost_wrapper {
    width: 970px;
    overflow-x: scroll;
    .rnd_cost_table {
      .total_text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95%;
        height: 42px;
        border-radius: 5px;
        background-color: #f0f0f0;
        margin-left: 2%;
      }
    }
  }
  .name_research {
    display: flex;
    align-items: center;
    margin-top: 20px;
    input[type="text"] {
      width: 365px;
      margin-left: 21px;
    }
  }

  .overflow_plan_table_div {
    width: 970px;
    overflow-x: scroll;
  }
  .overflow_plan_table {
    thead {
      tr {
        input {
          heigth: 24px;
        }
      }
    }
  }
  .rnd_target_table {
    .total_text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 95%;
      height: 42px;
      border-radius: 5px;
      background-color: #f0f0f0;
    }
    select {
      font-size: 14px;
      width: 100%;
    }
  }
}
