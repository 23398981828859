@import "src/include";

#MarketInfoMain {
    margin: auto;
    padding: 40px 0 100px 0;
    width: 1280px;

    &.show {
        .MarketCart {
            display: block !important;
        }
    }

    .tutorial-box {
        margin: 0 auto 35px auto;
        padding-left: 45px;
        display: flex;
        column-gap: 70px;
        width: 880px;
        height: 110px;
        border-radius: 20px;
        background-color: rgba(241, 241, 241, 0.3);

        .wrap_image {
            display: flex;
            align-items: center;
        }

        img {
            width: 63px;
            height: 55px;
        }

        .inner {
            padding-top: 10px;
            height: 100%;

            h2 {
                display: flex;
                align-items: center;
                font-size: 18px;
                font-family: NanumSquareOTF_acEB;
                line-height: 30px;
                color: #343434;
            }

            p {
                font-size: 14px;
                font-family: NanumSquareOTF_acB;
                color: #606060;
            }
        }
    }

    .marketInfoMain {

        .wrap_category {
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .category {
                display: flex;
                align-items: center;
                column-gap: 10px;
                font-size: 22px;
                font-family: NanumSquareOTF_acB;
                color: #343434;
            }

            a {
                padding: 0 0 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 140px;
                height: 30px;
                border-radius: 9px;
                background-color: #eff3fb;

                span {
                    display: block;
                    width: 87px;
                    font-size: 12px;
                    font-family: NanumSquareOTF_acB;
                    color: #606060;
                }
            }
        }

        &_header {
            margin-bottom: 50px;
            display: flex;
            align-items: center;

            .inner {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }

            .s_category {
                display: flex;
                align-items: center;
                column-gap: 30px;

                li {
                    font-size: 18px;
                    font-family: NanumSquareOTF_acB;
                    line-height: 24px;
                    color: #343434;
                    cursor: pointer;
                }

                li.active {
                    color: var(--color-Web-Orange);
                }
            }

            .keyword_find {
                display: flex;
                align-items: center;

                h2 {
                    margin-right: 10px;
                    width: 90px;
                    font-size: 16px;
                    font-family: NanumSquareOTF_acEB;
                    color: #1d334e;
                }

                input {
                    margin-right: 10px;
                    padding: 0 10px;
                    width: 300px;
                    height: 32px;
                    font-size: 14px;
                    border: 1px solid #aaaaaa;
                }
            }
        }

        &_list {
            display: flex;
            align-items: center;
            column-gap: 7px;
            row-gap: 15px;
            flex-wrap: wrap;
            width: 100vw;
            max-width: 1280px;

            @media screen and (max-width: 850px){
                justify-content: center;
            }

            p {
                font-size: 18px;
                font-family: NanumSquareOTF_acB;
                color: #606060;
            }
        }

        &_item {
            padding: 5px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            width: 250px;
            height: 251px;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            background-color: #fff;

            img {
                width: 240px;
                height: 169px;
                object-fit: contain;
            }

            .info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: 5px;
                height: 30px;

                h2 {
                    width: 150px;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    line-height: 16px;
                    color: #343434;
                    @include text-ellipsis(1);
                }

                p {
                    font-size: 14px;
                    color: #606060;
                }
            }

            .etc {
                display: flex;
                justify-content: space-between;
                align-items: center;

                button {
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                    background-color: transparent;

                    span {
                        font-size: 14px;
                        font-family: NanumSquareOTF_acB;
                        color: #343434;
                    }
                }
            }

            .checkbox {
                .value {
                    color: var(--color-Web-Orange);
                }
            }
        }


    }

    @media screen and (max-width: 850px) {
        padding-top: 80px;
        padding-bottom: 200px;


        .marketInfoMain {
            .wrap_category {
                margin: 0 5vw 10px 5vw;
                width: 90vw;

                .category {
                    i {
                        display: none;
                    }

                    p {
                        font-size: 18px;
                        font-family: NanumSquareOTF_acEB;
                        color: #1d334e;
                    }
                }
            }

            .marketInfoMain_header {
                margin-bottom: 25px;
                padding: 0 20px;
                width: 100vw;

                .s_category {
                    li {
                        font-size: 16px;
                    }
                }
            }
        }

        .floating_cart {
            position: fixed;
            right: 20px;
            bottom: 42px;
            display: flex !important;
            align-content: center;
            justify-content: center;
            width: 150px;
            font-size: 14px;
            font-family: NanumSquareOTF_acEB;
            color: #ffffff;
            border-radius: 28px;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
            background-color: #ffa600;

            i {
                height: 56px;
                background-position: center;
            }

            p {
                line-height: 56px;
            }
        }
    }

    @media screen and (max-width: 850px){
        .marketInfoMain_item {
            width: 45vw;
            height: 202px;

            .info,
            .etc {
                display: block;
            }

            .info {
                h2 {
                    font-family: NanumSquareOTF_acEB;
                }

                p {
                    font-size: 12px;
                }
            }

            .etc {
                button {
                    margin-left: auto;

                    span {
                        font-size: 12px;
                        color: #606060;
                    }
                }
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .checkbox {
            justify-content: left;
        }
    }
}
