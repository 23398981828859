@import "src/include";
#docTradeSampleRegister{
	width: 880px;
	margin: 0 auto;
	padding-top: 85px;
	.sample-register{
		margin-bottom: 45px;
		&_title{
			font-family: NanumSquareOTF_acB;
			font-size: 22px;
			color: #343434;
			padding-bottom: 7px;
			border-bottom: 1px solid #e7e7e7;
			margin-bottom: 26px;
		}
		&_nav-wrap{
			position: relative;
			height: 30px;
		}
		&_nav{
			display: flex;
			justify-content: start;
			align-items: center;
		}
		&_list{
			width: 110px;
			height: 100%;
			line-height: 30px;
			margin-right: 10px;

			&.active{
				.sample-register_list_link{
					font-family: NanumSquareOTF_acEB;
					color: var(--color-Web-Orange);
				}
			}
			&_link{
				display: block;
				width: 100%;
				height: 30px;
				font-size: 18px;
				font-family: NanumSquareOTF_acB;
				color: #343434;
				background-color: #ffffff;
			}
		}
		&_highlight{
			display: block;
			position: absolute;
			top: 50%;
			right: 0;
			font-size: 12px;
			transform: translateY(-50%);
			font-family: NanumSquareOTF_acB;
			color: #343434;
		}
	}
}