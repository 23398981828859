#PatentETC {
  margin: auto;
  padding: 30px 0px;
  width: 880px;

  .header {
    margin-bottom: 30px;
    position: relative;

    .title {
      margin-bottom: 13px;
      font-size: 22px;
    }

    .patent_info {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);
      word-break: break-word;

      .flag {
        margin-left: 10px;
      }
    }

    .btns {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;

      .btn_upload {
        width: 140px;
        height: 32px;
        font-size: 16px;
        color: #ffffff;
        background-color: #e92f2c;
      }
      .btn_save {
        margin-left: 12px;
        width: 64px;
        line-height: 32px;
        background-color: #19c77c;
        font-size: 16px;
        color: #ffffff;
      }
    }
  }

  table {
    th:nth-child(2),
    td:nth-child(2) {
      width: 60%;
    }

    td:last-child {
      color: rgba(255, 0, 0, 0.8);
      cursor: pointer;
    }
  }
}
