@import "src/include";
.oneclick{
	width: 810px;
	height: 203px;
	background: url(../../../assets/images/common/bg_main_oneclick.svg) no-repeat 50% 50%;
	background-size: cover;
	padding: 20px 25px;
	&_title{
		font-family: NanumSquareOTF_acEB;
		font-size: 28px;
		color: #1d334e;
		margin-bottom: 10px;
	}
	&_text{
		width: 340px;
		line-height: 18px;
		margin-bottom: 43px;
		word-break: keep-all;
		font-size: 14px;
		color: #343434;
	}
	.search{
		display: flex;
		height: 40px;
		justify-content: start;
		align-items: center;
		&_links{
			height: 100%;
			&_btn{
				display: block;
				width: 130px;
				height: 100%;
				background: url(../../../assets/images/common/icon_btn_list_orange.png) no-repeat 50% 50%;
				background-size: cover;
				margin-right: 10px;
			}
		}
		&_form{
			display: flex;
			height: 100%;
			margin-right: 10px;
			justify-content: start;
			align-items: center;
			&_input{
				width: 314px;
				height: 100%;
				border: none;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				padding-left: 15px;
			}
			&_btn{
				position: relative;
				width: 50px;
				height: 100%;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				background-color: #ff7a00;
				text-indent: -9999px;
				&::after{
					position: absolute;
					content: '';
					width: 24px;
					height: 24px;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
					background: url(../../../assets/images/common/icon-search-white.svg) no-repeat 50% 50%;
					background-size: cover;
				}
			}
			
		}
		&_notice{
			width: 151px;
			height: 32px;
			background: url(../../../assets/images/common/icon_btn_notice.png) no-repeat 50% 50%;
			background-size: cover;
		}
	}
}