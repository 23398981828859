@import "src/include";

#CyberMain {
    @import "Common.scss";
    width: 100%;
    min-height: 100vh;
    word-spacing: normal;
    .cybermain{
        &_boxTop{
            width: 100%;
            height: 140px;
            height: 90px;
            padding: 11px 0;
            background-color: #E5F6EC;
            margin-bottom: 11px;
            &_title{
                font-family: NanumSquareOTF_acEB;
                font-size: 20px;
                color: #343434;
                text-align: center;
                margin-bottom: 10px;
                word-spacing: 5px;
            }
            &_text{
                font-size: 12px;
                color: #343434;
                text-align: center;
                line-height: 16px;
                .style{
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    color: #343434;
                }
            }
        }
        &_content{
            &_text{
                font-size: 12px;
                color: #343434;
                text-align: center;
                line-height: 16px;
                margin-bottom: 10px;
                .style{
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    color: #343434;
                }
            }
            &_sub-text{
                margin-bottom: 7px;
                &_list{
                    font-family: NanumSquareOTF_acB;
                    font-size: 12px;
                    line-height: 20px;
                    color: var(--color-Niagara);
                    text-align: center;
                    .icon-style{
                        position: relative;
                        &::after{
                            position: absolute;
                            content: '';
                            width: 14px;
                            height: 14px;
                            background: url(../../../assets/images/cyber/icon_check.svg) no-repeat 50% 50%;
                            background-size: cover;
                            top: 50%;
                            left: -16px;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
            &_title{
                font-family: NanumSquareOTF_acEB;
                font-size: 20px;
                color: #343434;
                text-align: center;
                margin-bottom: 10px;
                word-spacing: 5px;
            }
            &_list-wrap{
                width: 240px;
                margin: 0 auto;
            }
            &_list{
                position: relative;
                display: block;
                background: linear-gradient(to right, #6bd3ff -4%, #00cd90 104%);
                margin-top: 24px;
                border-radius: 8px;
                padding: 3px;
                &:first-child{
                    margin-top: 0;
                    &::after{
                        display: none;
                    }
                }
                &::after{
                    position: absolute;
                    content: '';
                    width: 24px;
                    height: 24px;
                    top: -24px;
                    left: 50%;
                    transform: translateX(-50%);
                    background: url(../../../assets/images/cyber/icon-arrow-down.svg) no-repeat 50% 50%;
                    background-size: cover;
                }
                &_text{
                    text-align: center;
                    border-radius: 5px;
                    background: #fff;
                    padding: 3px 8px;
                    &_title{
                        font-family: NanumSquareOTF_acB;
                        font-size: 14px;
                        color: #343434;
                    }
                    &_subtitle{
                        font-family: NanumSquareOTF_acB;
                        font-size: 12px;
                        color: #747474;
                    }
                }
            }
            &_sub-list{
                font-family: NanumSquareOTF_acB;
                font-size: 12px;
                color: #959595;
                line-height: 16px;
                text-align: center;
                word-spacing: -1px;
                margin-top: 6px;
            }
        }
    }

    a {
        margin: 20px auto;
        display: block;
        font-size: 12px;
        font-family: NanumSquareOTF_acB;
        text-align: center;
    }
}