.tabs-navigator {
  & > ul:first-child {
    display: flex;
    font-size: 1.6rem;

    & > li {
      cursor: pointer;
      flex-grow: 1;
      text-align: center;
      padding: 15px 0;
      border-radius: 24px 24px 0 0;
    }
  }

  .error-div {
    color: red;
    font-size: 1.4rem;
    text-align: center;
  }

  & > ul:last-child {
    background-color: #fff7f4;
    border-radius: 24px;
    padding: 30px;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    gap: 12px;

    & > li {
      background-color: white;
      border-radius: 6px;
      padding: 0 10px;

      & > div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 10px 0;

        p {
          font-size: 1.2rem;
        }
        i {
          transition: all 300ms;
        }
      }

      & > div:last-child {
        display: grid;
        transition: all 300ms;

        p {
          font-size: 0.9rem;
          overflow: hidden;
        }
      }
    }
  }
}
