@import "src/include";
@import "src/icons";
#ChatAdmin {
	.pc {
		.chatAdmin {
			padding-top:42px;
			&_title {
				width:880px;
				margin:0 auto 30px;
				&_name {
					display:flex;
					justify-content:space-between;
					margin-bottom:8px;
				}
				&_head {
					font-size:22px;
				}
				&_button {
					display:flex;
					width:132px;
					height:32px;
					font-size:18px;
					color:#fff;
					background-color: var(--color-Alizarin-Crimson);
					justify-content:center;
					align-items:center;
					text-align:center;
				}
				&_text {
					height:21px;
					line-height:21px;
					color:rgba(0,0,0,0.87);
					margin-bottom:20px;
				}
				&_search-wrap {
					display:flex;
					width:557px;
					justify-content:center;
					margin:0 auto;
				}
				&_search {
					display:flex;
					width:calc(100% - 47px);
					border:0.5px solid rgba(0,0,0,0.54);
					&_select {
						width:100px;
						height:32px;
						padding-left:20px;
						font-size:14px;
					}
					&_input {
						width:410px;
						height:32px;
						padding-left:14px;
						background-color:transparent;
						border:none;
						font-size:14px;
					}
					&_button {
						position:relative;
						width:32px;
						height:32px;
						text-indent:-9999px;
						margin-left:15px;
						background-color:transparent;
						&::after {
							position:absolute;
							content:"";
							width:32px;
							height:32px;
							background:url(../../../assets/images/common/icon-noun-search.svg) no-repeat 50% 50%;
							background-size:cover;
							top:50%;
							left:50%;
							transform:translateX(-50%) translateY(-50%);
						}
					}
				}
			}
			&_chat {
				display:flex;
				width:1248px;
				height:830px;
				margin:0 auto;
				justify-content:space-between;
				.box-style {
					border-radius:5px;
					box-shadow:0 2px 3px 0 rgba(0,0,0,0.2);
					border:solid 1px #e7e7e7;
					background-color:#fff;
				}
				.num-style{
					display: inline-block;
					min-width: 16px;
					max-width: 36px;
					height: 16px;
					text-align: center;
					background-color: #f00;
					border-radius: 16px;
					padding: 3px 4px 3px 4px;
					font-family: NanumSquareOTF_acB;
					font-size: 11px;
					color: #fff;
				}
				&_info {
					width:316px;
					&_top {
						width:100%;
						display:flex;
						height:44px;
						align-items:center;
						justify-content:space-between;
						.top-utill {
							display:flex;
							font-family:NanumSquareOTF_acB;
							font-size:14px;
							justify-content:start;
							&_standard {
								font-family:NanumSquareOTF_acB;
								height:30px;
								margin:0 10px;
								padding:0 10px;
								background-color:transparent;
							}
							&_notread {
								font-family:NanumSquareOTF_acB;
								height:30px;
								margin:0 10px;
								padding:0 10px;
								background-color:transparent;
							}
						}
						.top-button {
							width:24px;
							height:24px;
							margin-right:14px;
							background-size:cover;
						}
					}
					&_content {
						height: calc(100% - 44px);
						overflow-y: auto;
						.depth1 {
							&_title {
								position:relative;
								width:100%;
								height:40px;
								text-align:left;
								padding:0 14px 0 26px;
								font-family:NanumSquareOTF_acB;
								font-size:14px;
								color:#fff;
								background-color:#000;
								cursor: default;
								&::after{
									position: absolute;
									content: '';
									width: 24px;
									height: 24px;
									background: url(../../../assets/images/common/icon-expand-more-white-bold.svg) no-repeat 50% 50%;
									top: 50%;
									right: 14px;
									transform: translateY(-50%) rotate(180deg);
									cursor: pointer;
									transition: transform 300ms ease-in-out ;
								}
								&.on{
									&::after{
										transform: translateY(-50%) rotate(0deg);
										transition: transform 300ms ease-in-out ;
									}
									& + .depth2-wrap{
										height: 100%;
										transition: opacity 300ms ease-in-out ;
										opacity: 1;
									}
								}
							}
						}
						.depth2-wrap{
							height: 0;
							overflow: hidden;
							transition: opacity 300ms ease-in-out ;
							opacity: 0;
						}
						.depth2{
							width: 100%;
							height: 70px;
							display: flex;
							justify-content: space-between;
							align-items: center;
							border-bottom: 1px solid #e7e7e7;
							background-color: #fff;
							&.admin-style{
								background-color: rgba(0, 149, 174, 0.1);
							}
							&.numtext-style{
								.depth2_chat_text{
									color: #000;
								}
							}
							&_img{
								position: relative;
								width: 60px;
								height: 100%;
							}
							&_img-content{
								position: absolute;
								width: 40px;
								height: 40px;
								top: 50%;
								left: 50%;
								transform: translateX(-50%) translateY(-50%);
								border-radius: 50%;
								object-fit: cover;
								cursor: pointer;
							}
							&_chat{
								width: calc(100% - 60px);
								height: 100%;
								padding: 6px 0 10px;
								&_title-wrap{
									display: flex;
									width: 100%;
									height: 18px;
									margin: 0 auto 5px;
									justify-content: space-between;
									align-items: center;
								}
								&_title{
									max-width: calc(100% - 50px);
									font-family: NanumSquareOTF_acB;
									font-size: 14px;
									color: rgba(0, 0, 0, 0.87);
									@include text-ellipsis(1);
								}
								&_time{
									width: 50px;
									margin-right: 14px;
									font-size: 12px;
									letter-spacing: -.4px;
									color: rgba(0, 0, 0, 0.87);
								}
								&_text-wrap{
									display: flex;
									width: 100%;
									height: 32px;
									justify-content: space-between;
									align-items: center;
								}
								&_text{
									max-width: calc(100% - 50px);
									height: 100%;
									line-height: 1.4;
									font-size: 12px;
									color: rgba(0, 0, 0, 0.54);
									@include text-ellipsis(2);
								}
								&_num{
									width: 50px;
									height: 100%;
									text-align: center;
									padding-right: 14px;
								}
							}
						}
						.respond {
							.depth1_title{
								background-color: var(--color-Bondi-Blue);
							}
						}
					}
				}
				&_talk {
					width:600px;
					&_top{
						display: flex;
						width: 100%;
						height: 48px;
						justify-content: start;
						align-items: center;
						padding: 0 9px 0 15px;
						&_title{
							font-family: NanumSquareOTF_acB;
							font-size: 22px;
							padding-right: 50px;
						}
						&_date{
							font-size: 12px;
							color: rgba(0, 0, 0, 0.87);
						}
					}
					&_content{
						height: calc(100% - 48px);
						padding: 8px 9px 8px 15px;
						overflow-y: auto;
						.chat{
							&_log{
								text-align: left;
								margin-bottom: 10px;
								.message{
									display: inline-block;
									max-width: 300px;
									line-height: 1.3;
									padding: 8px 8px 8px 12.5px;
									font-size: 14px;
									color: #000;
									background-image: none;
									background-color: #ddd;
									border-radius: 12.5px;
									word-spacing: 0;
									margin-bottom: 6px;
								}
								.sender_info{
									padding-left: 12px;
									margin-bottom: 12px;
								}
								.time{
									font-size: 12px;
									color: rgba(0, 0, 0, 0.87);
									padding: 0 0 0 18px;
								}
								&.my_chat{
									text-align: right;
									.message{
										max-width: 330px;
										padding: 8px 12.5px 8px 8px;
										color: #fff;
										background-image: linear-gradient(to right, #1bb6db, #1e7af1);
									}
									.time{
										padding: 0 18px 0 0;
									}
								}
							}
							&_end{
								text-align: center;
								margin-top: 20px;
								&_title{
									font-size: 14px;
									color: var(--color-Bondi-Blue);
								}
								&_date{
									height: 15px;
									line-height: 15px;
									font-size: 12px;
									color: rgba(0, 0, 0, 0.87);
								}
							}
						}
					}
				}
				&_client {
					width: 316px;
					&_info{
						height: 443px;
						margin-bottom: 10px;
						padding: 0 16px 10px;
						&_list-wrap{
							.title{
								font-family: NanumSquareOTF_acB;
								font-size: 14px;
							}
							.info{
								font-size: 14px;
							}
						}
						&_title-wrap{
							display: flex;
							width: 100%;
							justify-content: space-between;
							align-items: center;
							margin: 15px 0;
							.title{
								color: var(--color-Bondi-Blue);
							}
						}
						&_list{
							display: flex;
							width: 100%;
							justify-content: space-between;
							align-items: center;
							padding: 14px 0;
							border-bottom: 1px solid #e7e7e7;
						}
					}
					&_pay{
						height: 378px;
						&_top{
							display: flex;
							width: 100%;
							height: 40px;
							padding: 0 16px 0 26px;
							background-color: #fff;
							justify-content: space-between;
							align-items: center;
							.title{
								font-family: NanumSquareOTF_acB;
								font-size: 14px;
							}
							.icon_more_black{
								transform: rotate(90deg);
								transition: transform 300ms ease-in-out;
							}
							&.on{
								.icon_more_black{
									transform: rotate(-90deg);
									transition: transform 300ms ease-in-out;
								}
								& + .chatAdmin_chat_client_pay_content{
									.chatAdmin_chat_client_pay_content_list-wrap{
										height: auto;
										opacity: 1;
										transition: opacity 300ms ease-in-out;
									}
								}
							}
						}
						&_content{
							height: calc(100% - 40px);
							overflow-y: auto;
							&_list-wrap{
								height: 0;
								opacity: 0;
								overflow: hidden;
								transition: opacity 300ms ease-in-out;
							}
							&_list{
								height: 76px;
								border-bottom: 1px solid #e7e7e7;
								&.admin-style{
									background-color: rgba(0, 149, 174, 0.1);
								}
								.title{
									height: 42px;
									font-family: NanumSquareOTF_acB;
									font-size: 14px;
									color: rgba(0, 0, 0, 0.87);
									padding: 10px 15px 0 8px;
									margin: 0 auto 10px;
									@include text-ellipsis(2);
								}
								.info{
									display: flex;
									padding: 0 15px 0 8px;
									justify-content: space-between;
									align-items: center;
									&_charge{
										font-size: 14px;
										color: rgba(0, 0, 0, 0.87);
									}
									&_date{
										font-size: 12px;
										color: rgba(0, 0, 0, 0.87);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}