@import "src/include";

#CompetitorInfoNew {
  z-index: 999;
  width: 1500px;
  max-height: 800px;
  overflow: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  border-radius: 30px;
  box-shadow: 0 2px 4px 0 #d8d8d8;

  max-width: 1500px;
  margin: 0 auto;

  .close {
    position: absolute;
    right: 30px;
    top: 30px;
    background: transparent;
  }
  button {
    background-color: transparent;
  }

  table {
    background-color: #fff;

    th {
      background-color: #dcdcdc;
      color: #848484;
      border: 1px solid #cfcfcf;
    }

    td {
      color: #595959;
    }

    td.bg {
      background-color: #f4f4f4;
    }
  }

  .best {
    background-color: #1f5ad8;
  }

  .good {
    background-color: #14a569;
  }

  .normal {
    background-color: #ffc801;
  }

  .weak {
    background-color: #ff6f6e;
  }

  .grade_ss {
    background-color: #483acc;
  }

  .grade_s {
    background-color: #6a77fc;
  }

  .grade_a {
    background-color: #6a77fc;
  }

  .grade_b {
    background-color: #00d8c0;
  }

  .grade_c {
    background-color: #ffc801;
  }

  .grade_d {
    background-color: #ff6f6e;
  }

  .grade_e {
    background-color: #848484;
  }

  .grade_n {
    background-color: #dcdcdc;
  }

  .grade_ss_txt {
    color: #483acc;
  }

  .grade_s_txt {
    color: #6a77fc;
  }

  .grade_a_txt {
    color: #6a77fc;
  }

  .grade_b_txt {
    color: #00d8c0;
  }

  .grade_c_txt {
    color: #ffc801;
  }

  .grade_d_txt {
    color: #ff6f6e;
  }

  .grade_e_txt {
    color: #848484;
  }

  .grade_n_txt {
    color: #dcdcdc;
  }

  .amount {
    text-align: right;
    padding-right: 20px;
  }

  > header {
    button {
      padding: 10px 14px;
      border-radius: 20px;
      border: 1px solid #4e7ce9;
      background: transparent;
      color: #4e7ce9;
      margin-right: 6px;
      margin-bottom: 20px;
      &.on {
        background: #4e7ce9;
        color: #fff;
      }
    }
    .competitor_title {
      display: flex;
      justify-content: space-between;
      padding: 12px 26px;

      > div {
        display: flex;

        .oval {
          width: 63px;
          height: 63px;
          border: solid 1px #d8d8d8;
          border-radius: 50%;
        }

        p {
          font-size: 22px;
          letter-spacing: -0.25px;
          color: #343434;
          margin-left: 9px;
        }

        i {
          margin-left: 6px;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 18px;
          font-size: 16px;
        }

        .btn_download {
          width: 160px;
          height: 40px;
          border-radius: 10px;
          border: solid 1px #d8d8d8;
          background-color: #fff;
        }

        .btn_notify {
          width: 212px;
          height: 40px;
          border-radius: 10px;
          background-image: linear-gradient(to left, #0d7, #0078f1);
          color: #ffffff;
        }

        button {
          width: 131px;
          height: 40px;
          border-radius: 10px;
          background-color: #0078f1;
          color: #fff;
        }
      }
    }

    .info_tab {
      display: flex;
      height: 60px;
      border-bottom: 1px solid #f0f0f0;

      > p {
        width: 140px;
        height: 60px;
        font-size: 14px;
        color: #343434;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .active {
        border-bottom: 3px solid #0078f1;
      }
    }
  }

  .sec_tit {
    font-size: 22px;
    color: #0635e3;
    width: 100%;
    margin-bottom: 20px;

    &.auto {
      width: auto;
    }
  }

  .tit_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sec_tit {
      width: auto;
    }

    .mod {
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
          margin-right: 6px;
        }
      }
    }
  }

  > section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background-color: rgba(216,216,216,0.28);
    // gap: 40px;
    // padding: 22px 0;

    article:first-child {
      button {
        padding: 10px 14px;
        border-radius: 20px;
        border: 1px solid #4e7ce9;
        background: transparent;
        color: #4e7ce9;
        &.on {
          background: #4e7ce9;
          color: #fff;
        }
      }
    }

    .article_box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      align-items: stretch;
      > article {
        width: 49%;
        position: relative;

        &.sm {
          width: 30%;
        }
        .no_chart_image {
          position: static;
          transform: none;
          margin: 0 auto;
          display: block;
        }
      }
    }

    article {
      width: 100%;
      border-radius: 15px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      margin-bottom: 40px;
      padding: 30px;

      h5 {
        font-size: 22px;
        color: #4593f5;
        // margin: 18px 0 31px 24px;
      }

      .empty_data {
        text-align: center;
        font-size: 18px;
      }

      .empty_data_chart {
        text-align: center;
        font-size: 18px;
        color: #ffffff;
        margin-top: 50px;
      }

      .empty_data_chart_black {
        text-align: center;
        font-size: 18px;
        margin-top: 50px;
      }

      .cert_status {
        display: flex;
        align-items: center;
        justify-content: space-around;

        li {
          text-align: center;

          p {
            color: #9890e2;
            padding: 20px 40px;
            border: 2px solid #4593f5;
            border-radius: 36px;
            margin-bottom: 4px;
          }

          p.bg {
            background-color: #4593f5;
            color: #fff;
          }

          span {
            color: #9890e2;
          }

          span.color {
            color: #0078f1;
          }
        }
      }

      .comp_analys {
        // display: flex;
        // align-items: flex-start;
        .chart_box {
          width: 640px;
          margin: 0 auto;
          // flex: none;
        }
        > ul {
          > li {
            color: #595959;
            padding-left: 10px;
            position: relative;
            line-height: 1.6;
            margin-bottom: 16px;

            &::before {
              content: "";
              width: 4px;
              height: 4px;
              display: block;
              background-color: #595959;
              border-radius: 100%;
              position: absolute;
              left: 0;
              top: 10px;
            }

            strong {
              color: #0635e3;
              font-family: "Pretendard_extraBold";
            }
          }
        }
      }

      .biz_box {
        margin-bottom: 60px;

        .biz_top_box {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          > button {
            color: #fff;
            background-color: #0635e3;
            padding: 4px 12px;
            border-radius: 24px;
            margin: 0 10px;
          }

          .biz_tit {
            font-family: "Pretendard_extraBold";
            font-size: 28px;
          }

          .date {
            color: #ababab;
          }
        }

        .kind {
          > li {
            display: flex;
            align-items: flex-start;
            border-bottom: 1px solid #ababab;
            padding-bottom: 20px;
            margin-bottom: 20px;

            > button {
              width: 80px;
              flex: none;
              border: 1px solid #ababab;
              padding: 6px 12px;
              border-radius: 10px;
              background-color: #f4f4f4;
              color: #595959;
            }

            .kind_name {
              min-width: 80px;
              color: #595959;
              font-size: 18px;
            }

            .brand_list {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              max-height: 156px;
              overflow: hidden;

              &.expanded {
                max-height: none;
              }

              > li {
                color: #595959;
                padding: 4px 12px;
                border: 1px solid #0078f1;
                border-radius: 24px;
                text-align: center;
                margin-right: 10px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }

      .support_work {
        display: flex;
        align-items: center;
        justify-content: space-around;

        > li {
          display: flex;
          align-items: center;

          > p {
            color: #fff;
            background-color: #0635e3;
            padding: 10px 20px;
            border-radius: 32px;
            margin-right: 14px;
          }

          > strong {
            font-family: "Pretendard_extraBold";
          }
        }
      }

      .support_work_list {
        margin-bottom: 40px;

        > ul {
          width: 100%;
          display: flex;
          align-items: center;

          > li {
            width: 23%;
            border: 1px solid #d8d8d8;
            border-radius: 10px;
            padding: 20px;

            & + li {
              margin-left: 36px;
            }

            > p {
              color: #595959;
              margin-bottom: 20px;
              overflow: hidden;
              height: 40px;
              text-overflow: ellipsis;
              display: -webkit-box;
              word-wrap: break-word;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .status_box {
              margin-bottom: 20px;

              .status {
                color: #0635e3;
                background-color: #e6f2ff;
                padding: 4px 12px;
                border-radius: 24px;
                font-size: 14px;
              }
            }

            .competition {
              text-align: right;

              > strong {
                font-size: 24px;
                padding-left: 10px;
              }
            }
          }
        }
      }

      .support_work_tbl_box {
        table {
          td {
            border: 1px solid #cfcfcf;
          }
        }
      }
    }

    article.top_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px;
      margin-top: 30px;

      strong {
        display: inline;
      }
      .comp_name {
        font-size: 22px;
      }

      .ceo {
        font-size: 18px;
        color: #595959;
        padding: 0 24px;
      }

      .cate {
        font-size: 18px;
      }

      .utils {
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;

          li {
            margin-left: 40px;

            button {
              display: flex;
              align-items: center;
              justify-content: space-between;

              i {
                width: 28px;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }

    article.service {
      background-color: transparent;
      box-shadow: none;
      padding: 0;
    }

    article.new_work {
      background-color: transparent;
      box-shadow: none;
      padding: 0;

      ul {
        li {
          text-align: left;
          color: #595959;
          // cursor: pointer;
          border-radius: 15px;
          box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
          background-color: #fff;
          padding: 24px;
          margin-bottom: 20px;

          // &:hover {
          //   color: #0635e3;
          // }
        }
      }
    }

    article.insite {
      > h6 {
        color: #0635e3;
      }

      > ul {
        display: flex;
        align-items: center;
        justify-content: center;

        > li {
          text-align: center;

          & + li {
            margin-left: 80px;
          }

          > strong {
            margin-bottom: 10px;
            display: block;
          }

          > div {
            padding: 30px;
            border-radius: 100%;

            > span {
              height: 67px;
              line-height: 1.6;
              background-color: #fff;
              padding: 20px;
              display: inline-block;
              border-radius: 100%;
            }
          }
        }
      }
    }

    .ai_insite {
      margin-bottom: 40px;

      > ul {
        > li {
          .summary_box {
            background-color: #fff;
            border-radius: 10px;
            padding: 20px 30px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);

            > p {
              margin-right: 20px;
              color: #595959;
            }

            p.tit {
              font-size: 18px;
            }

            p.status {
              color: #fff;
              padding: 4px 12px;
              border-radius: 20px;
            }
          }

          > ul {
            > li {
              background-color: #fff;
              border-radius: 10px;
              padding: 20px 30px;
              margin-bottom: 20px;
              color: #595959;
              box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);

              > strong {
                color: #0635e3;
                font-size: 18px;
                display: block;
                margin-bottom: 10px;
              }

              > p {
                position: relative;
                padding-left: 20px;

                &::before {
                  content: "";
                  width: 4px;
                  height: 4px;
                  display: block;
                  background-color: #595959;
                  border-radius: 100%;
                  position: absolute;
                  left: 10px;
                  top: 7px;
                }
              }
            }
          }
        }
      }
    }

    .tech_rate {
      .type_box {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .type {
          .tag {
            color: #595959;
            display: block;
            margin-bottom: 20px;
          }

          .point {
            display: block;
            padding-top: 14px;
            text-align: center;
          }

          > ul {
            display: flex;
            align-items: center;

            > li {
              text-align: center;

              > strong {
                display: block;
                margin-bottom: 10px;
              }

              .grade_desc {
                color: #ababab;
                font-size: 14px;
                padding-top: 10px;
                display: inline-block;
              }

              .grade {
                width: 90px;
                height: 90px;
                text-align: center;
                line-height: 4;
                color: #fff;
                border-radius: 100%;
                font-size: 22px;
                box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);

                &.big_circle {
                  width: 110px;
                  height: 110px;
                  font-size: 24px;
                  line-height: 4.5;
                }
              }

              & + li {
                margin-left: 30px;
              }
            }
          }
        }
      }
    }

    .corp_info {
      article {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > p {
          color: #0635e3;
        }

        > button {
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
          color: #848484;
          padding: 16px 36px;
          font-size: 18px;
          border-radius: 32px;
        }
      }
    }

    .top_utils {
      width: 1500px;
      text-align: right;
      padding: 0 30px;
      margin: 30px 0;

      ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        li {
          margin-left: 31px;

          button {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            i {
              background-size: contain;
              margin-right: 6px;
            }
          }
        }
      }
    }

    .competitor_info_grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px 59px;

      > div {
        display: flex;
        align-items: center;
        padding: 6px 0;
        // min-height: 42px;
        // border-bottom: 1px solid #e7e7e7;

        > p:nth-child(1) {
          width: 110px;
          font-size: 16px;
          letter-spacing: 0.15px;
          color: #343434;
          font-family: "Pretendard_bold";
        }

        > p:nth-child(2),
        a {
          font-family: Pretendard_regular;
          color: #595959;
          font-size: 16px;
        }

        .homepage {
          color: #63bbb2;
          border-bottom: 1px solid #63bbb2;
        }

        .emp_num {
          * {
            color: #0635e3;
          }

          button {
            margin-left: 16px;
            background-color: #f4f6ff;
            border: solid 1px #0635e3;
            padding: 4px 12px;
            border-radius: 16px;
          }
        }
      }

      // >div:nth-child(3),
      // div:nth-child(5) {
      //   grid-column: 1 / 2;
      // }

      // >div:nth-child(8),
      // div:nth-child(9) {
      //   grid-column: 1 / span 3;
      // }
    }

    .rnd_list {
      > ul {
        display: flex;
        align-items: center;
        justify-content: space-around;

        > li {
          display: flex;
          align-items: center;

          .tit {
            color: #fff;
            background-color: #0635e3;
            padding: 10px 20px;
            border-radius: 24px;
            margin-right: 14px;
          }

          .num {
            display: flex;
            align-items: center;

            .item {
              text-align: center;
              margin-right: 14px;

              > p {
                &:last-of-type {
                  font-size: 20px;
                  font-family: "Pretendard_extraBold";
                  padding-top: 6px;
                }
              }
            }
          }
        }
      }
    }

    .keyword_wrapper {
      display: flex;
      gap: 7px;

      p {
        text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
        font-family: Pretendard_bold;
        color: #343434;
        padding: 6px 8px;
        font-size: 12px;
        border: 1px solid transparent;
        border-radius: 14px;
        background: linear-gradient(#ffffff, #ffffff) padding-box,
          linear-gradient(to left, #0d7, #0078f1);
        background-origin: border-box;
      }
    }

    .analysis {
      // padding: 0 25px;
      // display: grid;
      // grid-template-columns: repeat(5, 1fr);
      // gap: 11px;

      > p {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #4593f5;
        padding: 12px 32px 12px 42px;
        border-radius: 24px;
        cursor: pointer;
        margin-right: 16px;
        margin-bottom: 16px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // text-align: center;
        // padding: 0 10px;
        // width: 159px;
        // height: 74px;
        // border-radius: 37px;
        // background-color: #eaf8ff;
        // font-size: 16px;
      }
    }

    .employee {
      display: flex;
      gap: 20px;

      .employee_info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 17px 9px;

        p:nth-child(1) {
          width: 150px;
          font-size: 14px;
          letter-spacing: 0.15px;
          color: #343434;
        }

        p:nth-child(2) {
          width: 150px;
          font-size: 12px;
          text-align: right;
          letter-spacing: 0.15px;
          color: #959595;

          span {
            font-size: 26px;
            color: #343434;
          }
        }
      }
    }

    .financial_wrapper {
      margin-bottom: 40px;

      table {
        tbody {
          tr {
            // td:nth-child(1) {
            //   background-color: #f0f0f0;
            // }

            td {
              border: 1px solid #cfcfcf;
            }
          }
        }
      }
    }

    .sub_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      > p:nth-child(1) {
        font-size: 18px;
        letter-spacing: -0.5px;
        color: #0635e3;

        > span {
          font-weight: normal;
          font-size: 14px;
        }
      }

      > button {
        background-color: #4593f5;
        color: #fff;
        font-size: 16px;
        padding: 10px 20px;
        border-radius: 10px;
      }

      // >p:nth-child(2) {
      //   font-size: 12px;
      //   color: #959595;
      // }
    }

    .news_list {
      padding: 0 30px;

      .slick-list {
        .slick-slide {
          padding: 10px;
          .slide_item {
            cursor: pointer;
          }

          > div {
            background-color: #fff;
            border-radius: 20px;
            padding: 20px;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);

            .date {
              font-family: "Pretendard_extraBold";
              display: block;
              margin-bottom: 10px;
              font-size: 14px;
            }

            .title {
              height: 42px;
              display: -webkit-box;
              color: #483acc;
              font-family: "Pretendard_extraBold";
              font-size: 18px;
              margin-bottom: 10px;
              word-wrap: break-word;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              overflow: hidden;
              b {
                font-family: "Pretendard_extraBold";
              }
            }

            .content {
              display: -webkit-box;
              color: #595959;
              font-size: 14px;
              height: 114px;
              word-wrap: break-word;
              -webkit-line-clamp: 7;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }

      .more_box {
        text-align: center;

        .more {
          padding: 16px 50px;
          color: #fff;
          background-color: #0635e3;
          border-radius: 28px;
          margin-top: 20px;
        }
      }
    }

    .chart_wrapper {
      // width: 928px;
      // height: 266px;
      border-radius: 15px;
      border: solid 0.8px #d8d8d8;
      background-color: #fff;
      margin: 20px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px;

      > p {
        font-size: 14px;
        color: #343434;
        margin-top: 10px;
      }
    }

    .tech_skills {
      display: flex;
      padding: 0 30px;

      > div {
        width: 50%;

        > p {
          font-size: 18px;
          letter-spacing: -0.5px;
          color: #343434;
          margin-bottom: 10px;
        }
      }

      .total_text {
        text-align: center;
        margin-top: 19px;
        font-size: 14px;
        letter-spacing: -0.3px;
        color: #343434;

        span {
          margin: 0 10px;
          color: #0078f1;
          font-size: 16px;
        }
      }
    }

    .skill_eval {
      display: flex;
      justify-content: center;
      gap: 38px;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          display: flex;
          align-items: center;
          font-size: 14px;
          letter-spacing: -0.25px;
          color: #343434;
        }

        .oval {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 56px;
          height: 56px;
          background-color: #eaf8ff;
          border-radius: 50%;
          font-size: 18px;
          font-family: GmarketSansTTF_bold;
          margin: 23px 0;
          color: #0078f1;
        }

        span {
          font-size: 12px;
          color: #959595;
        }
      }

      > div:nth-child(1) {
        .oval {
          width: 82px;
          height: 82px;
          background-color: #0078f1;
          font-size: 22px;
          color: #fff;
          margin: 10px 0;
        }
      }
    }

    .company_skill_wrapper {
      > #GradeCompany {
        display: block;
        margin: 0 auto;
        width: 90%;

        .table_grade {
          width: 800px;
          margin: 38px 0 0 0;

          .data + .data {
            margin-left: 13px;
          }
        }
      }
    }

    .skill_info {
      display: flex;
      flex-direction: column;
      padding: 0 25px;
      gap: 10px;

      .skill_info_header {
        display: flex;

        p {
          font-size: 14px;
          letter-spacing: 0.15px;
          color: #4593f5;
        }

        p:nth-child(1) {
          width: 248px;
        }

        p:nth-child(2) {
          width: 271px;
        }

        p:nth-child(3) {
          width: 420px;
        }
      }

      .skill_info_items {
        display: flex;
        flex-direction: column;
        max-height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
      }

      .skill_info_item {
        display: flex;
        border-bottom: solid 1px #d8d8d8;
        padding: 5px;

        > div {
          padding: 10px;
        }

        div:nth-child(1) {
          width: 248px;
          height: 161px;
          border-radius: 45px;
          border: solid 1px #d8d8d8;
          display: block;
          margin: auto;
        }

        div:nth-child(2) {
          width: 271px;
          display: block;
          margin: auto;
        }

        div:nth-child(3) {
          width: 420px;
        }
      }
    }

    .patent_chart_wrapper {
      display: flex;
      padding: 0 20px;
    }

    .patent_trade {
      background-image: linear-gradient(to right, #ff7a00 32%, #ffa600 100%);
      box-shadow: 0 2px 4px 0 rgba(52, 52, 52, 0.15);
      padding: 11.3px 15.8px 26.4px 16.5px;
      border: none;

      canvas {
        position: relative;
        left: -10px;
        top: 5px;
      }
    }

    .annual_fee_and_year {
      position: relative;
      padding: 10.6px 0.6px 9px 5.4px;

      > canvas {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }

    .chart {
      position: relative;
      width: 233.8px;
      height: 200px;
      margin: 14px 12px 23.9px 0;
      border-radius: 15px;
      border: solid 0.8px #d8d8d8;
      margin-right: 34px;

      > p.chart_tit {
        margin-bottom: 10px;
        padding-left: 10px;
      }

      .chart_category_name {
        font-size: 14px;
        color: #fff;
      }

      .total_cnt {
        position: absolute;
        top: 44%;
        left: 35px;
        width: 90px;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
      }

      .add_label,
      .apply_label,
      .giveup_label {
        position: absolute;
        top: 35%;
        left: 155px;
        justify-content: center;
        align-items: center;
        width: 61px;
        height: 18px;
        white-space: pre;
        font-size: 12px;
        font-family: Pretendard_extraBold;
        color: var(--color-Web-Orange);
        text-align: center;
        background: #ffffff;
        z-index: 2;
        border-radius: 4px;
      }

      .add_label:after,
      .apply_label:after,
      .giveup_label:after {
        content: "";
        position: absolute;
        bottom: 26px;
        left: 10px;
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-top-color: #ffffff;
        border-bottom: 0;
        margin-left: -20px;
        margin-bottom: -20px;
        transform: rotate(90deg);
      }

      .apply_label {
        top: 50%;
        color: #ffa600;
        background-color: rgba(255, 255, 255, 0.8);
      }

      .apply_label:after {
        border-top-color: rgba(255, 255, 255, 0.8);
      }

      .giveup_label {
        top: 65%;
        color: #ffffff;
        background-color: #ffa94d;
      }

      .giveup_label:after {
        border-top-color: #ffa94d;
      }
    }

    .patent_list {
      > p {
        margin: 10px 0 10px 25px;
        font-size: 18px;
      }

      .circle {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 24px;
        border-radius: 16px;
        pointer-events: none;
        color: #ffffff;
        font-family: NanumSquareOTF_acEB;
        font-size: 12px;
      }
    }

    .trademark_wrapper {
      padding: 0 0 20px 25px;
      max-height: 600px;
      overflow-y: scroll;

      h6 {
        color: #4593f5;
        font-size: 14px;
        min-width: 110px;
      }

      .trademark_item {
        margin-top: 23px;
      }

      .trademark_name {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        height: 40px;
        border-bottom: 1px solid #f0f0f0;
      }

      .trademark_info {
        > .product_list {
          margin-top: 10px;
          display: flex;

          > p {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 110px;
            font-size: 14px;
            color: #343434;
          }

          > div {
            > p {
              display: inline-block;
              font-family: Pretendard_bold;
              padding: 6px 9px;
              margin: 0 7px 7px 0;
              font-size: 12px;
              text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
              border: 1px solid transparent;
              border-radius: 14px;
              background: linear-gradient(#ffffff, #ffffff) padding-box,
                linear-gradient(to left, #0d7, #0078f1);
              background-origin: border-box;
            }
          }
        }
      }
    }

    .design_wrapper {
      padding: 0 25px 20px 25px;
      max-height: 600px;
      overflow-y: scroll;

      .design_item {
        margin-top: 30px;
      }

      .design_title {
        display: flex;
        justify-content: space-between;
        height: 42px;
        border-bottom: 1px solid #f0f0f0;
        align-items: center;

        h6 {
          color: #4593f5;
          font-size: 14px;
          min-width: 110px;
        }

        > div {
          display: flex;

          > .design_name {
            font-size: 14px;
            color: #343434;
          }
        }
      }

      .design_category {
        .design_status {
          display: inline-block;
          font-family: Pretendard_bold;
          padding: 6px 9px;
          margin: 0 7px 0 0;
          font-size: 12px;
          text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
          border: 1px solid transparent;
          border-radius: 14px;
          background: linear-gradient(#ffffff, #ffffff) padding-box,
            linear-gradient(to left, #0d7, #0078f1);
          background-origin: border-box;
        }
      }

      .design_item {
        border-bottom: 1px solid #ababab;
        padding-bottom: 30px;

        .design_content {
          display: flex;
          gap: 24px;

          .design_list {
            .name_box {
              display: flex;
              align-items: center;
              margin-bottom: 14px;

              .design_name {
                font-family: "Pretendard_extraBold";
                font-size: 22px;
                margin-right: 10px;
              }
            }

            .design_status {
              color: #fff;
              background-color: #0635e3;
              padding: 4px 12px;
              border-radius: 24px;
            }

            .info {
              display: flex;

              .img_box {
                border-radius: 25px;
                border: solid 0.5px #ababab;
                width: 280px;
                height: 200px;
                display: flex;
                flex: none;
                align-items: center;
                justify-content: center;
              }

              .desc {
                margin-left: 30px;
                color: #595959;
              }
            }
          }

          // >div:first-child {
          //   width: 248px;
          //   height: 161px;
          //   border-radius: 45px;
          //   border: solid 1px #d8d8d8;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          // }

          // >div:last-child {
          //   font-size: 14px;
          //   letter-spacing: -0.3px;
          //   color: #343434;
          //   line-height: 1.57;
          // }
        }
      }
    }

    //.design_wrapper {
    //  padding: 0 25px 20px 25px;
    //  max-height: 600px;
    //  overflow-y: scroll;
    //
    //  table {
    //    thead {
    //      height: 42px;
    //    }
    //  }
    //}
    .competition_rate {
      > p {
        margin: 10px 0 10px 25px;
      }

      .auction_chart_wrapper,
      .grid_chart_wrapper {
        > div {
          width: 420px;
          height: 266px;
          border: solid 0.8px #d8d8d8;
          background-color: #fff;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;

          p {
            position: absolute;
            font-size: 14px;
            color: #343434;
            bottom: 5px;
          }
        }
      }

      .auction_chart_wrapper {
        display: flex;
        justify-content: center;
        gap: 10px;
      }

      .grid_chart_wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        place-items: center;
        gap: 10px;
      }
    }

    .auction_status_table {
      max-height: 400px;
      overflow-y: scroll;
    }

    .growth_rate {
      > p {
        margin: 10px 0 10px 25px;
        font-size: 18px;
      }

      > div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px 25px;

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 460px;
          height: 287px;
          border-radius: 15px;
          border: solid 0.8px #d8d8d8;
          background-color: #fff;

          span {
            font-size: 12px;
            color: #959595;
          }
        }
      }
    }

    .auction_status {
      > p {
        margin: 10px 0 10px 25px;
        font-size: 18px;
      }

      > div {
        display: flex;
        margin-top: 20px;
      }

      &_text {
        justify-content: center;
        gap: 200px;

        > p {
          font-size: 18px;
          letter-spacing: -0.5px;
          color: #0078f1;

          span:nth-child(1) {
            font-size: 26px;
            color: #343434;
            margin-left: 20px;
          }

          span:nth-child(2) {
            font-size: 12px;
            color: #959595;
          }
        }
      }

      &_box {
        padding: 0 19px;
        gap: 19px;

        > div {
          width: 234px;
          height: 144px;
          border-radius: 15px;
          border: solid 0.8px #d8d8d8;
          padding: 10px;
          position: relative;

          p:nth-child(1) {
            display: inline-block;
            font-size: 12px;
            font-family: Pretendard_extraBold;
            color: #0078f1;
            padding: 6px 8px;
            border-radius: 14px;
            background-color: #e6f2ff;
          }

          p:nth-child(2) {
            margin-top: 15px;
            font-size: 14px;
            color: #343434;
            line-height: 1.29;
            font-family: Pretendard_regular;
            @include text-ellipsis(3);
          }

          p:nth-child(3) {
            position: absolute;
            right: 5px;
            bottom: 5px;
            font-size: 26px;
            text-align: right;
            color: #343434;
            letter-spacing: -0.5px;
          }
        }
      }

      &_table {
        margin-top: 20px;
      }
    }

    .competitor_list {
      > p {
        margin: 10px 0 10px 25px;
        font-size: 18px;
      }
    }

    .company_info_items {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 13px;
      padding: 10px 0;
      max-height: 600px;
      overflow-y: scroll;
    }

    .company_info_item {
      display: flex;
      align-items: center;
      width: 100%;
      //height: 102px;
      border-radius: 15px;
      border: solid 0.8px #d8d8d8;
      background-color: #fff;
      position: relative;
      padding: 20px;

      img {
        width: 100px;
        height: 63px;
        margin-right: 30px;
      }

      button {
        position: absolute;
        right: 17px;
        width: 104px;
        height: 40px;
        font-size: 14px;
        color: #fff;
        border-radius: 10px;
        background-color: #0078f1;
      }

      > .item_text {
        line-height: 1.5;

        > p:nth-child(1) {
          font-size: 18px;
          letter-spacing: -0.5px;
          color: #343434;
          font-family: "Pretendard_extraBold";
        }

        > p:nth-child(2) {
          font-size: 14px;
          width: 310px;
          color: #595959;
          @include text-ellipsis(1);
        }

        > .item_text_category {
          width: 800px;

          > p {
            display: inline-block;
            font-family: Pretendard_bold;
            padding: 3px 9px;
            margin-right: 7px;
            font-size: 12px;
            text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
            border: 1px solid transparent;
            border-radius: 14px;
            background: linear-gradient(#ffffff, #ffffff) padding-box,
              linear-gradient(to left, #0d7, #0078f1);
            background-origin: border-box;
          }
        }
      }
    }

    .growth_compare_rate {
      > p {
        margin: 10px 0 10px 25px;
        font-size: 18px;
      }

      > div:nth-of-type(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 928px;
        height: 251px;
        margin: 0 auto;
        border-radius: 30px;
        border: solid 0.8px #d8d8d8;
        background-color: #fff;
      }

      > div:nth-of-type(2) {
        margin: 11px 0 0 45px;
        font-size: 14px;
        font-family: Pretendard_regular;
        line-height: 1.57;
        letter-spacing: -0.3px;
        color: #343434;
      }
    }

    .competitor_news {
      display: flex;
      padding: 0 25px;
      gap: 12px;

      > div {
        width: 340px;
        height: 322px;
        border-radius: 15px;
        border: solid 0.8px #d8d8d8;
        background-color: #fff;
        padding: 14px;
        position: relative;

        > p:nth-child(1) {
          font-size: 16px;
          color: #343434;
          margin-bottom: 20px;
        }

        > p:nth-child(2) {
          font-size: 14px;
          color: #343434;
          line-height: 1.29;
          @include text-ellipsis(10);
        }

        > p:nth-child(3) {
          position: absolute;
          font-size: 12px;
          color: #959595;
          right: 18px;
          bottom: 16px;
        }
      }
    }
  }

  .bg_section {
    width: 100%;
    background-color: #f6f9ff;
    border-top: 1px solid #1f5ad8;
    border-bottom: 1px solid #1f5ad8;
    margin-top: 40px;
    padding-top: 30px;
    padding-bottom: 40px;

    &.wh {
      background-color: #fff;
      border-top: none;
    }

    &.bt_none {
      border-top: none;
    }

    &.bb_none {
      border-bottom: none;
    }
  }

  .inner_box {
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 30px;
  }

  .big_tit {
    font-size: 26px;
    position: absolute;
    left: 30px;
    top: -76px;
    padding: 14px 20px 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 1px solid #1f5ad8;
    border-right: 1px solid #1f5ad8;
    border-left: 1px solid #1f5ad8;

    &.higher {
      top: -115px;
    }

    &.wh {
      background-color: #fff;
    }

    &.compet_tit {
      top: -116px;
    }
  }

  .big_tit.bg {
    background-color: #f6f9ff;
    color: #0635e3;
  }

  .no_chart_image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slick-arrow {
    > i {
      width: 25px;
      height: 47px;
    }
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before,
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
  .slick-disabled {
    opacity: 0.5;
  }
  .biz_wrapper {
    max-height: 500px;
    overflow-y: auto;
  }
  .demand_tbl {
    height: 177px;
    overflow-y: auto;
  }
  .cash {
    text-align: right;
    padding-right: 15px;
  }
}
