#Wrap_BillingView {
    position: relative;
    margin: auto;
    width: 980px;

    .btns {
        position: absolute;
        top: 36px;
        right: 50px;
        display: flex;
        align-items: center;

        button {
            width: 132px;
            line-height: 32px;
            font-size: 16px;
            color: #ffffff;
        }

        button.btn_download {
            margin-right: 6px;
            background-color: #96c719;
        }

        button.btn_print {
            background-color: #000000;
        }
    }
}

#BillingView {
    padding: 36px 50px 0 50px;

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;

        h2 {
            font-size: 32px;
            letter-spacing: 1.45px;
            color: #000000;
        }
    }

    .agent_info {
        margin-bottom: 50px;
        text-align: right;

        h2 {
            margin-bottom: 9px;
            font-size: 16px;
            font-family: NanumSquareOTF_acEB;
            line-height: 1.31;
            color: rgba(0, 0, 0, 0.87);
        }

        p {
            font-size: 14px;
            line-height: 1.29;
            color: #000000;
        }
    }

    .wrap_data {
        display: flex;

        .column {
            width: 260px;

            .row {
                display: flex;
                min-height: 30px;
                font-size: 14px;

                .title {
                    margin-right: 10px;
                    min-width: 110px;
                    width: 110px;
                    font-family: NanumSquareOTF_acB;
                }

                .desc {
                    width: 600px;
                    word-break: break-word;
                }

                ul {
                    li {
                        min-height: 30px;
                        cursor: pointer;
                    }
                }
            }

            .row+.row {
                margin-top: 10px;
            }
        }
        .column:nth-child(2) {
            width: auto;

            .row {
                .desc {
                    width: auto;
                }
            }
        }

        .column+.column {
            margin-left: 28px;
        }
    }

    .bill_info {
        margin: 60px 0;

        .thead {
            margin-bottom: 9px;

            .row {
                border-bottom: solid 2px #000000;

                p {
                    font-family: NanumSquareOTF_acEB;
                    line-height: 1.14;
                    letter-spacing: 0.25px;
                    color: #000000;
                }
            }
        }

        .tbody {
            min-height: 30px;

            .row:nth-child(1) {
                position: absolute;
            }
        }

        .tfoot {
            .row {
                padding-top: 8px;
                border-top: solid 2px #000000;

                p {
                    font-family: NanumSquareOTF_acEB;
                    font-size: 14px;
                    line-height: 1.14;
                    letter-spacing: 0.25px;
                    color: #000000;
                }

                p:nth-child(4) {
                    margin-right: 0;
                    font-family: NanumSquareOTF_acR;
                    text-align: right;
                }
            }
        }

        .row {
            div {
                display: flex;
                justify-content: space-between;
                width: 420px;
            }

            div+div {
                margin-left: 35px;
            }
        }

        .row {
            display: flex;
            align-items: center;
            min-height: 30px;

            p {
                font-size: 14px;
            }

            p:nth-child(1) {
                max-width: 300px;
            }

            p:nth-child(2) {
                min-width: 105px;
                text-align: right;
            }

            p:nth-child(3) {
                margin-right: 10px;
                max-width: 250px;
            }

            p:nth-child(4) {
                margin-right: 26px;
                max-width: 150px;
                text-align: right;
            }
        }

        .row.border {
            margin-top: 6px;
            height: 42px;
            border-top: solid 1px #e7e7e7;
        }
        .row.border+.row.border {
            margin-top: 8px;
        }
    }

    .account {
        margin-top: 45px;
        font-family: NanumSquareOTF_acB;
        font-size: 14px;
        letter-spacing: 0.25px;
        text-align: center;
        color: var(--color-Web-Orange);
    }
}
