@import "src/include";

#expositionSub{
	.pc{
		padding: 46px 0 0;
		.expositionSub{
			width: 880px;
			margin: 0 auto;
			&_title{
				font-size: 22px;
				margin-bottom: 30px;
			}
			&_content{
				&_title-wrap{
					border-bottom: 1px solid #e7e7e7;
					margin-bottom: 43px;
				}
				&_title{
					font-size: 22px;
					margin-bottom: 20px;
					text-align: center;
				}
				&_date{
					font-size: 14px;
					text-align: right;
					color: rgba(0, 0, 0, 0.87);
					margin-bottom: 10px;
				}
				&_info{
					display: flex;
					height: 350px;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 25px;
					&_img{
						width: 350px;
						height: 100%;
						background-color: #f1f1f1;
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					&_content{
						width: 500px;
						height: 100%;
						&_title{
							height: 48px;
							line-height: 30px;
							font-family: NanumSquareOTF_acEB;
							font-size: 16px;
							color: #343434;
							padding: 9px 0;
							border-bottom: 1px solid #e7e7e7;
						}
						.list{
							display: flex;
							justify-content: space-between;
							align-items: center;
							flex-wrap: wrap;
							margin-bottom: 5px;
							&_title{
								display: flex;
								justify-content: flex-start;
								align-items: center;
								width: 80px;
								height: 48px;
								padding: 9px 0;
								font-family: NanumSquareOTF_acB;
								font-size: 14px;
								color: #343434;
								border-bottom: 1px solid #e7e7e7;
							}
							&_info{
								display: flex;
								justify-content: flex-start;
								align-items: center;
								width: calc(100% - 80px);
								height: 48px;
								padding: 9px 0;
								font-family: NanumSquareOTF_acR;
								font-size: 14px;
								color: rgba(0, 0, 0, 0.87);
								border-bottom: 1px solid #e7e7e7;
							}
						}
						&_text-wrap{
							.text{
								overflow: hidden;
								height: 105px;
								font-size: 14px;
								color: rgba(0, 0, 0, 0.87);
								line-height: 1.5;
								padding: 0 5px;
								margin-bottom: 4px;
								@include text-ellipsis(3);
							}
							.btn-wrap{
								display: flex;
								justify-content: flex-end;
								align-items: center;
								.event{
									display: flex;
									justify-content: center;
									align-items: center;
									width: 140px;
									height: 32px;
									text-align: center;
									background-color: var(--color-Web-Orange);
									border: 1px solid var(--color-Web-Orange);
									border-radius: 4px;
									font-family: NanumSquareOTF_acEB;
									font-size: 14px;
									color: #fff;
									margin-right: 11px;
								}
								.copy{
									display: flex;
									justify-content: center;
									align-items: center;
									width: 140px;
									height: 32px;
									text-align: center;
									background-color: #fff;
									border: 1px solid #959595;
									border-radius: 4px;
									font-family: NanumSquareOTF_acEB;
									font-size: 14px;
									color: #343434;
								}
							}
						}
					}
				}
				&_intro{
					&_title{
						height: 38px;
						padding-bottom: 8px;
						border-bottom: 1px solid #e7e7e7;
						margin-bottom: 9px;
						font-family: NanumSquareOTF_acB;
						font-size: 14px;
						color: #343434;
					}
					&_section{
						padding-bottom: 40px;
						.content{
							font-family: NanumSquareOTF_acR;
							font-size: 14px;
							line-height: 24px;
							width: 100%;
							color: rgba(0, 0, 0, 0.87);
							.se-image-container{
								margin-top: 10px;
							}
						}
					}
				}
				&_file{
					padding-bottom: 40px;
					&_title{
						height: 38px;
						padding-bottom: 8px;
						border-bottom: 1px solid #e7e7e7;
						margin-bottom: 9px;
						font-family: NanumSquareOTF_acB;
						font-size: 14px;
						color: #343434;
					}
					&_list-wrap{
						font-family: NanumSquareOTF_acR;
						font-size: 14px;
						line-height: 24px;
						width: 100%;
						color: rgba(0, 0, 0, 0.87);
						padding: 16px;
						li{
							cursor: pointer;
						}
					}
				}
				&_map{
					padding-bottom: 25px;
					margin-bottom: 60px;
					border-bottom: 1px solid #e7e7e7;
					&_title{
						height: 38px;
						padding-bottom: 8px;
						border-bottom: 1px solid #e7e7e7;
						margin-bottom: 25px;
						font-family: NanumSquareOTF_acB;
						font-size: 14px;
						color: #343434;
						&_title{
							font-family: NanumSquareOTF_acB;
							font-size: 14px;
							color: #343434;
						}
					}
					&_section{
						#map{
							width: 100%;
							height: 300px;
							background-color: #f1f1f1;
							margin-bottom: 25px;
						}
						&_addr{
							font-size: 14px;
							color: rgba(0, 0, 0, 0.87);
						}
					}
				}
				&_btn-wrap{
					text-align: center;
					.btn_list{
						display: flex;
						justify-content: center;
						align-items: center;
						width: 120px;
						height: 32px;
						margin: 0 auto;
						background-color: #05ac9e;
						color: #fff;
						font-size: 16px;
						text-align: center;
						border-radius: 3px;
					}
				}
			}
		}
	}
	.mo{
		padding: 60px 0 0;
		.expositionSub{
			&_img{
				width: 100%;
				height: 65.27777777777778vw; /*235px(360)*/
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			&_content{
				width: 100%;
				margin: 0 auto;
				background-color: #f0f0f0;
				&_box{
					width: calc(100% - 40px);
					margin: 0 auto;
					background-color: #fff;
					padding: 0 16px 21px;
					section{
						border-bottom: 1px solid #d8d8d8;
						&:last-of-type{
							border-bottom: none;
						}
					}
				}
				&_title-wrap{
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
				}
				&_title{
					width : calc(100% - 83px);
					margin: 14px 0;
					font-family: NanumSquareOTF_acEB;
					font-size: 14px;
					color: #1d334e;
					line-height: 1.43;
					max-height: 40px;
					@include text-ellipsis(2);
				}
				&_day{
					width: 63px;
					margin: 14px 0;
					text-align: right;
					.today{
						display: flex;
						justify-content: center;
						align-items: center;
						width: 63px;
						height: 24px;
						font-size: 12px;
						letter-spacing: normal;
						border-radius: 12px;
						background-color: #ff4d00;
						color: #fff;
						text-align: center;
					}
					.dday{
						display: flex;
						justify-content: center;
						align-items: center;
						width: 63px;
						height: 24px;
						font-size: 12px;
						letter-spacing: normal;
						border-radius: 12px;
						background-color: #fff;
						font-family: NanumSquareOTF_acB;
						color: #1d334e;
						border: 2px solid #6bd3ff;
					}
				}
				&_table{
					padding: 11.5px 2px;
					.list{
						display: flex;
						justify-content: space-between;
						align-items: center;
						flex-wrap: wrap;
						font-family: NanumSquareOTF_acB;
						font-size: 12px;
						color: #606060;
						&_title{
							width: 82px;
							height: 18px;
							line-height: 18px;
							margin: 4px 0;
						}
						&_info{
							width: calc(100% - 82px);
							height: 18px;
							line-height: 18px;
							margin: 4px 0;
							@include text-ellipsis(1);
						}
					}
				}
				&_info{
					.text{
						padding: 11.5px 0 40px;
					}
				}
				&_map{
					padding: 11.5px 0;
					&_title{
						font-family: NanumSquareOTF_acB;
						font-size: 12px;
						color: #606060;
						padding-left: 2px;
						margin-bottom: 12px;
					}
					&_section{
						#map{
							width: 100%;
							height: 52.5vw; /*189px(360)*/
						}
						&_addr{
							margin-top: 10px;
							font-family: NanumSquareOTF_acR;
							font-size: 12px;
							color: #606060;
						}
					}
				}
				&_btn-wrap{
					margin-top: 21px;
					.btn_list{
						display: block;
						width: 65px;
						height: 28px;
						border-radius: 3px;
						line-height: 28px;
						background-color: #05ac9e;
						font-size: 12px;
						color: #fff;
						text-align: center;
						margin: 0 auto;
					}
				}
			}
		}
	}
}
