#SignupInvite {
    margin: auto;
    padding: 32px 0 40px 0;
    width: 90%;
    max-width: 600px;

    .page_title {
        margin-bottom: 28px;
        font-size: 24px;
        font-family: NanumSquareOTF_acEB;
        line-height: 30px;
        color: #443018;
        text-align: center;
    }

    .field {
        position: relative;

        .required {
            position: absolute;
            top: 3px;
            right: 0;
            font-size: 12px;
            color: #606060;
        }

        .f_title {
            margin-bottom: 8px;
            font-size: 12px;
            font-family: NanumSquareOTF_acEB;
            line-height: 18px;
            color: #443018;
        }

        input {
            padding: 0 20px;
            width: 100%;
            height: 40px;
            border-radius: 4px;
            border: solid 2px #d8d8d8;
        }

        .wrap_input {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 40px;
            border-radius: 4px;
            background-color: #ffffff;

            > p {
                padding: 0 20px;
                width: 100%;
                line-height: 40px;
                font-size: 14px;
                color: #606060;
                border-radius: 4px;
                border: 2px solid #d8d8d8;
                background-color: #f0f0f0;
            }
        }

        .wrap_input_validator {

            input {
                width: calc(75% - 8px);
                height: 40px;
            }

            button {
                margin-left: 8px;
                width: 25%;
                height: 40px;
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
                color: #fff;
                border-radius: 4px;
                background-color: var(--color-Web-Orange);
            }
        }

        .alert_msg {
            margin-top: 8px;
            font-size: 12px;
            font-family: NanumSquareOTF_acB;
            line-height: 18px;
            color: var(--color-Web-Orange);
        }
    }

    .btn {
        margin: 30px auto 0 auto;
        display: block;
        width: 208px;
        height: 42px;
        line-height: 42px;
        font-size: 16px;
        font-family: NanumSquareOTF_acEB;
        line-height: 22px;
        color: #ffffff;
        border-radius: 21px;
        background-color: var(--color-Web-Orange);
    }

    .field+.field {
        margin-top: 20px;
    }

    .btn_no {
        background-color: darkgray !important;
        pointer-events: none;
    }
}
