$gray-4: #d1d6db;
$gray-9: #333d4b;
$radius-10: 6px;

.DBox {
  // padding: 20px;
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  flex-shrink: 0;
  .content_tit {
    display: block;
    margin-bottom: 20px;
    font-size: 20px;
  }
  .slick-dots {
    width: auto;
    position: absolute;
    right: 0;
    top: 0;
    bottom: auto;
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-dots li button:before {
    font-size: 8px;
  }
  .slick-dots li.slick-active button:before {
    font-size: 12px;
  }
}
