@import "src/include";
#DocTradeMyTransactionSwitchPoint {
    .switchPoint {
        &_box {
            background-color: #f1f1f1;
            padding: 20px 50px;
            margin-bottom: 40px;
            &_title {
                font-family: NanumSquareOTF_acEB;
                font-size: 18px;
                height: 30px;
                line-height: 30px;
                margin-bottom: 4px;
            }
            &_text {
                font-family: NanumSquareOTF_acB;
                font-size: 14px;
                color: #606060;
                line-height: 20px;

                .style {

                    &_light {
                        font-family: NanumSquareOTF_acR;
                        font-size: 12px;
                        padding-left: 10px;
                    }

                    &_strong {
                        font-family: NanumSquareOTF_acB;
                        color: var(--color-Alizarin-Crimson);
                    }
                }
            }
        }
        &_conversion {
            margin-bottom: 80px;
        }

        &_title-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 32px;
            line-height: 32px;
            font-family: NanumSquareOTF_acB;
            font-size: 22px;
            color: #343434;
            margin-bottom: 30px;
        }

        &_button-section {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 13px;

            &_text {
                padding-left: 34px;
                position: relative;
                font-family: NanumSquareOTF_acR;
                font-size: 12px;
                color: var(--color-Alizarin-Crimson);

                &::after{
                    position: absolute;
                    content: '';
                    width: 24px;
                    height: 24px;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    background: url(../../../assets/images/common/icon-badge-information-red.svg) no-repeat 50% 50%;
                    background-size: cover;
                }
            }
            &_btn {
                width: 140px;
                height: 32px;
                font-size: 14px;
                color: #fff;
                background-color: var(--color-Web-Orange);
                border-radius: 4px;
                text-align: center;
            }
        }
    }
}