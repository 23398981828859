@import "src/include";
#docTradeSampleListTop {
    .sample_list-contentTop {
        position: relative;
        width: 950px;
        margin: 0 auto;
        padding-bottom: 9px;
        &_tabs {
            display: flex;
            justify-content: start;
            align-items: center;
            width: 200px;
            height: 26px;
        }
        &_tab {
            width: 60px;
            height: 100%;
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
            .sample_list-contentTop_tab_link {
                display: inline-block;
                width: 100%;
                height: 100%;
                line-height: 26px;
                background-color: #fff;
                border-radius: 4px;
                font-size: 12px;
                color: #343434;
                text-align: center;
                font-family: NanumSquareOTF_acEB;
                border: 1px solid #d8d8d8;
            }
            &:hover,
            &.active {
                .sample_list-contentTop_tab_link {
                    background-color: var(--color-Web-Orange);
                    border: 1px solid var(--color-Web-Orange);
                    color: #fff;
                }
            }
        }
        &_sort-select {
            position: absolute;
            top: 0;
            right: 9px;
            width: 111px;
            height: 26px;
            font-size: 14px;
            color: #000;
            letter-spacing: -0.25px;
            font-family: NanumSquareOTF_acB;
            padding-left: 5px;
        }
        &_sort-option {
            padding-left: 5px;
        }
    }
}