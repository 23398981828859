@import "src/include";

// 공통 스타일 변수
$border-light: solid 1px #e7e7e7;
$border-radius-default: 5px;
$box-shadow-default: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
$background-white: #ffffff;

// 공통 믹스인
@mixin flex-center {
  display: flex;
  align-items: center;
}

// Detail.company Detail.agent 스타일
#Detail.company,
#Detail.agent {
  width: 910px;

  .form {
    .field {
      padding: 12px 0 16px 0;
      border-radius: $border-radius-default;
      box-shadow: $box-shadow-default;
      border: $border-light;
      background-color: $background-white;
      position: relative;

      &.history {
        padding: 16px;
        .history_tit {
          @include flex-center;
        }
        .btns {
          > button {
            margin-left: 10px;
          }
        }
      }

      & > .checkpoint-btn {
        position: absolute;
        top: 55px;
        left: -20px;
        border-radius: $border-radius-default;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.19);
        @include flex-center;
        gap: 5px;
        padding: 5px 10px;
        transform: translateX(-100%);
      }

      .process_step {
        position: absolute;
        top: 18px;
        right: 920px;
        @include flex-center;

        &.history {
          position: static;
        }

        .circle.green {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #00ae02;
        }

        .f_title {
          margin-left: 10px;
          font-size: 20px;
          font-family: NanumSquareOTF_acB;
          white-space: pre;
        }
      }
    }

    .rows {
      padding: 0 12px 0 15px;

      .row.plus {
        width: 150px !important;
      }

      .declare_row {
        @include flex-center;
        width: 950px !important;
        height: 48px;

        .title_no_items {
          font-size: 16px;
          color: #4593f5;
        }

        .btn_delete {
          width: 64px;
          height: 32px;
          padding: 0 12px;
          letter-spacing: 0.86px;
          font-family: NanumSquareOTF_acB;
          color: #fff;
          font-size: 12px;
          border-radius: 4px;
          background-color: #e92f2c;
          margin-left: 20px;
        }
      }
    }

    .rows_inventor-add {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 12px 8px 15px !important;
      gap: 8px;

      .inventor-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .inventor-row {
        display: flex;
        gap: 8px;
        font-size: 14px;
        flex-direction: column;

        .inventor-section {
          @include flex-center;
          gap: 8px;

          input {
            padding: 5px 10px;
            height: unset;
          }
        }
      }

      .inventor {
        display: flex;
        gap: 8px;
        font-size: 14px;

        h2 {
          margin-top: 3px;
        }
      }

      &.right {
        justify-content: flex-end;
      }
    }
  }

  .form .refuse_field .rows {
    .row {
      position: relative;
      display: flex;
      justify-content: space-between;
      @include flex-center;
      width: 283px;
      min-height: 45px;
      font-size: 14px;
      border-bottom: $border-light;

      .title {
        @include flex-center;
        white-space: pre;
        font-family: NanumSquareOTF_acB;
        gap: 8px;
        width: 86px;
      }
    }
  }

  .btn_mypage_product {
    width: 100px;
    line-height: 24px;
    text-align: center;
    border: 2px solid var(--color-Web-Orange);
    @include border-radius(16px);
  }
}

// Detail.company, Detail.agent와 Detail.isNew 공통 스타일
#Detail.company,
#Detail.agent,
#Detail.isNew {
  .process_manage {
    margin: 17px 0 13px 0;
    background-color: rgba(29, 153, 230, 0.03);

    .p_menu {
      padding-left: 20px;
      @include flex-center;
      height: 50px;
      background-color: #f1f9fd;

      i {
        margin-right: 10px;
      }

      p {
        margin-right: 5px;
        font-size: 14px;
        font-family: NanumSquareOTF_acB;
        color: #1d81ee;
      }
    }

    .ToggleButtonWithSpan {
      span {
        width: 25px;
        font-size: 14px;
        font-family: NanumSquareOTF_acB;
        color: #1d81ee;
      }

      .ToggleButton {
        border: 1px solid #1ca0e3;
        background-color: $background-white;

        .dialog-button {
          top: 1px;
        }

        .off {
          left: 0;
          background-color: #1ca0e3;
        }

        &.on {
          background-color: #1ca0e3;
        }
      }
    }

    .rows:last-child {
      padding-bottom: 15px;
    }
  }
}

// Detail.no_tip 스타일
#Detail.no_tip {
  .row {
    .title {
      i {
        display: none !important;
      }
      i.icon_check_empty {
        display: block !important;
        cursor: pointer;
        width: 21px;
        height: 21px;
      }
    }
  }
}

// Detail.isNew 스타일
#Detail.isNew {
  width: 910px;

  .area_box {
    padding: 9px 0 11px 0 !important;
    border: $border-light;
    @include border-radius($border-radius-default);
    box-shadow: $box-shadow-default;
    background-color: $background-white;

    .header {
      padding: 0 12px 0 15px;

      h2 {
        font-family: NanumSquareOTF_acB;
      }
    }

    .rows {
      padding: 0 12px 0 15px;

      .declare_row {
        @include flex-center;
        width: 950px !important;
        height: 48px;

        .title_no_items {
          font-size: 16px;
          color: #4593f5;
        }

        .btn_delete {
          width: 64px;
          height: 32px;
          padding: 0 12px;
          letter-spacing: 0.86px;
          font-family: NanumSquareOTF_acB;
          color: #fff;
          font-size: 12px;
          border-radius: 4px;
          background-color: #e92f2c;
          margin-left: 20px;
        }
      }
    }
  }

  #InventionDetail {
    width: 910px;

    > h2 {
      margin-bottom: 8px;
      padding: 0 12px 0 15px;
      font-family: NanumSquareOTF_acB;
    }

    .field {
      padding: 0 12px 0 15px;

      > h2 {
        font-family: NanumSquareOTF_acB;
      }

      .rows {
        padding: 0;
      }
    }
  }
}

// 기본 Detail 스타일
#Detail {
  margin: auto;
  padding: 40px 0px;
  width: 880px;

  textarea {
    border-radius: 4px;
    border: solid 1px #d8d8d8;
    resize: none;
    padding: 16px;
    width: 100%;
    height: 200px;
  }

  .history_table {
    input[type="text"] {
      height: 30px;
    }

    i {
      cursor: pointer;
    }

    .history_date {
      display: flex;
      align-items: center;
      justify-content: center;

      > p {
        margin-right: 5px;
      }
    }
  }

  .add_btn {
    height: 32px;
    padding: 0 8px;
    letter-spacing: 0.86px;
    color: #fff;
    font-family: NanumSquareOTF_acB;
    font-size: 14px;
    border-radius: 4px;
    background-color: #4593f5;
  }

  .refuse_field {
    width: 95%;
    margin: 20px auto;
    padding: 20px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  // 헤더 스타일
  > .header {
    position: relative;
    margin-bottom: 29px;
    min-height: 120px;

    > .title {
      margin-bottom: 11px;
      font-size: 22px;
    }

    .info {
      position: relative;
      font-size: 16px;

      .manage_no {
        @include flex-center;

        i {
          margin-left: 10px;
        }
      }

      .option {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .checkpoint-link {
          padding: 0px 8px;
          box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.19);
          border-radius: $border-radius-default;
          height: 30px;
          display: grid;
          align-content: center;
        }

        .link_tab {
          display: flex;
          max-width: 720px;
          flex-wrap: wrap;

          li {
            margin-right: 10px;
            margin-bottom: 10px;

            a,
            button {
              padding: 0 8px;
              display: flex;
              justify-content: space-between;
              @include flex-center;
              min-width: 110px;
              height: 30px;
              border-radius: $border-radius-default;
              box-shadow: $box-shadow-default;
              background-color: $background-white;

              .title {
                font-size: 14px;
                font-family: NanumSquareOTF_acB;
              }
            }
          }
        }
      }

      .state {
        position: relative;
        margin-top: 10px;
        padding: 0 6px;
        min-width: 106px;
        line-height: 24px;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        @include border-radius(16px);
        background-color: #00ae02;
        cursor: pointer;

        .icon_more {
          position: absolute;
          top: 0;
          right: -24px;
          width: 24px;
          height: 24px;
        }
      }

      > ul {
        position: absolute;
        top: 100%;
        height: 400px;
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: $background-white;
        z-index: 9;

        button {
          display: block;
          width: 120px;
          height: 32px;
          font-size: 12px;
          border: solid 1px #d8d8d8;
          background-color: $background-white;

          & + button {
            border-top: none;
          }

          &:hover {
            background-color: rgba(0, 149, 174, 0.2);
            transition-duration: 0.5s;
          }
        }
      }
    }

    // 사이드 버튼 스타일
    .side_btns {
      position: fixed;
      top: 50%;
      left: calc(52% + 550px);
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      gap: 20px 0;

      button {
        font-size: 16px;
        color: #ffffff;
        border-radius: 4px;
        padding: 0 8px;
      }

      // 버튼 스타일 정의
      .btn_edit {
        width: 64px;
        line-height: 32px;
        background-color: var(--color-Sky-Blue);
      }

      .btn_save {
        width: 64px;
        line-height: 32px;
        background-color: var(--color-Royal-Blue);
      }

      .btn_register {
        width: 96px;
        line-height: 32px;
        background-color: var(--color-blue);
      }

      .btn_cancle {
        width: 64px;
        line-height: 32px;
        background-color: var(--color-Manatee);
      }

      .btn_delete {
        width: 120px;
        line-height: 32px;
        background-color: var(--color-Cinnamon);
      }

      .btn_add {
        width: 120px;
        line-height: 32px;
        background-color: var(--color-Shamrock-Green);
      }

      .btn_add_category,
      .btn_delete_category {
        width: 120px;
        line-height: 32px;
        border: solid 1px var(--color-Gallery);
        color: #000000;
        background-color: $background-white;
        cursor: pointer;
        text-align: center;
      }

      .btn_report {
        width: 164px;
        line-height: 32px;
        color: #ffffff;
        background-color: var(--color-Niagara);
      }

      .btn_mail {
        width: 180px;
        line-height: 32px;
        color: #4f5ef7;
        border: 1px solid #4f5ef7;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
        margin-left: 12px;

        i {
          margin-right: 6px;
        }
      }

      .btn_status {
        width: 180px;
        line-height: 32px;
        color: #4f5ef7;
        border: 1px solid #4f5ef7;
        background-color: #e8eaff;
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;

        i {
          width: 32px;
          height: 21px;
          margin-right: 6px;
        }
      }

      ul {
        position: absolute;
        right: 0;
        width: 120px;
      }
    }

    // 기본 버튼 그룹 스타일
    .btns {
      position: absolute;
      top: 36px;
      right: 0;
      float: right;
      display: flex;
      align-items: center;
      z-index: 2;

      button {
        font-size: 16px;
        color: #ffffff;
        border-radius: 4px;
        padding: 0 8px;
      }

      // 버튼 스타일 정의
      .btn_edit {
        margin-left: 12px;
        width: 64px;
        line-height: 32px;
        background-color: var(--color-Sky-Blue);
      }

      .btn_save {
        margin-left: 12px;
        width: 64px;
        line-height: 32px;
        background-color: var(--color-Royal-Blue);
      }

      .btn_register {
        width: 96px;
        line-height: 32px;
        background-color: var(--color-blue);
      }

      .btn_cancle {
        margin-left: 12px;
        width: 64px;
        line-height: 32px;
        background-color: var(--color-Manatee);
      }

      .btn_add {
        margin-left: 8px;
        width: 120px;
        line-height: 32px;
        background-color: var(--color-Shamrock-Green);
      }

      .btn_add_category {
        width: 120px;
        line-height: 32px;
        border: solid 1px var(--color-Gallery);
        color: #000000;
        background-color: $background-white;
        cursor: pointer;
        text-align: center;
      }

      .btn_report {
        width: 164px;
        line-height: 32px;
        color: #ffffff;
        background-color: var(--color-Niagara);
      }

      .btn_mail {
        width: 180px;
        line-height: 32px;
        color: #4f5ef7;
        border: 1px solid #4f5ef7;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
        margin-left: 12px;

        i {
          margin-right: 6px;
        }
      }

      .btn_status {
        width: 180px;
        line-height: 32px;
        color: #4f5ef7;
        border: 1px solid #4f5ef7;
        background-color: #e8eaff;
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;

        i {
          width: 32px;
          height: 21px;
          margin-right: 6px;
        }
      }

      ul {
        position: absolute;
        right: 0;
        width: 120px;
      }
    }

    .tip {
      position: absolute;
      top: 1px;
      left: 250px;
      display: flex;

      i {
        margin-right: 10px;
      }
    }
  }

  // 폼 스타일
  .form {
    clear: both;

    #InventionDetail {
      margin: 0;
      padding-top: 0;

      > h2 {
        font-size: 22px;
      }

      select {
        margin-right: 0;
      }
    }

    .field {
      position: relative;

      .header {
        margin-bottom: 20px;
        padding: 0 12px 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;

        > .title {
          margin: 0;
          display: flex;
          font-size: 22px;

          i {
            margin-left: 10px;
          }
        }
      }

      & + .field {
        margin-top: 61px;
      }
    }

    .field.company {
      .header {
        padding: 0 12px 0 15px;
      }
      .rows {
        flex-wrap: wrap;
        .row {
          width: 426px;
          margin-left: 0;
          &:nth-child(2n) {
            margin-left: 15px;
          }
          input {
            width: 135px;
          }
        }
      }
    }

    // 행 스타일
    .rows {
      display: flex;

      .row {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 283px;
        min-height: 45px;
        font-size: 14px;
        border-bottom: $border-light;

        .title {
          @include flex-center;
          white-space: pre;
          font-family: NanumSquareOTF_acB;
          gap: 8px;

          i.icon_info {
            display: none;
            margin-left: 4px;
            width: 24px;
            height: 24px;
            background: url("../../../../assets/images/common/icon-badge-information-black.png");

            &:hover:after {
              content: attr(data-content);
              position: absolute;
              white-space: pre-line;
              padding: 4px 8px;
              top: 100%;
              left: 0;
              min-width: 50%;
              max-width: 392px;
              line-height: 22px;
              font-size: 12px;
              font-style: normal;
              color: white;
              z-index: 2;
              border-radius: 9px;
              background-color: rgba(0, 0, 0, 0.85);
            }
          }
        }

        > p {
          max-width: 150px;
          word-break: break-word;
        }

        .icon_badge_del {
          margin-left: 15px;
        }
        .title-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2px;

          .sub-title {
            font-size: 10px;
          }
        }

        .btns {
          display: flex;
          align-items: center;

          span {
            white-space: pre;
          }

          .date_txt {
            margin-right: 5px;
            width: 40px;
            line-height: 24px;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            @include border-radius(16px);
            background-color: var(--color-Niagara);
          }

          button {
            margin-left: 5px;
          }

          .ToggleButton {
            margin-left: 10px;
          }

          &.prior {
            .ToggleButtonWithSpan {
              margin: 0;

              span {
                margin: 0;
              }
            }
          }
        }

        .ToggleButtonWithSpan {
          span {
            font-size: 14px;
          }
        }

        input {
          padding: 0 8px;
          width: 135px;
          height: 30px;
          font-size: 14px;
          text-align: left;
          border: solid 1px rgba(0, 0, 0, 0.56);

          &.long {
            width: 480px !important;
          }
        }

        select {
          padding-right: 20px;
          width: 135px;
          height: 32px;
          font-size: 14px;
          white-space: pre-wrap;
        }

        .btn_order {
          width: 121px;
          height: 30px;
          font-size: 14px;
          font-family: NanumSquareOTF_acB;
          color: #ffffff;
          background-color: var(--color-Niagara);
        }

        .icon_plus {
          margin-left: 15px;
          display: inline-block;
          width: 24px;
          height: 24px;
        }

        &:hover .title > i {
          display: block;
        }

        &.no_data {
          border-bottom: none;
        }

        &.colspan2 {
          justify-content: space-between;
          width: 580px;

          .title {
            width: 100px;
          }

          p {
            width: 450px;
            max-width: 450px;
          }
        }

        &.colspan3 {
          width: 870px;

          input.long {
            width: 720px !important;
          }
        }

        & + .row {
          margin-left: 15px;
        }

        &.disable {
          opacity: 0.3;
          pointer-events: none;
        }

        &.translate {
          font-family: NanumSquareOTF_acB;
        }
      }
    }

    .rows.inventor {
      .row:nth-child(3n + 1) {
        margin-left: 0;
      }
    }

    &.hidden_download {
      .icon_download {
        display: none;
      }
    }
  }

  .btn_remove {
    width: 120px;
    height: 32px;
    border-radius: 4px;
    color: #ffffff;
    background-color: var(--color-Alizarin-Crimson);
  }

  .not_editing {
    display: none;
  }

  .no_click {
    pointer-events: none;
    opacity: 0.4;
  }

  .icon_upload,
  .icon_download,
  .icon_download_attached {
    min-width: 24px;
    height: 24px;
  }

  .applicantors_list {
    z-index: 9999;
  }

  .comp_frame {
    width: 1390px;
    height: 100%;
    background-color: #fff;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border: 1px solid #eee;

    .close {
      text-align: right;
      padding: 20px 20px 0 0;
    }
  }
}
