.researchTechRequestList {
    .researchTechRequestList_content{
        .header {
            display: flex;

            justify-content: space-between;
            align-items: center;

            .download_cnt {
                font-family: NanumSquareOTF_acB, sans-serif;
                font-size: 18px;
                color: #343434;
                span {
                    color: #4593f5;
                }
            }
        }
    }

    &_content {
        h2 {
            margin-bottom: 20px;
            font-size: 22px;
            font-family: NanumSquareOTF_acB;
            color: #343434;
        }
    }

    table {
        thead {
            width: calc(100% - 5px);
        }

        th:nth-of-type(3),
        td:nth-of-type(3), {
            padding-left: 10px;
            width: 45%;
            text-align: left;
        }

        th:nth-of-type(2),
        td:nth-of-type(2), {
            width: 15%;
        }

        th:nth-of-type(4),
        td:nth-of-type(4),
        th:nth-of-type(5),
        td:nth-of-type(5),
        th:nth-of-type(6),
        td:nth-of-type(6), {
            width: 10%;
        }

        td {
            a {
                margin: auto;
                display: block;
                width: 24px;
                height: 24px;
                background-size: contain;
            }

            .not_download {
                opacity: 0.3;
                pointer-events: none;
            }

            .btn_load {
                margin: auto;
                display: block;
                width: 64px;
                line-height: 24px;
                font-size: 12px;
                font-family: NanumSquareOTF_acEB;
                color: #ffffff;
                border-radius: 4px;
                background-color: var(--color-Web-Orange);
            }
        }
    }
}