#IpcStatistic {
  .grid-cell {
    text-align: center;
  }
  .grid-header {
    text-align: center;
  }
  .ag-header-group-cell-label,
  .ag-header-cell-label {
    justify-content: center;
  }
}
