@import "../../../include";

#CyberNotify {
    @import "Common.scss";

    .head {
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 302px;

        h2 {
            font-size: 24px;
            font-family: NanumSquareOTF_acEB;
            line-height: 32px;
        }

        p {
            font-size: 32px;
            font-family: NanumSquareOTF_acB;
            line-height: 32px;
            color: #00a545;
        }
    }

    .wrap_list {
        margin: 10vh auto 0 auto;
        width: 333px;
        height: 60vh;
        overflow-y: scroll;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 49px;
            font-size: 12px;
            border-bottom: 1px solid #d8d8d8;

            .info {
                display: flex;
                align-items: center;
                column-gap: 6px;
            }

            .circle {
                width: 12px;
                height: 12px;
                @include border-radius(50%);
                background-color: #00a545;
            }
        }
    }
}