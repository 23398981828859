#ManageApproval {
  margin: auto;
  padding-top: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  column-gap: 20px;
  width: 1280px;

  .wrap_content {
    width: calc(100% - 150px);

    .header {
      margin-bottom: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        font-family: NanumSquareOTF_acEB;
        font-size: 22px;
        color: var(--color-Mine_Shaft);
      }
    }

    .system_list {
      padding-left: 15px;

      li {
        display: flex;
        align-items: center;
        column-gap: 15px;

        h2 {
          font-size: 18px;
          font-family: NanumSquareOTF_acB;
        }
      }
    }
  }
}
