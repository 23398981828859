@import "src/mixin";
@import "src/include";

#IntroService {
  margin: auto;
  max-width: 1280px;

  @include mobile {
    padding: 60px 0;
  }

  .banner_top {
    width: 100%;
  }

  .list {
    margin: 30px auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
    max-width: 910px;
  }

  .item {
    position: relative;
    padding: 0 10px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: 293px;
    height: 100px;
    border-radius: 5px;
    box-shadow: 0 2px 3px 1px #d8d8d8;
    background-color: #fff;
    cursor: pointer;

    @include mobile {
      margin: auto;
      justify-content: space-between;
      width: 90%;
      height: 75px;
      justify-content: unset;
      border-radius: 15px;
      box-shadow: 0 2px 8px 0 #b4e9ff;
      background-color: #fff;
    }

    .txt_area {
      display: flex;
      align-items: center;
      column-gap: 10px;

      @include mobile {
        row-gap: 10px;

        h2 {
          font-size: 24px;
          font-family: Pretendard_extraBold;
          color: var(--color-Tarawera);
          @include mobile {
            @include text-gradient-green;
          }
        }

        p {
          font-size: 14px;
          font-family: Pretendard_medium;
          color: #343434;
        }
      }
    }

    h2 {
      font-size: 30px;
      font-family: NanumSquareOTF_acEB;
      color: var(--color-Cornflower-Blue);

      @include mobile {
        font-size: 18px;
      }
    }

    p {
      font-size: 14px;
      font-family: NanumSquareOTF_acB;

      @include mobile {
        font-size: 10px;
      }
    }

    button {
      position: absolute;
      right: 5%;
    }
  }

  .upload_area {
    margin: 30px auto 0 auto;
    max-width: 910px;

    .title {
      margin-bottom: 20px;
      font-size: 22px;
      font-family: NanumSquareOTF_acB;

      @include mobile {
        margin: 0 auto 20px auto;
        width: 90%;
        font-family: NanumSquareOTF_acEB;
      }
    }

    .upload_list {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .upload_item {
        padding: 0 25px;
        display: flex;
        align-items: center;
        row-gap: 10px;
        width: 912px;
        height: 122px;
        border-radius: 5px;
        background-image: url("../../../assets/images/intro/bg_intro_servie_task.svg");
        background-size: cover;

        @include mobile {
          margin: auto;
          justify-content: center;
          flex-direction: column;
          row-gap: 5px;
          padding: 0 8.5px 0 14.5px;
          width: calc(90vw + 2px);
          background-image: none;
          border-radius: 15px;
          box-shadow: 0 2px 4px 0 #d8d8d8;
          border: solid 1.3px #0078f1;
          background-color: #f3f9ff;
          position: relative;
          img {
            position: absolute;
            left: 2%;
          }
        }

        p {
          width: calc(100% - 50px - 130px);
          font-size: 22px;
          font-family: NanumSquareOTF_acEB;
          color: var(--color-Cloud-Burst);

          @include mobile {
            width: 100%;
            font-size: 18px;
          }
        }

        .btn_pdf_download {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 159px;
          height: 40px;
          border-radius: 15px;
          background-color: #0078f1;
          color: #FFFFFF;
          font-family: NanumSquareOTF_acEB;
          i {
            margin-left: 10px;
          }
          @include mobile {
            margin-left: auto;
            width: 125px;
            height: 32px;
            font-size: 12px;
            margin-top: 10px;
            i {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
