$cellPadding: 5px 15px;
$cellBorderColor: #f0f0f0;

#AnnualSalesFee {
  margin: 0 auto 20px;
  // padding-top: 46px;
  width: 95%;

  & > div:first-child {
    font-size: 1.2rem;
    margin: auto;
    width: 95%;

    select {
      width: 80px;
      outline: none;
    }

    & > .title {
      font-size: 22px;
      margin-bottom: 20px;
    }

    .btns-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;

      button {
        font-size: 1.2rem;
        background-color: transparent;
        font-weight: 500px;
      }

      & > div {
        display: flex;
        gap: 12px;

        button {
          padding: 10px 30px;
          border-radius: 6px;
          color: #fff;
          margin-right: 10px;
        }

        button:first-child {
          background-color: #E42B2B;
        }

        button:last-child {
          background: #19191AE5;
        }
      }
    }
  }

  .annual-fee-grid {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    margin-top: 20px;
    border-bottom: 1px solid $cellBorderColor;
    overflow-y: auto;
    height: 500px; /* Define a fixed height for the container */

    //   General Styles

    .text-blue {
      color: blue;
    }
    .text-red {
      color: red;
    }

    .num-cell {
      padding: 5px 20px 0px 10px;
      text-align: right;
    }



    .header-cell {
      padding: 15px 15px 10px;
      border-bottom: solid 1px #959595;
      background-color: #f0f0f0;
      min-width: 110px;
      text-align: center;
    //   flex: 1;
    // box-sizing: border-box;
    }

    // .sticky-row {
    //   display: flex;
    //   justify-content: flex-end;
    //   grid-column: 1 / -1; /* Span the sticky row across all columns */
    //   position: sticky; /* Make it sticky */
    //   top: 0; /* Stick to the top */
    //   background-color: #f0f0f0; /* Optional: Background to differentiate */
    //   z-index: 1; /* Ensure it stays above other rows */
    //   padding: 10px; /* Padding for spacing */
    // }

    .sticky-header {
      position: sticky;
      top: 0;
    }

    .total-col {
      grid-column-start: 3;
      display: relative;
    }

    .report-info {
      grid-column: span 2;
      grid-row: span 9;
      border: 1px solid $cellBorderColor;
      border-width: 1px 1px 0;
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      min-width: 210px;

      & > h4 {
        font-size: 1.2rem;
        font-weight: 500;
      }

      & .indent-1 {
        padding-left: 10px;
      }
      & .indent-2 {
        padding-left: 18px;
      }

      & > div {
        padding-left: 32px;

        ul {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 5px;

          &::before {
            content: "";
            position: absolute;
            width: 2px;
            height: 79%;
            background-color: black;
            top: 50%;
            transform: translateY(-50%);
          }

          li:nth-child(2),
          li:nth-child(3) {
            padding-left: 1ch;
          }
        }
      }
    }

    .first-row {
      border-top: 1px solid $cellBorderColor;
      padding-top: 41px;
    }
    .last-row {
      padding-bottom: 15px;
    }
  }
  ::-webkit-scrollbar {
    height: 8px;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background color of scrollbar track */
    border-radius: 25px;
  }

  ::-webkit-scrollbar-thumb {
    background: #4593f5;
    border-radius: 25px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #1e59a1;
  }
}
