#Find {
    @media screen and (max-width: 850px){
        padding: 32px 0 100px 0;
    }

    .pc {
        .form {
            > div {
                margin: 54px auto 0 auto;
                width: 450px;
                padding: 44px 50px 37px 45px;
                border-radius: 5px;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
                border: solid 1px #e7e7e7;
                background-color: #ffffff;
            }

            .find_format {

                > h2 {
                    margin-bottom: 31px;
                    font-size: 24px;
                    font-family: NanumSquareOTF_acEB;
                }

                label {
                    display: block;
                    width: 100px;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    color: #000000;
                }

                select, input {
                    padding: 8px;
                    width: 250px;
                    height: 45px;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.75);
                    border: none;
                    border-bottom: solid 1px #e7e7e7;
                }

                .input_area {
                    display: flex;
                    align-items: center;
                    height: 40px;
                }

                .input_area+.input_area {
                    margin-top: 31px;
                }

                .btn_find {
                    margin-top: 51px;
                }
            }

            .show_find_txt {
                height: 384px;
                text-align: center;

                h2 {
                    height: 63px;
                    font-size: 24px;
                    font-family: NanumSquareOTF_acEB;
                }

                p {
                    margin-top: 67px;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                }

                .btn_login {
                    margin-top: 91px;
                }
            }
        }

        .btn {
            margin: auto;
            display: block;
            width: 203px;
            line-height: 40px;
            color: #ffffff;
            border-radius: 5px;
            background-color: var(--color-Web-Orange);
        }
    }

    .mo {

        .form {
            margin: auto;
            width: 90%;

            h2 {
                margin-bottom: 28px;
                font-size: 24px;
                font-family: NanumSquareOTF_acEB;
                line-height: 30px;
                color: #443018;
                text-align: center;
            }

            .input_area {
                margin: auto;
                width: 90%;

                p {
                    margin-bottom: 8px;
                    font-size: 12px;
                    font-family: NanumSquareOTF_acEB;
                    line-height: 18px;
                    color: #443018;
                }

                select,
                input {
                    padding: 0 12px;
                    width: 100%;
                    height: 40px;
                    font-size: 14px;
                    border: 2px solid #d8d8d8;
                    border-radius: 4px;
                 }
             }

            .input_area+.input_area {
                margin-top: 20px;
            }

            .btn {
                margin: 40px auto 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 208px;
                height: 42px;
                font-size: 16px;
                font-family: NanumSquareOTF_acEB;
                line-height: 22px;
                color: #ffffff;
                border-radius: 21px;
                background-color: var(--color-Web-Orange);
            }

            .show_find_txt {
                p {
                    text-align: center;
                }
            }
         }
    }

    .account_search {
        margin: 17px auto 0 auto;
        text-align: center;

        a {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.8);
        }
    }
}
