.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.row {
  display: flex;
  gap: 24px;
}

.fullWidth {
  width: 100%;
}
