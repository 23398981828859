#image_viewer {
    @import "../../../include";

    padding: 17px 14px 19px 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    min-width: 320px;
    height: 90vh;
    @include border-radius(5px);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #e7e7e7;
    background-color: #ffffff;
    z-index: 1000;

    @media screen and (max-width: 850px){
        width: 90%;
        height: auto;

        img {
            padding-top: 0 !important;
            height: auto !important;
        }
    }

    .header {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #e7e7e7;

        h2 {
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
        }
    }

    img {
        margin: auto;
        padding-top: 19px;
        display: block;
        width: 80%;
        height: 84vh;
        object-fit: contain;
    }

    .disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    .icon_more_arrow_left_small_black,
    .icon_more_arrow_right_small_black {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include border-radius(4px);
        background-color: rgba(216, 216, 216, 0.3);
    }

    .icon_more_arrow_left_small_black {
        left: 16px;
    }

    .icon_more_arrow_right_small_black {
        right: 16px;
    }
}