#InventorPatentList {
  width: 1280px;
  margin: 45px auto;

  > .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    h2 {
      font-size: 22px;
    }

    button {
      width: 150px;
      height: 32px;
      background-color: #e92f2c;
      border-radius: 4px;
      font-size: 16px;
      color: #FFFFFF;
      margin-right: 10px;
    }
  }

  .inventor_info_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 20px 0;
    > div {
      display: flex;
      align-items: center;
      > p:nth-child(1) {
        min-width: 100px;
      }
      > p:nth-child(2) {
        display: flex;
        align-items: center;
        min-height: 42px;
      }
      input[type="text"] {
        width: 80%;
      }
    }
    > div:nth-child(4) {
      grid-column: 1 / span 3;
      input[type="text"] {
        width: 100%;
      }
    }
  }

  .inventor_list {
    thead {
      background-color: black;

      tr th {
        color: #FFFFFF;
      }
    }

    tbody tr:hover {
      background-color: rgba(197, 223, 248, 0.5);
      cursor: pointer;
    }
  }
}
