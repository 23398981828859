#UsagePrice {
    @media screen and (max-width: 850px){
        padding-top: 60px;
    }

    .p_title {
        margin-top: 60px;
        font-size: 40px;
        font-family: NanumSquareOTF_acEB;
        text-align: center;
        color: #343434;
    }
    .color_orange {
        color: #FF7C00;
    }
    .p_desc {
        margin: 28px auto;
        width: 880px;
        font-size: 24px;
        font-family: NanumSquareOTF_acB;
        line-height: 32px;
        text-align: center;
        color: #343434;
        .color_blue{
            font-family: NanumSquareOTF_acEB;
            color: var(--color-Niagara);
        }
    }

    .m_desc{
        font-family: NanumSquareOTF_acEB;
        font-size: 20px;
        color: #443018;
        margin: 32px auto 30px;
        text-align: center;
    }

    .m_title{
        font-family: NanumSquareOTF_acEB;
        font-size: 24px;
        color: #443018;
        margin-bottom: 25px;
        text-align: center;
    }

    .another_bg {
        padding: 20px 0 29px 0;
        text-align: center;
        background-color: rgba(129, 202, 233, 0.2);
        &.green{
            background-color: rgba(171, 228, 211, 0.2);
        }
    }

    .vat {
        margin: 11px auto 16px auto;
        width: 1100px;
        font-size: 14px;
        font-family: NanumSquareOTF_acB;
        line-height: 20px;
        color: #959595;
    }

    .btn_start {
        margin: 0 auto 49px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 233px;
        height: 52px;
        font-size: 18px;
        font-family: NanumSquareOTF_acB;
        color: #ffffff;
        border-radius: 27px;
        background-image: linear-gradient(to right, #ff7a00, var(--color-Web-Orange) 100%);
    }

    .mo {
        img {
            display: block;
            margin: auto;
        }

        .btn_free_start {
            margin: 50px auto;
            display: block;
            width: 209px;
            height: 42px;
            font-size: 16px;
            font-family: NanumSquareOTF_acEB;
            color: #ffffff;
            border-radius: 21px;
            background-color: var(--color-Web-Orange);
        }
    }
}
