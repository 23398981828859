#DateRange {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 330px;
  width: 40%;
  .react-datepicker-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.54);
    margin: 0 5px;
  }
  input[type="text"] {
    height: 32px;
    border: none;
  }
}
