#InventionListView {
  margin: auto;
  margin-top: 36px;
  width: 1280px;

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 22px;
      font-family: NanumSquareOTF_acEB;
    }

    .btns {
      display: flex;
      align-items: center;

      > button {
        padding: 0 8px;
        border-radius: 4px;
        min-width: 132px;
        height: 32px;
        font-size: 15px;
        color: #ffffff;
        text-align: center;
      }

      button + button {
        margin-left: 8px;
      }

      .btn_excel_download {
        margin-right: 6px;
        background-color: #96c719;
      }
      .btn_tab_setting {
        font-size: 16px;
      }
      .btn_invention_form_upload {
        background-color: var(--color-Cinnamon);

        &:hover {
          background-color: #d35400;
        }
      }
      .btn_invention_form_download {
        background-color: var(--color-Shamrock-Green);

        &:hover {
          background-color: #1f8d4d;
        }
      }
      .btn_invention_add {
        background-color: var(--color-blue);

        &:hover {
          background-color: #0077e6;
        }
      }
      .btn_tab_setting,
      .btn_tab_setting_save {
        background-color: var(--color-Pickled-Bluewood);

        &:hover {
          background-color: #2a3b4a;
        }
      }

      .ToggleButtonWithSpan {
        margin-right: 31px;
      }
    }
  }

  > .wrap_tab_btns {
    padding: 0 25px;

    .checkbox {
      margin-right: 25px;

      .value {
        width: auto;
        white-space: pre;
      }
    }
  }

  .wrap_list {
    max-height: none; /* 제한 해제 */
    .list {
      height: 450px;
    }

    tr {
      cursor: pointer;
    }

    tbody {
      tr:hover {
        background-color: rgba(0, 149, 174, 0.1);
        transition-duration: 0.5s;
        cursor: pointer;
      }
    }
  }
}
