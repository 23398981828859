@import "include";
@import "color";
@import "icons";
@import "sipnner";
@import "mixin";

$hover-color: rgba(255, 166, 0, 0.1);
$hover-mo-color: rgba(29, 51, 78, 0.1);
$scrollbar-color: #4593f5;

@font-face {
  font-family: NanumSquareOTF_acR;
  src: url("assets/font/NanumSquareOTF_acR.otf"),
    url("assets/font/NanumSquareOTF_acR.ttf") format("truetype");
}

@font-face {
  font-family: NanumSquareOTF_acB;
  src: url("assets/font/NanumSquareOTF_acB.otf"),
    url("assets/font/NanumSquareOTF_acB.ttf") format("truetype");
}

@font-face {
  font-family: NanumSquareOTF_acEB;
  src: url("assets/font/NanumSquareOTF_acEB.otf"),
    url("assets/font/NanumSquareOTF_acEB.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  src: url("assets/font/NotoSansKR/NotoSansKR-Medium.otf");
}

@font-face {
  font-family: NotoSansKR-Bold;
  src: url("assets/font/NotoSansKR/NotoSansKR-Bold.otf");
}

@font-face {
  font-family: NotoSansKR-R;
  src: url("assets/font/NotoSansKR/NotoSansKR-Regular.otf");
}

@font-face {
  font-family: NotoSansCJKkr-B;
  src: url("assets/font/NotoSansCJKkr/NotoSansCJKkr-Bold.otf");
}

@font-face {
  font-family: NotoSansCJKkr-M;
  src: url("assets/font/NotoSansCJKkr/NotoSansCJKkr-Medium.otf");
}

@font-face {
  font-family: NotoSansCJKkr-D;
  src: url("assets/font/NotoSansCJKkr/NotoSansCJKkr-DemiLight.otf");
}

@font-face {
  font-family: NotoSansCJKkr-R;
  src: url("assets/font/NotoSansCJKkr/NotoSansCJKkr-Regular.otf");
}

@font-face {
  font-family: DFYingHei-W7-WINP-BF;
  src: url("assets/font/dfpy/DFPYingHei.ttf") format("truetype");
}

@font-face {
  font-family: SCDream3;
  src: url("assets/font/SCDream/SCDream3.otf");
}

@font-face {
  font-family: SCDream4;
  src: url("assets/font/SCDream/SCDream4.otf");
}

@font-face {
  font-family: SCDream6;
  src: url("assets/font/SCDream/SCDream6.otf");
}

@font-face {
  font-family: GmarketSans;
  src: url("assets/font/GmarketSans/GmarketSansTTFMedium.ttf");
}

@font-face {
  font-family: GmarketSansTTF_bold;
  src: url("assets/font/GmarketSans/GmarketSansTTFBold.ttf");
}

@font-face {
  font-family: Pretendard_bold;
  src: url("assets/font/Pretendard/Pretendard-Bold.otf");
}

@font-face {
  font-family: Pretendard_semiBold;
  src: url("assets/font/Pretendard/Pretendard-Regular.otf");
}

@font-face {
  font-family: Pretendard_light;
  src: url("assets/font/Pretendard/Pretendard-Light.otf");
}

@font-face {
  font-family: Pretendard_extraLight;
  src: url("assets/font/Pretendard/Pretendard-ExtraLight.otf");
}

@font-face {
  font-family: Pretendard_extraBold;
  src: url("assets/font/Pretendard/Pretendard-ExtraBold.otf");
}

@font-face {
  font-family: Pretendard_semiBold;
  src: url("assets/font/Pretendard/Pretendard-SemiBold.otf");
}

@font-face {
  font-family: Pretendard_regular;
  src: url("assets/font/Pretendard/Pretendard-Regular.otf");
}

@font-face {
  font-family: Pretendard_medium;
  src: url("assets/font/Pretendard/Pretendard-Medium.otf");
}

* {
  font-family: Pretendard_semiBold;
}

br {
  opacity: 0;
}

select {
  -webkit-appearance: none; /* 화살표 없애기 for chrome*/
  -moz-appearance: none; /* 화살표 없애기 for firefox*/
  appearance: none; /* 화살표 없애기 공통*/
  background: url("assets/images/common/icon-expand-more.svg") no-repeat 100%;
}

select::-ms-expand {
  display: none; /* 화살표 없애기 for IE10, 11*/
}

.App {
  min-width: 1280px;
}

.container {
  position: relative;
  min-height: calc(100vh - 300px);

  @include mobile {
    overflow-x: hidden;
  }
}

.ir_txt {
  font-size: 0;
}

.util_txt_hidden {
  font-size: 0;
}

.Toastify__toast-container--top-center {
  z-index: 10000;
}

input[type="number"]::-webkit-inner-spin-button {
  display: none;
}

input:focus,
textarea:focus {
  outline: none;
}

.color_main {
  color: var(--color-Web-Orange);
}

.color_blue {
  color: var(--color-Niagara);
}

.color_green {
  color: var(--color-EmeraldGreen);
}

.color_red {
  color: var(--color-Alizarin-Crimson);
}

i {
  display: inline-block;
  width: 24px;
  height: 24px;
}

button[class^="icon_"] {
  width: 24px;
  height: 24px;
}

table {
  width: 100%;
  table-layout: fixed;
  color: #343434;
  thead {
    background-color: #f0f0f0;
    tr {
      height: 46px;
    }

    th {
      font-size: 14px;
      font-family: Pretendard_bold;
      color: #343434;
      word-break: break-all;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #e7e7e7;
      td {
        padding: 7px 0;
        height: 43px;
        font-family: Pretendard_bold;
        font-size: 13px;
        text-align: center;
        word-break: break-all;
      }
    }
  }
}

.flex {
  display: flex;
}

.clearfix {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

.hidden {
  visibility: hidden;
  position: absolute;
  left: -9999em;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  background: none;
  font-size: 0;
  line-height: 0;
  text-indent: -9999em;
}

.emphasis {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    top: -2px;
    left: -8px;
    width: 4px;
    height: 4px;
    background-color: var(--color-Alizarin-Crimson);
    border-radius: 50%;
  }
}

.scrollbar_hidden::-webkit-scrollbar {
  width: 0 !important;
  background-color: transparent !important;
}

.custom_scroll::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;
  border-radius: 2px;
}

.custom_scroll::-webkit-scrollbar {
  width: 4px !important;
}

.custom_scroll::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.custom_scroll::-webkit-scrollbar-track-piece {
  background-color: transparent !important;
}

.remove_scrollbar::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}

.remove_scrollbar::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.remove_scrollbar::-webkit-scrollbar-track-piece {
  background-color: transparent !important;
}

.custom_select {
  width: 220px;
  height: 44px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid var(--color-Web-Orange);
  background: url("assets/images/common/icon-expand-more.svg") no-repeat 100%;

  .default {
    padding-left: 21px;
    cursor: pointer;
  }

  ul {
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    top: -1px;
    left: -1px;
    width: calc(100% + 6px);
    height: 265px;

    li {
      padding-left: 21px;
      width: 220px;
      height: 44px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      border: 1px solid var(--color-Web-Orange);
      border-bottom: none;
      background-color: white;
      cursor: pointer;
    }

    li:first-child {
      border-top: none;
    }

    li + li {
      border-top: 1px solid var(--color-Web-Orange);
    }

    li:last-child {
      border-bottom: 1px solid var(--color-Web-Orange);
    }
  }
}

.custom_hover {
  *:hover {
    background-color: $hover-color;
    transition-duration: 0.5s;
    cursor: pointer;
  }
}

.custom_tr_hover {
  tbody {
    tr:not(.not_styled):hover {
      background-color: rgba(107, 211, 255, 0.1);
      transition-duration: 0.5s;
      cursor: pointer;
    }
  }
}

.custom_radio {
  input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  input[type="radio"] + label {
    display: inline-block;
    position: relative;
    padding-left: 29px;
    cursor: pointer;
  }
  input[type="radio"] + label::before {
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: url(assets/images/common/icon-radio-off.svg) no-repeat 50% 50%;
    background-size: cover;
  }
  input[type="radio"]:checked + label::before {
    background: url(assets/images/common/icon-radio-on.svg) no-repeat 50% 50%;
    background-size: cover;
  }
}

.custom_checkbox {
  input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  input[type="checkbox"] + label {
    display: inline-block;
    position: relative;
    padding-left: 29px;
    cursor: pointer;
  }
  input[type="checkbox"] + label::before {
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: url(assets/images/common/icon-checkbox-off.svg) no-repeat 50%
      50%;
    background-size: cover;
  }
  input[type="checkbox"]:checked + label::before {
    background: url(assets/images/common/icon-checkbox-on.svg) no-repeat 50% 50%;
    background-size: cover;
  }
}

.table-box-wrap {
  position: relative;
  padding-top: 40px;

  .wrap_list {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 590px;
  }

  table {
    thead {
      position: absolute;
      top: 0;
      width: 100%;

      tr {
        display: inline-table;
        table-layout: fixed;
        width: 100%;
      }
    }
  }

  .wrap_list::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
  }

  .wrap_list::-webkit-scrollbar {
    width: 4px !important;
  }
}

.table-box-wrap:not(.not_align) {
  tbody {
    tr {
      display: inline-table;
      table-layout: fixed;
      width: 100%;
    }
  }
}

.no_focus_outline {
  *:focus {
    outline: none;
  }
}

.no_focus_outline:focus {
  outline: none;
}

// infinite
.infinite-scroll-component {
  overflow-x: hidden !important;
}

// datePicker
.react-datepicker__day--disabled {
  color: #ccc !important;
}

.grade_default,
.grade_N {
  font-family: NanumSquareOTF_acEB;
  background-color: #959595;
  color: #ffffff;
  font-size: 12px;
}

.grade_SS {
  font-family: NanumSquareOTF_acEB;
  color: #ffffff;
  background-color: #22c7e3;
  font-size: 12px;
}

.grade_S {
  font-family: NanumSquareOTF_acEB;
  color: #ffffff;
  background-color: #0780fb;
  font-size: 12px;
}

.grade_A,
.grade_AA,
.grade_AAA {
  font-family: NanumSquareOTF_acEB;
  color: #ffffff;
  background-color: #0078f1;
  font-size: 12px;
}

.grade_B,
.grade_BB,
.grade_BBB {
  font-family: NanumSquareOTF_acEB;
  background-color: #00ba78;
  font-size: 12px;
}

.grade_C,
.grade_CC,
.grade_CCC {
  font-family: NanumSquareOTF_acEB;
  background-color: #ffa600;
  font-size: 12px;
}

.grade_D {
  font-family: NanumSquareOTF_acEB;
  color: #ffffff;
  background-color: #ff6159;
  font-size: 12px;
}

.grade_E {
  font-family: NanumSquareOTF_acEB;
  color: #ffffff;
  background-color: #000000;
  font-size: 12px;
}

.mo {
  display: none !important;
}

.sun-editor-editable * {
  font-family: Pretendard_semiBold !important;
  letter-spacing: 0 !important;
  text-indent: 0 !important;
  text-align: initial;
}

.sun-editor,
.sun-editor-editable {
  table {
    td {
      border: 1px solid #e1e1e1 !important;
    }
  }

  p {
    margin: 0 !important;
  }
}

@include mobile {
  .App {
    min-width: 360px;
  }

  .pc {
    display: none !important;
  }

  .mo {
    display: block !important;
  }

  .table-box-wrap {
    .wrap_list::-webkit-scrollbar-thumb {
      background-color: $scrollbar-color;
    }
  }

  table {
    thead {
      th {
        font-size: 12px;
        font-family: Pretendard_bold;
        color: #ffffff;
      }
    }
  }

  .custom_hover {
    *:hover {
      background-color: $hover-mo-color;
    }
  }

  .custom_tr_hover {
    tbody {
      tr:not(.not_styled):hover {
        background-color: $hover-mo-color;
      }
    }
  }
}

.MuiButtonBase-root {
  font-family: Pretendard_bold !important;
  text-transform: none !important;
}

.react-datepicker-wrapper {
  width: auto;
}

body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) { display: none; }