@mixin filledStyle() {
  background-color: black;
  color: white;
}

@mixin alignCenter() {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -ms-flex-direction: column;
  justify-content: center;
  align-items: center;
}

.DragDrop {
  &-File {
    width: 100%;
    height: 100px;
    background-color: #f2f2f2;
    color: #c3c3c3;
    margin-top: 20px;

    @include alignCenter();
    cursor: pointer;
    transition: 0.12s ease-in;

    //&-Dragging {
    //  width: 100%;
    //  height: 100px;
    //  background-color: black;
    //  color: #c3c3c3;
    //  margin-top: 20px;
    //  @include filledStyle();
    //}
  }

  &-Files {
    margin-top: 1rem;

    &>div {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      color: #333;
      line-height: 1.6;
    }

    &-Filter {
      cursor: pointer;
      margin-left: 6px;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
