@import "src/mixin";
@import "src/include";
#TaskNotifyNotLogin {
  margin: 0 auto;
  > .mo {
    background-image: url("../../../assets/images/taskManage/bg-not-login-mo.png");
    background-size: cover;
    min-height: 580px;
    padding: 70px 0 0 0;
    > .notify_text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p {
        background-image: linear-gradient(to left, #0d7, #0078f1);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Pretendard_extraBold;
      }
      > p:nth-child(1) {
        font-size: 16px;
      }
      > p:nth-child(2) {
        font-size: 24px;
      }
      > p:nth-child(3) {
        font-size: 32px;
        span {
          font-size: 16px;
        }
      }
      > p:nth-child(4) {
        font-size: 24px;
      }
    }
    .notify_message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > div {
        display: flex;
        font-size: 12px;
        padding: 9px 19px 9px 8px;
        border-radius: 15px;
        box-shadow: 0 2px 4px 0 #d8d8d8;
        background-color: #fff;
        margin-top: 10px;
        i {
          margin-right: 8px;
        }
        span {
          color: #0078f1;
        }
      }
      > div:nth-child(2) {
        width: 300px;
        opacity: 0.75;
      }
      > div:nth-child(3) {
        width: 340px;
        opacity: 0.5;
      }
    }
    > img {
      display: block;
      width: 175px;
      height: 147px;
      margin: 8px auto 0 auto;
    }
    > button {
      display: block;
      height: 50px;
      width: 95%;
      border-radius: 15px;
      background-color: #0078f1;
      font-size: 16px;
      color: #FFFFFF;
      margin: 0 auto;
    }
  }
  > .pc {
    width: 1280px;
    height: 946px;
    margin: 0 auto;
    background-image: url("../../../assets/images/taskManage/bg-not-login.png");
    > .notify_top {
      position: relative;
      > img {
        position: absolute;
        top: 37px;
        right: 107px;
      }
    }
    .notify_text {
      display: flex;
      flex-direction: column;
      padding: 56px 0 0 127px;
      > span {
        background-image: linear-gradient(to left, #0d7, #0078f1);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      > span:nth-child(1) {
        font-size: 32px;
        width: 220px;
      }
      > span:nth-child(2),
      > span:nth-child(4) {
        width: 350px;
        font-size: 54px;
        font-family: Pretendard_extraBold;
        letter-spacing: -1.46px;
      }
      > span:nth-child(3) {
        font-size: 66px;
        font-family: Pretendard_extraBold;
        width: 330px;
        span {
          font-size: 32px;
        }
      }
      > button {
        width: 312px;
        height: 60px;
        border-radius: 30px;
        background-color: #0078f1;
        font-size: 18px;
        color: #fff;
        margin-top: 25px;
      }
    }
    .notify_message {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 52px;
      > p {
        display: flex;
        align-items: center;
        padding: 21px 45px;
        font-size: 16px;
        color: #343434;
        border-radius: 15px;
        box-shadow: 0 2px 4px 0 #d8d8d8;
        background-color: #fff;
        margin-bottom: 20px;
        i {
          margin-right: 12px;
        }
        span {
          color: #0078f1;
          margin: 0 2px;
        }
      }
    }
  }
}
