@import "src/include";
@import "src/mixin";

#TaskMain {
    width: 1280px;
    margin: 0 auto;
    .wrap_list::-webkit-scrollbar {
        width: 12px !important;
    }

    .table-box-wrap {
        .wrap_list {
            max-height: 800px;
        }
    }

    .wrap_search {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 15px;

        @include mobile {
            margin-top: 0;
        }
    }
    .search_area_pc {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 746px;
        height: 140px;
        background-color: var(--color-Malibu-opacity-015);

        .wrap {
            margin-bottom: 12px;
            display: flex;
            justify-content: space-between;
            width: 700px;

            .hash_list {
                display: flex;
                align-items: center;
                column-gap: 4px;

                li {
                    display: inline-block;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    padding: 7px 12px 7px 12px;
                    font-family: NanumSquareOTF_acEB;
                    border-radius: 14px;
                    color: #ffffff;
                    background-color: var(--color-Malibu);
                    cursor: pointer;
                }
            }

            .btn_notify {
                display: flex;
                align-items: center;
                column-gap: 5px;

                img {
                    width: 27px;
                    height: 24px;
                }

                p {
                    font-size: 18px;
                    font-family: NanumSquareOTF_acEB;
                    color: var(--color-Cloud-Burst);
                }
            }
        }

        .search_inner {
            position: relative;

            input {
                padding: 0 40px 0 20px;
                width: 700px;
                height: 50px;
                font-family: NanumSquareOTF_acB;
                border: none;
                border-radius: 5px;
                background-color: #fff;

                @include mobile {
                    width: 90vw;
                    height: 40px;
                    font-size: 14px;
                }
            }

            input:focus {
                box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
            }

            input::placeholder {
                font-family: NanumSquareOTF_acR;
                color: var(--color-Mine_Shaft);
            }

            button {
                position: absolute;
                top: 12px;
                right: 20px;

                @include mobile {
                    top: 7px;
                    right: 10px;
                }
            }
        }
    }

    .banner {
        .link_img {
            @include mobile {
                width: 100vw;
            }
        }
    }
    .wrap {
        .head {
            .inner {
                margin: 15px auto;
                display: flex;
                justify-content: space-between;
                align-items: end;
                width: 1280px;
                background-color: #fff;

                @include mobile {
                    margin: 0;
                    width: 100vw;
                    overflow-x: scroll;
                    overflow-y: hidden;
                }

                .tabs {
                    display: flex;
                    column-gap: 15px;

                    @include mobile {
                        margin: 10px 0;
                    }

                    button {
                        position: relative;
                        padding: 0 30px;
                        display: flex;
                        align-items: center;
                        column-gap: 15px;
                        height: 42px;
                        font-size: 16px;
                        font-family: NanumSquareOTF_acEB;
                        border: 1px solid var(--color-Cornflower-Blue);
                        border-radius: 24px;
                        background-color: #ffffff;

                        @include mobile {
                            width: 100%;
                            font-size: 14px;
                            white-space: pre;
                        }

                        span {
                            position: relative;
                            font-size: 12px;
                            color: var(--color-Silver-Chalice);

                            &:before {
                                content: "";
                                position: absolute;
                                top: 50%;
                                left: -8px;
                                transform: translateY(-50%);
                                width: 1px;
                                height: 18px;
                                background-color: var(--color-Alto);
                            }
                        }

                        &.active {
                            color: #ffffff;
                            background-color:var(--color-Cornflower-Blue);

                            span {
                                color: #ffffff;
                            }
                        }
                    }

                    .icon{
                        padding-left: 40px;
                        justify-content: center;
                        &::before{
                            position: absolute;
                            content: '';
                            width: 18px;
                            height: 18px;
                            top: 46%;
                            left: 15px;
                            transform: translateY(-50%);
                            background: url(../../../assets/images/mobile/icon_alert_circle.svg)no-repeat 50% 50%;
                            background-size: contain;
                        }
                    }
                }

                .count {
                    font-size: 16px;
                    font-family: NanumSquareOTF_acB;
                    color: var(--color-Scorpion);

                    span {
                        margin: 0 8px;
                        font-size: 30px;
                        font-family: NanumSquareOTF_acEB;
                        color: var(--color-Web-Orange);
                    }
                }
            }

            .wrap_btns {
                line-height: 40px;
                border-bottom: 1px solid var(--color-Gallery);

                .btns {
                    margin: auto;
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    width: 1280px;

                    .btn_view {
                        display: flex;
                        justify-content: end;
                        align-items: center;
                        column-gap: 10px;
                        width: 130px;
                        font-family: NanumSquareOTF_acB;
                        color: #343434;

                        i {
                            width: 20px;
                            height: 20px;
                            background-size: cover;
                        }
                    }
                }
            }
        }
    }

    .not_login_msg.show {
        display: flex;
    }

    .not_login_msg {
        position: absolute;
        top: calc(50% + 20px);
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        column-gap: 8px;
        width: 270px;
        height: 140px;
        border-radius: 8px;
        box-shadow: 0 0 7px 3px #d8d8d8;
        background: white;
        z-index: 1;
        cursor: pointer;
        p {
            font-family: NanumSquareOTF_acR;
            font-size: 13px;
            color: #343434;
            span {
                color: #4792f7;
                font-family: NanumSquareOTF_acB;
            }
        }
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 241px;
            height: 40px;
            background-color: #00BA78;
            box-shadow: 0 2px 2px 0 #f0f0f0;
            font-size: 14px;
            color: white;
            border-radius: 8px;
            font-family: NanumSquareOTF_acB;
        }
    }

    .animation {
        animation: bell .05s linear alternate-reverse 15;
    }

    .MuiTabs-root {
        margin: 20px 0;
        @include mobile {
            margin-bottom: 15px;
        }

        .MuiTabs-indicator {
            background-color: var(--color-Piction-Blue);
        }
    }

    .MuiTabs-flexContainer {
        border-bottom: 1px solid var(--color-Gallery);

        .MuiButtonBase-root {
            padding: 0;
            width: 200px;
            font-size: 16px;

            &.Mui-selected {
                font-family: NanumSquareOTF_acEB !important;
                color: var(--color-Piction-Blue);
            }

            @include mobile {
                min-width: 90px;
                width: 25%;
                font-size: 12px;
            }
        }
    }

    .task_box_list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 15px;
        width: 1280px;
        margin: 0 auto;
        @include mobile {
            flex-direction: column;
        }

        @include mobile {
            padding: 0 10px 10px 10px;
            flex-wrap: unset;
            justify-content: unset;
            overflow-x: hidden;
            overflow-y: hidden;
        }

        .task_item {
            padding: 10px;
            width: 550px;
            border-radius: 5px;
            box-shadow: 0 2px 4px 0 var(--color-Gallery);
            border: 1px solid var(--color-Gallery);
            cursor: pointer;

            @include mobile {
                width: 100%;
            }

            .task_head {
                margin-bottom: 5px;
                display: flex;
                align-items: center;
                column-gap: 5px;

                .task_state {
                    min-width: 55px;
                    width: 55px;
                    line-height: 22px;
                    font-size: 12px;
                    font-family: NanumSquareOTF_acEB;
                    color: #ffffff;
                    text-align: center;
                    border-radius: 10px;
                    background-color: var(--color-Web-Orange);
                }

                .task_title {
                    font-size: 16px;
                    font-family: NanumSquareOTF_acB;
                    @include text-ellipsis(1);
                }
            }

            .task_desc {
                margin-bottom: 5px;
                //height: 36px;
                font-size: 12px;
                line-height: 1.5;
                color: #606060;
                @include text-ellipsis(2);
            }

            .day {
                padding: 0 10px;
                display: inline-block;
                max-width: 100px;
                line-height: 21px;
                font-size: 12px;
                font-family: NanumSquareOTF_acEB;
                color: #ffffff;
                text-align: center;
                border-radius: 14px;
                background-color: var(--color-Piction-Blue);
            }

            .task_info_list {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                column-gap: 15px;
                row-gap: 10px;

                .wrap_date {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 10px;
                    row-gap: 10px;

                    &.col {
                        h2 {
                            letter-spacing: 10px;
                        }
                    }

                    @include mobile {
                        &.col {
                            flex-direction: column;
                        }
                    }
                }

                .task_info_item {
                    display: flex;
                    column-gap: 10px;
                    min-width: 130px;
                    font-size: 13px;
                    font-family: NanumSquareOTF_acB;
                    word-break: keep-all;

                    &.br_item {
                        width: 100%;

                        @include mobile {
                            width: auto;
                        }
                    }

                    &.br_item_mo {
                        width: 200px;
                    }

                    h2 {
                        width: 45px;
                        color: var(--color-Scorpion);
                    }

                    p {
                        color: var(--color-Mine_Shaft);
                    }
                }
            }

            .task_add_info {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .task_amount {
                    display: flex;
                    align-items: center;
                    column-gap: 5px;

                    p {
                        font-size: 14px;
                        font-family: NanumSquareOTF_acB;

                        b {
                            font-family: NanumSquareOTF_acEB;
                            color: var(--color-Piction-Blue);
                        }
                    }
                }
            }
        }
    }

    .gov_nav_btn {
        display: flex;
        margin: 20px 80px 0 auto;
        width: 156px;
        height: 40px;
        border-radius: 24px;
        background-image: linear-gradient(to left, #0d7, #0078f1);
        font-size: 16px;
        color: #fff;
        align-items: center;
        justify-content: center;
    }

    .korea_item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 170px;
        border: none !important;
        background-size: cover;
        background-repeat: no-repeat;

        &.type_1 {
            background-image: url("../../../assets/images/intro/bg-korean-type-1.svg");
        }

        &.type_2 {
            background-image: url("../../../assets/images/intro/bg-korean-type-2.svg");
        }

        &.type_3 {
            background-image: url("../../../assets/images/intro/bg-korean-type-3.svg");
        }

        p {
            margin-bottom: 5px;
            font-size: 18px;
            font-family: NanumSquareOTF_acB;
            text-align: center;
        }

        h2 {
            margin-bottom: 10px;
            max-width: 350px;
            font-size: 24px;
            font-family: NanumSquareOTF_acEB;
            color: #1d334e;
            text-align: center;
            word-break: keep-all;
            white-space: pre-wrap;
        }

        button {
            width: 88px;
            height: 30px;
            color: var(--color-Cornflower-Blue);
            border-radius: 14px;
            background-color: var(--color-Malibu-opacity-02);
        }
    }
    @keyframes bell {
        0% {
            margin-left: 5px;
        }
        100% {
            margin-left: 0;
        }
    }

    .category_list {
        margin: 20px auto 0 auto;
        max-width: 1280px;

        img {
            width: 140px;

            @include mobile {
                width: 100px;
            }
        }

        a:focus {
            outline: none;
        }
    }

    .table-box-wrap {
        margin: auto;
        max-width: 1280px;
    }

    @include mobile {
        padding-top: 60px;

        .wrap_list {
            margin-bottom: 20px;
            border-bottom: 1px solid #d8d8d8;
        }

        .title {
            margin: 35px 0 6px 0;
            font-size: 20px;
            font-family: NanumSquareOTF_acEB;
            text-align: center;
            color: #1d334e;
        }

        .desc {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            line-height: 20px;
            color: #606060;
            background-color: rgba(240, 240, 240, 0.5);
        }

        .tabs {
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
            width: 100%;

            button {
                width: 28%;
                height: 38px;
                font-size: 12px;
                font-family: NanumSquareOTF_acEB;
                color: #443018;
                @include border-radius(8px);
                box-shadow: 0 2px 3px 1px rgb(216 216 216 / 30%);
            }

            .active {
                color: #ffffff;
                background-color: #ffa600;
            }
        }

        .table-box-wrap {
            margin-bottom: 30px;
            .wrap_list {
                max-height: 350px;
                table {
                    thead {
                        background: #1d334e;
                    }
                }
            }
        }

        table {
            .table_title {
                padding-left: 10px;
                width: 65% !important;
                text-align: left;
            }

            .not_styled {
                td {
                    text-align: center;
                }
            }
        }

        .btnDown_wrap{
            position: relative;
            margin: 0 auto;
        }

        .btnDown {
            position: absolute;
            width: 24px;
            height: 24px;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            text-indent: -9999px;
            margin: 0;
            text-align: center;
            background: url(../../../assets/images/common/icon-download_mobile.svg) no-repeat 50% 50%;
            background-size: cover;
        }
    }
}
