@import "src/include";
#docTradeSampleRegisterFull {
	width: 1100px;
	margin: 0 auto;
	.full-register {
		&_box {
			width: 880px;
			height: 80px;
			margin: 0 auto;
			line-height: 1.29;
			background-color: #f1f1f1;
			border-radius: 20px;
			padding: 22px 51px;
			font-family: NanumSquareOTF_acB;
			font-size: 14px;
			color: #606060;
			margin-bottom: 22px;
		}
		&_table-top{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 880px;
			height: 32px;
			line-height: 32px;
			margin: 0 auto 11px;
			&_selectfile{
				width: 50%;
				font-family: NanumSquareOTF_acB;
				font-size: 14px;
				em{
					font-family: NanumSquareOTF_acB;
				}
			}
			&_btn-wrap{
				display: flex;
				justify-content: start;
				align-items: center;
				width: 50%;

				.open-pop{
					display: flex;
					justify-content: start;
					align-items: center;
					margin-right: 10px;
					background-color: transparent;

					.icon_attention{
						width: 24px;
						height: 24px;
						background-size: cover;
					}
					.text{
						height: 32px;
						line-height: 32px;
						font-size: 12px;
						color: #606060;
					}
				}
				.sampleFileAdd{
					width: 132px;
					height: 32px;
					line-height: 32px;
					text-align: center;
					font-size: 16px;
					border-radius: 4px;
					background-color: #ffa600;
					color: #fff;
				}
			}
		}
		&_table-wrap{
			margin-bottom: 60px;
			th.style{
				text-align: left;
				padding-left: 26px;
			}
		}
	}
}