#DetailDownloadPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 35px 45px;
    width: 780px;
    height: 480px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    z-index: 1000;

    .title {
        margin-bottom: 25px;
        font-size: 22px;
        font-family: NanumSquareOTF_acB;
        color: #343434;
    }

    .wrap_table {
        overflow-y: scroll;
        height: 350px;

        table {
            table-layout: fixed;

            tbody {
                tr {
                    height: 47px;
                }
            }
        }
        .btn_delete {
            color: var(--color-Alizarin-Crimson);
            background-color: transparent;
        }
    }
    .icon_exit {
        position: absolute;
        top: 35px;
        right: 43px;
    }
}
