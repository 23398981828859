#AdminCheckPoint {
  margin: auto;
  padding-top: 50px;
  width: 880px;

  .page_title {
    margin-bottom: 40px;
    height: 40px;
    font-size: 24px;
    font-family: NanumSquareOTF_acB;
    color: #443018;
    border-bottom: 1px solid #ddd;
  }

  .articles {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 20px;
  }

  .field {
    background-color: #fdf7f4;
  }
  .qafield {
    background-color: #fff2f5;
  }
  .regist {
    background-color: #f9f5fd;
  }
  .ipm {
    background-color: red;
  }
  span {
    margin-right: 14px;
    padding-right: 14px;
    display: flex;
    align-items: center;
    width: 70px;
    font-family: NanumSquareOTF_acB;
    border-right: 1px solid #ddd;
  }

  textarea,
  input,
  select {
    font-size: 14px;
  }

  textarea,
  input[type="text"] {
    padding: 10px;
    width: 100%;
    border: 1px solid #ddd;
  }

  select {
    width: 135px;
    height: 38px;
  }

  p {
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 800px;
    white-space: pre-wrap;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn_upload {
  margin: 30px auto 0 auto;
  display: block;
  width: 150px;
  height: 40px;
  font-size: 18px;
  font-family: NanumSquareOTF_acB;
  color: #ffffff;
  background-color: var(--color-Web-Orange);
}
.faq_body {
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: none;
  padding: 20px;
  border-radius: 0 0 10px 10px;
}
.input {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fdf7f4;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.answer.open {
  margin-top: 50px;
}
