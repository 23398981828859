.fixed-down-box {
    width: 60%;
    border: 1px solid #c7c9cc;
    border-radius: 4px;
    margin: 20px auto;
    padding: 25px;
    background-color: white;
    position: sticky;
    bottom: 0;

    .header {
        position: relative;
        .title {
            text-align: center;
            font-size: 20px;
            font-weight: 600;
        }

        .toggle-button {
            background-color: transparent;
            border: 1px solid black;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 30px;
            cursor: pointer;

            &.opened {
                background-color: transparent;
                color: inherit;

                &:hover {
                    background-color: #f5f5f5;
                }
            }

            &.closed {
                background-color: black;
                color: white;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.8);
                }
            }
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: start;
        padding-top: 40px;

        .left-section {
            font-size: 16px;
            font-weight: 600;
            margin-top: 23px;
            flex: 1;

            .red-text {
                margin: 23px 0;
                color: #e42b2b;
            }

            .green-text {
                color: #00b51b;
            }
        }

        .divider {
            height: 190px;
            align-self: center;
            width: 1px;
            background-color: #e1e4e7;
        }

        .right-section {
            flex: 1;
            text-align: left;
            margin: 23px;
            font-size: 16px;
            font-weight: 700;

            .packages {
                margin-bottom: 23px;
            }

            .sub-toggle {
                cursor: pointer;
                display: inline;

                .university-data {
                    font-size: 13px;
                    margin-top: 11px;
                    font-weight: normal;
                }
            }
        }
    }
}