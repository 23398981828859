.reply-row {
  border-bottom: none;

  tr {
    background-color: #f5f7f8;
  }
  .check-field {
    text-align: center;
  }
}

.reply-toggle {
  display: flex;
  align-items: center;

  &.no-replies {
    margin-left: 24px;
  }
}
