.icon_detail {
  background: url("assets/images/common/icon-detail.svg") no-repeat;
}

.icon_traffic_lights {
  background: url("assets/images/common/icon-traffic-lights.svg") no-repeat;
}

.icon_attached_file {
  background: url("assets/images/common/icon-attached-file.svg") no-repeat;
}

.icon_admin {
  background: url("assets/images/common/icon-admin.svg") no-repeat;
}

.icon_home {
  background: url("assets/images/common/icon-home.svg") no-repeat;
}

.icon_patent_payment {
  background: url("assets/images/common/icon-patent-payment.svg") no-repeat;
}

.icon_badge_question {
  background: url("assets/images/common/icon-badge-question.png") no-repeat;
}

.icon_question_mark {
  background: url("assets/images/common/icon-question-with-bg.svg") no-repeat;
}

.icon_next_white {
  background: url("assets/images/common/icon-next-white.svg") no-repeat;
}

.icon_next_black {
  background: url("assets/images/common/icon-next-black.svg") no-repeat;
}

.icon_hourglass {
  background: url("assets/images/common/icon-hourglass.svg") no-repeat;
}

.icon_hourglass_small {
  background: url("assets/images/common/icon-hourglass-small.svg") no-repeat;
}

.icon_payment {
  background: url("assets/images/common/icon-payment.svg") no-repeat;
}

.icon_search_black_small {
  background: url("assets/images/common/icon-search-small.svg") no-repeat;
}

.icon_search_black {
  background: url("assets/images/common/icon_search_black.svg") no-repeat;
}

.icon_search_blue {
  background: url("assets/images/common/icon_search_blue.svg") no-repeat;
}

.icon_noun {
  background: url("assets/images/common/icon-noun.svg") no-repeat;
}

.icon_blue_add {
  background: url("assets/images/common/icon-blue-add.svg") no-repeat;
}

.icon_blue_add_s {
  background: url("assets/images/common/icon-blue-add-s.svg") no-repeat;
}

.icon_green_add {
  background: url("assets/images/common/icon-green-add.svg") no-repeat;
}

.icon_white_add {
  background: url("assets/images/common/icon-add-white.svg") no-repeat;
}

.icon_badge_more_blue {
  background: url("assets/images/common/icon-badge-more-blue.svg") no-repeat;
}

.icon_check_black {
  background: url("assets/images/common/icon-check.svg") no-repeat;
}

.icon_check_white {
  background: url("assets/images/common/icon-check-white.svg") no-repeat;
}

.icon_check_green {
  background: url("assets/images/common/icon-check-green.svg") no-repeat;
}

.icon_check_blue {
  background: url("assets/images/common/icon-checkbox-off-blue.svg") no-repeat;
}

.icon_check_orange {
  background: url("assets/images/common/icon-checkbox-off-orange.svg") no-repeat;
}
.icon_check_circle {
  background: url("assets/images/common/icon/icon-check-circle.svg") no-repeat;
}
.icon_check_empty {
  background: url("assets/images/common/icon/icon-check-circle-empty.svg")
    no-repeat;
}

.icon_checkbox_gray {
  background: url("assets/images/common/icon-checkbox-off-gray.svg") no-repeat;
}

.icon_warning_empty {
  background: url("assets/images/common/icon/icon-warning-circle-empty.svg")
    no-repeat;
}

.icon_edit {
  background: url("assets/images/common/icon-edit.svg") no-repeat;
}

.icon_exit {
  background: url("assets/images/common/icon-exit.svg") no-repeat;
}

.icon_exit_white {
  background: url("assets/images/common/icon-exit-white.svg") no-repeat;
}

.icon_exit_gray {
  background: url("assets/images/common/icon-exit-gray.svg") no-repeat;
}

.icon_send {
  background: url("assets/images/chat/icon-send.svg") no-repeat;
}

i[class^="icon_flag_"] {
  background-position: center;
}

.icon_flag_al,
.icon_flag_AL {
  background: url("assets/images/common/icon-flag-al.png") no-repeat;
}

.icon_flag_at,
.icon_flag_AT {
  background: url("assets/images/common/icon-flag-at.png") no-repeat;
}

.icon_flag_be,
.icon_flag_BE {
  background: url("assets/images/common/icon-flag-be.png") no-repeat;
}

.icon_flag_bg,
.icon_flag_BG {
  background: url("assets/images/common/icon-flag-bg.png") no-repeat;
}

.icon_flag_ch,
.icon_flag_CH {
  background: url("assets/images/common/icon-flag-ch.png") no-repeat;
}

.icon_flag_cy,
.icon_flag_CY {
  background: url("assets/images/common/icon-flag-cy.png") no-repeat;
}

.icon_flag_cz,
.icon_flag_CZ {
  background: url("assets/images/common/icon-flag-cz.png") no-repeat;
}

.icon_flag_dk,
.icon_flag_DK {
  background: url("assets/images/common/icon-flag-dk.png") no-repeat;
}

.icon_flag_ee,
.icon_flag_EE {
  background: url("assets/images/common/icon-flag-ee.png") no-repeat;
}

.icon_flag_es,
.icon_flag_ES {
  background: url("assets/images/common/icon-flag-es.png") no-repeat;
}

.icon_flag_fi,
.icon_flag_FI {
  background: url("assets/images/common/icon-flag-fi.png") no-repeat;
}

.icon_flag_fr,
.icon_flag_FR {
  background: url("assets/images/common/icon-flag-fr.png") no-repeat;
}

.icon_flag_gb,
.icon_flag_GB {
  background: url("assets/images/common/icon-flag-gb.png") no-repeat;
}

.icon_flag_gr,
.icon_flag_GR {
  background: url("assets/images/common/icon-flag-gr.png") no-repeat;
}

.icon_flag_hr,
.icon_flag_HR {
  background: url("assets/images/common/icon-flag-hr.png") no-repeat;
}

.icon_flag_hu,
.icon_flag_HU {
  background: url("assets/images/common/icon-flag-hu.png") no-repeat;
}

.icon_flag_ie,
.icon_flag_IE {
  background: url("assets/images/common/icon-flag-ie.png") no-repeat;
}

.icon_flag_is,
.icon_flag_IS {
  background: url("assets/images/common/icon-flag-is.png") no-repeat;
}

.icon_flag_it,
.icon_flag_IT {
  background: url("assets/images/common/icon-flag-it.png") no-repeat;
}

.icon_flag_li,
.icon_flag_LI {
  background: url("assets/images/common/icon-flag-li.png") no-repeat;
}

.icon_flag_lt,
.icon_flag_LT {
  background: url("assets/images/common/icon-flag-lt.png") no-repeat;
}

.icon_flag_lu,
.icon_flag_LU {
  background: url("assets/images/common/icon-flag-lu.png") no-repeat;
}

.icon_flag_lv,
.icon_flag_LV {
  background: url("assets/images/common/icon-flag-lv.png") no-repeat;
}

.icon_flag_mc,
.icon_flag_MC {
  background: url("assets/images/common/icon-flag-mc.png") no-repeat;
}

.icon_flag_mk,
.icon_flag_MK {
  background: url("assets/images/common/icon-flag-mk.png") no-repeat;
}

.icon_flag_mt,
.icon_flag_MT {
  background: url("assets/images/common/icon-flag-mt.png") no-repeat;
}

.icon_flag_nl,
.icon_flag_NL {
  background: url("assets/images/common/icon-flag-nl.png") no-repeat;
}

.icon_flag_no,
.icon_flag_NO {
  background: url("assets/images/common/icon-flag-no.png") no-repeat;
}

.icon_flag_pl,
.icon_flag_PL {
  background: url("assets/images/common/icon-flag-pl.png") no-repeat;
}

.icon_flag_pt,
.icon_flag_PT {
  background: url("assets/images/common/icon-flag-pt.png") no-repeat;
}

.icon_flag_ro,
.icon_flag_RO {
  background: url("assets/images/common/icon-flag-ro.png") no-repeat;
}

.icon_flag_rs,
.icon_flag_RS {
  background: url("assets/images/common/icon-flag-rs.png") no-repeat;
}

.icon_flag_se,
.icon_flag_SE {
  background: url("assets/images/common/icon-flag-se.png") no-repeat;
}

.icon_flag_si,
.icon_flag_SI {
  background: url("assets/images/common/icon-flag-si.png") no-repeat;
}

.icon_flag_sk,
.icon_flag_SK {
  background: url("assets/images/common/icon-flag-sk.png") no-repeat;
}

.icon_flag_sm,
.icon_flag_SM {
  background: url("assets/images/common/icon-flag-sm.png") no-repeat;
}

.icon_flag_tr,
.icon_flag_TR {
  background: url("assets/images/common/icon-flag-tr.png") no-repeat;
}

.icon_flag_sg,
.icon_flag_SG {
  background: url("assets/images/common/icon-flag-sg.png") no-repeat;
}

.icon_flag_kr,
.icon_flag_KR {
  background: url("assets/images/common/icon-flag-kr.png") no-repeat;
}

.icon_flag_us,
.icon_flag_US {
  background: url("assets/images/common/icon-flag-us.png") no-repeat;
}

.icon_flag_jp,
.icon_flag_JP {
  background: url("assets/images/common/icon-flag-jp.png") no-repeat;
}

.icon_flag_cn,
.icon_flag_CN {
  background: url("assets/images/common/icon-flag-cn.png") no-repeat;
}

.icon_flag_ep,
.icon_flag_EP {
  background: url("assets/images/common/icon-flag-ep.png") no-repeat;
}

.icon_flag_de,
.icon_flag_DE {
  background: url("assets/images/common/icon-flag-de.png") no-repeat;
}

.icon_flag_gb,
.icon_flag_GB {
  background: url("assets/images/common/icon-flag-gb.png") no-repeat;
}

.icon_flag_mx,
.icon_flag_MX {
  background: url("assets/images/common/icon-flag-mx.png") no-repeat;
}

.icon_flag_au,
.icon_flag_AU {
  background: url("assets/images/common/icon-flag-au.png") no-repeat;
}

.icon_flag_br,
.icon_flag_BR {
  background: url("assets/images/common/icon-flag-br.png") no-repeat;
}

.icon_flag_ca,
.icon_flag_CA {
  background: url("assets/images/common/icon-flag-ca.png") no-repeat;
}
.icon_flag_id,
.icon_flag_ID {
  background: url("assets/images/common/icon-flag-id.png") no-repeat;
}
.icon_flag_il,
.icon_flag_IL {
  background: url("assets/images/common/icon-flag-il.png") no-repeat;
}

.icon_flag_IN,
.icon_flag_IN {
  background: url("assets/images/common/icon-flag-in.png") no-repeat;
}

.icon_flag_mo,
.icon_flag_MO {
  background: url("assets/images/common/icon-flag-mo.png") no-repeat;
}

.icon_flag_nz,
.icon_flag_NZ {
  background: url("assets/images/common/icon-flag-nz.png") no-repeat;
}

.icon_flag_ru,
.icon_flag_RU {
  background: url("assets/images/common/icon-flag-ru.png") no-repeat;
}

.icon_flag_sg,
.icon_flag_SG {
  background: url("assets/images/common/icon-flag-sg.png") no-repeat;
}

.icon_flag_tw,
.icon_flag_TW {
  background: url("assets/images/common/icon-flag-tw.png") no-repeat;
}

.icon_flag_vn,
.icon_flag_VN {
  background: url("assets/images/common/icon-flag-vn.png") no-repeat;
}

.icon_flag_sp,
.icon_flag_SP {
  background: url("assets/images/common/icon-flag-sp.png") no-repeat;
}

.icon_flag_ph,
.icon_flag_PH {
  background: url("assets/images/common/icon-flag-ph.png") no-repeat;
}

.icon_flag_hk,
.icon_flag_HK {
  background: url("assets/images/common/icon-flag-hk.png") no-repeat;
}

.icon_flag_ea,
.icon_flag_EA {
  width: 16px;
  height: 16px;
  background: url("assets/images/common/icon-flag-ea.png") no-repeat;
  background-size: contain;
}

.icon_flag_za,
.icon_flag_ZA {
  background: url("assets/images/common/icon-flag-za.png") no-repeat;
}

.icon_flag_pct,
.icon_flag_PCT {
  display: none;
}

.icon_download {
  background: url("assets/images/common/icon-download.svg") no-repeat;
}

.icon_download_blue {
  background: url("assets/images/common/icon-download.png") no-repeat;
}

.icon_download_attached {
  background: url("assets/images/common/icon-download-attached.svg") no-repeat;
}

.icon_download_attached_white {
  background: url("assets/images/common/icon-download-attached-white.svg")
    no-repeat;
}

.icon_upload {
  background: url("assets/images/common/icon-upload.svg") no-repeat;
}

.icon_more {
  background: url("assets/images/common/icon-expand-more.svg") no-repeat;
}

.icon_more_white {
  background: url("assets/images/common/icon-expand-more-white.svg") no-repeat;
}

.icon_more_red {
  background: url("assets/images/common/icon-more-red.svg") no-repeat;
}

.icon_more_black {
  background: url("assets/images/common/icon-more-black.svg") no-repeat;
}

.icon_more_down_black {
  rotate: 90deg;
  background: url("assets/images/common/icon-more-black.svg") no-repeat;
}

.icon_more_dot {
  background: url("assets/images/common/icon-more-dot.svg") no-repeat;
}

.icon_next {
  background: url("assets/images/common/icon-next.svg") no-repeat;
}

.icon_plus {
  background: url("assets/images/common/icon-plus.svg") no-repeat;
}

.icon_calendar {
  background: url("assets/images/common/icon-calendar.svg") no-repeat;
}

.icon_badge_ok {
  background: url("assets/images/common/icon-badge-ok.svg") no-repeat;
}

.icon_badge_del {
  background: url("assets/images/common/icon-badge-del.svg") no-repeat;
}

.icon_badge_info {
  background: url("assets/images/common/icon-badge-information.png") no-repeat;
}

.icon_badge_info_black {
  background: url("assets/images/common/icon-badge-information-black.png")
    no-repeat;
}

.icon_badge_info_green {
  background: url("assets/images/common/icon-badge-information-green.png")
    no-repeat;
}

.icon_badge_info_gray {
  background: url("assets/images/common/icon-badge-information-gray.svg")
    no-repeat;
}

.icon_badge_info_white {
  background: url("assets/images/common/icon-badge-information-white.svg")
    no-repeat;
}

.icon_info_with_bg {
  background: url("assets/images/common/icon-information-with-bg.svg") no-repeat;
}

.icon_info_with_white_bg {
  background: url("assets/images/common/icon-information-with-bg-white.svg")
    no-repeat;
}

.icon_badge_open {
  background: url("assets/images/common/button-badge-open.svg") no-repeat;
}

.icon_message {
  background: url("assets/images/common/icon_message.svg") no-repeat;
}

.icon_check_black {
  background: url("assets/images/common/icon_check_black.svg") no-repeat;
}

.icon_checkbox_blue {
  background: url("assets/images/common/icon-checkbox-on-blue.svg") no-repeat;
}

.icon_minimize {
  background: url("assets/images/chat/icon-minimize.svg") no-repeat;
}

.icon_m_menu {
  background: url("assets/images/common/icon_m_menu.svg") no-repeat;
}

.icon_m_bell {
  background: url("assets/images/mobile/icon_m_bell.svg") no-repeat;
}

.icon_m_bell_on {
  background: url("assets/images/mobile/icon_m_bell_on.svg") no-repeat;
}

.icon_m_call {
  background: url("assets/images/mobile/icon_m_call.svg") no-repeat;
}

.icon_m_fax {
  background: url("assets/images/mobile/icon_m_fax.svg") no-repeat;
}

.icon_m_mail {
  background: url("assets/images/mobile/icon_m_mail.svg") no-repeat;
}

.icon_m_search {
  background: url("assets/images/mobile/icon_search.svg") no-repeat;
}

.icon_more_arrow_up {
  background: url("assets/images/mobile/icon_more_arrow_up_black.svg") no-repeat;
}

.icon_more_arrow_up_white {
  background: url("assets/images/common/icon_more_arrow_up_white.svg") no-repeat;
}

.icon_more_arrow_down {
  background: url("assets/images/mobile/icon_arrow_down.svg") no-repeat;
}

.icon_more_arrow_down_black {
  background: url("assets/images/mobile/icon_arrow_down_black.svg") no-repeat;
}

.icon_more_arrow_left_black {
  transform: rotate(180deg);
  background: url("assets/images/mobile/icon_more_arrow_right_black.svg")
    no-repeat;
}

.icon_more_arrow_left_black_big {
  width: 64px;
  height: 64px;
  transform: rotate(180deg);
  background: url("assets/images/mobile/icon_more_arrow_right_black.svg")
    no-repeat;
}

.icon_more_arrow_right {
  background: url("assets/images/mobile/icon_arrow_right.svg") no-repeat;
}

.icon_more_arrow_right_black {
  background: url("assets/images/mobile/icon_more_arrow_right_black.svg")
    no-repeat;
  background-size: contain;
}

.icon_more_arrow_right_black_big {
  width: 64px;
  height: 64px;
  background: url("assets/images/mobile/icon_more_arrow_right_black.svg")
    no-repeat;
  background-size: contain;
}

.icon_more_arrow_left_small_black {
  background: url("assets/images/mobile/icon_more_arrow_left_small_black.svg")
    no-repeat;
}

.icon_more_arrow_right_small_black {
  background: url("assets/images/mobile/icon_more_arrow_right_small_black.svg")
    no-repeat;
}

.icon_more_arrow_right_white {
  background: url("assets/images/mobile/icon_more_arrow_right_white.svg")
    no-repeat;
}

.icon_arrow_right_orange {
  background: url("assets/images/common/icon_arrow_right_orange.svg") no-repeat;
}

.icon_arrow_right_blue {
  background: url("assets/images/common/icon-arrow-right-blue.svg") no-repeat;
}

.icon_arrow_right_white {
  background: url("assets/images/common/icon-arrow-right-white.svg") no-repeat;
}

.icon_arrow_right_s {
  background: url("assets/images/common/icon-arrow-right-s.svg") no-repeat;
}

.icon_lock {
  background: url("assets/images/mobile/icon_lock.svg") no-repeat;
}

.icon_alert_circle {
  width: 24px;
  height: 24px;
  background: url("assets/images/mobile/icon_alert_circle.svg") no-repeat;
}

.icon_condition {
  background: url("assets/images/mobile/icon_condition.svg") no-repeat;
}

.icon_condition_white {
  background: url("assets/images/common/icon_condition_white.svg") no-repeat;
}

.icon_pencil {
  background: url("assets/images/common/icon-pencil.svg") no-repeat;
}

.icon_pencil-black {
  background: url("assets/images/common/icon-pencil-bk.svg") no-repeat;
}

.icon_pencil_outline {
  background: url("assets/images/common/icon_pencil_outline.svg") no-repeat;
}

.icon_badge_del_white {
  background: url("assets/images/common/icon-badge-del-white.svg") no-repeat;
}

.icon_camera {
  background: url("assets/images/common/icon-camera.svg") no-repeat;
}

.icon_posting_pencil {
  width: 32px;
  height: 32px;
  background: url("assets/images/common/icon-posting.svg") no-repeat;
}

.icon_link {
  background: url("assets/images/common/icon-link.svg") no-repeat;
}

.icon_orange_add {
  background: url("assets/images/common/icon_plus_orange.svg") no-repeat;
}

.icon_search_white {
  background: url("assets/images/common/icon-search-white.svg") no-repeat;
}

.icon_search_gray {
  background: url("assets/images/common/icon-search-gray.svg") no-repeat;
}

.icon_search_blue {
  background: url("assets/images/common/icon-search-blue.svg") no-repeat;
}

.icon_can_duplicates {
  width: 78px;
  background: url("assets/images/common/icon_can_duplicates.png") no-repeat;
}

.icon_fav_on {
  background: url("assets/images/common/icon-fav-on.png") no-repeat;
}

.icon_fav_off {
  background: url("assets/images/common/icon_fav_off.svg") no-repeat;
}

.icon_red_dot {
  background: url("assets/images/common/icon_red_dot.svg") no-repeat;
}

.icon_task {
  background: url("assets/images/common/icon-task.svg") no-repeat;
}

.icon_idea {
  background: url("assets/images/common/icon-idea.svg") no-repeat;
}

.icon_bulb {
  background: url("assets/images/intro/icon_bulb.svg") no-repeat;
  background-size: contain;
}

.icon_bulb_blue {
  background: url("assets/images/taskManage/icon_bulb_blue.svg") no-repeat;
  background-size: contain;
}

.icon_bulb_check {
  background: url("assets/images/common/icon_bulb_check.png") no-repeat;
}

.icon_grade_auto {
  background: url("assets/images/intro/icon_grade_auto.svg") no-repeat;
  background-size: contain;
}

.icon_task {
  background: url("assets/images/intro/icon_task.svg") no-repeat;
  background-size: contain;
}

.icon_trade {
  background: url("assets/images/intro/icon_trade.svg") no-repeat;
  background-size: contain;
}

.icon_pay_manage {
  background: url("assets/images/intro/icon_pay_manage.svg") no-repeat;
  background-size: contain;
}

.icon_invest {
  background: url("assets/images/intro/icon_invest.svg") no-repeat;
  background-size: contain;
}

.icon_two_person {
  background: url("assets/images/intro/icon_two_person.svg") no-repeat;
  background-size: contain;
}

.icon_play {
  background: url("assets/images/intro/icon_play.svg") no-repeat;
  background-size: contain;
}

.icon_pause {
  background: url("assets/images/intro/icon_pause.svg") no-repeat;
  background-size: contain;
}

.icon_print {
  background: url("assets/images/common/icon-print-outline.svg") no-repeat;
}

.icon_annual {
  background: url("assets/images/intro/icon_annual.svg") no-repeat;
  background-size: contain;
}

.icon_ip_loans {
  background: url("assets/images/intro/icon_ip_loans.svg") no-repeat;
  background-size: contain;
}

.icon_open_market {
  background: url("assets/images/intro/icon_open_market.svg") no-repeat;
  background-size: contain;
}

.icon_company_matching {
  background: url("assets/images/intro/icon_company_matching.svg") no-repeat;
  background-size: contain;
}

.icon_open_market {
  background: url("assets/images/intro/icon_open_market.svg") no-repeat;
  background-size: contain;
}

.icon_plus_white {
  background: url("assets/images/common/icon_plus_white.svg") no-repeat;
}

.icon_personal {
  background: url("assets/images/common/icon_personal.png") no-repeat;
}

.icon_combined_shape {
  background: url("assets/images/common/bg_combined_shape.svg") no-repeat;
  background-size: contain;
}

.icon_refresh {
  width: 16px;
  height: 16px;
  background: url("assets/images/common/icon_refresh.svg") no-repeat;
  background-size: contain;
}

.icon_kakao {
  width: 32px;
  height: 32px;
  background: url("assets/images/signup/ic_kakaotalk.svg") no-repeat;
}

.icon_electric_bulb {
  background: url("assets/images/common/icon_electric_bulb.svg") no-repeat;
}

.icon_document {
  background: url("assets/images/doctrade/icon_document.svg") no-repeat;
}

.icon_attention {
  background: url("assets/images/common/icon-attention.svg") no-repeat 50% 50%;
}

.icon_cyber-check {
  background: url("assets/images/cyber/icon_check.svg") no-repeat 50% 50%;
}

.icon_i {
  background: url("assets/images/common/icon-i.svg") no-repeat 50% 50%;
}

.icon_money {
  background: url("assets/images/common/icon-money.svg") no-repeat 50% 50%;
}

.icon_add {
  background: url("assets/images/common/icon-add.svg") no-repeat 50% 50%;
}

.icon_mail_gray {
  width: 32px;
  height: 32px;
  background: url("assets/images/common/icon-mail-gray.svg") no-repeat;
}

.icon_mail_red {
  width: 32px;
  height: 32px;
  background: url("assets/images/common/icon-mail-red.svg") no-repeat;
}

.icon_mail_yellow {
  width: 32px;
  height: 32px;
  background: url("assets/images/common/icon-mail-yellow.svg") no-repeat;
}

.icon_mail_green {
  width: 32px;
  height: 32px;
  background: url("assets/images/common/icon-mail-green.svg") no-repeat;
}

.icon_kakaotalk {
  width: 38px;
  height: 38px;
  background: url("assets/images/common/ic-kakaotalk.png") no-repeat 50% 50%;
  background-size: contain;
}

.icon_notify {
  width: 18px;
  height: 19px;
  background: url("assets/images/common/icon-notification-blue.svg") no-repeat
    50% 50%;
  background-size: contain;
}

.icon_notification_blue_full {
  background: url("assets/images/common/icon-notification-blue-full.svg")
    no-repeat;
}

.icon_arrow_up_white {
  width: 24px;
  height: 24px;
  background: url("assets/images/common/icon-arrow-down-white.svg") no-repeat
    50% 50%;
  transform: rotate(180deg); /* 180도 회전 */
}

.icon_arrow_down_white {
  width: 24px;
  height: 24px;
  background: url("assets/images/common/icon-arrow-down-white.svg") no-repeat
    50% 50%;
}

.icon_arrow_up_gray {
  width: 24px;
  height: 24px;
  background: url("assets/images/common/icon-arrow-up-gray.svg") no-repeat 50%
    50%;
}

.icon_arrow_down_gray {
  width: 24px;
  height: 24px;
  background: url("assets/images/common/icon-arrow-down-gray.svg") no-repeat 50%
    50%;
}

.icon_user_outline_blue {
  min-width: 24px;
  min-height: 24px;
  background: url("assets/images/login/icon-user-outline-blue.svg") no-repeat;
  background-size: contain;
}

.icon_user_outline {
  min-width: 24px;
  min-height: 24px;
  background: url("assets/images/login/icon-user-outline.svg") no-repeat;
  background-size: contain;
}

.icon_user_outline_blue {
  min-width: 24px;
  min-height: 24px;
  background: url("assets/images/login/icon-user-outline-blue.svg") no-repeat;
  background-size: contain;
}
.icon_building_outline {
  min-width: 24px;
  min-height: 24px;
  background: url("assets/images/login/icon-building-outline.svg") no-repeat;
  background-size: contain;
}
.icon_building_outline_blue {
  min-width: 24px;
  min-height: 24px;
  background: url("assets/images/login/icon-building-outline-blue.svg") no-repeat;
  background-size: contain;
}

.icon_matching {
  width: 24px;
  height: 24px;
  background: url("assets/images/intro/tab-matching.svg") no-repeat;
  background-size: contain;
}

.icon_matching_blue {
  width: 24px;
  height: 24px;
  background: url("assets/images/intro/tab-matching-blue.svg") no-repeat;
  background-size: contain;
}

.icon_notification {
  width: 18px;
  height: 18px;
  background: url("assets/images/common/icon-notification.svg") no-repeat;
  background-size: contain;
}

.icon_notification_on {
  width: 18px;
  height: 18px;
  background: url("assets/images/common/icon-notification-on.svg") no-repeat;
  background-size: contain;
}

.icon_notification_blue {
  width: 24px;
  height: 24px;
  background: url("assets/images/common/icon-notification-blue.svg") no-repeat;
  background-size: contain;
}

.icon_double_arrow_right {
  background: url("assets/images/common/icon-double-arrow-right.svg") no-repeat;
}

.icon_double_arrow_right_white {
  width: 24px;
  height: 24px;
  background: url("assets/images/common/icon-double-arrow-right-white.svg")
    no-repeat;
  background-size: contain;
}

.icon_double_arrow_right_blue {
  background: url("assets/images/common/icon-double-arrow-right-blue.svg")
    no-repeat;
}

.icon_recommend_category {
  width: 67px;
  height: 68px;
  background: url("assets/images/common/icon-recommend-category.svg") no-repeat;
  background-size: contain;
}

.icon_del_small_with_bg_gray {
  background: url("assets/images/common/icon-del-small-with-bg-gray.svg")
    no-repeat;
}

.icon_del_small_with_bg_white {
  background: url("assets/images/common/icon-del-small-with-bg-white.svg")
    no-repeat;
}

.icon_del_small_with_bg {
  background: url("assets/images/common/icon-del-small-with-bg.svg") no-repeat;
}

.icon_del_trashcan {
  background: url("assets/images/common/icon-del-trashcan.svg") no-repeat;
}

.icon_del_small_white {
  background: url("assets/images/common/icon-del-small-white.svg") no-repeat;
}

.icon_reference {
  background: url("assets/images/common/icon-reference.svg") no-repeat;
}

.icon_view {
  background: url("assets/images/common/icon-eye-view.png") no-repeat;
}

.icon_hidden {
  background: url("assets/images/common/icon-eye-hidden.png") no-repeat;
}

.icon_write {
  background: url("assets/images/common/write_icon.png") no-repeat;
}

.icon_mail_purple {
  width: 30px;
  height: 21.1px;
  background: url("assets/images/common/icon-mail-purple.png") no-repeat;
}

.icon_annual_response {
  background: url("assets/images/annual/annual_auth.png") no-repeat;
}

.icon_annual_res_chk {
  background: url("assets/images/annual/annual_chk_res.png") no-repeat;
}

.icon_annual_chk {
  background: url("assets/images/annual/annual_chk.png") no-repeat;
}

.icon_annual_reject {
  background: url("assets/images/annual/annual_reject.png") no-repeat;
}

.icon_annual_etc {
  background: url("assets/images/annual/annual_etc.png") no-repeat;
}

.icon_pdf {
  background: url("assets/images/common/icon_pdf.png") no-repeat;
}
.icon_calendar_blue {
  background: url("assets/images/annual/icon_calendar.png") no-repeat;
}
.icon_people {
  background: url("assets/images/annual/icon_people.png") no-repeat;
}
.icon_search_green {
  background: url("assets/images/annual/icon_search.png") no-repeat;
}
.icon_summary {
  background: url("assets/images/annual/icon_summary.png") no-repeat;
}
.icon_call {
  width: 32px;
  height: 32px;
  background: url("assets/images/common/icon-call.png") no-repeat;
  background-size: contain;
}
.icon_email {
  width: 32px;
  height: 32px;
  background: url("assets/images/common/icon-email.png") no-repeat;
  background-size: contain;
}
.icon_excel {
  background: url("assets/images/statistic/excel.png") no-repeat;
}
.icon_image {
  background: url("assets/images/statistic/image.png") no-repeat;
}
.icon_pdf2 {
  background: url("assets/images/statistic/pdf.png") no-repeat;
}
.dd_pdf {
  background: url("assets/images/dueDiligence/pdf_dd.png") no-repeat;
}
.dd_excel {
  background: url("assets/images/dueDiligence/excel_dd.png") no-repeat;
}
.dd_doc {
  background: url("assets/images/dueDiligence/doc_dd.png") no-repeat;
}
