@import "src/include";

#ConsortiumModify{
	.consortiumModify{
		width: 880px;
		margin: 0 auto;
		padding: 85px 0 0;
		&_title-wrap{
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #e7e7e7;
			margin-bottom: 50px;
			padding-bottom: 7px;
		}
		&_title{
			font-family: NanumSquareOTF_acB;
			font-size: 22px;
			color: #343434;
		}
		&_btnTop-wrap{
			display: flex;
			justify-content: center;
			align-items: center;
			
		}
		&_btn-modify{
			display: inline-block;
			width: 64px;
			height: 32px;
			line-height: 32px;
			font-size: 16px;
			text-align: center;
			color: #fff;
			background-color: #19c77c;
			margin-right: 8px;
			border-radius: 4px;
		}
		&_btn-del{
			display: inline-block;
			width: 64px;
			height: 32px;
			line-height: 32px;
			font-size: 16px;
			text-align: center;
			color: #fff;
			background-color: #343434;
			border-radius: 4px;
		}
		&_info{
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 30px;
			&_img{
				position: relative;
				width: 283px;
				height: 230px;
				background: #f0f0f0;
				img{
					display: block;
					width: 100%;
					height: 100%;
					object-fit: contain;
					border: 1px solid #e7e7e7;
				}
				label{
					position: absolute;
					text-indent: -9999px;
					width: 24px;
					height: 24px;
					top: -28px;
					right: 0;
					background-size: cover;
					cursor: pointer;
				}
			}
			&_table{
				width: calc(100% - 283px);
				height: 230px;
				padding-left: 12px;
				.list_table{
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;
					align-items: center;
					&_title{
						width: 110px;
						height: 46px;
						line-height: 30px;
						padding: 8px 0;
						border-bottom: 1px solid #e7e7e7;
						label{
							font-family: NanumSquareOTF_acB;
							font-size: 14px;
							color: #343434;
						}
					}
					&_info{
						height: 46px;
						padding: 8px 10px;
						border-bottom: 1px solid #e7e7e7;
						font-size: 14px;
						color: rgba(0, 0, 0, 0.87);

						input{
							width: 100%;
							height: 100%;
							border: .5px solid #d8d8d8;
							background-color: transparent;
							padding: 0 15px;
							font-size: 14px;
							color: rgba(0, 0, 0, 0.87);
						}
						&.col-1-1{
							width: calc(100% - 110px);
						}
						&.col-1-2{
							width: calc((100% - 220px) / 2);
						}
					}
				}
			}
		}
		&_textarea{
			&_title-wrap{
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 10px;
			}
			&_title{
				font-family: NanumSquareOTF_acB;
				font-size: 14px;
				color: #343434;
			}
			.list_table{
				display: flex;
				justify-content: space-between;
				align-items: center;
				&_title{
					font-family: NanumSquareOTF_acB;
					font-size: 14px;
					color: #343434;
					margin-right: 10px;
				}
				&_info{
					display: flex;
					justify-content: space-between;
					align-items: center;
					.icon_upload{
						display: inline-block;
						text-indent: -9999px;
						width: 24px;
						height: 24px;
						background-size: cover;
						cursor: pointer;
					}
				}
			}
			&_content{
				width: 100%;
				height: 300px;
				resize: none;
				padding: 15px;
				margin-bottom: 60px;
				font-size: 14px;
				color: #343434;
				border: .5px solid #d8d8d8;
			}
		}
		&_btn-wrap{
			margin: 0 auto;
			text-align: center;
		}
		&_btn{
			display: inline-block;
			width: 120px;
			height: 32px;
			line-height: 32px;
			margin: 0 auto;
			background-color: #05ac9e;
			color: #fff;
			text-align: center;
			border-radius: 4px;
		}
	}
}