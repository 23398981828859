#DocTradeSelectDate {
  .content_table {
    &_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      line-height: 32px;
      margin-bottom: 5px;
      &_title {
        display: flex;
        justify-content: start;
        align-items: start;
        width: 271px;
      }
      &_text {
        width: 56px;
        margin-right: 5px;
        font-family: NanumSquareOTF_acB;
        font-size: 14px;
        color: #343434;
      }
      &_radio {
        width: 60px;
        margin-right: 10px;
        padding: 3px 0;
        &_input {
          &:checked {
            + .content_table_top_radio_label {
              background-color: var(--color-Web-Orange);
              color: #fff;
              border: 1px solid var(--color-Web-Orange);
            }
          }
        }
        &_label {
          display: block;
          width: 100%;
          height: 26px;
          line-height: 26px;
          text-align: center;
          font-family: NanumSquareOTF_acEB;
          font-size: 12px;
          color: #343434;
          border: 1px solid #d8d8d8;
          border-radius: 4px;
          cursor: pointer;
          background-color: #fff;
        }
      }
      &_date {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 190px;
        &_start {
          display: flex;
          align-items: center;
          column-gap: 10px;
          width: 114px;
          p {
            font-size: 14px;
            line-height: 18px;
            color: #443018;
          }
          .react-datepicker-wrapper {
            input[type="text"] {
              height: 32px;
            }
          }
        }
        .style {
          width: 34px;
          text-align: center;
        }
        &_end {
          display: flex;
          align-items: center;
          column-gap: 10px;
          width: 114px;
          p {
            font-size: 14px;
            line-height: 18px;
            color: #443018;
          }
          .react-datepicker-wrapper {
            input[type="text"] {
              height: 32px;
            }
          }
        }
        &_ico {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &_lookup {
          width: 140px;
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          color: #fff;
          background-color: var(--color-Web-Orange);
          border-radius: 4px;
          text-align: center;
          margin-left: 17px;
        }
      }
    }
  }
}
