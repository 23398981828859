@import "src/mixin";
#IntroFindBizPrice {

  .intro_pc {
    .top_banner_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;

      .text {
        margin-top: 22px;
      }
    }

    .detail_desc {
      text-align: center;
      font-size: 35px;
      font-family: NanumSquareOTF_acR, sans-serif;
      margin-bottom: 40px;

      .bold {
        font-family: NanumSquareOTF_acB, sans-serif;
      }
    }

    .main_content {
      display: flex;
      justify-content: center;
    }
    .intro_item {
      position: relative;
      min-width: 828px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      &:nth-child(1) {
        button {
          margin: 30px 0 60px 47%;
        }
      }
      &:nth-child(2) {
        button {
          margin: -20px 0 100px 47%;
        }
      }
      &:nth-child(3) {
        button {
          margin: -50px 0 60px 47%;
        }
      }
      &:nth-child(4) {
        button {
          margin: 0 0 60px 47%;
        }
      }
    }
    .show_price {
      margin: 0 0 60px 47%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 224px;
      height: 42px;
      font-size: 16px;
      font-family: NanumSquareOTF_acEB, sans-serif;
      color: #ffffff;
      border-radius: 20px;
      background-color: #4593f5;
    }
    .intro_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .page_title {
    margin-top: 55px;
    font-size: 35px;
    text-align: center;

    @include mobile {
      padding-top: 20px;
      font-size: 24px;
      line-height: 38px;
    }

    span {
      font-family: NanumSquareOTF_acEB;
      color: #1d334e;
    }
  }

  .notify {
    margin: 40px 0 35px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
    height: 70px;
    background-color: rgba(107, 211, 255, 0.2);

    @include mobile {
      margin: 25px 0;
      flex-direction: column;
      row-gap: 12px;
    }

    p {
      position: relative;
      padding-left: 30px;
      font-size: 16px;
      font-family: NanumSquareOTF_acB;
      color: #1d334e;

      @include mobile {
        padding-left: 20px;
        font-size: 12px;
      }

      &:before {
        content: '';
        position: absolute;
        top: -3px;
        left: 0;
        width: 24px;
        height: 24px;
        background: url("../../../assets/images/common/icon-check-blue.svg") no-repeat;
        background-size: contain;

        @include mobile {
          width: 16px;
          height: 16px;
        }
      }
    };
  }

  .notify_blue {
    margin: 40px 0 35px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    background-color: rgba(107, 211, 255, 0.2);

    .sale {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 47px;
      height: 18px;
      border-radius: 5px;
      background-color: #54b7e0;

      font-size: 12px;
      font-family: NanumSquareOTF_acEB, sans-serif;
      color: #fff;
      letter-spacing: normal;
    }

    @include mobile {
      margin: 25px 0;
      flex-direction: column;
      row-gap: 12px;
    }

    .text {
      position: relative;
      padding-left: 10px;
      font-size: 16px;
      font-family: NanumSquareOTF_acB;
      color: #1d334e;

      @include mobile {
        padding-left: 20px;
        font-size: 12px;
      }

    }

    .coffee_img {
      width: 37px;
      height: 34px;
      position: relative;
      margin-left: 10px;

      img {
        position: absolute;
        top: -5px;
      }
    }
  }

  .notify_wh {
    margin-top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 50px;

    @include mobile {
      margin: 25px 0;
      flex-direction: column;
      row-gap: 12px;
    }

    p {
      position: relative;
      padding-left: 30px;
      font-size: 18px;
      font-family: NanumSquareOTF_acB;
      color: #1d334e;

      @include mobile {
        padding-left: 20px;
        font-size: 12px;
      }

      &:before {
        content: '';
        position: absolute;
        top: -3px;
        left: 0;
        width: 24px;
        height: 24px;
        background: url("../../../assets/images/common/icon-check-blue.svg") no-repeat;
        background-size: contain;

        @include mobile {
          width: 16px;
          height: 16px;
        }
      }
    };
  }

  .wrap_price_table {
    display: flex;
    justify-content: center;
    align-items: baseline;
    column-gap: 30px;

    @include mobile {
      flex-direction: column;
      row-gap: 15px;
    }

    img {
      width: 360px;
      border-radius: 5px;
      box-shadow: 0 2px 3px 1px #d8d8d8;
      background-color: #fff;

      @include mobile {
        margin: auto;
        width: 90%;
      }
    }
  }

  .wrap_price_table_pc {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    margin-bottom: 40px;

    .price {
      width: 360px;
      height: 185px;
      border-radius: 5px;
      box-shadow: 0 2px 3px 1px #d8d8d8;
      padding: 24px 20px;

      .top {
        height: 37px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .wrap {
          font-family: NanumSquareOTF_acB, sans-serif;
          font-size: 18px;
          display: flex;
          column-gap: 7px;
          margin-top: -5px;

          .additional {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: NanumSquareOTF_acR, sans-serif;
            font-size: 14px;
            color: #fff;


            background-color: #4593f5;
            border-radius: 20px;

            width: 80px;
            height: 20px;

            &.pro {
              background: linear-gradient(to right, #4593f5, #6bd3ff);
            }

            &.premium {
              background-color: #4593f5;
              border-radius: 5px;
            }
          }
        }

        .wrap1 {
          display: flex;
          align-items: center;
          column-gap: 7px;

          .etc {
            font-size: 14px;
            font-family: NanumSquareOTF_acB, sans-serif;
            letter-spacing: 1px;
            color: #4593F5;
          }
        }
      }

      .middle {
        height: 32px;

        &.premium{
          margin-top: 0;
        }

        .desc {
          font-size: 14px;
          font-family: NanumSquareOTF_acR, sans-serif;
          color: #959595;
          letter-spacing: 0.3px;

          &.second {
            margin-top: 1.5px;
          }

          &.pro {
            text-decoration: line-through;
          }
        }

        .sale {
          font-size: 16px;
          font-family: NanumSquareOTF_acB, sans-serif;
          letter-spacing: -0.3px;
          color: #4593F5;
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-end;

        &.pro {
          margin-top: -5px;
        }

        .amount {
          font-size: 40px;
          font-family: NotoSansKR-Bold, sans-serif;
          letter-spacing: -2px;
          color: #4593F5;

          &.premium {
            font-size: 35px;
          }
        }

        .won {
          margin-left: 5px;
          font-size: 18px;
          font-family: NanumSquareOTF_acB, sans-serif;
        }

        .vat {
          margin-top: 5px;
          font-size: 14px;
          font-family: NanumSquareOTF_acR, sans-serif;
          color: #959595;
        }

        .btn {
          width: 70px;
          height: 32px;
          border-radius: 4px;
          background-color: #4593F5;
          font-size: 13px;
          font-family: NanumSquareOTF_acB, sans-serif;
          color: #fff;

          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .btn_signup {
    margin: 60px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 206px;
    height: 42px;
    font-size: 16px;
    font-family: NanumSquareOTF_acEB;
    color: #ffffff;
    border-radius: 20px;
    background-color: #4593f5;
  }

  .subscription {
    padding: 20px 0;
    font-size: 30px;
    color: var(--color-Scorpion);
    text-align: center;
    background-color: var(--color-Gallery-opacity-02);

    .title {
      margin-bottom: 40px;

      @include mobile {
        font-size: 24px;
        line-height: 38px;
      }

      span {
        font-family: NanumSquareOTF_acEB;
        color: var(--color-Cloud-Burst);
      }
    }

    img {
      margin: auto;
      display: block;

      @include mobile {
        width: 90%;
      }
    }

    img+img {
      margin-top: 20px;
    }

    .desc {
      margin: 25px auto 0 auto;
      width: 900px;
      font-size: 14px;
      color: var(--color-Dusty-Gray);
      text-align: left;

      @include mobile {
        padding: 0 calc(5% + 10px);
        width: 100%;
        text-indent: -10px;
      }

      p {
        line-height: 18px;
      }
    }
  }
}
