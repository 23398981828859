@import "../../../include";

#EducationDetail {
    margin: auto;
    padding-top: 45px;
    width: 967px;

    .page_title {
        font-size: 22px;
        font-family: NanumSquareOTF_acB;
        line-height: 30px;
    }

    .post_info {
        margin-bottom: 43px;
        font-size: 14px;
        line-height: 30px;
        text-align: right;
        border-bottom: 1px solid #e7e7e7;

        span {
            margin-left: 15px;
        }
    }

    .intro_area {
        display: flex;
        column-gap: 20px;

        h2 {
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            color: #343434;
        }

        p {
            padding: 0;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.87);
        }
    }

    .btn_list {
        margin: 45px auto 0 auto;
        display: block;
        width: 120px;
        height: 32px;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        background-color: #05ac9e;
    }
}