@import "src/include";

#IntroIP {
    margin: auto;
    width: 880px;

    h2 {
        color: #343434;
    }

    > .header {
        margin-bottom: 14px;
        display: block;

        h2 {
            font-size: 24px;
            font-family: NanumSquareOTF_acEB;
        }
    }

    .step {
        padding: 15px 106px 15px 70px;
        height: 207px;
        text-align: center;
        @include border-radius(20px);
        background-color: rgba(241, 241, 241 , 0.3);
    }

    .desc_auto {
        margin: 35px auto 19px auto;
        display: block;
    }

    .wrap_content {
        margin-top: 39px;
        margin-bottom: 46px;
        display: flex;
        column-gap: 19px;

        .info {
            .header {
                margin-bottom: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 24px;

                h2 {
                    margin: 0;
                    font-size: 22px;
                    font-family: NanumSquareOTF_acB;
                }

                a {
                    width: 64px;
                    height: 24px;
                    font-size: 12px;
                    font-family: NanumSquareOTF_acEB;
                    line-height: 24px;
                    text-align: center;
                    color: #ffffff;
                    @include border-radius(4px);
                    background-color: var(--color-Web-Orange);
                    cursor: pointer;
                }

            }
        }

        .info:first-child {
            width: 457px;
        }

        .info:last-child {
            width: 405px;
        }

        .content_list {

            .item {
                display: flex;
                cursor: pointer;
                column-gap: 15px;

                img {
                    min-width: 115px;
                    width: 115px;
                    height: 65px;
                    object-fit: cover;
                }

                .item_info {
                    width: 327px;

                    h2 {
                        margin-bottom: 10px;
                        font-family: NanumSquareOTF_acB;
                        font-size: 14px;
                    }

                    p {
                        line-height: 16px;
                        font-size: 14px;
                        @include text-ellipsis(2);
                    }
                }

                .item_info.youtube {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    font-size: 13px;
                }
            }

            .item+.item {
                margin-top: 22px;
            }
        }
    }
}
