.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 22px;
    font-family: NanumSquareOTF_acEB;
  }

  .button-group {
    display: flex;
    gap: 4px;
    margin-top: 16px;

    button {
      width: 140px;
      height: 40px;
      font-size: 16px;
      border-radius: 4px;
      border: 1px solid var(--color-Forest-Green);
      background-color: white;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      &:hover {
        background-color: color-mix(
          in srgb,
          var(--color-Forest-Green) 30%,
          transparent
        );
      }
      &.selected {
        background-color: var(--color-Forest-Green);
        color: white;
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;

    & > button {
      width: 120px;
      height: 32px;
      font-size: 16px;
      border-radius: 4px;
      padding: 0 8px;
      color: #ffffff;
      text-align: center;
    }

    .btn_add {
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 32px;
      font-size: 16px;
      border-radius: 4px;
      color: #ffffff;
      padding: 0 8px;
      background-color: var(--color-blue);
    }

    .btn_excel_download {
      margin-right: 6px;
      background-color: #96c719;
    }

    .btn_tab_setting,
    .btn_tab_setting_save {
      background-color: var(--color-Pickled-Bluewood);
    }

    .ToggleButtonWithSpan {
      margin-right: 31px;
    }
  }
}

.wrap_search {
  margin: 0 0 16px 0;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  select {
    padding-left: 8px;
    width: 100px;
    height: 32px;
    font-size: 14px;
    border: solid 1px rgba(0, 0, 0, 0.54);
    border-radius: 4px;
  }
  .input_search {
    padding: 0px 8px;
    min-width: 330px;
    width: 40%;
    height: 32px;
    font-size: 14px;
    border: solid 1px rgba(0, 0, 0, 0.54);
  }
  .icon_search_black {
    width: 32px;
    height: 32px;
    background: url("../../../assets/images/common/icon_search_black.svg")
      no-repeat;
  }
  select::placeholder,
  input::placeholder {
    color: rgba(0, 0, 0, 0.56);
  }
}

.sorting {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;

  .checkbox + .checkbox {
    margin-left: 35px;
  }
}

.table-box-wrap {
  margin-top: 13px;
}

.wrap_list {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 590px;
}

table {
  display: table;
  table-layout: fixed;

  th {
    ul {
      overflow-y: scroll;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: 150px;
      background-color: white;
      color: black;
      border: 1px solid rgb(0, 149, 174);
      z-index: 2;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      li {
        padding: 4px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 32px;
        font-size: 12px;
        font-family: NanumSquareOTF_acR;

        .checkbox {
          padding: 0 10px;
          width: 100%;
          height: 100%;
          justify-content: flex-start;

          .checkmark {
            min-width: 20px;
          }
        }

        span {
          font-size: 12px;
          text-align: left;
        }
      }

      li + li {
        border-top: 1px solid rgb(0, 149, 174);
      }
    }
    ul::-webkit-scrollbar {
      display: none;
    }
  }

  th.dp_none {
    ul {
      display: none;
    }
  }

  tbody {
    height: 50px;

    tr {
      height: 50px;

      td {
        word-break: break-all;

        &.apply_number {
          white-space: pre;
        }
      }
    }
    tr:not(.more_view):not(.not_styled):hover {
      background-color: rgba(0, 149, 174, 0.1);
      transition-duration: 0.5s;
      cursor: pointer;
    }
    tr.active {
      background-color: rgba(0, 149, 174, 0.1);
    }

    tr.more_view {
      display: none;
      height: 101.5px;
      border: none;

      td {
        padding: 2px 0;
        display: block;
        width: 1280px;
        height: 101.5px;
        border-bottom: 1px solid #e7e7e7;

        a {
          position: relative;
          display: inline-block;
          padding: 5px 8px 9px 21px;
          width: 142px;
          height: 87px;
          @include border-radius(5px);

          .title {
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            text-align: left;
          }

          p {
            position: absolute;
            bottom: 9px;
            width: 100px;
            display: flex;

            i {
              top: -7px;
              right: 0;
            }
          }
        }
        a + a {
          margin-left: 17px;
        }
        button {
          position: relative;
          top: 10px;
          margin-left: 31px;
        }
        .detail_info {
          border: solid 2px #96c719;
        }
        .process_state {
          border: solid 2px #e35d5c;
        }
        .relative_file {
          border: solid 2px #05ac9e;
        }
        .family {
          border: solid 2px #4292dd;
        }
        .payment {
          border: solid 2px #1bb6db;
        }
        .grade {
          border: solid 2px #fea502;
        }
      }
    }
  }
}

i {
  position: absolute;
  top: 2px;
  right: 12px;
}

.no_click {
  pointer-events: none;
  opacity: 0.4;
}
