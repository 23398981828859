@import "../../../include";

#TutorialMain {
    margin: auto;
    padding-top: 42px;
    width: 880px;
    min-height: calc(100vh - 110px - 80px);

    .page_title {
        margin-bottom: 60px;
        font-size: 22px;
        font-family: NanumSquareOTF_acEB;
    }

    .wrap {
        display: flex;
        align-items: center;

        > div {
            height: 385px;

            .head {
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h2 {
                    font-size: 22px;
                    font-family: NanumSquareOTF_acB;
                }

                .more {
                    display: flex;
                    align-items: center;
                    font-size: 11px;
                    text-align: right;

                    p {
                        margin-right: 15px;
                    }

                    i {
                        background-size: contain;
                    }
                }
            }

            .item {

                p {
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    @include text-ellipsis(2);
                }

                img {
                    margin-bottom: 10px;
                    object-fit: contain;
                    border: 1px solid #e7e7e7;
                }
            }
        }

        .area_video {
            margin-right: 15px;

            .list {
                display: flex;
                flex-wrap: wrap;
                column-gap: 21px;
                row-gap: 10px;

                .item {
                    width: 200px;
                    height: 170px;

                    img {
                        width: 100%;
                        height: 112px;
                    }
                }
            }
        }
    }
}
