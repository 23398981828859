@import "../../../include";

#CyberMyPoint {
    @import "Common.scss";

    .desc {
        text-align: center;
    }

    .tabs {
        margin-bottom: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;

        li {
            width: 130px;
            line-height: 18px;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            text-align: center;
        }

        li:first-child {
            border-right: 1px solid #979797;
        }
    }

    .btn_point_change {
        margin: 10px auto 36px auto;
    }

    .wrap_list {
        margin: 0 auto 30px auto;
        width: 333px;

        > h2 {
            font-size: 14px;
            font-family: NanumSquareOTF_acEB;
        }

        ul {
            margin-top: 33px;
            border-top: 2px solid #00a545;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 53px;
                border-bottom: 1px solid #d8d8d8;

                div {
                    h2 {
                        font-size: 12px;
                        line-height: 24px;
                    }

                    p {
                        font-size: 10px;
                    }
                }

                .point {
                    font-size: 14px;
                }

                .add {
                    color: #ff0000;
                }

                .minus {
                    color: #0049ff;
                }
            }
        }
    }
}