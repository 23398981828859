@import "src/include";
@import "src/mixin";

#TechTransfer {
  display: flex;
  flex-direction: column;
  .grid-cell {
    text-align: center;
    button {
      padding: 7px 14px;
      border-radius: 6px;
      background: #4e7ce9;
      color: #fff;
      border: solid 1px #4e7ce9;
      border-radius: 20px;
      &:hover {
        background: #2c64e7;
      }
    }
  }
  .grid-header {
    text-align: center;
  }
  .ag-header-group-cell-label,
  .ag-header-cell-label {
    justify-content: center;
  }
  .search_inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 5px;

    input {
      padding: 0 60px 0 100px;
      width: 700px;
      height: 50px;
      font-family: NanumSquareOTF_acB;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background-color: #fff;
      @include mobile {
        width: 90vw;
        height: 40px;
        font-size: 14px;
      }
    }

    input::placeholder {
      font-family: NanumSquareOTF_acR;
      color: var(--color-Mine_Shaft);
    }

    button {
      position: absolute;
      top: 12px;
      right: 20px;

      @include mobile {
        top: 7px;
        right: 10px;
      }
    }
    select {
      width: 80px;
      position: absolute;
      top: 15px;
      left: 15px;
      padding: 2px 4px; /* 상하 2px, 좌우 4px */
      border: none; /* 테두리 제거 */
      outline: none; /* 클릭 시 포커스 테두리 제거 */
      background-color: white; /* 배경 유지 */
      font-size: 14px;
      cursor: pointer;

      @include mobile {
        top: 10px;
        left: 5px;
      }
    }
  }
  > p {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.2);
  }
}
