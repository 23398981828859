.checkpoint-report {
  padding: 15px;
  background-color: #818a96;
  color: #fff;
  width: 410px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

  & > header {
    display: flex;
    justify-content: space-between;

    i {
      cursor: pointer;
    }
  }

  .loading {
    margin: auto;
    width: 60px;
    height: 60px;
  }

  & > div {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & > ul {
    padding: 6px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;

    &::-webkit-scrollbar {
      width: 11px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(199, 199, 199);
      border-radius: 30px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgb(177, 177, 177);
    }

    li {
      i {
        min-width: 30px;
      }

      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}

.check-point-title {
  display: flex;
  align-items: flex-end;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  text-align: left; /* 왼쪽 정렬 */
  gap: 8px;
}

.check-point-title span {
  font-size: 12px; /* 부제목 글씨 크기 */
  font-weight: 300; /* 일반 글씨체 */
  color: #e6e6e6; /* 밝은 회색 */
  line-height: 1.45; /* 줄 간격 조정 */
}
