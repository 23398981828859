#DashBoardTop {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  strong {
    font-family: "Pretendard_extraBold";
  }
  button {
    background-color: transparent;
  }
  .menu_tit {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #eee;
    padding: 10px 0;
    margin-left: 60px;
    > strong {
      display: block;
      margin-right: 60px;
      color: #333;
      font-size: 18px;
    }
    .download {
      display: flex;
      align-items: center;
      > button {
        margin-left: 10px;
      }
    }
  }
}
