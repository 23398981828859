#pagination {
    margin: 20px 0;

    .pagination {
        max-width: 600px;
        height: 36px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .item {
        display: block;
        width: 36px;
        height: 36px;

        .link {
            display: block;
            width: 100%;
            height: 100%;
            line-height: 36px;
            text-align: center;
            font-size: 14px;
            color: #343434;
            background-color: #f9f9f9;
            border: 0.5px solid #d8d8d8;
        }
        &.on,
        &:hover {
            .link {
                color: #fff;
                background-color: #1bb3f3;
            }
        }

        &.start {
            position: relative;
            width: 55px;

            .link {
                border-radius: 4px;

                &::after {
                    position: absolute;
                    content: "";
                    width: 24px;
                    height: 25px;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    background: url(../../../assets/images/common/icon-arrow-start.svg)
                        no-repeat;
                    background-size: cover;
                }
            }
        }
        &.prev {
            position: relative;

            .link {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;

                &::after {
                    position: absolute;
                    content: "";
                    width: 24px;
                    height: 25px;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    background: url(../../../assets/images/common/icon-arrow-prev.svg)
                        no-repeat;
                    background-size: cover;
                }
            }
        }
        &.next {
            position: relative;
            .link {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                &::after {
                    position: absolute;
                    content: "";
                    width: 24px;
                    height: 25px;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    background: url(../../../assets/images/common/icon-arrow-next.svg)
                        no-repeat;
                    background-size: cover;
                }
            }
        }
        &.end {
            position: relative;
            width: 55px;

            .link {
                border-radius: 4px;

                &::after {
                    position: absolute;
                    content: "";
                    width: 24px;
                    height: 25px;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    background: url(../../../assets/images/common/icon-arrow-end.svg)
                        no-repeat;
                    background-size: cover;
                }
            }
        }
    }

    .item-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        margin: 0 5px;

        .item {
            .link {
                border-right: none;
            }
        }

        .item:last-of-type {
            .link {
                border-right: 0.5px solid #d8d8d8;
            }
        }
    }
}
