@media print {
  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 0;
    padding: 0;
  }

  table {
    margin: 0;
    padding: 0;
  }
}

table {
  padding: 5px;
}
#deadlineList {
  margin: 30px auto;
  width: 80%;
  min-width: 1300px;

  .area_box {
    margin: 0 auto 34px auto;
    padding: 19px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 55px;
    max-width: 880px;
    height: 110px;
    border-radius: 20px;
    background-color: rgb(241, 241, 241, 0.3);

    .inner {
      h2 {
        margin-bottom: 6px;
        font-size: 20px;
        font-family: Pretendard_bold;
      }

      p {
        font-size: 16px;
        color: #343434;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 22px;
      font-family: Pretendard_bold;
      color: #343434;
    }

    .btns {
      display: flex;
      column-gap: 8px;

      .ToggleButtonWithSpan {
        margin-right: 31px;
      }

      .btn_download {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 132px;
        height: 32px;
        font-size: 16px;
        font-family: Pretendard_bold;
        color: #ffffff;
        border-radius: 4px;
        padding: 0 8px;
        background-color: var(--color-Web-Orange);
      }

      .btn_setting {
        width: 132px;
        line-height: 32px;
        font-size: 16px;
        color: #ffffff;
        border-radius: 4px;
        padding: 0 8px;
        text-align: center;
        background-color: var(--color-Alizarin-Crimson);
      }
    }
  }

  .sorting {
    p {
      margin: 8px 0 21px 0;
      display: flex;
      align-items: center;
      column-gap: 7px;
      font-size: 14px;
      color: #606060;
    }

    .sort_check {
      margin-top: 25px;
      position: relative;
      display: flex;
      flex-flow: wrap;

      .checkbox {
        margin-bottom: 11px;

        .value {
          display: block;
          width: 130px;
        }
      }
    }
  }
  .change_view {
    width: 100%;
    display: flex;
    font-family: Pretendard_bold;
    font-size: 16px;
    color: #343434;
    margin: 24px 0;
    > div {
      width: 50%;
      text-align: center;
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 15px;
      cursor: pointer;
    }
  }
  .table-box-wrap {
    margin-top: 27px;
  }

  table {
    tbody {
      tr:not(.more_view):not(.not_styled):hover {
        background-color: rgba(0, 149, 174, 0.1);
        transition-duration: 0.5s;
        cursor: pointer;
      }
      tr {
        height: 40px;
        td {
          div {
            line-height: 30px;
          }

          .middle_date {
            display: none;
            color: var(--color-Web-Orange);
          }
        }
      }

      tr.on {
        td {
          vertical-align: baseline;
        }

        .middle_date {
          display: block;
        }
      }
    }
  }
}
