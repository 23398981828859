.close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: transparent;
}
.back-btn{
  position: absolute;
  left: 20px;
  top: 20px;
  background-color: transparent;
  margin: 30px;
}

.main-modal {
  width: 80vw;
  min-width: 800px;
  height: 600px;
  padding: 40px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .selectboxes-div {
      display: flex;
      gap: 15px;
      align-items: center;
    }

    & > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      & > p:nth-child(2) {
        color: blue;
      }
      & > p:last-child {
        color: red;
      }
    }

    & > button {
      background-color: #4593f5;
      color: white;
      border-radius: 6px;
      padding: 6px;
    }
  }

  & > div:last-child {
    flex-grow: 1;
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    width: 3px; /* Width of scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background color of scrollbar track */
  }

  ::-webkit-scrollbar-thumb {
    background: #4593f5;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #1e59a1;
  }

  .loading-div {
    display: grid;
    place-items: center;
    height: 100%;
  }
}

.sales-form {
  padding: 0px 30px 30px 30px;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  flex-wrap: wrap;
  gap: 10px;
  background-color: transparent;
  width: 100%;
  position: relative;
  z-index: 1;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 15;
    width: 70px;
    height: 70px;
  }

  & > h1 {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 12px;
  }

  & > div {
    display: flex;
    gap: 10px;
    align-items: center;

    & > input {
      height: 42px;
      border: 1px solid #d8d8d8;
      border-radius: 4px;
      font-size: 14px;
      color: black;
      padding: 12px 0px 12px 10px;
      min-width: 200px;
    }

    & > div {
      min-width: calc(100% - 80px);
    }

    & > div input,
    & > input {
      width: 100%;
    }

    & > label {
      min-width: 70px;
      text-align: center;
      display: none;
    }

    &:last-child textarea {
      width: 100%;
      padding: 10px;
    }
  }

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & button[type="submit"] {
      padding: 10px;
      align-self: center;
      background-color: #309BF8;
      color: white;
      border-radius: 8px;
      width: 173.13px;
      height: 100%;
    }
  }

  & > div button[type="submit"]:disabled{
    background-color: #ABABAB;
  }
}

.deposite-header {
  & > header > div:nth-child(2) {
    display: flex;
    flex-direction: column;

    & > p:nth-child(2) {
      color: blue;
    }
    & > p:last-child {
      color: red;
    }
  }
}
