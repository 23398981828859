@import "src/include";

#SignupSuccess {
    @import "./common.scss";

    .wrap {
        .noti {
            margin: 35px auto 0 auto;
            line-height: 50px;
            text-align: center;

            span {
                font-size: 40px;
                font-family: NanumSquareOTF_acEB;
            }
        }

        .btn_confirm {
            margin: 30px auto 0 auto;
            display: block;
            width: 160px;
            height: 45px;
            font-size: 16px;
            font-family: NanumSquareOTF_acEB;
            line-height: 45px;
            color: #ffffff;
            text-align: center;
            @include border-radius(5px);
            background: #e92f2c;
        }

        .personal_notify {
            margin: 50px auto 20px auto;
            width: 90%;
            font-size: 20px;
            font-family: NanumSquareOTF_acEB;
        }

        .company_code {
            margin: auto;
            width: 450px;

            .box {
                padding: 8px 0 15px 0;
                border-radius: 5px;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
                border: solid 1px #e7e7e7;
                background-color: #ffffff;
                text-align: center;

                h2 {
                    font-size: 22px;
                    font-family: NanumSquareOTF_acEB;
                    line-height: 60px;
                }

                input {
                    margin-bottom: 28px;
                    width: 100%;
                    height: 50px;
                    font-size: 40px;
                    font-family: NanumSquareOTF_acEB;
                    text-align: center;
                    border: none;
                }

                input:focus {
                    outline: none;
                }

                button {
                    background-color: transparent;
                }
            }

            .company_code_info {
                margin-top: 16px;
                display: flex;
                justify-content: center;

                span {
                    margin-left: 10px;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    text-align: left;
                }
            }
        }
    }

  @media screen and (max-width: 850px){
      padding-top: 92px;
      width: 100vw;
      min-height: calc(100vh - 60px);
      background-color: #f0f0f0;

      .wrap {
          margin: auto;
          width: 90%;

          .noti {
              margin: 0;
              color: #443018;
              text-align: center;

              span {
                  font-size: 24px !important;
              }

              * {
                  font-family: NanumSquareOTF_acEB;
              }
          }

          .alert {
              margin: 28px 0 20px 0;
              font-size: 14px;
              font-family: NanumSquareOTF_acB;
              color: #747474;
              line-height: 20px;
              text-align: center;
          }

          .company_code {
              margin-top: 10px;
              width: 85%;

              .box {
                  h2 {
                      margin: auto;
                      padding-bottom: 8px;
                      width: 60%;
                      font-size: 16px;
                      font-family: NanumSquareOTF_acB;
                      line-height: 22px;
                      color: #797979;
                      border-bottom: 1px solid #d8d8d8;
                  }

                  input {
                      margin: 0;
                      font-size: 20px;
                      color: #443018;
                  }

                  .btn {
                      width: 96px;
                      height: 30px;
                      font-size: 12px;
                      background-color: var(--color-Web-Orange);
                  }

                  .company_code_info {
                      margin: 13px auto 0 auto;
                      width: 90%;

                      span {
                          margin-left: 6px;
                          width: 80%;
                          font-size: 12px;
                          font-family: NanumSquareOTF_acB;
                          text-align: left;
                          color: #959595;
                      }
                  }
              }
          }
          .show {
              display: block !important;
          }

          .hide {
              display: none !important;
          }
      }

      .wrap.investor {
          padding-top: 98px;

          .noti {
              margin-bottom: 42px;
              font-size: 28px;
              line-height: 36px;
          }
      }

  }
}
