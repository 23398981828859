.checkpoint-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 880px;
  margin: 40px auto;

  & > div:first-child {
    background-color: #f9fafb;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 20px 0;

    & > div:first-child {
      display: flex;
      gap: 25px;

      & > img {
        width: 100px;
      }
    }

    & > div:nth-of-type(2) {
      line-height: 1.6;
      text-align: center;
    }
  }
}
