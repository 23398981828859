@import "../../../include";

#PopupTaskFile {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 880px;
    min-height: 30vh;
    @include border-radius(5px);
    border: solid 1px #e7e7e7;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    z-index: 10000;

    .head {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #e7e7e7;
        background-color: #FFCC80;

        h2 {
            font-size: 20px;
            font-family: NanumSquareOTF_acB;
            color: #343434;
        }
    }

    ul {
        padding: 30px 45px;
        height: calc(30vh - 55px);
        background-color: #FFF3E0;

        li {
            list-style: disc;
            font-size: 14px;
            color: #443018;
            cursor: pointer;
        }

        li+li {
            margin-top: 10px;
        }
    }
}