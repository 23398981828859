.fieldWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}

.required {
  color: red;
  font-size: 14px;
  margin-left: 4px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;

  /* ✅ readOnly 상태일 경우 스타일 추가 */
  &:read-only {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    color: #777;
  }
}
