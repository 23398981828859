#AnnualFeeChart {
    position: relative;
    display: flex;
    height: 350px;

    .axes_line {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        p {
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #e7e7e7;
        }
    }

    .axes {
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 12px;
        font-family: NanumSquareOTF_acEB;
        text-align: right;
        background-color: #ffffff;
        z-index: 2;
        p {
            color: #959595;
            letter-spacing: -0.5px;
        }
        p:first-child {
            position: relative;
            top: -5px;
        }

        p:last-child {
            position: relative;
            top: 5px;
        }
    }

    .bars {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: solid 1px #e7e7e7;
    }

    .bar:after {
        position: absolute;
        bottom: -30px;
        width: 100%;
        height: 20px;
        font-size: 12px;
        font-family: NanumSquareOTF_acB;
        text-align: center;
        content: attr(data-year);
    }
    .disable.bar:after {
        opacity: 0.4;
    }

    .bar:hover .balloon{
        display: flex;
    }

    .bar {
        position: relative;
        top: 70%;
        max-width: 34.3px;
        width: 1vw;
        height: 30%;

        p {
            position: relative;
            top: -30px;
            width: 100%;
            height: 20px;
            font-size: 12px;
            font-family: NanumSquareOTF_acB;
            text-align: center;
            z-index: 2;
        }

        .data {
            position: relative;
            top: -20px;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.05);
            cursor: pointer;
        }

        .balloon {
            padding: 8px;
            position: absolute;
            top: -40px;
            left: 50%;
            transform: translateX(-50%);
            display: none;
            justify-content: center;
            align-items: center;
            min-width: 80px;
            height: 27px;
            white-space: pre;
            font-size: 11px;
            font-family: NanumSquareOTF_acEB;
            color: #ffffff;
            text-align: center;
            background: #282828;
            border-radius: 7px;
            z-index: 2;
        }
        .balloon:after {
            content: "";
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(50%);
            width: 0;
            height: 0;
            border: 10px solid transparent;
            border-top-color: #282828;
            border-bottom: 0;
            margin-left: -20px;
            margin-bottom: -20px;
        }
    }

    @media screen and (max-width: 1200px){
        .bar {
            p {
                opacity: 0;
            }
        }
    }

    @media screen and (max-width: 900px){
        .bar:after {
            font-size: 10px;
        }

        .axes {
            font-size: 10px;
        }
    }
}
