@import "../../../include";

& {
    margin: auto;
    padding: 45px 0 61px;
    max-width: 640px;

    .page_title {
        margin: auto;
        padding: 16px 15px 0;
        width: 100%;
        font-size: 24px;
        font-family: NanumSquareOTF_acEB;
    }

    .desc {
        margin: 0 15px;
        padding: 6px 0 6px 15px;
        min-width: 333px;
        font-size: 12px;
        line-height: 24px;
        border-bottom: 2px solid #00a545;
    }

    .sum_point {
        margin: 18px auto 30px auto;
        padding: 0 15px;
        min-width: 302px;
        font-size: 32px;
        font-family: NanumSquareOTF_acB;
        line-height: 24px;
        color: #00a545;
        text-align: right;
    }

    .form {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        width: 318px;

        .field {

            .head {
                margin-bottom: 6px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 24px;

                h2 {
                    font-size: 12px;
                    font-family: NanumSquareOTF_acEB;
                }

                p {
                    font-size: 12px;
                    color: #606060;
                }
            }

            input {
                padding: 0 10px;
                width: 100%;
                height: 38px;
                font-size: 12px;
                color: #343434;
                @include border-radius(3px);
                border: 2px solid #d8d8d8;
            }

            .body {
                display: flex;
                align-items: center;
                column-gap: 9px;

                input {
                    width: 231px;
                }

                button {
                    width: 77px;
                    height: 40px;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acEB;
                    color: #ffffff;
                    @include border-radius(4px);
                    background-color: #00a545;
                }
            }
        }
    }

    .wrap_info {
        margin: 17px auto 10px auto;
        width: 333px;

        .notify {
            margin-bottom: 7px;
            display: flex;
            align-items: center;
            column-gap: 8px;
            font-size: 14px;
            font-family: NanumSquareOTF_acEB;
        }

        ul {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            li {
                position: relative;
                padding-left: 25px;
                font-size: 12px;
                line-height: 24px;

                span {
                    color: #ff0000;
                }
            }

            li:before {
                content: "";
                position: absolute;
                top: 7px;
                left: 7px;
                width: 10px;
                height: 10px;
                @include border-radius(50%);
                background-color: #00a545;
            }
        }
    }

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 52px;
        font-size: 16px;
        font-family: NanumSquareOTF_acEB;
        color: #ffffff;
        @include border-radius(5px);
        background-color: #00a545;
    }
}