@import "src/include";
.sample_list-contents {
    &_list{
        &.mo{
            width: calc(50% - 8px);
            height: 47.22222222222222vw; /*170px(360)*/
            margin-bottom: 20px;
            .sample_list-contents{
                &_left{
                    width: 100%;
                    height: 27.77777777777778vw; /*100px(360)*/
                    margin-bottom: 2.222222222222222vw; /*8px(360)*/
                    border-radius: 4px;
                    overflow: hidden;
                }
                &_img-link{
                    display: block;
                    width: 100%;
                    height: 100%;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border: 1px solid #e8e8e8;
                    }
                }
                &_center{
                    .formatInfo{
                        height: 18px;
                        line-height: 18px;
                        font-family: NanumSquareOTF_acEB;
                        font-size: 12px;
                        color: #4593f5;
                        margin-bottom: 0.5555555555555556vw; /*2px(360)*/
                    }
                    .listTitle{
                        height: 20px;
                        line-height: 20px;
                        font-family: NanumSquareOTF_acEB;
                        font-size: 14px;
                        color: #1d334e;
                        @include text-ellipsis(1);
                    }
                    .priceInfo{
                        font-family: NanumSquareOTF_acEB;
                        font-size: 16px;
                        color: #747474;
                    }
                }
            }
        }
    }
    &_list {
        &.pc{
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(50% - 7.5px);
            height: 140px;
            background-color: #fff;
            box-shadow: 1px 2px 5px 0 rgba(52, 52, 52, 0.3);
            margin-bottom: 15px;
            .sample_list-contents{
                &_left {
                    width: 193px;
                    height: 100%;
                }
                &_img-link {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
                &_img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &_center {
                    position: relative;
                    width: calc(100% - 193px);
                    height: 100%;
                    padding: 15px 15px 10px 10px;
                }
                &_list-title {
                    margin-bottom: 6px;
                    font-family: NanumSquareOTF_acB;
                    font-size: 14px;
                    line-height: 1.43;
                    color: #343434;
                    max-height: 43px;
                    word-break: keep-all;
                    @include text-ellipsis(2);
                }
                &_list-format {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    margin-bottom: 12px;
                    .formatTitle {
                        width: 60px;
                        font-size: 12px;
                        letter-spacing: -0.4px;
                        color: rgba(0, 0, 0, 0.87);
                    }
                    .formatInfo {
                        width: calc(100% - 60px);
                        font-family: NanumSquareOTF_acB;
                        font-size: 12px;
                        letter-spacing: -0.4px;
                        color: #343434;
                    }
                }
                &_list-price {
                    display: flex;
                    position: absolute;
                    width: 100%;
                    height: 55px;
                    left: 0;
                    bottom: 0;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;
                    .priceInfo {
                        font-family: NanumSquareOTF_acB;
                        font-size: 16px;
                        letter-spacing: -0.25px;
                        color: var(--color-Web-Orange);
                    }
                    .btn-trade{
                        width: 83px;
                        height: 35px;
                        line-height: 36px;
                        text-align: center;
                        border-radius: 4px;
                        background-color: #4593f5;
                        font-size: 14px;
                        color: #fff;
                    }
                }
            }
        }
    }
}