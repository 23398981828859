@import "src/include";

.MarketInfoCartItem {
  position: relative;
  padding: 5px;
  width: 160px;
  height: 191px;
  border: 1px solid #e6e6e6;

  .icon_badge_del  {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  img {
    width: 153px;
    height: 110px;
    object-fit: contain;
  }

  h2 {
    height: 24px;
    font-size: 14px;
    font-family: NanumSquareOTF_acB;
    line-height: 24px;
    color: #343434;
    @include text-ellipsis(1);
  }

  p {
    height: 24px;
    font-size: 12px;
    font-family: NanumSquareOTF_acB;
    line-height: 24px;
    color: #606060;
    text-align: right;
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      display: flex;
      align-items: center;
      column-gap: 5px;
      cursor: pointer;

      span {
        font-size: 12px;
        font-family: NanumSquareOTF_acB;
        color: #606060;
      }
    }

    .checkbox {
      column-gap: 5px;
      .value {
        font-size: 12px;
        color: var(--color-Web-Orange);
      }
    }
  }
}