#AnnualCountList {
    margin: auto;
    padding-top: 42px;
    width: 1280px;

    .header {
        margin-bottom: 114px;
        display: flex;
        justify-content: space-between;


        .info {
            h2 {
                margin-bottom: 10px;
                font-size: 22px;
                font-family: NanumSquareOTF_acEB;
            }

            p {
                line-height: 21px;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.87);
            }

            .noti {
                margin-top: 10px;
                display: flex;
                align-items: center;
                column-gap: 8px;
                font-size: 14px;
                color: #606060;
            }
        }

        .legend {

            .item {
                display: flex;
                align-items: center;

                .circle {
                    margin-right: 10px;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                }

                .yellow {
                    background-color: #ffac12;
                }

                .blue {
                    background-color: #0095ae;
                }

                p {
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                }
            }

            .item+.item {
                margin-top: 10px;
            }
        }
    }
}
