$gray-2: #f2f4f6;
$gray-3: #f9fafb;
$gray-4: #d1d6db;
$gray-9: #333d4b;

#DDashboard {
  max-width: 1920px;
  min-width: 1300px;
  width: 80%;
  margin: 30px auto;
  color: $gray-9;
  strong,
  p,
  th,
  td,
  .heatmap {
    font-family: "Pretendard_Regular";
    color: $gray-9;
  }
  strong {
    font-family: "Pretendard_bold";
  }
  table {
    th {
      background-color: $gray-2;
    }
    td {
      padding: 7px;
    }
  }
  .bg_gray {
    background-color: $gray-3;
  }
  .wrap {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    //padding: 40px 0;
  }
  .row {
    margin-bottom: 20px;
    display: flex;
    // gap: 20px;
    justify-content: space-between;
    min-height: 300px;
  }
  .row1 {
    > .DBox {
      width: calc(60% - 20px);
      &:first-of-type {
        width: 40%;
      }
    }
  }
  .row2 {
    > .DBox {
      width: calc((100% - 40px) / 3);
    }
  }
  .row3 {
    > .DBox {
      width: calc((100% - 20px) / 2);
    }
  }
  .treemap {
    position: relative;
    &:hover {
      .total_cnt {
        display: none;
      }
    }
    .total_cnt {
      font-size: 20px;
      display: block;
      margin-bottom: 10px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      //color: #ff4545;
      color: var(--color-Alizarin-Crimson);
      padding: 12px 18px;
      border-radius: 10px;
    }
  }

  .tech_info {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .avg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -64%);
      text-align: center;
      font-size: 24px;
    }
    .levels {
      > ul {
        > li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          > p {
            font-size: 16px;
          }
          > strong {
            font-size: 16px;
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 12px;
            text-align: center;
            color: #fff;
            padding: 10px;
            border-radius: 40px;
            margin-left: 4px;
          }
        }
      }
    }
  }
  .tech_box {
    position: relative;
  }
  #Annuity {
    text-align: center;
    margin-top: 90px;
    > strong {
      display: block;
      font-size: 32px;
      margin-bottom: 10px;
    }
    > p {
      font-size: 20px;
    }
  }
  .half_doughnut {
    position: relative;
    .txt {
      position: absolute;
      left: 50%;
      bottom: 20px;
      transform: translateX(-50%);
      text-align: center;
      strong {
        font-size: 32px;
        display: block;
        margin-bottom: 10px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .survival {
    display: flex;
    align-items: center;
  }
  .world_box {
    display: flex;
    align-items: center;
    justify-content: center;
    .sub_tit {
      text-align: center;
      padding-bottom: 10px;
    }
  }
  #IpStatistic {
    max-width: 1400px;
    padding: 20px;
    margin: 0;
    .section {
      margin-bottom: 0;
    }
  }
  .tech_map {
    display: flex;
    #KoreaMap {
      svg {
        width: 240px;
      }
    }
  }
  .modal {
    z-index: 999;
  }
  .chart_box {
    width: 540px;
    &.auto_width {
      width: auto;
    }
    &.flex {
      width: 640px;
      display: flex;
    }
  }
  .double_chart {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .InfoTable {
    flex: 1;
    & + .InfoTable {
      margin-top: 20px;
    }
    .tbl_wrap {
      max-height: 264px;
      overflow-y: auto;
      thead {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 99;
        th {
          &:first-child {
            position: sticky;
            left: 0;
          }
        }
      }
      tbody {
        > tr {
          > td {
            &:first-child {
              position: sticky;
              left: 0;
              background-color: #fff;
            }
          }
        }
      }
    }
    .table_desc {
      padding: 10px;
      > ul {
        > li {
          margin-bottom: 4px;
          font-size: 14px;
          display: flex;
          * {
            color: #555555;
          }
          > strong {
            padding-right: 4px;
          }
        }
      }
    }
    .units {
      color: #6b7684;
      text-align: right;
      padding-bottom: 10px;
    }
    .no_data {
      text-align: center;
      color: #6b7684;
    }
  }

  .info_slide {
    margin-bottom: 20px;
  }
  // .slick-slider {
  //   height: 100%;
  // }
  // .slick-list {
  //   height: 100%;
  // }
  // .slick-track {
  //   height: 100%;
  // }
  // .slick-slide {
  //   height: 100%;
  //   > div {
  //     height: 100%;
  //     .CustomSlide {
  //       height: 100%;
  //       .content_wrap {
  //         height: 100%;
  //         .content_box {
  //           height: 100%;
  //         }
  //       }
  //     }
  //   }
  // }

  .tech_level_chart_box {
    width: auto;
    display: flex;
    align-items: center;
    .tech_level_chart {
      position: relative;
      .avg {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 24px;
      }
      canvas {
        position: relative;
        z-index: 99;
      }
    }
    .levels {
      > ul {
        > li {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 10px;
          > p {
            font-size: 16px;
          }
          > strong {
            font-size: 16px;
            display: inline-block;
            width: auto;
            height: 30px;
            line-height: 12px;
            text-align: center;
            color: #fff;
            padding: 10px;
            border-radius: 40px;
            margin-left: 4px;
          }
        }
      }
    }
  }

  .next_annual {
    text-align: center;
    // margin-top: 50px;
    > .cost {
      display: block;
      font-size: 32px;
      margin-bottom: 10px;
    }
    > .year {
      font-size: 20px;
    }
  }
  .warn {
    // text-align: right;
    font-size: 18px;
    font-family: "Pretendard_semiBold";
    padding: 10px 0;
    > span {
      display: block;
      //background-color: #ff4545;
      background-color: var(--color-Alizarin-Crimson);
      color: #fff;
      padding: 10px 16px;
      border-radius: 6px;
    }
  }

  #DistributionPop {
    > strong {
      display: block;
      margin-bottom: 10px;
    }
  }
  #CandidatePop {
    > strong {
      display: block;
      margin-bottom: 10px;
    }
  }

  #IpStatistic {
    .status_box {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      justify-items: center;
      button:not(.link) {
        padding: 10px 14px;
        border-radius: 6px;
        border: 1px solid #4e7ce9;
        color: #4e7ce9;
        background: transparent;
        margin-right: 6px;
        border-radius: 20px;
        &.on {
          background: #4e7ce9;
          color: #fff;
        }
      }
      .cate_box {
        display: block;
        width: 100%;
        > div {
          display: flex;
          align-items: center;
          > p {
            width: 80px;
          }
        }
        .cate {
          margin-bottom: 20px;
          > ul {
            display: flex;
            align-items: center;
          }
        }
        .keyword {
          .search {
            margin-left: 6px;
          }
        }
      }
      .keyword {
        margin-bottom: 20px;
      }
    }
    .selected {
      padding: 10px 14px;
      background: #4e7ce9;
      color: #fff;
      margin-right: 6px;
      margin-bottom: 6px;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 20px;
      font-size: 14px;
      i {
        width: 16px;
        height: 16px;
        background-size: contain;
        margin-left: 6px;
      }
    }
    .keyword_list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      > li {
        padding: 10px 14px;
        border-radius: 20px;
        background: $gray-4;
        color: #fff;
        margin-right: 6px;
        margin-bottom: 6px;
        cursor: pointer;
        &.on {
          background-color: #4e7ce9;
        }
      }
    }
    #pagination {
      .item {
        &.end,
        &.start {
          width: 36px;
        }
        &:hover {
          background-color: #4e7ce9;
          border-radius: 100%;
        }
        .link {
          border: none;
          background-color: transparent;
        }
        &.on {
          .link {
            background-color: #4e7ce9;
            border-radius: 100%;
          }
        }
      }
    }
  }
  #MiniLoading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .inventor_find {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .layout_tit {
      margin-bottom: 0;
      margin-right: 12px;
    }
    .sch_box {
      display: flex;
      align-items: center;
      border: 1px solid #dedede;
      border-radius: 10px;
      padding: 0 6px;
      input {
        border: none;
      }
    }
    button {
      background-color: transparent;
    }
  }

  .categoryTableWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .category {
      display: flex;
      justify-content: center;
      gap: 20px;
      button:not(.link) {
        padding: 10px 14px;
        border-radius: 20px;
        border: 1px solid #4e7ce9;
        background: transparent;
        color: #4e7ce9;
        margin-right: 6px;
        &.on {
          background: #4e7ce9;
          color: #fff;
        }
      }
    }

    tbody {
      > tr {
        &:hover {
          cursor: pointer;
          background-color: rgba(107, 211, 255, 0.2);
        }
      }
    }
  }

  #Modal_table {
    z-index: 999;
    width: 1400px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    border-radius: 30px;
    box-shadow: 0 2px 4px 0 #d8d8d8;
    #pagination {
      .item {
        &.end,
        &.start {
          width: 36px;
        }
        &:hover {
          background-color: #4e7ce9;
          border-radius: 100%;
        }
        .link {
          border: none;
          background-color: transparent;
        }
        &.on {
          .link {
            background-color: #4e7ce9;
            border-radius: 100%;
          }
        }
      }
    }
    > strong {
      display: block;
      margin-bottom: 10px;
    }
    .close {
      position: absolute;
      right: 30px;
      top: 30px;
      background: transparent;
    }
    .tbl_box {
      max-height: none;
      margin-top: 30px;
      .popup {
        position: absolute;
        top: 100%; /* 셀 바로 아래 */
        left: 50%; /* 셀의 가운데 */
        transform: translateX(-50%); /* 가운데 정렬 */
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 1000; /* 팝업을 다른 요소 위에 표시 */
        white-space: nowrap; /* 텍스트 줄바꿈 방지 */
        text-align: center; /* 텍스트 중앙 정렬 */
      }

      tbody {
        > tr {
          &:hover {
            cursor: pointer;
            background-color: rgba(107, 211, 255, 0.2);
          }
          td {
            position: relative; /* 팝업 위치 기준 설정 */
          }
        }
      }
    }
  }

  .graphWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .graphModal {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: 960px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    border-radius: 30px;
    box-shadow: 0 2px 4px 0 #d8d8d8;
    .close {
      position: absolute;
      right: 30px;
      top: 30px;
      background: transparent;
    }
  }
}
