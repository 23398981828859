#NotFoundPage {
    margin: auto;
    padding-top: 200px;
    max-width: 700px;
    text-align: center;

    img {
        margin-bottom: 40px;
    }

    h2 {
        margin-bottom: 18px;
        font-size: 24px;
        font-family: NanumSquareOTF_acB;
        color: rgba(0, 0, 0, 0.87);
    }

    p {
        margin-bottom: 60px;
        font-size: 14px;

        span {
            margin-bottom: 10px;
            display: block;
            font-size: 14px;
            color: #0095b0;
        }
    }

    button {
        margin: auto;
        display: block;
        width: 300px;
        line-height: 49px;
        font-size: 20px;
        color: #ffffff;
        text-align: center;
        background-color: #0095b0;
    }
}
