@import "src/mixin";

#MyPageFindbizAccountEdit {
  max-width: 800px;

  @include mobile {
    margin: auto;
    width: 90%;
  }

  .head {
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    column-gap: 10px;

    h2 {
      font-size: 22px;
      font-family: NanumSquareOTF_acB;
      line-height: 32px;
      color: var(--color-Mine_Shaft);
    }

    p {
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 50px;
      height: 32px;
      font-size: 12px;
      font-family: NanumSquareOTF_acB;
      color: #ffffff;
      border-radius: 8px;
      background-color: var(--color-Malibu);
    }
  }

  .account_area {
    margin-bottom: 120px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 70px;
    row-gap: 25px;

    .wrap_info {
      width: 365px;

      @include mobile {
        width: 100%;
      }

      &.one {
        margin-right: calc(50% + 35px);
        width: 100%;
      }

      h2 {
        margin-bottom: 5px;
        font-size: 14px;
        font-family: NanumSquareOTF_acB;
        color: var(--color-Mine_Shaft);
      }

      .info {
        padding: 5px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--color-Gallery);

        input,
        p {
          width: 240px;
          font-size: 14px;
          line-height: 25px;
          color: var(--color-Mine_Shaft);
          border: none;

          @include mobile {
            width: 200px;
          }
        }

        button {
          padding: 0 10px;
          min-width: 50px;
          height: 32px;
          font-size: 12px;
          font-family: NanumSquareOTF_acB;
          color: var(--color-Scorpion);
          border-radius: 4px;
          border: 1px solid var(--color-Alto);
          background-color: #ffffff;

          &.success {
            border: 1px solid var(--color-Cornflower-Blue);
            cursor: default;
          }
        }

        .btn_list {
          display: flex;
          align-items: center;
          column-gap: 3px;

          .btn_save {
            color: #ffffff;
            border: none;
            background-color: var(--color-Cornflower-Blue);
          }
        }
      }

      .notify {
        font-size: 12px;
        font-family: NanumSquareOTF_acB;
        line-height: 25px;
        color: var(--color-Scorpion);

        span {
          color: var(--color-Cornflower-Blue);
        }
      }
    }
  }

  .unsubscription {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    height: 18px;

    p {
      font-size: 12px;
      font-family: NanumSquareOTF_acB;
      color: var(--color-Scorpion);
    }

    button {
      font-size: 12px;
      font-family: NanumSquareOTF_acEB;
      color: var(--color-Mine_Shaft);
      background-color: transparent;
    }
  }

  .signout {
    display: flex;
    align-items: center;
    column-gap: 5px;
    height: 18px;
    opacity: 0.5;

    p {
      font-size: 12px;
      font-family: NanumSquareOTF_acB;
      color: var(--color-Scorpion);
    }

    a {
      font-size: 12px;
      font-family: NanumSquareOTF_acEB;
      color: var(--color-Mine_Shaft);
      background-color: transparent;
    }
  }
}