#MyProduct {
  margin: auto;
  padding-top: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  column-gap: 20px;
  width: 1280px;

  .form {
    width: calc(100% - 150px);

    .header {
      margin-bottom: 41px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;

      h2 {
        font-size: 22px;
        font-family: NanumSquareOTF_acEB;
      }

      button {
        width: 64px;
        height: 32px;
        font-size: 16px;
        color: #ffffff;
        background-color: #96c719;
      }
    }

    table {
      th:nth-child(2),
      td:nth-child(2) {
        padding-left: 30px;
        text-align: left;
      }

      tbody {
        tr {
          height: 57px;

          td {
            font-size: 14px;
            text-align: center;
          }
        }
      }
    }
  }

  .specific-buttons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-bottom: 20px;
    > button {
      width: 100% !important;
      border-radius: 5px;
      color: black !important;
    }
    > button:hover {
      background-color: #55efc4;
    }
    > .isClicked {
      background-color: #55efc4;
    }
  }
  @import "Common";

  .icon_edit {
    display: inline !important;
    width: 24px !important;
    height: 24px !important;
  }

  input {
    padding: 0 12px;
    width: 100%;
    height: 40px;
    font-size: 14px;
    text-align: left;
    border: solid 1px rgba(0, 0, 0, 0.56);
  }

  .btn_add {
    margin-top: 10px;
    width: 881px !important;
    height: 40px !important;
    background-color: #4593f5;
  }
}
