@import "src/include";

#PopupTradeMarkAdd {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    z-index: 2;

    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
        z-index: 2;

        h2 {
            font-size: 16px;
            font-family: NanumSquareOTF_acEB;
            color: #443018;
        }

        button {
            position: absolute;
            left: 16px;
            background-size: contain;
        }
    }

    .trade_item_select {
        padding-top: 24px;

        .head {
            margin-bottom: 13px;
            padding: 0 5%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
                line-height: 20px;
                color: #443018;
            }

            .btn_select {
                width: 92px;
                height: 26px;
                font-size: 12px;
                font-family: NanumSquareOTF_acEB;
                color: #ffffff;
                border-radius: 4px;
                background-color: var(--color-Web-Orange);
            }
        }

        .table-box-wrap {
            width: 90%;
            margin: 0 auto;
            .wrap_list {
                max-height: 400px;

                table {
                    th:nth-child(1),
                    td:nth-child(1) {
                        width: 15%;
                    }

                    td:last-child {
                        font-family: NanumSquareOTF_acB;
                        color: #606060;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 850px) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 50px;
        width: 880px;
        height: 500px;
        border: 1px solid var(--color-Web-Orange);

        .header {
            display: none;
        }


        .trade_item_select {
            padding: 0;

            .head {
                margin-bottom: 30px;
                padding: 0;

                h2 {
                    font-size: 22px;
                }

                .btns {
                    display: flex;
                    align-items: center;
                    column-gap: 20px;

                    .btn_select {
                        width: 120px;
                        height: 32px;
                        font-size: 16px;
                        font-family: NanumSquareOTF_acR;
                        line-height: 32px;
                        border-radius: 0;
                        @include border-radius(0);
                    }
                }
            }
        }

        .table-box-wrap {
            .wrap_list {
                max-height: 295px !important;
            }
        }
    }
}