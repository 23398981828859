@import "src/include";
@import "src/mixin";

#TaskNotifySetting {
    margin: 0 auto;
    width: 1280px;
    > .header {
        width: 1280px;
        height: 180px;
        padding: 36px 0 0 36px;
        background-color: #e6f2ff;
        > h2 {
            @include text-gradient-green;
            font-size: 32px;
            letter-spacing: -0.75px;
        }
        > h5 {
            font-size: 16px;
            line-height: 1.38;
            letter-spacing: -1px;
            color: #343434;
            margin-top: 4px;
        }
    }
    @include mobile {
        width: 100%;
        .header {
            width: 100%;
            height: 120px;
            margin-top: 60px;
            padding: 14px 0 18px 13px;
            > h2 {
                @include text-gradient-green;
                font-size: 32px;
                letter-spacing: -0.75px;
                font-family: GmarketSansTTF_bold;
            }
            > h5 {
                font-size: 13px;
                line-height: 1.38;
                color: #343434;
                margin-top: 4px;
                font-family: Pretendard_regular;
            }
        }
    }
    .btns {
        margin-top: 28.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 15px;

        button {
            width: 93px;
            height: 45px;
            font-size: 16px;
            font-family: Pretendard_extraBold;
            line-height: 45px;
        }

        .btn_back {
            border: solid 1px #959595;
            @include border-radius(4px);
            background-color: #ffffff;
        }

        .btn_save {
            color: #ffffff;
            @include border-radius(4px);
            background-color: var(--color-Alizarin-Crimson);
        }
    }

    .pc {
        padding: 20px 28px;
        > h3 {
            font-size: 22px;
            letter-spacing: -0.25px;
            color: #343434;
            font-family: Pretendard_medium;
        }

        > div {
            width: 1060px;
            border-radius: 30px;
            box-shadow: 0 2px 4px 0 #d8d8d8;
            background-color: #fff;
            min-height: 105px;
            margin: 35px auto;
        }
        .keyword_input {
            display: flex;
            justify-content: center;
            flex-direction: column;
            .keyword_input_top {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 13px;
            }
            input[type="text"] {
                width: 500px;
                height: 45px;
            }
            button {
                width: 185px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15px;
                background-image: linear-gradient(to left, #0d7, #0078f1);
                color: #FFFFFF;
                font-size: 16px;
                i {
                    margin-right: 5px;
                }
            }
        }

        .keyword_input_bottom {
            display: flex;
            align-items: center;
            margin: 10px 0 0 208px;
            gap: 15px;
            > p {
                font-size: 12px;
                color: #0078f1;
            }
            label {
                display: flex;
                align-items: center;
                span {
                    margin-left: 30px;
                }
            }
            input[type="checkbox"] {
                display: none;
            }
            input[type="checkbox"] + label::before {
                display: inline-block;
                content: url("../../../assets/images/common/button-checkbox-off.svg");
                width: 24px;
                height: 24px;
                position: absolute;
            }
            input[type="checkbox"]:checked + label::after {
                content: url("../../../assets/images/common/button-checkbox-on.svg");
                width: 24px;
                height: 24px;
                position: absolute;
            }
        }
        .toggle_btn {
            position: relative;
            display: inline-block;
            width: 42.1px;
            height: 18px;
            input {
                opacity: 0;
                width: 0;
                height: 0;
            }
            span {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: #FFFFFF;
                transition: 0.3s;
                border-radius: 30px;
                border: 1px solid #0078f1;
            }
            span:before {
                position: absolute;
                content: "";
                width: 10px;
                height: 10px;
                left: 3px;
                bottom: 2.6px;
                background-color: #0078f1;
                border-radius: 50%;
                transition: 0.3s;
            }

            input:checked + span {
                background-color: #0078f1;
            }

            input:checked + span:before {
                right: 10px;
                background: #FFFFFF;
                transform: translateX(24px);
            }
        }
    }
    @include mobile {
        .wrap_keyword {
            width: 100%;
            padding: 26px 0 0 0;
            > h3 {
                margin-left: 18px;
                font-size: 18px;
                letter-spacing: -0.5px;
                color: #343434;
                font-family: Pretendard_semiBold;
            }
        }
        .mobile_view {
            > .keyword_input {
                width: 98%;
                border-radius: 15px;
                box-shadow: 0 2px 8px 0 #b4e9ff;
                background-color: #fff;
                padding: 14px 9px 12px 10px;
                margin: 10px auto 0 auto;
                .input_wrapper {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 20px;
                    border: solid 1px #0078f1;
                    padding: 0 10px;
                    input[type="text"] {
                        width: 95%;
                        height: 20px;
                        border: none;
                    }
                }
                .keyword_input_top {
                    display: flex;
                    justify-content: space-between;
                }
                .keyword_input_bottom {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 25px 0 12px 0;
                    > p {
                        font-size: 12px;
                        color: #0078f1;
                    }
                    > div {
                        display: flex;
                        gap: 5px;
                    }
                    label {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        color: #606060;
                        span {
                            margin-left: 25px;
                        }
                    }
                    input[type="checkbox"] {
                        display: none;
                    }
                    input[type="checkbox"] + label::before {
                        display: inline-block;
                        content: url("../../../assets/images/common/button-checkbox-off.svg");
                        width: 24px;
                        height: 24px;
                        position: absolute;
                    }
                    input[type="checkbox"]:checked + label::after {
                        content: url("../../../assets/images/common/button-checkbox-on.svg");
                        width: 24px;
                        height: 24px;
                        position: absolute;
                    }
                }
                .toggle_btn {
                    position: relative;
                    display: inline-block;
                    width: 42.1px;
                    height: 18px;
                    input {
                        opacity: 0;
                        width: 0;
                        height: 0;
                    }
                    span {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: #FFFFFF;
                        transition: 0.3s;
                        border-radius: 30px;
                        border: 1px solid #0078f1;
                    }
                    span:before {
                        position: absolute;
                        content: "";
                        width: 10px;
                        height: 10px;
                        left: 3px;
                        bottom: 2.6px;
                        background-color: #0078f1;
                        border-radius: 50%;
                        transition: 0.3s;
                    }

                    input:checked + span {
                        background-color: #0078f1;
                    }

                    input:checked + span:before {
                        right: 10px;
                        background: #FFFFFF;
                        transform: translateX(24px);
                    }
                }
            }
        }
    }
    .keyword_table {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 30px;
        .keyword_table_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            > p {
                font-size: 14px;
                color: #0078f1;
                font-family: Pretendard_regular;
            }
            > select {
                width: 80px;
                font-size: 12px;
                color: #959595;
            }
        }
        table {
            width: 1000px;
            thead tr {
                height: 35px;
                background-image: linear-gradient(to right, #0078f1, #01bdb4);
                th {
                    color: #FFFFFF;
                }
            }
            .table_checklist {
                input[type="checkbox"] {
                    display: none;
                }
                label {
                    padding: 2px 6px;
                    border-radius: 9px;
                    background-color: #e6f2ff;
                    margin-left: 5px;
                }
                input:checked + label {
                    border: solid 1px #0078f1;
                    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
                    color: #0078f1;
                }
            }
        }
        .keyword_pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 40px 0 20px 0;
        }
    }
    @include mobile {
        .keyword_table {
            width: 98%;
            padding: 15px;
            margin: 20px auto 0 auto;
            border-radius: 15px;
            box-shadow: 0 2px 4px 0 #d8d8d8;
            background-color: #fff;
            table {
                width: 100%;
                th:nth-child(1),td:nth-child(1) {
                    width: 10%;
                }
                th:nth-child(2),td:nth-child(2) {
                    width: 25%;
                }
                th:nth-child(3),td:nth-child(3) {
                    width: 50%;
                }
                th:nth-child(4),td:nth-child(4) {
                    width: 15%;
                }
            }
        }
    }
}
