#TradeMarkEventPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 780px;
  min-height: 700px;
  padding: 43px 61px;
  background-color: #fff;
  border: 1px solid #606060;
  border-radius: 10px;
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    p {
      font-size: 20px;
    }
    i {
      cursor: pointer;
    }
  }
  section {
    .search_wrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 90%;
      border: 1px solid #606060;
      border-radius: 5px;
      margin: 20px auto;
      input[type="text"] {
        width: 90%;
        border: none;
      }
      i {
        cursor: pointer;
      }
    }
    .table_wrapper {
      max-height: 500px;
      overflow-y: scroll;
    }
    .event_table {
      width: 100%;
      tbody {
        padding: 5px 0;
        tr td{
          width: 650px;
        }
        tr:hover {
          background-color: #ffd4a6;
          cursor: pointer;
        }
        .btn_go {
          padding: 5px 10px;
          background-color: #4593f5;
          border-radius: 4px;
          color: white;
        }
      }
    }
  }
}
