@import "src/include";

#ConsortiumDetail{
	.pc{
		.consortiumDetail{
			width: 880px;
			margin: 0 auto;
			padding: 85px 0 0;
			&_title-wrap{
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #e7e7e7;
				margin-bottom: 50px;
				padding-bottom: 7px;
			}
			&_title{
				font-family: NanumSquareOTF_acB;
				font-size: 22px;
				color: #343434;
			}
			&_btnTop-wrap{
				display: flex;
				justify-content: center;
				align-items: center;
			}
			&_btn-modify{
				display: inline-block;
				width: 64px;
				height: 32px;
				line-height: 32px;
				text-align: center;
				color: #fff;
				background-color: #96c719;
				border-radius: 4px;
				margin-right: 8px;
				font-size: 16px;
			}
			&_btn-del{
				display: inline-block;
				width: 120px;
				height: 32px;
				line-height: 32px;
				text-align: center;
				color: #fff;
				background-color: var(--color-Alizarin-Crimson);
				border-radius: 4px;
				font-size: 16px;
			}
			&_info{
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 30px;
				&_img{
					width: 283px;
					height: 230px;
					img{
						display: block;
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
				&_table{
					width: calc(100% - 283px);
					height: 230px;
					padding-left: 12px;
					.list_table{
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-start;
						align-items: center;
						&_title{
							display: flex;
							justify-content: flex-start;
							align-items: center;
							width: 88px;
							height: 46px;
							padding: 8px 0;
							border-bottom: 1px solid #e7e7e7;
							font-family: NanumSquareOTF_acB;
							font-size: 14px;
							color: #343434;
						}
						&_info{
							display: flex;
							justify-content: flex-start;
							align-items: center;
							height: 46px;
							padding: 8px 10px;
							border-bottom: 1px solid #e7e7e7;
							font-size: 14px;
							color: rgba(0, 0, 0, 0.87);
								.style-ellipsis{
									@include text-ellipsis(2);
								}
							&.col-1-1{
								width: calc(100% - 88px);
							}
							&.col-1-2{
								width: calc((100% - 176px) / 2);
							}
						}
					}
				}
			}
			&_textarea{
				&_title-wrap{
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 10px;
				}
				&_title{
					font-family: NanumSquareOTF_acB;
					font-size: 14px;
					color: #343434;
				}
				.list_table{
					display: flex;
					justify-content: space-between;
					align-items: center;
					&_title{
						margin-right: 10px;
						font-family: NanumSquareOTF_acB;
						font-size: 14px;
						color: #343434;
					}
					&_info{
						display: flex;
						justify-content: space-between;
						align-items: center;
						cursor: pointer;
						.icon_download{
							display: inline-block;
							width: 24px;
							height: 24px;
							background-size: cover;
						}
					}
				}
				&_content{
					width: 100%;
					height: 300px;
					resize: none;
					padding: 15px;
					margin-bottom: 60px;
					font-size: 14px;
					color: #343434;
					border: .5px solid #d8d8d8;
				}
			}
			&_btn-wrap{
				margin: 0 auto;
				text-align: center;
			}
			&_btn{
				display: inline-block;
				width: 120px;
				height: 32px;
				line-height: 32px;
				margin: 0 auto;
				background-color: #05ac9e;
				color: #fff;
				text-align: center;
				border-radius: 4px;
			}
		}
	}
	.mo{
		.consortiumDetail{
			background-color: #f0f0f0;
			padding: 85px 20px 40px;
			&_box{
				border-radius: 8px;
				background-color: #fff;
			}
			&_title{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				height: 40px;
				.list_table{
					display: flex;
					justify-content: flex-start;
					align-items: center;
					width: calc(100% - 40px);
					height: 100%;
					margin : 0 20px;
					border-bottom: 1px solid #d8d8d8;
					&_title{
						font-family: NanumSquareOTF_acEB;
						font-size: 12px;
						color: #606060;
						width: 56px;
					}
					&_info{
						font-family: NanumSquareOTF_acEB;
						font-size: 14px;
						color: #1d334e;
						width: calc(100% - 56px);
						&_viewLogo{
							width: 110px;
						}
					}
				}
			}
			&_info{
				position: relative;
				.list_table{
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-wrap: wrap;
					width: calc(100% - 40px);
					margin: 0 20px;
					padding: 11.5px 0 16.5px;
					border-bottom: 1px solid #d8d8d8;
					&_title{
						width: 90px;
						height: 25px;
						line-height: 25px;
						font-family: NanumSquareOTF_acB;
						font-size: 12px;
						color: #959595;
					}
					&_info{
						width: calc(100% - 90px);
						height: 25px;
						line-height: 25px;
						font-family: NanumSquareOTF_acB;
						font-size: 12px;
						color: #606060;
					}
				}
				&_viewLogo{
					position: absolute;
					display: flex;
					justify-content: space-between;
					align-items: center;
					top: 8.5px;
					right: 20px;
					width: 100px;
					height: 24px;
					font-family: NanumSquareOTF_acB;
					font-size: 12px;
					color: #959595;
					&_btn{
						display: block;
						width: 24px;
						height: 24px;
						text-indent: -9999px;
						background: url(../../../assets/images/common/icon-download_mobile.svg) no-repeat 50% 50%;
						background-size: cover;
					}
				}
			}
			&_subInfo{
				.list_table{
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					width: calc(100% - 40px);
					flex-wrap: wrap;
					margin: 0 auto;
					padding: 11.5px 0 16.5px;
					border-bottom: 1px solid #d8d8d8;
					&_title{
						width: 90px;
						min-height: 25px;
						line-height: 18px;
						font-family: NanumSquareOTF_acB;
						font-size: 12px;
						color: #959595;
					}
					&_info{
						width: calc(100% - 90px);
						min-height: 25px;
						line-height: 18px;
						font-family: NanumSquareOTF_acB;
						font-size: 12px;
						color: #606060;
					}
				}
			}
			&_text{
				position: relative;
				width: calc(100% - 40px);
				padding: 11.5px 0 26px;
				margin: 0 auto;
				word-break: break-all;
				&_title{
					font-family: NanumSquareOTF_acEB;
					font-size: 12px;
					color: #606060;
					margin-bottom: 14px;
				}
				&_info{
					font-family: NanumSquareOTF_acB;
					font-size: 12px;
					line-height: 18px;
					color: #959595;
				}
				&_btn{
					position: absolute;
					display: flex;
					justify-content: space-between;
					align-items: center;

					top: 8.5px;
					right: 0;
					width: 100px;
					height: 24px;
					font-family: NanumSquareOTF_acB;
					font-size: 12px;
					color: #959595;
					&_intro{
						display: block;
						width: 24px;
						height: 24px;
						text-indent: -9999px;
						background: url(../../../assets/images/common/icon-download_mobile.svg) no-repeat 50% 50%;
						background-size: cover;
					}
				}
			}
		}
	}
}
