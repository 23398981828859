$blueColor: #4593f5;

.select-div {
  position: relative;

  & > button {
    background-color: $blueColor;
    color: white;
    padding: 10px 15px;
    border-radius: 8px;
    min-width: 60px;
  }

  &:has(i) {
    display: flex;
    align-items: center;

    i {
      margin-left: -12px;
      transition: all 150ms;
    }
  }

  &:first-child button {
    width: 110px;
  }

  & > ul {
    position: absolute;
    width: 80%;
    top: 100%;
    left: 0;
    color: $blueColor;
    max-height: 400px;
    overflow-y: auto;
    font-size: 1.3rem;

    li {
      padding: 6px;
      border: 1px solid $blueColor;
      text-align: center;
      background-color: white;
      cursor: pointer;

      &:hover {
        background-color: $blueColor;
        color: white;
      }
    }
  }
}
