#MarketInfoPopupCart {
    @import "../../../include";
    padding: 22px 55px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1010px;
    min-height: 460px;
    border: 1px solid #f0f0f0;
    background-color: #ffffff;
    z-index: 2;

    .header {
      padding-bottom: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e7e7e7;

      h2 {
          font-size: 22px;
          font-family: NanumSquareOTF_acB;
          color: #343434;
      }
    }

    .body_head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
          font-size: 14px;
          font-family: NanumSquareOTF_acEB;
          color: #343434;
      }

      .upload {
          display: flex;
          align-items: center;
          column-gap: 10px;

          p {
              display: flex;
              align-items: center;
              column-gap: 5px;
              font-size: 12px;
              color: #606060;
          }

          button {
              width: 132px;
              height: 32px;
              font-size: 14px;
              font-family: NanumSquareOTF_acB;
              color: #ffffff;
              border-radius: 4px;
              background-color: #1bb3f3;
          }
      }
    }

    .list {
         padding: 18px 0 24px 0;
         display: flex;
         align-items: center;
         column-gap: 10px;
         border-bottom: 1px solid #e7e7e7;
         overflow-x: scroll;
         overflow-y: hidden;

        li {
            padding: 0 4px;
            width: 160px;
            height: 191px;
            border: 1px solid #e6e6e6;

            img {
                width: 152px;
                height: 110px;
                object-fit: contain;
            }

            h2 {
              height: 24px;
              font-size: 14px;
              font-family: NanumSquareOTF_acB;
              line-height: 24px;
              color: #343434;
              @include text-ellipsis(1);
            }

            .info {
              display: flex;
              justify-content: space-between;
              align-items: center;

              p {
                display: flex;
                justify-content: center;
                align-content: center;
                column-gap: 5px;
                cursor: pointer;
              }
            }

            p {
              height: 24px;
                font-size: 12px;
                font-family: NanumSquareOTF_acB;
                line-height: 24px;
                color: #606060;
                text-align: right;
            }
        }

        .checkbox {
            justify-content: left;
        }
    }

    .btn_select_success {
        margin: 24px auto 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 132px;
        height: 32px;
        font-size: 16px;
        color: #ffffff;
        border-radius: 4px;
        background-color: #4593f5;
    }
}