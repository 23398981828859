@import "src/include";

#CustomerService {

    @media screen and (max-width: 650px){
        padding-top: 60px;
    }

    .intro {
        span {
            font-family: NanumSquareOTF_acEB;
            color: #05ac9e;
        }
    }

    .pc {
        padding-top: 62px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .intro {
            margin: 0 auto 39.5px auto;
            padding-bottom: 29.5px;
            width: 880px;
            text-align: center;
            color: #343434;
            p:nth-child(1) {
                font-size: 35px;
                font-family: NanumSquareOTF_acR;
                span {
                    color: #1d334e;
                }
            }

            p:nth-child(2) {
                font-size: 16px;
                font-family: NanumSquareOTF_acB;
                color: #1d334e;
                margin-top: 40px;
            }
            .contact_info {
                width: 900px;
                height: 120px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                box-shadow: 0 2px 3px 1px #d8d8d8;
                border-radius: 5px;
                margin-top: 26.5px;
                div {
                    height: 50%;
                    width: 100%;
                }
                .contact_text {
                    text-align: left;
                    padding: 21px 0 0 30px;
                    font-size: 18px;
                    span {
                        color: #343434;
                        font-family: NanumSquareOTF_acB;
                    }
                    img {
                        padding-left: 5px;
                    }
                }
                .contact_text_info {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    p:nth-child(1) {
                        font-size: 18px;
                        color: #343434;
                        padding: 0 0 17px 30px;
                        font-family: NanumSquareOTF_acEB;
                        span {
                            font-size: 40px;
                            letter-spacing: -2px;
                            font-family: NotoSansKR-Bold;
                        }
                    }
                    p:nth-child(2) {
                        font-size: 14px;
                        color: #959595;
                        padding: 0 32px 17px 0;
                        font-family: NanumSquareOTF_acR;
                    }
                }
            }
        }
        .contact_us {
            width: 1280px;
            height: 650px;
            background-color: #fcfcfc;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .contact_us_text {
                font-family: NanumSquareOTF_acR;
                font-size: 30px;
                letter-spacing: -1px;
                color: #606060;
                margin: 21px 0 40px 0;

            }
        }
        .contact_request {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 950px;
            height: 500px;
            background-color: #ffffff;
            border-radius: 5px;
            box-shadow: 0 2px 4px 0 #f0f0f0;
            border: solid 1px #f0f0f0;
            margin-bottom: 49px;
        }
        .contact_request_left {
            width: 50%;
            height: 100%;
            margin-top: 5px;
            .column {
                margin: 25px 0 0 18px;
                width: 90%;
                height: 60px;
                font-size: 14px;
                color: #343434;
                font-family: NanumSquareOTF_acB;
                input[type="text"] {
                    border: none;
                    border-bottom: 1px solid #f0f0f0;
                    margin: 12px 0 9px 0;
                    width: 100%;
                }
            }
            .radio_btn {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 105px;
                label > span {
                    padding-left: 4px;
                }
            }
        }
        .contact_request_right {
            width: 50%;
            height: 100%;
            .column {
                padding-top: 28px;
                h2 {
                    font-family: NanumSquareOTF_acB;
                    font-size: 14px;
                    letter-spacing: -0.25px;
                    margin-bottom: 5px;
                }
                textarea {
                    width: 450px;
                    height: 370px;
                    padding-top: 5px;
                    border: 1px solid #f0f0f0;
                    resize: none;
                }
            }
            button {
                width: 450px;
                height: 50px;
                margin-top: 12px;
                color: white;
                font-size: 16px;
                font-family: NanumSquareOTF_acB;
                border-radius: 5px;
            }
        }
        .checkbox {
            margin: 7px 0 12px 0;
            justify-content: flex-end;
        }

        .tab {
            padding-top: 16px;
            width: 284px;
            border-radius: 5px;
            box-shadow: 0 2px 3px 0 rgb(0 0 0 / 20%);
            background-color: #ffffff;
        }
    }

    .mo {
        .mo_banner {
            width: 100%;
            height: 100px;
            background-image: url("../../../assets/images/common/mobile-banner.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% 100px;
        }
        .intro {
            text-align: center;

            h2 {
                margin-top: 15px;
                font-size: 24px;
                font-family: NanumSquareOTF_acEB;
                color: #343434;
                span {
                    color: #1d334e;
                }
            }
            p {
                font-size: 13px;
                line-height: 20px;
                font-family: NanumSquareOTF_acB;
                color: #1d334e;
                margin-top: 15px;
                margin-bottom: 10px;
            }
        }

        .form_wrapper {
            background-color: rgba(240, 240, 240, 0.2);
            width: 100%;
            padding: 9px 10px 14px 10px;
        }
        .form {
            background: #fff;
            border-radius: 15px;
            color: #343434;
            height: 845px;
            h2 {
                text-align: center;
                padding-top: 11px;
                padding-bottom: 20px;
                font-family: NanumSquareOTF_acB;
                font-size: 22px;
            }
            .field {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;
                width: 90%;
                height: 60px;
                border-bottom: 1px solid #d8d8d8;
                p {
                    margin-bottom: 10px;
                    font-size: 14px;
                    line-height: 18px;
                    font-family: NanumSquareOTF_acEB;
                    color: #343434;
                }

                input {
                    width: 100%;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    line-height: 20px;
                    border: none;
                }
                input[type="text"] {
                    padding: inherit;
                    height: 24px;
                }
                textarea {
                    padding: 5px 0;
                    width: 100%;
                    height: 230px;
                    resize: none;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;
                    border: 1px solid #d8d8d8;
                }
            }

            .field+.field {
                margin-top: 15px;
            }
            .textAreaField {
                height: 255px;
            }
            .checkbox {
                display: flex;
                font-size: 14px;
                font-family: NanumSquareOTF_acB;

            }
            .btn_send {
                margin: 16px auto 28px auto;
                display: block;
                width: 90%;
                height: 42px;
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
                color: #ffffff;
                border-radius: 4px;
            }
        }

        .contact_phone_mo {
            width: 100%;
            height: 120px;
            margin: auto;
            border-top: solid 1px #f0f0f0;
            .contact_text {
                text-align: left;
                padding: 21px 0 0 30px;
                font-size: 16px;
                span {
                    color: #343434;
                    font-family: NanumSquareOTF_acB;
                }
                img {
                    padding-left: 5px;
                }
            }
            .contact_text_info {
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                padding-bottom: 15px;
                .contact_text_info_left {
                    display: flex;
                    align-items: center;
                    p {
                        font-size: 18px;
                        color: #343434;
                        font-family: NanumSquareOTF_acEB;
                    };
                }
                .contact_text_info_right {
                    span {
                        font-size: 33px;
                        font-family: NotoSansKR;
                        font-weight: bold;
                    }
                    p {
                        font-family: NanumSquareOTF_acR;
                        font-size: 14px;
                        color: #959595;
                    }
                }
            }
        }
        .contact_info {
            margin: 14px auto 0 auto;
            width: 100%;
            height: 120px;
            background-color: #ffffff;
            border-radius: 5px;
            box-shadow: 0 2px 3px 1px #d8d8d8;
            padding: 21px 21px 27px;
            .contact_text {
                font-family: NanumSquareOTF_acB;
                font-size: 18px;
                color: #343434;
                img {
                    padding-left: 6px;
                }
            }
            .contact_text_info {
                padding-top: 10px;
                p > span{
                    font-family: NotoSansKR;
                    font-size: 33px;
                    font-weight: bold;
                };
            }
        }
    }
}
