#MExpectationAnnual {
    position: relative;
    background-color: #ffffff;
    z-index: 3;

  .header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;

        button {
            position: absolute;
            left: 20px;
            background-size: contain;
        }

        h2 {
            font-size: 16px;
            font-family: NanumSquareOTF_acEB;
            line-height: 22px;
            color: #443018;
        }
    }

  .wrap {
      margin: 21px auto;
      width: 90%;

      .w_title {
          margin-bottom: 21px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          h2 {
              font-size: 18px;
              font-family: NanumSquareOTF_acEB;
              line-height: 24px;
              color: #443018;
          }

          button {
              width: 129px;
              height: 26px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              font-family: NanumSquareOTF_acB;
              line-height: 18px;
              color: #959595;
              border-radius: 13px;
              border: solid 2px #d8d8d8;
              background-color: #ffffff;

              i {
                  margin-right: 4px;
                  width: 18px;
                  height: 18px;
                  background-size: contain;
              }
          }
      }
  }

    .setting {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        pointer-events: none;

        .dimmed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            opacity: 0.3;
            background-color: #443018;
            pointer-events: auto;
        }

        .menu {
            position: relative;
            top: 0;
            left: 100vw;
            min-width: 270px;
            width: 75vw;
            height: 100vh;
            overflow-y: scroll;
            background-color: #ffffff;
            z-index: 2;
            transition: 1s;
            pointer-events: auto;

            h2 {
                padding-left: 24px;
            }

            .btn {
                margin: auto;
                display: block;
                width: 48px;
                height: 24px;
                font-size: 12px;
                font-family: NanumSquareOTF_acEB;
                line-height: 22px;
                color: #ffffff;
                border-radius: 21px;
                background-color: var(--color-Web-Orange);
            }

            .m_head {
                position: relative;
                .title {
                    height: 56px;
                    font-size: 16px;
                    font-family: NanumSquareOTF_acEB;
                    line-height: 56px;
                    color: #443018;
                }

                button {
                    position: absolute;
                    top: 16px;
                    right: 20px;
                }
            }

            .list {

                .l_head {
                    padding-right: 15px;
                    height: 39.5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #d8d8d8;
                    background-color: #f0f0f0;

                  .l_name {
                        font-size: 14px;
                        font-family: NanumSquareOTF_acEB;
                        color: #606060;
                    }
                }

                ul {
                    .item {
                        padding: 9.5px 20px 9.5px 0;
                        min-height: 39.5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid #d8d8d8;

                        h2 {
                            font-size: 14px;
                            font-family: NanumSquareOTF_acB;
                            color: #747474;
                        }
                    }
                }
            }
        }
    }

    .menu_animation {
        left: 25vw !important;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    .checkbox {
        .checkmark {
            margin-right: 0;
            border: 2px solid #d8d8d8;
            border-radius: 4px;
            background-color: #ffffff;
        }

        input:checked ~ .checkmark {
            border: none;
            background-color: var(--color-Web-Orange) !important;
        }
    }

}
