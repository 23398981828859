#MailingForm {
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  min-height: 600px;
  background-color: #fff;
  z-index: 7;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);

  header {
    background-color: #00c6be;
    color: #fff;
    text-align: center;
    padding: 14px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    strong {
      font-size: 24px;
    }

    i {
      position: absolute;
      right: 14px;
      top: 14px;
    }
  }

  footer {
    border-top: none;
    text-align: center;
    padding-bottom: 30px;

    button {
      color: #fff;
      background-color: #00c6be;
      font-size: 20px;
      padding: 8px 16px;
      border-radius: 10px;
    }
  }

  .box {
    padding: 30px;
  }

  table {
    margin-bottom: 10px;

    tr {
      height: 30px;
    }

    th,
    td {
      text-align: left;
    }

    th {
      color: #707070;
    }

    input[type="text"] {
      width: 100%;
      border: none;
      height: 30px;
    }

    input[type="radio"].lm {
      margin-left: 10px;
    }

    .title {
      font-size: 24px;
      color: #000;
      padding: 0;
      margin-bottom: 10px;
    }

    .address_str {
      display: inline-block;
      width: auto;
      height: 18px;
      border: 1px solid #d9d9d9;
      background-color: #ededed;
      border-radius: 3px;
      padding: 0 5px;

      >span {
        font-size: 14px;
        vertical-align: top;
      }

      >i {
        width: 16px;
        height: 18px;
        background-size: contain;
      }
    }
  }

  .cont {
    border-top: 1px solid #acacac;
    padding-top: 10px;
    textarea {
      width: 100%;
      height: 100px;
    }
    .patent_info {
      >p {
        padding-bottom: 10px;
      }
    }
  }

  input[type="number"] {
    line-height: 28px;
    border: 1px solid #c1c1c1;
    width: 50px;
    padding: 0 4px;
    margin-right: 4px;
  }

  .icon_pdf {
    width: 34px;
    height: 34px;
    cursor: pointer;
  }
}
