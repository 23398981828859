#CyberMenuBar {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    width: 100%;
    height: 61px;
    box-shadow: 0 -1px 5px -1px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    z-index: 2;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 2px;

        span {
            font-size: 11px;
            font-family: NanumSquareOTF_acB;
        }
    }
}