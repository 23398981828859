#MypageCandidatePop {
  z-index: 999;
  width: 1000px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  border-radius: 30px;
  box-shadow: 0 2px 4px 0 #d8d8d8;
  #pagination {
    .item {
      &.end,
      &.start {
        width: 36px;
      }
      &:hover {
        background-color: #4e7ce9;
        border-radius: 100%;
      }
      .link {
        border: none;
        background-color: transparent;
      }
      &.on {
        .link {
          background-color: #4e7ce9;
          border-radius: 100%;
        }
      }
    }
  }

  button {
    background-color: transparent;
  }

  strong {
    display: block;
    margin-bottom: 15px;
  }
  .close {
    position: absolute;
    right: 30px;
    top: 30px;
    background: transparent;
  }
  .more {
    background: transparent;
    color: #0078f1;
    border: 1px solid #0078f1;
    padding: 4px 12px;
    border-radius: 20px;
  }
  .tbl_box {
    max-height: none;
    // tbody {
    //   > tr {
    //     &:hover {
    //       cursor: pointer;
    //       background-color: rgba(107, 211, 255, 0.2);
    //     }
    //   }
    // }
  }
  .grade {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 22px;
    border-radius: 8px;
  }
}
