.ContentLayout {
  border-bottom: 2px solid #ededed;
  padding-bottom: 20px;
  position: relative;

  .header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  & + .ContentLayout {
    margin-top: 30px;
  }
  &.border_none {
    border-bottom: none;
    padding: 20px;
  }
  .table_layout {
    display: flex;
    align-items: center;
    gap: 20px;
    > div {
      flex: none;
      width: 40%;
      &.korean {
        width: 30%;
      }
    }
  }
  .layout_tit {
    display: block;
    font-size: 18px;
  }
  .title_button {
    padding: 7px 14px;
    border-radius: 20px;
    background: #4e7ce9 !important;
    color: #fff;
    font-size: 14px;
  }
}
