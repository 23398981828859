#MPatentListView {
    position: relative;
    top: -60px;
    background-color: #ffffff;
    z-index: 3;

    @media screen and (max-width: 850px) {
        padding-top: 60px;
    }

    .header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;

        button {
            position: absolute;
            left: 20px;
            background-size: contain;
        }

        h2 {
            font-size: 16px;
            font-family: NanumSquareOTF_acEB;
            line-height: 22px;
            color: #443018;
        }
    }

    .wrap_search {
        margin: 21px auto 16px auto;
        width: 90%;
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: solid 2px #d8d8d8;

        select {
            padding-left: 12px;
            width: 30%;
            font-size: 12px;
            font-family: NanumSquareOTF_acEB;
            color: #606060;
        }

        select:focus {
            outline: none;
        }

        input {
            margin: 0 8px;
            width: calc(70% - 8px - 24px - 16px);
            font-size: 14px;
            color: #606060;
            border: none;
        }

        input::placeholder {
            color: #959595;
        }
    }

    .table-box-wrap {
        width: 90%;
        margin: 0 auto 24px;

        .wrap_list {
            height: 329px;
        }
    }

    table {
        min-height: 135px;

        thead {
            tr {
                th {
                    font-size: 12px;
                    font-family: NanumSquareOTF_acB;
                    color: #606060;
                }

                th:first-child {
                    width: 15%;
                }
                th:nth-child(2) {
                    width: 15%;
                }
            }
        }

        tbody {
            tr {
                td:first-child {
                    width: 15%;
                }

                td:nth-child(2) {
                    width: 15%;
                }

                td:nth-child(3) {
                    font-family: NanumSquareOTF_acB;
                    color: #606060;
                    text-decoration: underline;
                }

                td:nth-child(4) {
                    color: #747474;
                }
            }

            tr {
                td {
                    font-size: 12px;
                }
            }
        }
    }

    .circle {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 24px;
        border-radius: 16px;
        pointer-events: none;
    }


    .wrap_how {
        position: relative;
        bottom: -60px;
        background-color: #ffffff;

        img {
            width: 100%;
        }

        .btn {
            position: absolute;
            left: 50%;
            bottom: 10%;
            transform: translateX(-50%);
            width: 137px;
            line-height: 32px;
            height: 32px;
            font-size: 12px;
            font-family: NanumSquareOTF_acEB;
            color: #ffffff;
            border-radius: 16px;
            background-image: linear-gradient(to top, #ff7a00, var(--color-Web-Orange));
            cursor: pointer;
        }
    }
}
