.btn_add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    font-size: 14px;
    color: white;
    background-color: #4593f5;
}

.btns {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
}

.btn_prev {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 93px;
    height: 45px;
    font-size: 16px;
    font-family: NanumSquareOTF_acEB;
    color: #606060 !important;
    border-radius: 4px;
    border: 1px solid #959595;
    background-color: #ffffff;
}

.btn_next_step {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 93px;
    height: 45px;
    font-size: 16px;
    font-family: NanumSquareOTF_acEB;
    color: #ffffff !important;
    border-radius: 4px;
    background-color: #e92f2c;
}

.btn_no {
    background-color: darkgray !important;
    pointer-events: none;
}

@media screen and (max-width: 850px) {
    .btn {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 42px;
        font-size: 16px;
        font-family: NanumSquareOTF_acEB;
        line-height: 22px;
        color: #ffffff !important;
        border: none !important;
        border-radius: 21px;
        background-color: var(--color-Web-Orange);
    }

    .btn_prev {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: NanumSquareOTF_acEB;
        color: #606060 !important;
        border: 1px solid #959595 !important;
        background-color: #ffffff !important;
    }
}
