
.sub_title_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}

.sub_title {
  font-family: NanumSquareOTF_acEB;
  font-size: 18px;
  color: #343434;
}

.check_text {
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
  font-family: NanumSquareOTF_acB;
  font-size: 12px;
  color: #4593f5;
  margin-right: 16px;
}

.small_title {
  font-family: NanumSquareOTF_acB;
  font-size: 14px;
  line-height: 1.43;
  color: #343434;
}

.rnd_plan {
  margin-top: 20px;
  .margin_10 {
    margin-top: 10px;
  }
  h2 {
    font-size: 22px;
    color: #4593F5;
    padding-top: 20px;
  }
}

.plan_text {
  display: flex;
  padding: 20px 0 13px 0;
  align-items: center;
  justify-content: space-between;
  h2 {
    padding-top: inherit;
  }
  span {
    text-align: right;
    font-family: NanumSquareOTF_acB;
    font-size: 12px;
    color: #606060;
    margin-right: 15px;
  }
  div {
    display: flex;
    align-items: center;
  }
}

.rnd_textarea_wrapper {
  display: flex;
  position: relative;
  .rnd_icon {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 20px;
    #BringingUpIcon {
      margin: inherit;
    }
    .info_icon {
      margin: 5px 0 5px 0;
    }
    div {
      margin-top: 5px;
    }
  }
  textarea {
    width: 858px;
    height: 277px;
  }
}
