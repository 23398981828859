@import "src/include";

#PopupTaskDetail {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 0 18px 0;
    width: 880px;
    max-height: 90vh;
    @include border-radius(5px);
    border: solid 1px #e7e7e7;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    z-index: 10000;
    overflow-y: scroll;

    .title_area {
        padding: 22px 10px 8px 18px;
        position: sticky;
        top: 0;
        background-color: #ffffff;
        border-bottom: solid 1px #e7e7e7;
        z-index: 2;

        .post_title {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;

            h2 {
                font-size: 22px;
                font-family: NanumSquareOTF_acB;
                line-height: 24px;
            }

            .title {
                width: 745px;
            }

        }

        .title_state_inner {
            .desc {
                display: flex;
                align-items: center;
                width: 745px;
            }
        }
    }

    .detail_area {
        margin: 0 18px;
        padding: 25px 0;
        border-bottom: solid 1px #e7e7e7;

        .wrap_data {
            display: flex;
            flex-direction: column;
            row-gap: 18px;

            .datas {
                display: flex;
                column-gap: 15px;

                .data {
                    display: flex;
                    column-gap: 20px;
                    width: 33%;
                    font-size: 14px;
                    line-height: 30px;

                    h2 {
                        min-width: 50px;
                        font-family: NanumSquareOTF_acB;
                    }

                    p {
                        word-break: keep-all;
                    }

                    button {
                        width: 140px;
                        height: 32px;
                        font-size: 14px;
                        font-family: NanumSquareOTF_acB;
                        color: #ffffff;
                        @include border-radius(4px);
                    }

                    .btn_go {
                        background-color: var(--color-Web-Orange);
                    }

                    .btn_add {
                        background-color: #4593f5;
                    }
                }

                .data.files {
                    .inner {
                        p {
                            display: flex;
                            column-gap: 5px;
                            cursor: pointer;
                            font-size: 12px;
                            line-height: 24px;
                        }
                    }
                }

                .data.rowspan2 {
                    width: 68%;
                }

                .data.rowspan3 {
                    width: 100%;
                }
            }
        }
    }

    .content_area {
        padding: 24px 18px;
        position: relative;
        font-size: 14px;

        button {
            margin-left: auto;
            margin-bottom: 20px;
            display: block;
            width: 140px;
            height: 32px;
            font-size: 14px;
            font-family: NanumSquareOTF_acEB;
            color: #ffffff;
            @include border-radius(4px);
            background-color: #05ac9e;
        }

        h2 {
            margin-bottom: 10px;
            font-family: NanumSquareOTF_acB;
        }

        .inner {
            .title {
                padding-left: 10px;
                display: flex;
                align-items: center;
                column-gap: 8px;
                height: 32px;
                font-size: 14px;
                font-family: NanumSquareOTF_acB;
                color: #343434;
                background-color: rgba(216, 216, 216, 0.5);
            }
        }

        .content {
            padding: 10px 52px 60px 38px;
            line-height: 25px;
        }

        .content * {
            font-size: 13px !important;
        }
    }

    .btn_close {
        margin: auto;
        display: block;
        width: 132px;
        height: 32px;
        font-size: 16px;
        color: #ffffff;
        background-color: #e92f2c;
    }
}
