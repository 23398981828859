// Spinner
$spinner-size: 100px;
$spinner-color: #96c719;
$spinner-border-size: 5px;

.spinner,
.spinner:after {
    position: relative;
    box-sizing: border-box;
}

.spinner {
    width: $spinner-size;
    height: $spinner-size;
    display: block;
    color: $spinner-color;

    &:after {
        content: "";
        width: 100%;
        height: 100%;
        display: inline-block;
        border: $spinner-border-size solid currentColor;
        border-bottom-color: transparent;
        @include border-radius(100%);
        background: transparent;
        animation: ball-clip-rotate .75s linear infinite;
    }
}

@keyframes ball-clip-rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}