@import "src/include";
#PopupMobileTaskDetail{
	position: fixed;
	top: 0;
	padding-top: 60px;
	width: 100vw;
	height: 100vh;
	background-color: #f0f0f0;
	overflow: scroll;
	z-index: 1;

	.taskDetail{
		padding-top: 18px;
		padding-bottom: 12px;
		&_box{
			width: calc(100% - 40px);
			padding: 12px 16px;
			margin: 0 auto 20px;
			background-color: #fff;
			border-radius: 8px;
		}
		.list_table{
			border-bottom: 1px solid #d8d8d8;
			&:last-of-type{
				border-bottom: none;
			}
			&_title{
				width: 50%;
				line-height: 18px;
				font-family: NanumSquareOTF_acB;
				font-size: 12px;
				color: #959595;
				margin-bottom: 8px;
				&:last-of-type{
					margin-bottom: 0;
				}
			}
			&_info{
				width: 50%;
				line-height: 18px;
				font-family: NanumSquareOTF_acB;
				font-size: 12px;
				color: #606060;
				margin-bottom: 8px;
				&:last-of-type{
					margin-bottom: 0;
				}
			}
			&.header{
				display: flex;
				justify-content: center;
				align-items: flex-start;
				flex-wrap: wrap;
				padding-bottom: 11.5px;
				.list_table_title{
					width: 50px;
					font-family: NanumSquareOTF_acEB;
					font-size: 12px;
					color: #606060;
				}
				.list_table_info{
					width: calc(100% - 50px);
					padding-left: 5px;
				}
			}
			&.info{
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				padding: 11.5px 0;
				.list_table_title{
					&.col-1-4{
						width: 50px;
					}
				}
				.list_table_info{
					&.col-1-4{
						width: calc(50% - 50px);
						padding: 0 5px;
					}
				}
			}
			&.subinfo{
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				padding: 11.5px 0;
			}
			&.reference{
				position: relative;
				.list_table_title{
					width: 100%;
					padding-top: 14.5px;
					font-family: NanumSquareOTF_acEB;
					font-size: 12px;
					color: #606060;
					margin-bottom: 13px;
				}
				.list_table_info{
					width: 100%;
				}
				.btnCopy{
					position: absolute;
					text-indent: -9999px;
					width: 24px;
					height: 24px;
					top: 11.5px;
					right: 0;
					background: url(../../../assets/images/common/icon-download_mobile.svg) no-repeat 50% 50%;
					background-size: cover;
				}
			}
		}
		.style{
			&_title{
				font-family: NanumSquareOTF_acEB !important;
				font-size: 14px !important;
				color: #1d334e !important;
			}
			&_table{
				.list_table_title{
					width: 100%;
					font-family: NanumSquareOTF_acEB;
					font-size: 12px;
					color: #606060;
					margin: 11.5px 0 12px;
				}
				.list_table_info{
					padding: 0;
					width: 100%;
					line-height: 18px;
					letter-spacing: normal;
					word-spacing: normal;
					font-family: NanumSquareOTF_acB;
					font-size: 12px;
					color: #959595;
					margin-bottom: 11.5px;
					overflow: scroll;

					* {
						font-size: 13px !important;
					}
				}
			}
		}
		.btnNotice{
			display: flex;
			justify-content: center;
			align-items: center;
			width: calc(100% - 40px);
			height: 40px;
			margin: 0 auto 20px;
			border-radius: 4px;
			background-color: #81cae9;
			font-family: NanumSquareOTF_acEB;
			font-size: 16px;
			color: #fff;
		}
	}
}