#MyPageFindbizAccountPw {
  max-width: 800px;

  .head {
    margin-bottom: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;

    h2 {
      font-size: 22px;
      font-family: NanumSquareOTF_acB;
      line-height: 32px;
      color: var(--color-Mine_Shaft);
    }
  }

  .account_area {
    display: flex;
    flex-wrap: wrap;
    column-gap: 70px;
    row-gap: 25px;

    .wrap_info {
      width: calc(50% - 35px);

      label {
        margin-bottom: 5px;
        font-size: 14px;
        font-family: NanumSquareOTF_acB;
        color: var(--color-Mine_Shaft);
      }

      .info {
        padding: 5px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--color-Gallery);

        input {
          width: 100%;
          font-size: 14px;
          line-height: 25px;
          color: var(--color-Mine_Shaft);
          border: none;
        }
      }

      .notify {
        font-size: 12px;
        font-family: NanumSquareOTF_acB;
        line-height: 25px;
        color: var(--color-Red-opacity-08);

        span {
          color: var(--color-Cornflower-Blue);
        }
      }
    }
  }

  .btn_save {
    margin: 20px auto 0 auto;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    font-size: 14px;
    font-family: NanumSquareOTF_acB;
    color: #ffffff;
    border: 1px solid var(--color-Alto);
    background-color: var(--color-Cornflower-Blue);

    &.btn_disabled {
      background-color: var(--color-Alto);
      pointer-events: none;
    }
  }
}