@import "src/mixin";
@import "src/include";

#IntroTaskList {
    > .tabs {
        display: flex;
        margin-top: 30px;
        column-gap: 15px;

        @include mobile {
            margin: 10px 0;
        }

        button {
            padding: 0 30px;
            display: flex;
            align-items: center;
            column-gap: 15px;
            height: 42px;
            font-size: 16px;
            font-family: NanumSquareOTF_acEB;
            border: 1px solid var(--color-Cornflower-Blue);
            border-radius: 24px;
            background-color: #ffffff;

            @include mobile {
                margin-top: 20px;
                width: 30%;
                font-size: 12px;
                white-space: pre;
                justify-content: center;
            }

            span {
                font-size: 12px;
                color: var(--color-Silver-Chalice);

                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: -8px;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 18px;
                    background-color: var(--color-Alto);
                }
            }

            &.active {
                color: #ffffff;
                background-color:var(--color-Cornflower-Blue);

                span {
                    color: #ffffff;
                }
            }
        }

        .icon{
            padding-left: 40px;
            justify-content: center;
            &::before{
                position: absolute;
                content: '';
                width: 18px;
                height: 18px;
                top: 46%;
                left: 15px;
                transform: translateY(-50%);
                //background: url(../../../assets/images/mobile/icon_alert_circle.svg)no-repeat 50% 50%;
                background-size: contain;
            }
        }
    }

    .task_box_list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 15px;

        @include mobile {
            flex-direction: column;
        }

        @include mobile {
            padding: 0 10px 10px 10px;
            flex-wrap: unset;
            justify-content: unset;
            overflow-x: hidden;
            overflow-y: hidden;
        }

        .task_item {
            padding: 10px;
            width: 550px;
            border-radius: 5px;
            box-shadow: 0 2px 4px 0 var(--color-Gallery);
            border: 1px solid var(--color-Gallery);
            cursor: pointer;

            @include mobile {
                width: 100%;
            }

            .task_head {
                margin-bottom: 5px;
                display: flex;
                align-items: center;
                column-gap: 5px;

                .task_state {
                    min-width: 55px;
                    width: 55px;
                    line-height: 22px;
                    font-size: 12px;
                    font-family: NanumSquareOTF_acEB;
                    color: #ffffff;
                    text-align: center;
                    border-radius: 10px;
                    background-color: var(--color-Web-Orange);
                }

                .task_title {
                    font-size: 16px;
                    font-family: NanumSquareOTF_acB;
                    @include text-ellipsis(1);
                }
            }

            .task_desc {
                margin-bottom: 5px;
                //height: 36px;
                font-size: 12px;
                line-height: 1.5;
                color: #606060;
                @include text-ellipsis(2);
            }

            .day {
                padding: 0 10px;
                display: inline-block;
                max-width: 100px;
                line-height: 21px;
                font-size: 12px;
                font-family: NanumSquareOTF_acEB;
                color: #ffffff;
                text-align: center;
                border-radius: 14px;
                background-color: var(--color-Piction-Blue);
            }

            .task_info_list {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                column-gap: 15px;
                row-gap: 10px;

                .wrap_date {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 10px;
                    row-gap: 10px;

                    &.col {
                        h2 {
                            letter-spacing: 10px;
                        }
                    }

                    @include mobile {
                        &.col {
                            flex-direction: column;
                        }
                    }
                }

                .task_info_item {
                    display: flex;
                    column-gap: 10px;
                    min-width: 130px;
                    font-size: 13px;
                    font-family: NanumSquareOTF_acB;
                    word-break: keep-all;

                    &.br_item {
                        width: 100%;

                        @include mobile {
                            width: auto;
                        }
                    }

                    &.br_item_mo {
                        width: 200px;
                    }

                    h2 {
                        width: 45px;
                        color: var(--color-Scorpion);
                    }

                    p {
                        color: var(--color-Mine_Shaft);
                    }
                }
            }

            .task_add_info {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .task_amount {
                    display: flex;
                    align-items: center;
                    column-gap: 5px;

                    p {
                        font-size: 14px;
                        font-family: NanumSquareOTF_acB;

                        b {
                            font-family: NanumSquareOTF_acEB;
                            color: var(--color-Piction-Blue);
                        }
                    }
                }
            }
        }
    }
    .gov_nav_btn {
        display: flex;
        margin: 20px 80px 0 auto;
        width: 156px;
        height: 40px;
        border-radius: 24px;
        background-image: linear-gradient(to left, #0d7, #0078f1);
        font-size: 16px;
        color: #fff;
        align-items: center;
        justify-content: center;
    }
    .korea_item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 170px;
        border: none !important;
        background-size: cover;
        background-repeat: no-repeat;

        &.type_1 {
            background-image: url("../../../../assets/images/intro/bg-korean-type-1.svg");
        }

        &.type_2 {
            background-image: url("../../../../assets/images/intro/bg-korean-type-2.svg");
        }

        &.type_3 {
            background-image: url("../../../../assets/images/intro/bg-korean-type-3.svg");
        }

        p {
            margin-bottom: 5px;
            font-size: 18px;
            font-family: NanumSquareOTF_acB;
            text-align: center;
        }

        h2 {
            margin-bottom: 10px;
            max-width: 350px;
            font-size: 24px;
            font-family: NanumSquareOTF_acEB;
            color: #1d334e;
            text-align: center;
            word-break: keep-all;
            white-space: pre-wrap;
        }

        button {
            width: 88px;
            height: 30px;
            color: var(--color-Cornflower-Blue);
            border-radius: 14px;
            background-color: var(--color-Malibu-opacity-02);
        }
    }

    .table_title {
        @include mobile {
            padding-left: 8px;
            width: 200px;
        }
    }
}
