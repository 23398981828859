// InventorModal.module.scss

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modalContainer {
  background-color: #fff;
  border-radius: 8px;
  min-width: 400px;
  max-width: 600px;
  padding: 30px 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  font-size: 1.2rem;
  font-weight: bold;

  p {
    position: relative;
    display: inline-block;
    font-size: 12px;
    font-family: NanumSquareOTF_acB;
    color: #606060;
    i {
      position: absolute;
      top: -5px;
      left: -10px;
    }
  }
}

.label {
  position: relative;
  display: block;
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 0.9rem;
  i {
    position: absolute;
    top: -5px;
    left: -10px;
  }
}

.input,
.select {
  width: 100%;
  padding: 8px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
  outline: none;

  &:focus {
    border-color: #0984e3;
    box-shadow: 0 0 5px rgba(9, 132, 227, 0.5);
  }
}

.select {
  margin-bottom: 20px;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  .primaryButton {
    background-color: #0984e3;
    color: #fff;
    border: none;
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;

    &:hover {
      background-color: #74b9ff;
    }
  }

  .secondaryButton {
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;

    &:hover {
      background-color: #f2f2f2;
    }
  }
}
