.react-datepicker {
  .date_header {
    position: relative;
    display: flex;
    justify-content: center;

    .btn_date_prev {
      position: absolute;
      top: 5px;
      left: 10px;
      margin: 0px 5px 0px 0px;
      height: 10px;
      border: 0.45rem solid transparent;
      border-right-color: #ccc;
    }

    .btn_date_next {
      position: absolute;
      top: 5px;
      right: 10px;
      margin: 0px 5px 0px 0px;
      height: 10px;
      border: 0.45rem solid transparent;
      border-left-color: #ccc;
    }
  }

  button:focus {
    outline: none;
  }
}

/* 삭제 버튼 클래스 추가 */
.datepicker-clear-button {
  position: absolute;
  right: 8px;
  top: 50%;
  color: white;
  transform: translateY(-50%);
  border: none;
  width: 16px;
  height: 16px;
  background: var(--color-Alizarin-Crimson);
  border-radius: 50%;
  cursor: pointer;
  padding: 2px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
