.confirm-div {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px 0;
  width: 400px;

  & button {
    color: white;
    padding: 8px;
    border-radius: 8px;
  }

  &.delete-modal {
    &>div {
      display: flex;
      gap: 10px;
      justify-content: center;
      border-radius: 8px;

      &>button {
        &:first-child {
          background-color: #23d300;
        }

        &:last-child {
          background-color: #ff5151;
        }
      }
    }
  }
}

.new-toast {
  position: absolute;
  z-index: 100;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 488px;
  height: 64px;
  padding: 12px 48px;
  gap: 0px;
  border-radius: 8px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;


  & button {
    position: absolute;
    width: 48px;
    height: 40px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #FFFFFF26;
    border-radius: 10px 0 0 10px;
  }

  &>h1 {
    font-size: 1.4rem;
    font-weight: 500;
  }
}