@import "src/include";

#consultComMain{
    width: 1280px;
    margin: 0 auto;
    .consultComMain{
        padding: 40px 0;
        &_box-wrap{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 880px;
            height: 110px;
            margin: 0 auto 45px;
            padding: 10px 15px;
            background-color: #f1f1f1;
            border-radius: 20px;
        }
        &_box{
            width: calc(100% - 123px);
            height: 100%;
            position: relative;
            padding: 20px 0 20px 160px;
            &::after{
                position: absolute;
                content: '';
                width: 63px;
                height: 60px;
                top: 50%;
                left: 46px;
                transform: translateY(-50%);
                background: url(../../../assets/images/consultCom/icon_consultCom_box.svg)no-repeat 50% 50%;
                background-size: cover;
            }
            &_title{
                font-family: NanumSquareOTF_acEB;
                font-size: 18px;
                color: #343434;
                margin-bottom: 10px;
            }
            &_sub{
                font-family: NanumSquareOTF_acB;
                font-size: 14px;
                color: #606060;
            }
            &_btn{
                width: 123px;
                height: 24px;
                text-indent: -9999px;
                background: url(../../../assets/images/common/icon_tutorial.png) no-repeat 50% 50%;
                background-size: cover;
            }
        }
        &_content{
            width: 1060px;
            margin: 0 auto;
            padding: 16px 25px 20px 25px;
            background-color: rgba(240, 240, 240, 0.2);
            &_title{
                font-family: NanumSquareOTF_acB;
                font-size: 22px;
                color: #343434;
                margin-bottom: 35px;
            }
            &_list{
                position: relative;
                display: flex;
                width: 1010px;
                height: 145px;
                justify-content: flex-start;
                align-items: center;
                background-color: #fff;
                margin-bottom: 10px;
                border-radius: 5px;
                border: solid 1px #f0f0f0;
                &:last-of-type{
                    margin-bottom: 0;
                }
                &_img{
                    width: 255px;
                    height: 100%;
                    a{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width : 100%;
                        height: 100%;
                    }
                    img{
                        max-width: 185px;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                &_text{
                    padding: 12px 10px;
                    width: 80%;
                    height: 100%;
                    &_title{
                        font-family: NanumSquareOTF_acB;
                        font-size: 16px;
                        color: #343434;
                        margin-bottom: 10px;
                    }
                    .table_list{
                        display: flex;
                        flex-direction: column;
                        .table_list_field {
                            color: #4593F5;
                            font-family: NanumSquareOTF_acR;
                            font-size: 13px;
                            padding-bottom: 3px;
                        }
                        .table_list_item {
                            display: flex;
                            padding-top: 8px;
                        }
                        .table_list_title {
                            width: 100px;
                            font-family: NanumSquareOTF_acR;
                            font-size: 14px;
                            color: #606060;
                            padding-left: 5px;
                            border-left: 1px solid #f0f0f0;
                        }
                        .table_list_info {
                            font-family: NanumSquareOTF_acB;
                            font-size: 14px;
                            letter-spacing: -0.47px;
                            color: #343434;
                        }
                    }
                    .btnMore{
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 117px;
                        height: 32px;
                        right: 19px;
                        bottom: 13px;
                        font-family: NanumSquareOTF_acB;
                        font-size: 12px;
                        color: #fff;
                        background-color: #4593f5;
                        border-radius: 4px;
                    }
                }
            }
            &_pagination-wrap{
                margin-top: 68px;
            }
        }

        @media screen and (max-width: 850px) {
            margin: auto;
            padding-top: 80px;
            width: 90vw;

            &_content_title {
                margin-bottom: 20px;
            }

            &_content_list {
                height: auto;
            }

            &_content_list_text {
                padding: 10px;
                width: 100%;
            }
            &_content_list_img {
                display: none;
            }

            &_box-wrap {
                display: none;
            }

            &_content_list_text {
                .btnMore {
                    position: relative;
                    right: auto;
                    bottom: auto;
                    height: 35px;
                }
            }

            &_content_list {
                min-height: 185px;
            }

            &_content_pagination-wrap {
                padding-top: 20px;
            }
        }
    }
}
