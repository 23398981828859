@import "src/include";

#TradeNotifySetting {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 60px;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    z-index: 2;

    .header {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
        z-index: 2;

        h2 {
            font-size: 16px;
            font-family: NanumSquareOTF_acEB;
            color: #443018;
        }

        button {
            position: absolute;
            left: 16px;
            background-size: contain;
        }
    }

    .setting {

        .s_head {
            margin-bottom: 10px;
            padding: 0 5%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            background-color: #f0f0f0;

            .setting_title {
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
                color: #606060;
            }

            button {
                width: 65px;
                height: 25px;
                font-size: 12px;
                font-family: NanumSquareOTF_acB;
                color: #ffffff;
                border-radius: 21px;
                background-color: var(--color-Web-Orange);
            }
        }

        .area_search {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            input {
                padding: 0 18px;
                width: 90%;
                height: 38px;
                font-size: 14px;
                color: #959595;
                @include border-radius(3px);
                border: 2px solid #d8d8d8;
            }

            button {
                position: absolute;
                top: 7px;
                right: calc(5% + 10px);
            }
        }

        .keyword_list {
            margin-top: 10px;
            padding: 0 5%;

            h2 {
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
                line-height: 26px;
                color: #606060;
            }

            ul {
                margin-top: 10px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                column-gap: 6px;
                row-gap: 8px;

                li {
                    display: flex;
                    align-items: center;

                    p {
                        margin-right: 4px;
                        padding: 0 12px;
                        height: 24px;
                        font-size: 12px;
                        line-height: 24px;
                        color: #606060;
                        @include border-radius(12.5px);
                        border: 1px solid #a9a9a9;
                    }

                    button {
                        width: 8px;
                        height: 8px;
                        background-size: contain;
                    }
                }
            }
        }
    }
}