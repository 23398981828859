#ResearchTechMain {
  margin: auto;
  padding-top: 40px;
  width: 1280px;

  .tutorial-box {
    margin: 0 auto 35px auto;
    padding-left: 45px;
    display: flex;
    column-gap: 70px;
    width: 880px;
    height: 110px;
    border-radius: 20px;
    background-color: rgba(241, 241, 241, 0.3);

    .wrap_image {
      display: flex;
      align-items: center;
    }

    img {
      width: 63px;
      height: 55px;
    }

    .inner {
      padding-top: 10px;
      height: 100%;

      h2 {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: NanumSquareOTF_acEB;
        line-height: 30px;
        color: #343434;
      }

      p {
        font-size: 14px;
        font-family: NanumSquareOTF_acB;
        color: #606060;
      }
    }
  }

  .wrap_category {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .category {
      display: flex;
      align-items: center;
      column-gap: 10px;
      font-size: 22px;
      font-family: NanumSquareOTF_acB;
      color: #343434;
    }

    a {
      padding: 0 0 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 30px;
      border-radius: 9px;
      background-color: #eff3fb;

      span {
        display: block;
        width: 87px;
        font-size: 12px;
        font-family: NanumSquareOTF_acB;
        color: #606060;
      }
    }
  }

  .keyword_find {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;

    h2 {
      font-size: 16px;
      font-family: NanumSquareOTF_acEB;
    }

    input {
      padding: 0 40px 0 15px;
      width: 300px;
      height: 32px;
      font-size: 14px;
      border: 1px solid var(--color-Alto);

      &::placeholder {
        color: var(--color-Dusty-Gray);
      }
    }

    .icon_del_small_with_bg {
      position: absolute;
      right: 45px;
    }
  }

  table {
    margin-top: 40px;

    thead {
      background-color: var(--color-Gallery);

      th {
        color: var(--color-Mine_Shaft);
      }
    }

    th:nth-child(2),
    td:nth-child(2) {
      padding-left: 20px;
      text-align: left;
    }
  }
}