@import "src/include";
#Home {
  margin: auto;
  width: 967px;

  &.findbiz {
    width: auto;
    #TaskMain {
      .head {
        &.pc {
          .inner {
            padding-right: 0;
            .tabs {
              width: 1280px;
            }
          }
          .btns {
            width: 1280px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 850px) {
    padding-top: 60px;
    width: 100% !important;
  }

  > .title {
    margin-top: 14px;
    display: flex;
    font-size: 22px;
    font-family: NanumSquareOTF_acB;
    color: rgba(0, 0, 0, 0.87);

    h2:first-child {
      margin-top: 7px;
      width: 600px;
      font-size: 16px;
    }
  }

  .pc {
    .section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1200px;
      margin: 20px auto;
    }
    .intro_header {
      margin: 25px 0 30px 0;
      > h2 {
        background-image: linear-gradient(to left, #0d7, #21c6e3);
        font-family: GmarketSansTTF_bold;
        font-size: 30px;
        line-height: 1.33;
        letter-spacing: -1px;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
      }
      > h5 {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: -0.5px;
        color: #606060;
      }
    }
    .wrap_area_box {
      display: flex;
      column-gap: 18px;

      .area_box {
        width: 100%;
        height: 230px;

        > ul,
        .inner > ul {
          padding-left: 31px;
          display: flex;
          column-gap: 70px;

          li {
            img {
              cursor: pointer;
            }
          }
        }

        .wrap_title {
          height: 25px;
          margin-bottom: 13px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          p {
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            color: #606060;
          }
        }

        .inner {
          display: flex;
          column-gap: 57px;
        }
      }
      #pagination {
        margin-top: 50px;
      }
    }

    .area_box {
      padding: 20px 40px;
      @include border-radius(20px);
      background-color: #f6f6f6;

      .title {
        padding-right: 20px;
        display: flex;
        justify-content: space-between;
        font-size: 22px;
        font-family: NanumSquareOTF_acB;
        color: rgba(0, 0, 0, 0.87);

        h2:last-child {
          width: 265px;
        }
      }

      h2 {
        font-size: 22px;
        font-family: NanumSquareOTF_acEB;
        color: #443018;
      }
    }

    .area_box.shadow {
      box-shadow: 1px -2px 4px 0 rgba(52, 52, 52, 0.15);
    }

    a {
      display: block;
      height: 100%;
    }

    .wrap_chart {
      position: relative;
      padding: 16px 20px;
      width: 283px;
      height: 245px;
      @include border-radius(8px);
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
      background-image: linear-gradient(
        to right,
        #ff7a00,
        var(--color-Web-Orange)
      );

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;

        h2 {
          font-size: 20px;
          font-family: NanumSquareOTF_acEB;
          line-height: 24px;
          color: #ffffff;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 85px;
          font-size: 12px;
          font-family: NanumSquareOTF_acB;
          color: #ffffff;
        }
      }

      .chart {
        position: relative;

        canvas {
          position: relative;
          left: -12px;
        }

        .total_cnt {
          position: absolute;
          top: 43%;
          left: 42px;
          width: 90px;
          font-size: 12px;
          color: #ffffff;
          text-align: center;

          span {
            font-size: 24px;
            font-family: NanumSquareOTF_acEB;
          }
        }

        .add_label,
        .apply_label,
        .giveup_label {
          position: absolute;
          top: 10%;
          left: 182px;
          justify-content: center;
          align-items: center;
          min-width: 70px;
          line-height: 24px;
          white-space: pre;
          font-size: 12px;
          font-family: NanumSquareOTF_acEB;
          color: var(--color-Web-Orange);
          text-align: center;
          background: #ffffff;
          @include border-radius(7px);
          z-index: 2;
        }

        .add_label:after,
        .apply_label:after,
        .giveup_label:after {
          content: "";
          position: absolute;
          bottom: 29px;
          left: 10px;
          width: 0;
          height: 0;
          border: 7px solid transparent;
          border-top-color: #ffffff;
          border-bottom: 0;
          margin-left: -20px;
          margin-bottom: -20px;
          transform: rotate(90deg);
        }

        .apply_label {
          top: 35%;
          color: #ffa012;
          background-color: #ffd4a6;
        }

        .apply_label:after {
          border-top-color: #ffd4a6;
        }

        .giveup_label {
          top: 60%;
          color: #ffffff;
          background-color: #ffa94d;
        }

        .giveup_label:after {
          border-top-color: #ffa94d;
        }
      }
    }
  }

  /* 메뉴 탭 css */
  .wrap_tab {
    margin-bottom: 10px;
    display: flex;
    column-gap: 14px;

    > div {
      width: 284px;
    }

    .header {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-size: 20px;
        font-family: NanumSquareOTF_acB;
      }
    }

    .inner_tab {
      > .tab_link:first-child {
        margin-bottom: 11px;
      }

      .divide_tab {
        display: flex;
        width: 284px;

        .tab_link {
          width: 50%;
        }

        .tab_link + .tab_link {
          margin-left: 8px;
        }
      }
    }

    .divide_tab {
      display: flex;
      flex-direction: column;
      row-gap: 18px;

      > div {
        height: 70px;
      }
    }

    .tab_info {
      width: 283px;
      height: 245px;
      @include border-radius(5px);
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
      background-color: #ffffff;
    }

    .tab_payment {
      padding: 16px 20px;

      .header {
        margin-bottom: 5px;
        position: relative;

        a {
          margin-left: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 112px !important;
          height: 24px;
          font-size: 12px;
          background: url("../../../assets/images/common/icon-more-black.svg")
            no-repeat right;
        }
      }

      p {
        font-size: 14px;
        color: #000000;
      }
    }

    .tab_count {
      padding: 16px 20px;
    }

    .tab_my_patent_use {
      background: url("../../../assets/images/common/bg_ip_loans.svg") no-repeat
        rgba(198, 238, 255, 0.4) 85% 100%;
    }

    .tab_us_free {
      position: relative;
      background-color: rgba(128, 206, 183, 0.2);

      a {
        padding: 12px 6px 12px 13px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        i {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }

        p:first-child {
          font-size: 14px;
          font-family: NanumSquareOTF_acEB;
        }

        p {
          font-size: 14px;
          font-family: NanumSquareOTF_acB;
          color: #006849;
        }
      }
    }

    .tab_invest {
      background: url("../../../assets/images/common/bg_invest.svg") no-repeat
        rgba(128, 206, 183, 0.2) 120% 20%;

      a {
        display: flex;
        align-items: flex-end;

        p {
          font-size: 16px;
          font-family: NanumSquareOTF_acB;
        }
      }
    }

    .tab_invention {
      a {
        padding-left: 13px;
      }

      .icon_invention {
        background: url("../../../assets/images/common/icon-invention.svg")
          no-repeat;
      }
    }

    .tab_date_manage,
    .tab_invention {
      position: relative;
      height: 61px;
      @include border-radius(5px);
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
      background-color: #ffffff;

      a {
        padding: 16px 20px;
        display: flex;
        align-items: center;

        i {
          margin-right: 20px;
        }

        p {
          font-size: 18px;
          font-family: NanumSquareOTF_acEB;
        }

        button {
          position: absolute;
          right: 18px;
        }
      }
    }

    .tab_my_info {
      padding: 16px 20px;
      position: relative;
      background-color: #ffffff;

      > h2 {
        margin-bottom: 5px;
        font-size: 22px;
        font-family: NanumSquareOTF_acEB;
        color: rgba(0, 0, 0, 0.87);
        @include text-ellipsis(1);
      }

      > p {
        font-size: 14px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.87);
      }

      .btns {
        position: absolute;
        bottom: 9px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin-right: 10px;
          width: 127px;
          font-size: 14px;
          font-family: NanumSquareOTF_acB;

          span {
            color: #ff7a00;
          }
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 135px;
          height: 32px;
          font-size: 14px;
          font-family: NanumSquareOTF_acB;
          @include border-radius(16px);
        }

        .btn_mypage {
          color: #ffffff;
          background-image: linear-gradient(
            to right,
            #ff7a00 32%,
            var(--color-Web-Orange)
          );
        }

        .btn_admin {
          border: 2px solid var(--color-Web-Orange);
        }
      }
    }

    .tab_patent_market {
      position: relative;
      background: url("../../../assets/images/common/bg_open_market.svg")
        no-repeat rgba(198, 238, 255, 0.4) 93% 38px;

      a {
        p {
          margin-top: 10px;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .tab_payment_manage {
      position: relative;
      height: 130px;
      background-color: #d8f3ff;

      a {
        padding: 12px 28px;
        display: flex;

        img {
          margin-top: 14px;
          width: 65.8px;
          height: 78px;
        }

        .tab_payment_info {
          margin: 5px 0 0 22px;

          h2 {
            margin-bottom: 5px;
          }

          p {
            font-size: 14px;
            line-height: 18px;
          }
        }

        button {
          position: absolute;
          top: 14px;
          right: 13px;
        }
      }
    }

    .btn_click {
      margin-left: 6px;
      display: flex;
      align-items: center;
      width: 55px;
      height: 30px;
      text-align: left;
      font-size: 12px;
      font-family: NanumSquareOTF_acEB;
      color: var(--color-Alizarin-Crimson);
      background: url("../../../assets/images/common/icon-more-red.svg")
        no-repeat right 3px;
    }

    .btn_detail_click {
      margin-left: 48px;
      display: flex;
      align-items: center;
      width: 86px;
      height: 30px;
      text-align: left;
      font-size: 12px;
      font-family: NanumSquareOTF_acEB;
      background: url("../../../assets/images/common/icon-more-red.svg")
        no-repeat right 3px;
    }

    .tab_un_event {
      height: 117px;
      background: url("../../../assets/images/common/icon_bell.svg") no-repeat
        #ffcf27 right;

      a {
        padding: 16px 20px;

        h2 {
          font-size: 18px;
          font-family: NanumSquareOTF_acEB;
          color: #443018;
          background: url("../../../assets/images/common/icon-next.svg")
            no-repeat right;
        }

        .desc {
          margin: 3px 0 11px 0;
          font-size: 14px;
          line-height: 24px;
          color: #443018;
          text-align: left;
        }

        p {
          font-size: 18px;
          font-family: NanumSquareOTF_acB;
          color: #ffffff;
          text-align: right;

          span {
            margin-right: 15px;
            font-size: 30px;
            font-family: NanumSquareOTF_acEB;
          }
        }
      }
    }

    .tab_un_event.agent {
      height: 61px;

      a {
        display: flex;
        align-items: center;

        h2 {
          background: none;
        }

        p {
          width: 200px;
          font-size: 18px;
          font-family: NanumSquareOTF_acEB;

          span {
            margin-right: 7px;
            font-size: 24px;
          }
        }
      }
    }
  }

  .tab_link {
    width: 284px;
    height: 117px;
    @include border-radius(5px);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);

    a {
      padding: 12px 13px;

      .header {
        margin-bottom: 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 30px;

        span {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-family: NanumSquareOTF_acB;
        }

        i {
          margin-right: 5px;
        }

        .icon_next_white {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      h2 {
        font-size: 18px;
        font-family: NanumSquareOTF_acB;
        line-height: 24px;
      }

      p {
        font-size: 13px;
      }
    }
  }

  .btn_link {
    margin-left: 8px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 112px !important;
    height: 24px;
    font-size: 12px;
    background: url("../../../assets/images/common/icon-more-black.svg")
      no-repeat right;
  }

  /* 공통 */
  .no_data {
    padding-top: 60px;
    height: 150px;
    font-size: 13px !important;
    font-family: NanumSquareOTF_acB;
    color: #ffffff;
    text-align: center;
  }

  .no_data_black {
    color: #000000;
    opacity: 0.4;
  }

  .nav {
    position: absolute;
    right: 20px;
    bottom: 17px;
    display: flex;
    justify-content: center;
    align-items: center;

    .circle {
      width: 8px;
      height: 8px;
      @include border-radius(50%);
      background-color: rgba(255, 255, 255, 0.3);
      cursor: pointer;
    }

    .circle + .circle {
      margin-left: 10px;
    }

    .active {
      background-color: #ffffff;
    }
  }

  .mo {
    .findx_area {
      position: relative;

      img {
        width: 100%;
      }

      p {
        position: absolute;
        left: 40vw;
        bottom: 18vw;
        font-size: 3.5vw;
        font-family: NanumSquareOTF_acEB;
        color: #1d334e;
      }

      .btn_findx {
        position: absolute;
        left: 40vw;
        bottom: 6vw;
        width: 32vw;
        height: 9vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3.5vw;
        font-family: NanumSquareOTF_acEB;
        color: #ffffff;
        @include border-radius(5vw);
        background-color: #1bb3f3;
      }
    }

    .area_title {
      margin-bottom: 24px;
      padding-left: 20px;
      font-size: 20px;
      font-family: NanumSquareOTF_acEB;
    }

    .btn_more {
      position: absolute;
      top: 5px;
      right: 20px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: NanumSquareOTF_acB;
      line-height: 18px;
      color: #606060;
      background-color: transparent;

      i {
        margin-bottom: 2px;
        width: 20px !important;
        height: 20px !important;
      }
    }

    .chart_area {
      margin-bottom: 144px;
      padding-top: 40px;
      //width: 880px;
      height: 278px;
      background-color: #f0f0f0;

      .list {
        .item {
          .content {
            padding: 0 20px;
            width: 90%;
            height: 200px;
            background-image: linear-gradient(
              to right,
              #ff7a00,
              var(--color-Web-Orange)
            );
          }
        }
      }

      .wrap_chart {
        position: relative;
        width: 80vw;

        .chart {
          position: relative;
          padding: 18px 18px 18px 24px;
          width: calc(100vw - 50px);
          height: 262px;
          @include border-radius(8px);
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
          background-image: linear-gradient(
            to right,
            #ff7a00,
            var(--color-Web-Orange)
          );

          .header {
            margin-bottom: 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
              font-size: 18px;
              font-family: NanumSquareOTF_acEB;
              line-height: 24px;
              color: #ffffff;
            }

            a {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 76px;
              font-size: 12px;
              font-family: NanumSquareOTF_acB;
              color: #ffffff;
            }
          }

          .total_cnt {
            position: absolute;
            top: 52%;
            left: 62px;
            width: 90px;
            font-size: 12px;
            line-height: 42px;
            color: #ffffff;
            text-align: center;

            span {
              font-size: 32px;
              font-family: NanumSquareOTF_acEB;
            }
          }

          .add_label,
          .apply_label,
          .giveup_label {
            position: absolute;
            top: 35%;
            left: 210px;
            justify-content: center;
            align-items: center;
            min-width: 80px;
            line-height: 26px;
            white-space: pre;
            font-size: 12px;
            font-family: NanumSquareOTF_acEB;
            color: var(--color-Web-Orange);
            text-align: center;
            background: #ffffff;
            @include border-radius(7px);
            z-index: 2;
          }

          .add_label:after,
          .apply_label:after,
          .giveup_label:after {
            content: "";
            position: absolute;
            bottom: 30px;
            left: 10px;
            width: 0;
            height: 0;
            border: 7px solid transparent;
            border-top-color: #ffffff;
            border-bottom: 0;
            margin-left: -20px;
            margin-bottom: -20px;
            transform: rotate(90deg);
          }

          .apply_label {
            top: 50%;
            color: #ffa012;
            background-color: #ffd4a6;
          }

          .apply_label:after {
            border-top-color: #ffd4a6;
          }

          .giveup_label {
            top: 65%;
            color: #ffffff;
            background-color: #ffa94d;
          }

          .giveup_label:after {
            border-top-color: #ffa94d;
          }
        }
      }
    }

    .grade_area {
      margin: 0 auto 40px auto;
      width: calc(100vw - 40px);
      height: 104px;

      .my_grade {
        padding: 0 16px 0 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 104px;
        @include border-radius(8px);
        background-color: rgba(255, 166, 0, 0.2);

        .circle {
          min-width: 70px;
          height: 70px;
          font-size: 26px;
          font-family: NanumSquareOTF_acB;
          line-height: 70px;
          color: #ffffff;
          text-align: center;
          border: 2px solid #ffffff;
          @include border-radius(50%);
          background-color: var(--color-Web-Orange);
        }

        .inner {
          margin: 0 12px 0 11px;
          width: calc(100% - 70px - 30px - 23px);

          h2 {
            margin-bottom: 2px;
            font-size: 19px;
            font-family: NanumSquareOTF_acEB;
            line-height: 26px;
            span {
              color: #747474;
            }
          }
        }
      }
    }

    .date_manage_area {
      position: relative;
      max-height: 518px;

      ul {
        padding: 0 20px;

        li {
          padding: 12px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #d8d8d8;

          .date {
            min-width: 49px;
            height: 26px;
            font-size: 14px;
            font-family: NanumSquareOTF_acEB;
            line-height: 20px;
            color: var(--color-Web-Orange);
            text-align: center;
            border: 2px solid var(--color-Web-Orange);
            @include border-radius(13px);
          }

          .date_info {
            padding-right: 14px;
            width: 80%;

            .title {
              margin-bottom: 2px;
              @include text-ellipsis(1);
              font-size: 14px;
              font-family: NanumSquareOTF_acEB;
              line-height: 20px;
              color: #443018;
            }

            .number {
              margin-bottom: 12px;
              height: 18px;
              font-size: 12px;
              font-family: NanumSquareOTF_acB;
              line-height: 18px;
              color: #707070;
            }

            .inner {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .title {
                font-size: 14px;
                font-family: NanumSquareOTF_acB;
                line-height: 20px;
                color: #747474;
              }

              .value {
                font-size: 12px;
                font-family: NanumSquareOTF_acB;
                color: #707070;
              }
            }
          }
        }

        li.today {
          @include border-radius(8px);
          border: solid 2px #ff4d00;

          .date {
            width: 63px;
            height: 24px;
            font-size: 12px;
            font-family: NanumSquareOTF_acEB;
            line-height: 24px;
            color: #ffffff;
            text-align: center;
            border: none;
            @include border-radius(13px);
            background-color: #ff4d00;
          }
        }

        li:last-child {
          margin-bottom: 40px;
        }

        li + li {
          margin-top: 12px;
        }
      }
    }

    .my_price {
      margin-bottom: 60px;
      padding: 30px 20px 20px;
      background-color: #f0f0f0;

      .area_title {
        padding-left: 0;
      }

      .wrap {
        .header {
          padding: 16px 16px 16px 12px;
          color: #ffffff;
          @include border-radius(4px);
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
          background-image: linear-gradient(
            to right,
            #ff7a00,
            var(--color-Web-Orange)
          );

          h2 {
            margin-bottom: 6px;
            font-size: 14px;
            font-family: NanumSquareOTF_acEB;
            line-height: 20px;
            color: #ffffff;
          }

          p {
            font-size: 30px;
            font-family: NanumSquareOTF_acEB;
            line-height: 30px;
            color: #ffffff;
          }
        }

        .content {
          padding: 12px 14px;
          font-size: 12px;
          font-family: NanumSquareOTF_acB;
          line-height: 18px;
          color: #aaaaaa;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          background-color: #ffffff;

          .detail_price {
            padding-bottom: 7.5px;

            li {
              display: flex;
              align-items: center;
              justify-content: space-between;

              p {
                span {
                  margin-right: 8px;
                  font-size: 14px;
                  font-family: NanumSquareOTF_acEB;
                }
              }
            }

            li + li {
              margin-top: 8px;
            }
          }

          .total {
            padding-top: 7.5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #d8d8d8;

            p {
              font-size: 14px;
              line-height: 18px;
              color: #606060;

              span {
                margin-right: 8px;
                line-height: 24px;
                font-family: NanumSquareOTF_acEB;
              }
            }
          }

          .compare {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              font-size: 14px;
              line-height: 18px;
              color: var(--color-Web-Orange);

              span {
                margin-right: 8px;
                font-size: 18px;
                line-height: 24px;
                font-family: NanumSquareOTF_acEB;
              }
            }
          }
        }
      }
    }

    .ip_edu {
      margin-bottom: 60px;
      position: relative;

      .list {
        padding: 0 20px;
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;

        .item {
          min-width: 192px;
          width: 55%;

          img {
            margin-bottom: 8px;
          }

          h2 {
            margin-bottom: 4px;
            font-size: 12px;
            font-family: NanumSquareOTF_acEB;
            line-height: 18px;
            color: var(--color-Web-Orange);
          }

          p {
            @include text-ellipsis(2);
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
            line-height: 20px;
            color: #443018;
          }
        }

        .item + .item {
          margin-left: 16px;
        }
      }
    }

    .more_view_area {
      margin-bottom: 58px;

      .list {
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        column-gap: 20px;
        width: 100vw;
        overflow-x: scroll;
        overflow-y: hidden;
      }
    }

    .alert {
      margin: 15px;
      display: flex;

      i {
        margin-right: 8px;
      }

      p {
        font-size: 12px;
        font-family: NanumSquareOTF_acB;
        line-height: 18px;
        color: #959595;
      }
    }

    .slick-track {
      width: 1360px;
    }

    .slick-dots {
      bottom: -20px !important;

      li {
        margin: 0;
      }

      li.slick-active button:before {
        opacity: 1;
        color: var(--color-Web-Orange);
      }

      li button:before {
        opacity: 0.5;
        color: #d8d8d8;
      }
    }
  }

  .demo_alert {
    padding: 5px 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    width: 600px;
    height: 180px;
    left: 50%;
    bottom: 2%;
    transform: translateX(-50%);
    font-size: 16px;
    font-family: NanumSquareOTF_acB;
    color: #ffffff;
    background-color: var(--color-Web-Orange);
    z-index: 10;

    @media screen and (max-width: 850px) {
      left: 0;
      width: 100%;
      height: 120px;
      transform: none;
      font-size: 14px;
    }

    p {
      text-align: center;
    }

    p:last-child {
      font-family: NanumSquareOTF_acEB;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
