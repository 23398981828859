#IpStatisticNew {
  .grid-header,
  .grid-cell {
    font-size: 12px;
  }
  .grid-header {
    background-color: #f0f0f0;
    position: sticky;
    top: 0;
  }
  .box_wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    > div {
      & + div {
        margin-left: 40px;
        margin-top: 20px;
      }
      &:nth-of-type(4n) {
        margin-left: 0;
      }
    }
  }
  #RightCheck {
    .total {
      font-size: 22px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  #PatentList {
    margin-bottom: 60px;
  }
  #IpStatistic {
    max-width: none;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 60px;
    input[type="text"] {
      height: 34px;
      padding: 6px 0px 6px 6px;
    }
    .tbl_box {
      min-height: 263px;
    }
    .status_box {
      .cate_box {
        .cate {
          margin-bottom: 10px;
        }
      }
    }
    .status_box {
      button:not(.link) {
        background: rgba(44, 89, 133, 0.7);
        border-radius: 4px;
        padding: 4px 12px;
        font-size: 16px;
        &.on {
          background: rgb(44, 89, 133);
        }
      }
      .cate_box {
        > div {
          margin-bottom: 10px;
        }
      }
    }
    .keyword_list {
      > li {
        padding: 4px 12px;
        background-color: transparent;
        border-radius: 4px;
        color: #333;
        border: 1px solid #d7d7d7;
        &.on {
          background: rgb(44, 89, 133);
          color: #fff;
        }
      }
    }
    .selected {
      background: rgb(44, 89, 133);
      padding: 4px 10px;
      border-radius: 4px;
      margin-bottom: 0;
    }
  }
  #DistributionPop {
    > strong {
      display: block;
      margin-bottom: 20px;
    }
  }
  .modal {
    z-index: 9999;
  }
}
