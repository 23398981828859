$blueColor: #4593f5;

.select-div {
  position: relative;

  & > button {
    background-color: transparent;
    color: #9C9C9C;
    border: 1px solid #ABABAB;
    padding: 10px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:disabled {
      color: #D9D9D9;
      border: 1px solid #E8E8E8;
    }
  }

  &:first-child button {
    width: 110px;
  }

  & > ul {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    color: #ABABAB;
    max-height: 400px;
    overflow-y: auto;

    li {
      padding: 6px 0px;
      border: 1px solid #ABABAB;
      text-align: center;
      background-color: white;
      color: #ABABAB;
      cursor: pointer;

      &:hover {
        background-color: #ABABAB;
        color: white;
      }
    }
  }
}
