#AnnualFeeReport {
  position: fixed;
  width: 1380px;
  min-height: 530px;
  height: calc(100vh - 99px);
  border: solid 1px #d8d8d8;
  background-color: #fff;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #343434;
  padding: 24px 20px;
  font-family: "Pretendard_Regular";

  // ===================
  // max-height: 600px;
  overflow-y: auto;
  // ===================

  strong {
    font-family: "Pretendard_Bold";
  }

  table {
    .left {
      text-align: left;
    }
  }

  .tit_icon {
    width: 32px;
    height: 32px;
  }
  i.icon_badge_info_black {
    &:hover:after {
      content: attr(data-content);
      position: absolute;
      white-space: pre-line;
      padding: 4px 8px;
      top: 100%;
      right: 0;
      // min-width: 300px;
      max-width: 520px;
      line-height: 22px;
      font-size: 12px;
      font-style: normal;
      color: white;
      z-index: 2;
      border-radius: 9px;
      background-color: rgba(0, 0, 0, 0.85);
    }
  }

  .round_box {
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    > p {
      letter-spacing: 0.01rem;
      line-height: 1.4;
      word-break: keep-all;
    }
  }

  .border_box {
    border: solid 1px #d4d4d4;
  }

  .bg_green {
    background-color: #f2fbf7;
  }
  .bg_purple {
    background-color: #f7f5fc;
  }
  .bg_gray {
    background-color: #fafafb;
  }
  .bg_mint {
    background-color: #f4fbfb;
  }
  .bg_pink {
    background-color: #fdf4f1;
  }
  .bg_blue {
    background-color: #f5f9fe;
  }
  .smart {
    color: #fff;
    padding: 4px 8px;
    border-radius: 14px;
    margin-right: 4px;
    font-size: 12px;
  }
  .smart_red {
    background-color: #e72222;
  }

  .smart_yellow {
    background-color: #ffa627;
  }
  .smart_orange {
    background-color: #ffa627;
  }

  .smart_green {
    background-color: #05ac9e;
  }

  .smart_gray {
    background-color: #e7e7e7;
    color: #343434;
  }
  .tit_box {
    padding-top: 10px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    position: relative;
    .download_btn {
      margin-right: 10px;
      padding: 5px 10px;
      background-color: #0075FF;
      color:white;
      border-radius: 5px;

    }
    > strong {
      font-size: 24px;
    }
    .tip {
      display: flex;
      align-items: center;
      > i {
        margin-right: 6px;
      }
    }
  }
  .box_tit {
    font-size: 20px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .box_tit_sm {
    font-size: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .info {
    display: flex;
    align-items: center;
    font-size: 16px;

    > div {
      &:first-of-type {
        position: relative;
        margin-right: 40px;
        &::after {
          content: "";
          width: 2px;
          height: 14px;
          display: block;
          background-color: #d4d4d4;
          position: absolute;
          right: -20px;
          top: 5px;
          display: none;
        }
      }
      > p {
        display: flex;
        align-items: center;
        > i {
          margin-right: 6px;
        }
        > strong {
          padding-right: 10px;
        }
      }
    }
  }
  .sub_tit {
    margin-top: 50px;
    position: relative;
    padding-bottom: 20px;
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #d4d4d4;
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .dot_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    > li {
      position: relative;
      font-size: 16px;
      padding-left: 20px;
      &::before {
        content: "";
        width: 8px;
        height: 8px;
        display: block;
        background-color: #2cb997;
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 5px;
      }
      &:nth-child(5) {
        grid-column: 1 / 3;
      }
    }

    &.summary {
      display: block;
      > li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &::before {
          background-color: #8661f6;
        }
      }
      .grade {
        color: #fff;
        padding: 4px 8px;
        border-radius: 10px;
      }
    }
  }
  .pd_list {
    > li {
      padding: 10px 12px;
      box-shadow: 0 3px 16px 0 rgba(195, 62, 62, 0.12);
      background-color: #fff;
      border-radius: 20px;
      margin-bottom: 12px;
      margin-right: 12px;
      display: inline-block;
    }
  }
  .comp_list {
    display: flex;
    flex-wrap: wrap;
    max-height: 558px;
    overflow-y: auto;
    > li {
      display: flex;
      align-items: center;
      width: 533px;
      height: 102px;
      border-radius: 15px;
      border: 0.8px solid #d8d8d8;
      background-color: #fff;
      position: relative;
      margin-bottom: 10px;
      &:nth-child(2n-1) {
        margin-right: 10px;
      }
      > img {
        width: 63px;
        height: 63px;
        margin: 13px;
      }
      .item_text {
        line-height: 1.5;
        > p:first-child {
          font-size: 18px;
          letter-spacing: -0.5px;
          color: #343434;
          font-family: "Pretendard_bold";
        }
        > p:nth-child(2) {
          font-size: 14px;
          width: 310px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      .item_text_category {
        > p {
          display: inline-block;
          font-family: "Pretendard_bold";
          padding: 3px 9px;
          margin-right: 7px;
          font-size: 12px;
          text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
          border: 1px solid transparent;
          border-radius: 14px;
          background: linear-gradient(#fff, #fff) padding-box,
            linear-gradient(270deg, #0d7, #0078f1);
          background-origin: border-box;
        }
      }
      button {
        position: absolute;
        right: 17px;
        width: 104px;
        height: 40px;
        font-size: 14px;
        color: #fff;
        border-radius: 10px;
        background-image: linear-gradient(270deg, #0d7, #0078f1);
      }
    }
  }
  .graph_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .graph {
      width: 49%;
      padding: 28px;
      border-radius: 20px;
      box-shadow: 0 3px 16px 0 rgba(29, 95, 195, 0.12);
      background-color: #fff;
      margin-bottom: 20px;
      position: relative;
      > strong {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 28px;
        position: relative;
      }
    }
  }
  .close {
    text-align: right;
  }
  .rcm_grade {
    display: grid;
    grid-template-columns: 56px 1fr;
    grid-gap: 10px;
    align-items: center;
    > strong {
      height: 50px;
      width: 50px;
      display: block;
      text-align: center;
      font-size: 14px;
      border-radius: 100%;
      padding: 0;
    }
  }
  .smart_list {
    margin-top: 10px;
    > li {
      > strong {
        min-width: 90px;
        display: inline-block;
      }
      & + li {
        margin-top: 10px;
      }
      .grade {
        padding: 4px 8px;
        border-radius: 14px;
        margin-left: 4px;
        color: #fff;
      }
    }
  }

  .market_lilst {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    position: relative;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid #eee;
      padding: 10px;
      border-radius: 10px;
      &.view {
        border: none;
        padding: 0;
      }
      > img {
        max-width: 100%;
        height: 270px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .cont {
        > strong {
          width: 100%;
          font-size: 14px;
          font-family: Pretendard_bold;
          min-height: 40px;
          line-height: 1.43;
          color: #343434;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }
        .btn_box {
          min-height: 30px;
          text-align: right;
          > button {
            width: 85px;
            height: 24px;
            font-family: Pretendard_extraBold;
            font-size: 12px;
            color: #4593f5;
            border-radius: 14px;
            background-color: rgba(107, 211, 255, 0.2);
          }
        }
      }
    }
  }
  .more_btn {
    text-align: right;
    > button {
      width: 120px;
      height: 36px;
      font-family: Pretendard_extraBold;
      font-size: 14px;
      color: #4593f5;
      border-radius: 24px;
      background-color: rgba(107, 211, 255, 0.2);
    }
  }
}

#Family.annual_report {
  width: auto;
  .tree {
    margin-top: auto;
    .root {
      .node {
        border: none;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 3px 16px 0 rgba(195, 62, 62, 0.12);
        .info {
          width: 210px;
        }
      }
    }
  }
}

#MarketInfoPopupDetail {
  max-width: 100%;
  position: absolute;
}
