#KeywordListWithPerson {
  padding: 4px 18px;
  position: absolute;
  top: 45px;
  left: 0;
  width: 681px;
  max-height: 400px;
  border: solid 1px #d8d8d8;
  background-color: #ffffff;
  overflow-y: scroll;
  z-index: 2;

  li {
    margin-top: 3px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    min-height: 40px;
    border-bottom: solid 1px #e7e7e7;
    cursor: pointer;

    h2 {
      width: 130px;
      font-size: 14px;
      font-family: NanumSquareOTF_acEB;
      line-height: 1.33;
      color: #443018;
      word-break: keep-all;
    }

    p {
      display: flex;
      align-items: center;
      column-gap: 12px;
      width: 530px;
      font-size: 12px;
      line-height: 1.33;
      font-family: NanumSquareOTF_acB;
      color: #343434;
      word-break: keep-all;
    }
  }

  li:hover {
    background-color: rgba(0, 149, 174, 0.1);

    h2 {
      font-family: NanumSquareOTF_acB;
      color: var(--color-Web-Orange);
    }
  }

  @media screen and (max-width: 850px) {
    li {
      display: block;

      p {
        width: 100%;

        i {
          min-width: 20px;
        }
      }
    }
  }
}