.modal {
  width: 1400px;
  height: 90vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 15px 30px 0 rgba(#000, 0.25);
  iframe {
    z-index: 10;
  }
  button {
    position: absolute;
    right: 50px;
    top: 50px;
    z-index: 15;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      width: 40px;
      height: 40px;
    }
  }
}
