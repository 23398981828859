@import "src/mixin";
@import "src/include";

.ToggleButtonWithSpan {
    display: flex;
    align-items: center;

    span {
        //display: block;
        margin-right: 14px;
        font-size: 12px;
        @include mobile {
            margin-right: 8px;
        }
    }
}
