@import "src/include";
.docTradeSampleRegisterIndividualList{
	td{
		height: 50px;
		font-family: NanumSquareOTF_acB;

		button {
			display: inline-block;
			width: 24px;
			height: 24px;
			background-color: transparent;
		}
		&:last-child{
			position: relative;
			.icon_badge_del{
				position: absolute;
				display: inline-block;
				width: 24px;
				height: 24px;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
			}
		}
	}
}