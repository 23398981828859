.confirm-div {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  background-color: white;
  padding: 20px 0;
  width: 400px;

  & button {
    color: white;
    padding: 8px;
    border-radius: 8px;
  }

  &.delete-modal {
    & > div {
      display: flex;
      gap: 10px;
      justify-content: center;
      border-radius: 8px;

      & > button {
        &:first-child {
          background-color: #23d300;
        }
        &:last-child {
          background-color: #ff5151;
        }
      }
    }
  }

  &.success-modal > button {
    background-color: #787878;
    align-self: center;
  }

  &.error-modal > button {
    background-color: red;
    align-self: center;
  }

  & > h1 {
    font-size: 1.4rem;
    font-weight: 500;
  }
}
