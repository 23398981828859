@import "../../../include";

#InvestmentView {
    margin: auto;
    margin-top: 36px;
    width: 1280px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 22px;
            font-family: NanumSquareOTF_acB;
        }

        .btns {
            display: flex;
            align-items: center;

            & > button {
                width: 132px;
                height: 32px;
                font-size: 16px;
                color: #ffffff;
                text-align: center;
            }

            button+button {
                margin-left: 8px;
            }

            .btn_patent_add {
                margin-right: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 132px;
                height: 32px;
                font-size: 15px;
                color: #ffffff;
                background-color: #4593f5;
            }

            .btn_excel_download {
                margin-right: 6px;
                background-color: #96c719;
            }

            .btn_tab_setting, .btn_tab_setting_save {
                background-color: #e92f2c;
            }

            .btn_print {
                width: 132px;
                background-color: #4593f5;
            }

            .btn_update {
                width: 64px;
                background-color: #96c719;
            }

            .btn_save {
                width: 64px;
                background-color: #19c77c;
            }

            .ToggleButtonWithSpan {
                margin-right: 31px;
            }
        }
    }
    .wrap_search {
        margin: 18px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        select {
            padding-left: 10px;
            width: 100px;
            height: 32px;
            font-size: 14px;
            border: solid 1px rgba(0, 0, 0, 0.54);
        }

        .input_search {
            padding: 0px 10px;
            min-width: 330px;
            width: 40%;
            height: 32px;
            font-size: 14px;
            border: solid 1px rgba(0, 0, 0, 0.54);
            border-left: 0;
        }

        .icon_search_black {
            margin-left: 15px;
            width: 32px;
            height: 32px;
            background: url("../../../assets/images/common/icon_search_black.svg") no-repeat;
        }

        select::placeholder, input::placeholder {
          color: rgba(0, 0, 0, 0.56);
        }
    }

    .sorting {
        margin: 5px 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;

        .checkbox+.checkbox {
            margin-left: 35px;
        }
    }

    .wrap_list {
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 800px;
        min-height: 200px;

    }

    table {
        width: calc(100% - 1px);
        table-layout: fixed;

        thead {
            tr {
                width: 1280px;
            }
        }

        th {
            ul {
                overflow-y: scroll;
                position: absolute;
                top: 100%;
                width: 100%;
                max-height: 150px;
                background-color: white;
                color: black;
                border: 1px solid rgb(0, 149, 174);
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */

                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 32px;
                    font-size: 12px;
                    font-family: NanumSquareOTF_acR;
                    cursor: pointer;
                }
                li+li {
                    border-top: 1px solid rgb(0, 149, 174);
                }
            }
            ul::-webkit-scrollbar {
                display: none;
            }
        }

        th.dp_none {
            ul {
                display: none;
            }
        }

        tbody {
            tr {
                height: 50px;
                td {
                    word-break: break-all;
                    border-left: 1px solid #e7e7e7;

                    input {
                        padding: 0 4px;
                        width: 80%;
                        height: 30px;
                        font-size: 12px;
                        border: solid 0.5px rgba(0, 0, 0, 0.56);
                    }

                    select {
                        width: 80%;
                        height: 30px;
                        font-size: 12px;
                    }

                    div+div {
                        margin-top: 5px;
                    }

                    .empty_info {
                        opacity: 0.5;
                        font-size: 12px;
                        line-height: 1.25;
                        letter-spacing: -0.4px;
                        text-align: center;
                        color: rgba(0, 0, 0, 0.87);
                    }
                }
                td:last-child {
                    border-right: 1px solid #e7e7e7;
                }
                td.no_border {
                    border-left: none;
                }
                td.border_right {
                    border-right: 1px solid #e7e7e7;
                }
            }
        }
    }

    .circle {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 24px;
        border-radius: 16px;
        pointer-events: none;
    }

    i {
        position: absolute;
        top: 2px;
        right: 12px;
    }

    .icon_detail {
        background: url("../../../assets/images/common/icon-detail.svg") no-repeat;
    }
    .icon_traffic_lights {
        background: url("../../../assets/images/common/icon-traffic-lights.svg") no-repeat;
    }
    .icon_attached_file {
        background: url("../../../assets/images/common/icon-attached-file.svg") no-repeat;
    }
    .icon_home {
        background: url("../../../assets/images/common/icon-home.svg") no-repeat;
    }
    .icon_patent_payment {
        background: url("../../../assets/images/common/icon-patent-payment.svg") no-repeat;
    }

}

