.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  background-color: #fff;
  border-radius: 8px;
  min-width: 400px;
  max-width: 600px;
  padding: 30px 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  font-size: 1.2rem;
  font-weight: bold;
}

.inventorInfo {
  background-color: #fff;
  border: 1px solid #ddd; /* 연한 그레이 테두리 */
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05); /* 살짝 그림자 추가 */
}
.infoRow {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  font-weight: bold;
  font-size: 15px;
  color: #444;
}

.value {
  font-size: 15px;
  color: #666;
  flex: 1;
}
.inputWithDelete {
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px; // 아이디 입력 필드와 버튼 간격 조정
}
.deleteButton {
  background-color: #e74c3c; // 🔴 빨간색 (위험 강조)
  color: white;
  position: absolute;
  right: 0px;
  top: -8px;
  border: 1px solid #e74c3c;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: darken(#e74c3c, 10%);
    border-color: darken(#e74c3c, 10%);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(231, 76, 60, 0.6);
  }

  &:active {
    background-color: darken(#e74c3c, 15%);
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.required {
  color: red;
  font-size: 14px;
  margin-left: 4px;
}

input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: -12px;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
}

.submitButton {
  background-color: #0984e3;
  color: #fff;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #74b9ff;
  }
}

.cancelButton {
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #f2f2f2;
  }
}
