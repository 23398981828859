#AdminMarketCategory {
    margin: auto;
    padding-top: 40px;
    width: 880px;

  .articles {
    margin-bottom: 50px;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }

  .field {
    position: relative;
    padding: 16px 0;
    display: flex;
    column-gap: 14px;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    button {
      padding: 8px;
      color: white;
    }
    ul {
      li {
          display: flex;
          align-items: center;
          column-gap: 10px;
      }
    }
    span {
      padding-right: 14px;
      display: flex;
      min-width: 70px;
      width: 100px;
      line-height: 25px;
      font-family: NanumSquareOTF_acB;
      border-right: 1px solid #ddd;
    }
    .delete_btn {
      background: #d63031;
    }
    .add_btn {
      background: #0984e3;
    }
    .update_btn {
      background: #576574;
    }
    input[type="text"] {
      padding: 10px;
      width: 80px;
      height: 25px;
      font-size: 12px;
      border: 1px solid #ddd;
    }

    select {
      width: 150px;
      height: 25px;
      border: 1px solid #ddd;
    }
  }
  .btn_list {
    display: flex;
    align-content: center;
    justify-content: space-evenly;
    button {
      background-color: #fff;
      display:inline-block;
      padding: 8px;
      color: white;
    }
    button:nth-child(1) {
      background: #00b894;
    }
    button:nth-child(2) {
      background: #d63031;
    }
  }
}
