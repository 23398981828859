@import "src/include";
#TaskMyAddList {
    margin: auto;
    padding: 48px 0 68px 0;
    width: 1280px;

    .header {
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;

        .inner {
            display: flex;
            align-items: center;
            column-gap: 10px;

            h2 {
                font-size: 22px;
                font-family: NanumSquareOTF_acB;
            }
        }

        button {
            display: flex;
            align-items: center;
            margin-right: 15px;
            height: 32px;
            font-size: 16px;
            color: black;
            background-color: #fff;
            font-family: NanumSquareOTF_acB;
            p {
                padding-left: 5px;
            };
        }
    }

    .list {
        .item {
            .dday {
                font-family: NanumSquareOTF_acEB;
                font-size: 12px;
                border-radius: 5px;
                background-color: #00ba78;
                padding: 3px 8px;
                color: white;
            }
            background-color: rgba(240, 240, 240, 0.14);
            width: 1060px;
            margin: auto;
            .title_area {
                width: 100%;
                padding-top: 22px;
                .post_title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    h2 {
                        margin-left: 9px;
                        font-size: 20px;
                        font-family: NanumSquareOTF_acEB;
                        color: #343434;
                    }
                    button {
                        width: 64px;
                        height: 32px;
                        font-size: 16px;
                        color: #ffffff;
                        background-color: #e92f2c;
                    }

                }

                .title_state_inner {
                    margin-bottom: 8px;

                    .desc {
                        display: flex;
                        align-items: center;
                        width: 745px;
                    }
                }

                .title_state_inner.endDate {
                    background-color: #e92f2c;
                }
            }

            .detail_area {
                padding: 25px 0;

                .wrap_data {
                    display: flex;
                    flex-direction: column;
                    row-gap: 18px;
                    background-color: #fff;
                    border-radius: 5px;
                    border: solid 1px #f0f0f0;
                    width: 1015px;
                    padding: 15px 0 15px 25px;
                    margin: 0 auto;
                    .datas {
                        display: flex;
                        justify-content: space-between;
                        .data {
                            display: flex;
                            align-items: center;
                            column-gap: 20px;
                            width: 33%;
                            font-size: 14px;
                            line-height: 20px;
                            border-left: 1px solid #f0f0f0;
                            padding-left: 9px;
                            h2 {
                                min-width: 80px;
                                font-family: NanumSquareOTF_acR;
                                color: #606060;
                            }
                            .dday {
                                color: white;
                            }
                            p {
                                color: #343434;
                                font-family: NanumSquareOTF_acB;
                                word-break: keep-all;
                            }

                            button {
                                width: 140px;
                                height: 32px;
                                font-size: 14px;
                                font-family: NanumSquareOTF_acB;
                                color: #ffffff;
                                @include border-radius(4px);
                            }

                            .btn_go {
                                background-color: var(--color-Web-Orange);
                            }

                            .btn_add {
                                background-color: #4593f5;
                            }
                            .amount_color_blue {
                                color: #4593F5;
                                font-size: 20px;
                                font-family: NanumSquareOTF_acEB;
                            }
                        }

                        .data.files {

                            .inner {

                                p {
                                    display: flex;
                                    align-items: flex-start;
                                    column-gap: 5px;
                                    cursor: pointer;

                                    i {
                                        margin-top: 4px;
                                        min-width: 24px;
                                    }
                                }

                                p:nth-child(n + 2) {
                                    padding-left: 29px;
                                }
                            }
                        }

                        .data.rowspan2 {
                            width: 68%;
                        }

                        .data.rowspan3 {
                            width: 100%;
                        }
                    }
                }
                .contact_wrap_data {
                    display: flex;
                    flex-direction: column;
                    row-gap: 18px;
                    background-color: rgba(240, 240, 240, 0.4);
                    border-radius: 5px;
                    border: solid 1px #f0f0f0;
                    width: 1015px;
                    padding: 15px 0 15px 25px;
                    margin: 12px auto 24px auto;
                    .contact_datas {
                    }
                    .contact_data {
                        display: flex;
                        padding-left: 9px;
                        column-gap: 20px;
                        font-family: AppleSDGothicNeo;
                        color: #606060;
                        font-size: 14px;
                        h2 {
                            min-width: 80px;
                        }
                        p {
                            font-family: NanumSquareOTF_acB;
                            color: #343434;
                            line-height: 24px;
                        }
                    }
                }
                .file_wrap_data {
                    width: 1015px;
                    padding: 0 0 0 25px;
                    margin: 12px auto 0 auto;
                    display: flex;
                    .file_data {
                        display: flex;
                        width: 80%;
                    }
                    .link_btn {
                        width: 20%;
                        display: flex;
                        flex-direction: column;
                        button {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #fff;
                            border-radius: 4px;
                            border: solid 1px #d8d8d8;
                            background-color: #fff;
                            width: 185px;
                            height: 50px;
                            span {
                                font-family: NanumSquareOTF_acB;
                                font-size: 16px;
                                color: #343434;
                                padding-left: 5px;
                            }
                        }
                        button:nth-child(2) {
                            margin-top: 7px;
                        }
                    }
                    h2 {
                        min-width: 80px;
                    }
                    .inner {
                        margin-left: 30px;
                        p {
                            display: flex;
                            align-items: center;
                            color: #343434;
                            padding-bottom: 15px;
                            font-family: NanumSquareOTF_acB;
                            font-size: 14px;
                            cursor: pointer;
                            i {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }

            .content_area {
                padding: 0;
                height: 0;
                font-size: 14px;
                overflow: hidden;

                h2 {
                    margin-bottom: 10px;
                    font-family: NanumSquareOTF_acB;
                }

                .content {
                    line-height: 25px;
                }
            }

            .btn_toggle {
                margin: 15px auto 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 5px;
                width: 230px;
                height: 32px;
                font-size: 16px;
                font-family: NanumSquareOTF_acB;
                color: #ffffff;
                @include border-radius(16px);
                background-color: #4593f5;
            }
        }

        .item+.item {
            margin-top: 30px;
        }

        .item.animation {
            .content_area {
                padding: 24px 18px;
                height: auto;
            }
        }
    }

    .btn_more {
        margin: 30px auto 0 auto;
        display: block;
        width: 190px;
        height: 40px;
        font-size: 16px;
        font-family: NanumSquareOTF_acEB;
        color: #747474;
        text-align: center;
        @include border-radius(20px);
        border: solid 2px #959595;
        background-color: #ffffff;
    }
}
