#Calculator {
    margin: auto;
    width: 630px;

    @media screen and (max-width: 850px){
        zoom: 0.6;
    }

    .input {
        position: relative;
        padding-top: 40px;
        background-color: rgba(247, 247, 247, 0.5);

        > .title {
            margin-bottom: 20px;
            font-size: 38px;
            font-family: NanumSquareOTF_acEB;
            color: #57442d;
            text-align: center;

            span {
                color: #00E635;
            }
        }

        > .desc {
            position: absolute;
            left: 50%;
            bottom: 15px;
            font-size: 16px;
            font-family: NanumSquareOTF_acB;
            color: #575756;
            text-align: center;
            transform: translateX(-50%);
        }

        .wrap_search {

            > .company {
                position: relative;
                margin: auto;
                padding: 0 10px;
                display: block;
                width: 500px;
                height: 50px;
                border: 3px solid #00E635;
                border-radius: 8px;
            }

            input:focus {
                box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
            }

            .btn_search {
                position: absolute;
                top: 112px;
                right: 82px;
                width: 30px;
                height: 30px;
                background: url("../../assets/images/calc/icon_calc_search.svg") no-repeat;
            }
        }
    }

    .icon_badge_del {
        position: absolute;
        top: 114px;
        right: 120px;
        border: none;
    }

    .keyword_list {
        position: absolute;
        top: 150px;
        left: 65px;
        padding: 0 18px;
        width: 500px;
        max-height: 400px;
        background-color: #ffffff;
        border: 1px solid #d8d8d8;
        overflow-y: scroll;
        z-index: 2;

        li {
            padding: 10px 0;
            display: flex;
            align-items: center;
            column-gap: 10px;
            flex-wrap: wrap;
            min-height: 40px;
            font-size: 14px;
            border-bottom: 1px solid #d8d8d8;
            cursor: pointer;
        }
    }

    .img_person {
        margin-left: auto;
        display: block;
    }
}