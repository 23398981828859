@import "src/include";
#matchingBizDetail{
	.pc{
		.matchingBizDetail{
			width: 880px;
			margin: 0 auto;
			padding: 85px 0 65px;
			&_title-wrap{
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #e7e7e7;
				padding: 0 0 8px 0;
			}
			&_title{
				font-size: 22px;
				font-family: NanumSquareOTF_acB;
			}
			// &_date{
			// 	font-size: 14px;
			// 	color: #343434;
			// }
			&_btn-wrap{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				.btn{
					&_mod{
						display: block;
						width: 64px;
						height: 32px;
						line-height: 32px;
						background-color: #96c719;
						font-family: NanumSquareOTF_acEB;
						font-size: 16px;
						color: #fff;
						text-align: center;
						border-radius: 4px;
						margin-right: 8px;
					}
					&_del{
						display: block;
						width: 120px;
						height: 32px;
						line-height: 32px;
						background-color: var(--color-Alizarin-Crimson);
						font-family: NanumSquareOTF_acEB;
						font-size: 16px;
						color: #fff;
						text-align: center;
						border-radius: 4px;
					}
				}
			}
			&_subTitle{
				color: rgba(0, 0, 0, 0.87);
				padding: 8px 0;
				margin-bottom: 25px;
			}
			&_table{
				padding: 0 2px;
				&_row{
					display: flex;
					height: 46px;
					justify-content: space-between;
					align-items: center;
					&:nth-of-type(2){
						margin-top: 10px;
					}
					.col-1-3{
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: calc(100% / 3);
					}
					.col-2-3{
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: calc((100% / 3) * 2);
					}
					.title{
						width: 85px;
						font-family: NanumSquareOTF_acB;
						font-size: 14px;
						color: #343434;
					}
					.info{
						width: calc(100% - 85px);
						font-size: 14px;
						color: #343434;
					}
					.color-blue{
						font-family: NanumSquareOTF_acEB;
						color: #4593f5;
					}
					.font-big{
						font-size: 22px;
					}
					.font-strong{
						font-family: NanumSquareOTF_acB;
					}
					//.dDay{
					//	display: inline-block;
					//	width: 60px;
					//	height: 22px;
					//	line-height: 22px;
					//	font-family: NanumSquareOTF_acEB;
					//	font-size: 12px;
					//	text-align: center;
					//	color: #aaa;
					//	border-radius: 11px;
					//	border: 2px solid #6bd3ff;
					//	margin-left: 10px;
					//}
				}
				.borderBottom-gray{
					border-bottom: 1px solid #e7e7e7;
				}
			}
			&_file{
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 58px;
				border-bottom: 1px solid #e7e7e7;
				margin-bottom: 20px;
				&_text-wrap{
					display: flex;
					justify-content: flex-start;
					align-items: center;
					width: 100%;
					height: 100%;
				}
				&_title{
					width: 50px;
					font-family: NanumSquareOTF_acB;
					font-size: 14px;
					color: #343434;
				}
				&_text{
					display: flex;
					justify-content: flex-start;
					align-items: center;
					width: calc(100% - 50px);
					padding-left: 28px;
					font-size: 14px;
					color: rgba(0, 0, 0, 0.87);
					.icon_download_attached{
						display: block;
						width: 24px;
						height: 24px;
					}
					&_title{
						width: calc(100% - 34px);
						height: 16px;
						@include text-ellipsis(1);
						cursor: pointer;
					}
				}
			}
			&_box{
				width: 870px;
				margin: 0 auto 50px;
				&_title{
					position: relative;
					width: 100%;
					height: 30px;
					background-color: #d8d8d8;
					border-radius: 4px;
					line-height: 30px;
					padding-left: 38px;
					font-family: NanumSquareOTF_acB;
					font-size: 14px;
					color: #343434;
					&::after{
						position: absolute;
						content: '';
						width: 24px;
						height: 24px;
						background: url(../../../assets/images/common/icon-file.svg)no-repeat 50% 50%;
						background-size: cover;
						top: 50%;
						left: 10px;
						transform: translateY(-50%);
					}
				}
				&_text{
					padding: 10px 0;
					width: 780px;
					margin: 0 auto;
					font-size: 14px;
					line-height: 18px;
					color: rgba(0, 0, 0, 0.87);
					white-space: break-spaces;
				}
			}
			&_btn-wrap{
				text-align: center;
			}
			&_btn{
				display: block;
				width: 120px;
				height: 32px;
				line-height: 32px;
				font-size: 16px;
				color: #fff;
				background-color: #05ac9e;
				border-radius: 4px;
				margin: 0 auto;
			}
		}
	}
	.mo{
		.matchingBizDetail{
			margin-top: 60px;
			padding: 18px 20px 22px;
			background-color: #f0f0f0;
			&_box{
				background-color: #fff;
				border-radius: 8px;
				margin-bottom: 15px;
				padding: 8px 18px 15px;
				&_list{
					display: flex;
					align-items: flex-start;
					justify-content: center;
					flex-wrap: wrap;
					border-bottom: 1px solid #d8d8d8;
					padding: 9px 0;
					.matchingBizDetail_box_title{
						width: 100px;
						min-height: 22px;
						line-height: 22px;
						font-family: NanumSquareOTF_acEB;
						font-size: 12px;
						color: #959595;
					}
					.matchingBizDetail_box_data{
						width: calc(100% - 100px);
						min-height: 22px;
						line-height: 22px;
						font-family: NanumSquareOTF_acB;
						font-size: 12px;
						color: #606060;
					}
					&.style-title{
						.matchingBizDetail_box_title{
							width: 50px;
							color: #606060;
							&:nth-of-type(1){
								margin-bottom: 7px;
							}
						}
						.matchingBizDetail_box_data{
							width: calc(100% - 50px);
							font-family: NanumSquareOTF_acEB;
							font-size: 14px;
							color: #1d334e;
							padding-left: 5px;
							&.style-font{
								font-family: NanumSquareOTF_acB;
								color: #606060;
							}
							&:nth-of-type(1){
								margin-bottom: 7px;
							}
						}
					}
				}
				&_title{
					&.style-file{
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 8px 0 6px;
						.title{
							font-family: NanumSquareOTF_acEB;
							font-size: 12px;
							color: #606060;
						}
						.btn{
							width: 24px;
							height: 24px;
							text-indent: -9999px;
							background: url(../../../assets/images/common/icon-download_mobile.svg) no-repeat 50% 50%;
							background-size: cover;
						}
					}
				}
				&_data{
					&.style-font{
						font-family: NanumSquareOTF_acB;
						font-size: 12px;
						color: #606060;
					}
				}
				&.style-des{
					padding: 18px;
					.matchingBizDetail_box_title{
						font-family: NanumSquareOTF_acEB;
						font-size: 12px;
						color: #606060;
						margin-bottom: 10px;
					}
					.matchingBizDetail_box_data{
						font-family: NanumSquareOTF_acB;
						font-size: 12px;
						color: #606060;
					}
				}
			}
			&_btn{
				display: block;
				width: 206px;
				height: 42px;
				background-color: #4593f5;
				font-family: NanumSquareOTF_acEB;
				font-size: 16px;
				color: #fff;
				text-align: center;
				border-radius: 21px;
				margin: 0 auto;
			}
		}
	}
}