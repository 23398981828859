@import "src/include";

#SignupInput {
    margin: auto;
    padding-top: 100px;
    width: 880px;

    @import "./common.scss";

    @media screen and (max-width: 850px) {
        padding-top: 92px;
        width: 100vw;
        min-height: calc(100vh - 60px);
    }

    .pc {
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                font-size: 22px;
                font-family: NanumSquareOTF_acB;
            }

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 5px;
                width: 190px;
                height: 45px;
                font-size: 16px;
                font-family: NanumSquareOTF_acB;
                color: #343434;
                @include border-radius(4px);
                border: 1px solid #959595;
                background-color: #ffffff;
            }
        }

        .input_content {

            .box {
                margin-top: 53px;
                padding: 20px 0;
                @include border-radius(5px);
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
                border: solid 1px #e7e7e7;
                background-color: #ffffff;
            }

            .alert_notify {
                margin-bottom: 17px;
                padding-left: 48px;
                display: flex;
                align-items: flex-end;
                column-gap: 12px;

                p {
                    font-size: 16px;
                    font-family: NanumSquareOTF_acEB;
                    color: #606060;
                }
            }

            .icon_red_dot {
                position: absolute;
                top: 4px;
                left: -15px;
            }

            > .field {
                padding: 0 48px;
            }

            .field {
                margin-bottom: 30px;
                position: relative;
                display: flex;
                align-items: center;
                line-height: 32px;
                font-size: 14px;

                .f_head {
                    position: relative;
                    width: 100px;
                    font-family: NanumSquareOTF_acEB;
                }

                .f_content {
                    padding: 6px;
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 250px;
                    border-bottom: 1px solid #d8d8d8;

                    p {
                        width: 600px;
                    }

                    input, select {
                        padding: 0 10px 0 15px;
                        width: 100%;
                        height: 32px;
                        font-size: 14px;
                        border: none;
                    }

                    input.disabled {
                        color: #606060;
                        background-color: #f0f0f0;
                        pointer-events: none;
                    }

                    .alert_msg {
                        position: absolute;
                        left: 0;
                        bottom: -32px;
                        font-size: 11px;

                    }

                    .alert_msg.true {
                        color: #19c77c;
                    }

                    .alert_msg.false {
                        color: rgba(255, 0, 0, 0.8);
                    }

                    .btn_check {
                        width: 64px;
                        height: 24px;
                        font-size: 12px;
                        font-family: NanumSquareOTF_acB;
                        color: #ffffff;
                        @include border-radius(4px);
                        background-color: var(--color-Web-Orange);
                    }

                    .icon_calendar {
                        position: relative;
                        top: 4px;
                    }
                }

                .f_content.max {
                    width: 650px;
                }
            }

            .wrap_field {
                margin-bottom: 30px;
                padding: 0 48px;
                position: relative;
                display: flex;
                column-gap: 41px;

                .field {
                    margin-bottom: 0;
                }

                .field_txt {
                    align-items: flex-start;
                }

                textarea {
                    margin-top: 8px;
                    padding: 8px;
                    width: 640px;
                    height: 127px;
                    font-size: 14px;
                    border: 1px solid #e7e7e7;
                    resize: none;
                }
            }

            .patent {
                margin-bottom: 64px;
                display: block;
                width: 750px;

                .header {
                    margin-bottom: 30px;
                }

                ul {
                    li {
                        margin-left: auto;
                    }
                }

                .btns {
                    display: flex;

                    .btn_search_add {
                        margin-right: 10px;
                        width: 136px;
                        height: 32px;
                        font-size: 14px;
                        color: #ffffff;
                        text-align: center;
                        background-color: #96c719;
                    }

                    .btn_add {
                        width: 136px;
                        height: 32px;
                        font-size: 14px;
                        color: #ffffff;
                        text-align: center;
                        background-color: #3786c2;
                    }
                }
            }

            .product {
                display: block;
                width: 750px;

                .p_head {
                    margin-bottom: 20px;
                    width: 100%;
                }

                ul {
                    li.f_content {
                        margin-left: auto;
                        margin-bottom: 8px;

                        input {
                            width: 92%;
                        }
                    }
                }

                .btn_add {
                    float: right;
                    width: 650px;
                    background-color: #0095ae;
                }
            }

            .action_no {
                opacity: 0.3;
                pointer-events: none;
            }
        }

        .btns {
            margin-top: 50px;
        }

        .wrap_option {
            margin-top: 40px;

            .head {
                margin: 0 auto 7px auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 764px;

                h2 {
                    display: flex;
                    align-items: center;
                    column-gap: 12px;
                    font-size: 16px;
                    font-family: NanumSquareOTF_acEB;
                    line-height: 21px;
                    color: #606060;

                    i {
                        background-position: 0 -3px;
                    }
                }

                .ToggleButtonWithSpan {
                    span {
                        font-size: 16px;
                        font-family: NanumSquareOTF_acEB;
                        line-height: 21px;
                        color: #606060;
                    }
                }
            }

            .desc {
                padding-left: 93px;
                font-size: 12px;
                font-family: NanumSquareOTF_acB;
                line-height: 25px;
                color: #606060;
            }
        }

        .wrap_required {
            margin-top: 20px;
            text-align: right;

            p {
                position: relative;
                display: inline-block;
                font-size: 12px;
                font-family: NanumSquareOTF_acB;
                color: #606060;
            }

            .icon_red_dot {
                position: absolute;
                top: -5px;
                left: -15px;
            }
        }

        .alert {
            position: absolute;
            top: calc(100% + 6px);
            right: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 2px;
            font-size: 12px;
            font-family: NanumSquareOTF_acB;
            color: #606060;

            .icon_red_dot {
                top: 5px;
                left: -10px;
            }
        }
    }

    .mo {

        .wrap_step {
            padding-bottom: 50px;

            .step {
                margin: auto;
                width: 90%;

                .step_title {
                    margin-bottom: 28px;
                    font-size: 24px;
                    font-family: NanumSquareOTF_acEB;
                    color: #443018;
                    text-align: center;
                }

                .btn_kakao {
                    margin-top: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    column-gap: 13px;
                    min-width: 320px;
                    width: 100%;
                    height: 42px;
                    font-size: 16px;
                    font-family: NanumSquareOTF_acEB;
                    line-height: 22px;
                    color: #443018;
                    @include border-radius(4px);
                    background-color: #fae255;
                }

                .field {
                    position: relative;

                    .required {
                        position: absolute;
                        top: 3px;
                        right: 0;
                        font-size: 12px;
                        color: #606060;
                    }

                    .f_head,
                    .f_title {
                        margin-bottom: 8px;
                        font-size: 12px;
                        font-family: NanumSquareOTF_acEB;
                        line-height: 18px;
                        color: #443018;
                    }

                    .wrap_input {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        height: 40px;
                        border-radius: 4px;
                        background-color: #ffffff;

                        > p {
                            padding: 0 20px;
                            width: 100%;
                            height: 40px;
                            font-size: 14px;
                            line-height: 40px;
                            color: #606060;
                            border-radius: 4px;
                            border: 2px solid #d8d8d8;
                            background-color: #f0f0f0;
                        }

                        > p.birth {
                            padding-left: 45px;
                            background-color: #ffffff;

                            .react-datepicker-wrapper {
                                position: absolute;
                                top: 7px;
                                left: 7px;
                            }
                        }


                        .wrap_input.search {
                            input {
                                width: calc(100% - 40px);
                                z-index: 1;
                            }
                        }

                        .wrap_view {
                            margin-bottom: 29px;

                            ul {
                                max-height: 146px;
                                overflow-x: hidden;
                                overflow-y: scroll;
                                border-radius: 4px;
                                background-color: #ffffff;

                                li {
                                    padding: 8px 16px;
                                    display: flex;
                                    align-items: center;
                                    min-height: 40px;

                                    .empty {
                                        font-size: 12px;
                                        font-family: NanumSquareOTF_acB;
                                        line-height: 18px;
                                        color: #464646;
                                    }

                                    .info {
                                        width: 90%;

                                        h2 {
                                            font-size: 12px;
                                            font-family: NanumSquareOTF_acB;
                                            line-height: 18px;
                                            color: #464646;
                                        }

                                        p {
                                            font-size: 12px;
                                            line-height: 18px;
                                            color: #959595;
                                        }
                                    }

                                    .state {
                                        font-size: 12px;
                                        font-family: NanumSquareOTF_acB;
                                        line-height: 18px;
                                        color: #757575;
                                    }
                                }

                                li + li {
                                    border-top: 1px solid #d8d8d8;
                                }
                            }

                            button {
                                margin: 10px auto 0 auto;
                                display: block;
                                font-size: 12px;
                                font-family: NanumSquareOTF_acEB;
                                line-height: 18px;
                                color: #747474;
                                background-color: transparent;
                                text-decoration: underline;
                            }
                        }

                        .wrap_input_list {

                            .wrap_add {
                                padding: 0 16px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                height: 40px;
                                border-radius: 4px;
                                background-color: #ffffff;

                                .icon_plus {
                                    background: url("../../../assets/images/mobile/icon_plus.svg") no-repeat;
                                }

                                .btn_add_txt {
                                    width: 56px;
                                    font-size: 14px;
                                    font-family: NanumSquareOTF_acEB;
                                    line-height: 20px;
                                    color: var(--color-Web-Orange);
                                    white-space: pre;
                                    background-color: transparent;
                                }

                                .add_product {
                                    margin: 0 12px;
                                    width: calc(100% - 56px);
                                    height: 32px;
                                    font-size: 14px;
                                    font-family: NanumSquareOTF_acB;
                                    line-height: 20px;
                                    color: #606060;
                                    border: none;
                                }
                            }

                            ul {
                                li {
                                    position: relative;
                                    padding: 0 16px;
                                    display: flex;
                                    align-items: center;
                                    height: 40px;
                                    background-color: #ffffff;

                                    input {
                                        padding-left: 56px;
                                        padding-right: 40px;
                                        width: 100%;
                                        height: 38px;
                                        font-size: 14px;
                                        color: #959595;
                                        border: none;
                                        border-bottom: 1px solid #c9c9c9;
                                    }

                                    .icon_exit {
                                        position: absolute;
                                        right: 16px;
                                        width: 20px;
                                        height: 20px;
                                        background-size: contain;
                                    }
                                }
                            }
                        }

                        .wrap_input_validator {
                            display: flex;

                            input {
                                width: calc(75% - 8px);
                                height: 40px;
                            }

                            button {
                                margin-left: 8px;
                                width: 25%;
                                height: 40px;
                                font-size: 14px;
                                font-family: NanumSquareOTF_acEB;
                                color: #ffffff;
                                border-radius: 4px;
                                background-color: var(--color-Web-Orange);
                            }
                        }

                        .wrap_select {

                            select {
                                padding: 0 20px;
                                width: 100%;
                                height: 40px;
                                font-size: 14px;
                                font-family: NanumSquareOTF_acB;
                                color: #606060;
                                border-radius: 4px;
                                background-color: #ffffff;
                            }
                        }

                        .alert_msg {
                            margin-top: 8px;
                            font-size: 12px;
                            font-family: NanumSquareOTF_acB;
                            line-height: 18px;
                            color: var(--color-Web-Orange);
                        }

                        .alert {
                            margin-top: 8px;
                            display: flex;
                            align-items: center;

                            i {
                                margin-right: 6px;
                            }

                            p {
                                font-size: 12px;
                                font-family: NanumSquareOTF_acB;
                                line-height: 18px;
                                color: #959595;
                            }
                        }
                    }

                    .field + .field {
                        margin-top: 20px;
                    }

                    .alert {
                        margin: 8px 0 12px 0;
                        display: flex;
                        align-items: center;

                        i {
                            margin-right: 6px;
                        }

                        p {
                            font-size: 12px;
                            font-family: NanumSquareOTF_acB;
                            line-height: 18px;
                            color: #959595;
                        }
                    }

                    .btn {
                        margin-top: 24px;
                    }
                }
            }

            input {
                padding: 0 20px;
                width: 70%;
                height: 40px;
                font-size: 14px;
                color: #606060;
                border-radius: 4px;
                border: 2px solid #d8d8d8;
                background-color: #ffffff;
            }
            input[type="password"] {
                width: 100%;
            }
            input::placeholder {
                color: #959595;
            }
            input[type="text"]:disabled {
                opacity: 0.3;
                pointer-events: none;
            }
            .input_search_data {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 850px) {
        #SignupInput.add_page {
            background-color: #f0f0f0;

            .mo {
                .wrap_step {
                    .step {
                        .field {
                            .wrap_input {
                                padding-right: 16px;

                                input {
                                    border: none;
                                }

                                input::placeholder {
                                    font-size: 12px !important;
                                }

                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 850px) {
        .wrap_input_validator {
            input {
                width: 60%;
            }
            button {
                width: 77px;
                height: 40px;
                padding: 10px 14px;
                border-radius: 4px;
                background-color: #ffa600;
                color: white;
                margin-left: 8px;
            }
        }
        .form_ipnow {
            margin-top: 20px;
            .head {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }

            p:nth-of-type(1) {
                font-size: 12px;
                font-family: NanumSquareOTF_acEB;
                text-align: center;
            }

            p:nth-of-type(2) {
                display: flex;
                margin: 16px 0 0 10px;

                i {
                    width: 30px;
                    height: 30px;
                }

                span {
                    padding-right: 6px;
                    color: #959595;
                    font-size: 12px;
                    font-family: NanumSquareOTF_acB;
                    width: 80%;
                }
            }

            .ToggleButtonWithSpan {
                margin-top: 20px;
            }

            label {
                font-size: 12px;
                color: #443018;
                font-family: NanumSquareOTF_acEB;
            }

            .wrap_input {
                margin: 10px 0 10px 0;
                .btn_check {
                    background-color: #ffa600;
                }
                .keyword_list {
                    position: inherit;
                    top: 100%;
                    padding: 0 20px;
                    width: 100%;
                    max-height: 218px;
                    overflow-x: hidden;
                    overflow-y: scroll;
                    @include border-radius(4px);
                    border: 2px solid #d8d8d8;
                    border-top: none;
                    background-color: #ffffff;
                    z-index: 10;

                    li {
                        padding: 10px 0;
                        width: 100%;
                        min-height: 40px;
                        font-size: 14px;
                        color: #747474;

                        p {
                            margin-top: 4px;
                            span {
                                font-family: NanumSquareOTF_acB;
                            }
                        }
                    }

                    li+li {
                        border-top: 1px solid #d8d8d8;
                    }
                }
            }
        }
    }
}
