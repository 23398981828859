#RenewalDashBoard {
  margin-top: 60px;
  .show_dashboard {
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .popup_wrapper {
    width: 100%;
    height: 100vh;
    background-color: rgba(52, 52, 52, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    //transform: translate(-50%, -50%);

  }
  .popup {
    width: 280px;
    height: 360px;
    border-radius: 30px;
    background-color: rgba(52,52,52,0.9);
    position: absolute;
    z-index: 10;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 37px;
    text-align: center;
    color: #FFFFFF;
    .popup_info_text {
      line-height: 1.27;
      margin-bottom: 15px;
    }
    .green {
      color: #0d7;
    }
    .f14 {
      font-family: Pretendard_semiBold;
      font-size: 14px;
    }
    .f16 {
      font-size: 16px;
    }
    .f30 {
      font-size: 30px;
      font-family: Pretendard_extraBold;
    }
    .f32 {
      font-family: GmarketSansTTF_bold;
      font-size: 32px;
    }
    .g_sans {
      font-family: GmarketSansTTF_bold;
    }
    .gradient {
      background-image: linear-gradient(to right, #0078f1, #0d7);
      letter-spacing: -1px;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    button {
      height: 28px;
      border-radius: 14px;
      background-color: #0078f1;
      font-family: Pretendard_extraBold;
      padding: 0 12px;
      font-size: 14px;
      color: #FFFFFF;
    }
    i {
      position: absolute;
      top: 19px;
      right: 21px;
    }
  }
}
