@import "src/include";

#expositionMain{
	.pc{
		.expositionMain{
			width: 950px;
			margin: 0 auto;
			padding: 40px 0;
			&_box-wrap{
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				width: 880px;
				height: 110px;
				margin: 0 auto 45px;
				padding: 10px 15px;
				background-color: #f1f1f1;
				border-radius: 20px;
			}
			&_box{
				width: calc(100% - 123px);
				height: 100%;
				position: relative;
				padding: 20px 0 20px 160px;
				&::after{
					position: absolute;
					content: '';
					width: 67px;
					height: 53px;
					top: 50%;
					left: 40px;
					transform: translateY(-50%);
					background: url(../../../assets/images/exposition/ico_exposition_box.svg)no-repeat 50% 50%;
					background-size: cover;
				}
				&_title{
					font-family: NanumSquareOTF_acEB;
					font-size: 18px;
					color: #343434;
					margin-bottom: 10px;
				}
				&_sub{
					font-family: NanumSquareOTF_acB;
					font-size: 14px;
					color: #606060;
				}
				&_btn{
					width: 123px;
					height: 24px;
					text-indent: -9999px;
					background: url(../../../assets//images/common/icon_tutorial.png) no-repeat 50% 50%;
					background-size: cover;
				}
			}
			&_title-wrap{
				display: flex;
				width: 880px;
				margin: 0 auto 30px;
				justify-content: space-between;
				align-items: center;
			}
			&_text-wrap{
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
			&_title{
				width: 100px;
				font-family: NanumSquareOTF_acB;
				font-size: 22px;
				color: #343434;
			}
			&_menu{
				display: flex;
				justify-content: space-between;
				align-items: center;
				&_list{
					margin: 0 20px;
					button[type="button"]{
						font-family: NanumSquareOTF_acB;
						font-size: 18px;
						color: #343434;
						background-color: transparent;
					}
					&.on{
						button[type="button"]{
							font-family: NanumSquareOTF_acEB;
							color: var(--color-Web-Orange);
						}
					}
				}
			}
			&_fav{
				&_btn{
					position: relative;
					width: 140px;
					height: 32px;
					line-height: 30px;
					font-family: NanumSquareOTF_acEB;
					font-size: 14px;
					color: #343434;
					padding: 0 20px 0 40px;
					background-color: transparent;
					border: 2px solid #ffa600;
					border-radius: 4px;
					&::after{
						position: absolute;
						content: "";
						width: 16px;
						height: 16px;
						top: 50%;
						left: 15px;
						transform: translateY(-50%);
						background: url(../../../assets/images/common/icon-fav-on.svg) no-repeat 50% 50%;
						background-size: cover;
					}
				}
			}
			&_search{
				display: flex;
				width: 530px;
				height: 32px;
				margin: 0 auto 35px;
				justify-content: center;
				align-items: center;
				&_input-wrap{
					display: flex;
					height: 100%;
					justify-content: center;
					align-items: center;
					border: 1px solid #d8d8d8;
					border-radius: 4px;
					margin-right: 5px;
				}
				&_select{
					width: 110px;
					height: 100%;
					font-family: NanumSquareOTF_acB;
					font-size: 14px;
					color: #343434;
					padding-left: 10px;
					border: none;
				}
				&_input{
					width: 380px;
					height: 100%;
					input[type="search"]{
						width: 100%;
						height: 100%;
						border: none;
						background-color: transparent;
					}
				}
				&_btn{
					width: 24px;
					height: 24px;
					text-indent: -9999px;
					background-color: transparent;
					background: url(../../../assets/images/common/icon-search.svg) no-repeat 50% 50%;
					background-size: cover;
				}
			}
			&_table-top{
				height: 24px;
				margin-bottom: 10px;
				text-align: right;
				&_select{
					width: 110px;
					height: 100%;
					font-family: NanumSquareOTF_acB;
					font-size: 14px;
					color: #343434;
				}
			}
			&_table{
				border-top: 1px solid #d8d8d8;
				margin-bottom: 60px;
			}
			&_list{
				display: flex;
				height: 140px;
				justify-content: center;
				align-items: flex-start;
				border-bottom: 1px solid #d8d8d8;
				padding: 10px 20px;
				&_img{
					width: 165px;
					height: 100%;
					border: 1px solid #d8d8d8;
					margin-right: 10px;
					img{
						display: block;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				&_box{
					width: calc(100% - 175px);
					height: 100%;
					&_title{
						font-family: NanumSquareOTF_acB;
						font-size: 14px;
						color: #343434;
						padding: 5px 0;
						margin-bottom: 15px;
					}
					&_info-wrap{
						font-family: NanumSquareOTF_acB;
						font-size: 12px;
						color: #343434;
					}
					&_date{
						margin-bottom: 10px;
					}
					&_info{
						margin-bottom: 15px;
					}
					&_day{
						font-family: NanumSquareOTF_acR;
						.strong{
							font-family: NanumSquareOTF_acB;
							font-size: 14px;
							color: var(--color-Web-Orange);
							padding-left: 10px;
						}
					}
					&_fav{
						.btn_fav{
							width: 24px;
							height: 24px;
							overflow: hidden;
							text-indent: -9999px;
							&.on{
								background: url(../../../assets/images/common/icon-fav-on.svg) no-repeat 50% 50%;
								background-size: cover;
							}
							&.off{
								background: url(../../../assets/images/common/icon-fav-off.svg) no-repeat 50% 50%;
								background-size: cover;
							}
						}
					}
				}
			}
		}
	}
	.mo{
		.expositionMain{
			width: 100vw;
			overflow: hidden;
			&_top{
				width: 100%;
				height: 122px;
				margin: 60px 0 32px;
				padding-top: 32px;
				text-align: center;
				background-image: linear-gradient(to top, rgba(129, 202, 233, 0.2), rgba(128, 206, 183, 0.2));
			}
			&_title{
				font-family: NanumSquareOTF_acEB;
				font-size: 20px;
				color: #1d334e;
				margin-bottom: 12px;
			}
			&_subtitle{
				font-family: NanumSquareOTF_acB;
				font-size: 14px;
				color: #606060;
				text-align: center;
			}
		}
	}
}