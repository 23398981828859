@import "src/App";

#TradeMarkListView {
  margin: 30px auto;
  width: 80%;
  min-width: 1300px;

  @import "src/components/common/css/ListView";

  .trademark_class {
    p {
      @include text-ellipsis(1);
    }
  }

  table {
    th {
      height: 40px;
      font-size: 14px;
      font-family: NanumSquareOTF_acB;
      color: #ffffff;
      word-break: break-all;
    }
    thead {
      background-color: var(--color-Cloud-Burst);
      .apply-number-text {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .sort-options {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100px;
      max-height: 150px;
      background-color: white;
      color: black;
      border: 1px solid #0095ae;
      z-index: 2;
      label {
        display: flex;
        align-items: center;
        min-height: 32px;
        font-size: 12px;
        font-family: Pretendard_semiBold;
        padding: 0 10px;
        input[type="checkbox"] {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
      }
    }
  }
}
