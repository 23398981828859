#ResearchTechRequestEdit {
  margin: auto;
  padding-top: 40px;
  max-width: 880px;

  .tutorial-box {
    margin: 0 auto 35px auto;
    padding-left: 45px;
    display: flex;
    column-gap: 70px;
    width: 880px;
    height: 110px;
    border-radius: 20px;
    background-color: rgba(241, 241, 241, 0.3);

    .wrap_image {
      display: flex;
      align-items: center;
    }

    img {
      width: 63px;
      height: 55px;
    }

    .inner {
      padding-top: 10px;
      height: 100%;

      h2 {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: NanumSquareOTF_acEB;
        line-height: 30px;
        color: #343434;
      }

      p {
        font-size: 14px;
        font-family: NanumSquareOTF_acB;
        color: #606060;
      }
    }
  }

  .contents {
    .page_title {
      margin-bottom: 20px;
      font-size: 22px;
      font-family: NanumSquareOTF_acB;
      line-height: 25px;
    }

    .field {
      margin-top: 25px;

      p {
        margin-bottom: 10px;
        font-size: 14px;
        font-family: NanumSquareOTF_acB;
      }

      input {
        padding: 0 15px;
        width: 100%;
        height: 40px;
        border: 1px solid var(--color-Alto);

        &::placeholder {
          color: var(--color-Dusty-Gray);
        }
      }

      .inputs {
        display: flex;
        align-items: center;
        column-gap: 13px;

        input {
          width: 210px;
        }
      }

      textarea {
        padding: 15px;
        width: 100%;
        height: 246px;
        border: 1px solid var(--color-Alto);
        resize: none;

        &::placeholder {
          color: var(--color-Dusty-Gray);
        }
      }

      input,
      textarea {
        font-size: 14px;
      }
    }
  }

  .btn_request {
    margin: 35px auto 0 auto;
    display: block;
    width: 120px;
    height: 32px;
    font-size: 16px;
    font-family: NanumSquareOTF_acEB;
    color: #ffffff;
    border-radius: 4px;
    background-color: #19c77c;

    &::placeholder {
      color: var(--color-Dusty-Gray);
    }
  }
}