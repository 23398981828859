.tooltip {
  position: absolute;
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  white-space: pre-wrap;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);

  &-top {
    transform: translateY(-5%);
  }

  &-bottom {
    transform: translateY(5%);
  }

  &-left {
    transform: translateX(-5%);
  }

  &-right {
    transform: translateX(5%);
  }
}
