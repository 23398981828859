#Terms {
    margin: auto;
    padding-top: 29px;
    width: 710px;

    h2 {
        margin-bottom: 46px;
        font-size: 24px;
        font-family: NanumSquareOTF_acB;
        text-align: center;
        color: rgba(0, 0, 0, 0.87);
    }

    p {
        padding: 20px;
        max-height: 600px;
        overflow-x: hidden;
        overflow-y: scroll;
        font-size: 14px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.95);
    }

    .btn_confirm {
        margin: 50px auto 47px auto;
        display: block;
        width: 208px;
        height: 42px;
        font-size: 16px;
        font-family: NanumSquareOTF_acEB;
        color: #ffffff;
        border-radius: 21px;
        background-color: var(--color-Web-Orange);
    }

    @media screen and (max-width: 850px){
        margin: auto;
        padding-top: 92px;
        width: 90vw;

        h2 {
            margin-bottom: 32px;
            font-size: 24px;
            font-family: NanumSquareOTF_acEB;
            line-height: 30px;
            color: #443018;
        }

        p {
            height: 327px;
            padding: 18px 23px;
            font-size: 12px;
            font-family: NanumSquareOTF_acB;
            color: #747474;
            border-radius: 4px;
            background-color: #f0f0f0;
        }
    }
}
