@import "src/include";

#TradeMain {

    .pc {
        position: relative;

        .banner {
            position: relative;
            width: 100%;
            height: 200px;
            background-color: #e6f3e6;
            z-index: -1;

            .txt {
                margin: auto;
                padding-top: 33px;
                width: 880px;

                p:first-child {
                    margin-bottom: 8px;
                    height: 50px;
                    font-size: 22px;
                    font-family: NanumSquareOTF_acB;
                }

                p:nth-child(2) {
                    font-size: 22px;
                    line-height: 43px;
                    font-family: NanumSquareOTF_acB;
                }

                p:last-child {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.87);
                }
            }

            img {
                position: absolute;
                top: -39px;
                right: 15%;
            }
        }

        .wrap {
            .w_head {
                padding: 30px 0;
                display: flex;
                justify-content: center;
                column-gap: 9px;

                .search_area {

                    .title {
                        margin-bottom: 19px;
                        font-size: 22px;
                        font-family: NanumSquareOTF_acB;
                    }
                    .inner {
                        position: relative;
                        width: 585px;
                        display: flex;
                        align-items: center;

                        select {
                            position: absolute;
                            top: 0;
                            left: 0;
                            padding-left: 15px;
                            width: 130px;
                            height: 45px;
                            font-size: 14px;
                            font-family: NanumSquareOTF_acB;
                            color: #000000;
                            border: none;
                        }

                        input {
                            padding-left: 140px;
                            width: 465px;
                            height: 45px;
                            font-size: 14px;
                            color: #959595;
                            border-top-left-radius: 5px;
                            border-bottom-left-radius: 5px;
                            border: solid 1px #d8d8d8;
                        }

                        .btn_search {
                            width: 60px;
                            height: 45px;
                            font-size: 16px;
                            font-family: NanumSquareOTF_acEB;
                            color: #ffffff;
                            border-top-right-radius: 5px;
                            border-bottom-right-radius: 5px;
                            background-color: var(--color-Web-Orange);
                            background-position: center;
                        }

                        .icon_condition {
                            margin-left: 6px;
                            width: 53px;
                            height: 45px;
                            @include border-radius(5px);
                            border: solid 1px #d8d8d8;
                            background-color: #ffffff;
                            background-position: center;
                        }
                    }
                }

                .my_trade_area {

                    .title {
                        margin-bottom: 19px;
                        font-size: 22px;
                        font-family: NanumSquareOTF_acB;
                    }

                    .inner {
                        display: flex;
                        align-items: center;
                        column-gap: 9px;

                        .btn_my_trade,
                        .btn_trade_post {
                            height: 45px;
                            @include border-radius(4px);
                        }

                        .btn_my_trade {
                            width: 130px;
                            font-size: 16px;
                            font-family: NanumSquareOTF_acB;
                            border: solid 2px var(--color-Web-Orange);
                            background-color: #ffffff;
                        }

                        .btn_trade_post {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 150px;
                            color: #ffffff;
                            background-color: var(--color-Web-Orange);

                            i {
                                width: 24px;
                                height: 24px;
                                background-size: contain;
                            }

                            span {
                                width: 79px;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            .w_head.border_line {
                border-bottom: 1px solid #e7e7e7;
            }

            .wrap_list {
                margin: 30px auto 0 auto;
                width: 1280px;

                .l_head {
                    margin-bottom: 28px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        font-size: 22px;
                        font-family: NanumSquareOTF_acB;

                        span {
                            margin-left: 8px;
                            font-size: 16px;
                            font-family: NanumSquareOTF_acR;
                        }
                    }

                    a {
                        display: flex;
                        align-items: center;
                        column-gap: 8px;

                        span {
                            font-size: 11px;
                        }
                    }
                }

                .list {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 16px;
                    row-gap: 28px;
                }

                .item {
                    display: block;
                    width: 240px;
                    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
                    background-color: #ffffff;

                    img {
                        margin-bottom: 7px;
                        width: 100%;
                        height: 169px;
                        object-fit: contain;
                        border-radius: 4px;
                        background-color: #ffffff;
                    }

                    .p_class {
                        font-size: 14px;
                        font-family: NanumSquareOTF_acB;
                        line-height: 16px;
                        color: var(--color-Web-Orange);
                        @include text-ellipsis(1);
                        border-top: 1px solid #f4f4f4;
                    }

                    .p_name {
                        font-size: 14px;
                        font-family: NanumSquareOTF_acB;
                        line-height: 30px;
                    }

                    .inner {
                        display: flex;
                        justify-content: space-between;

                        .proposal {
                            width: 130px;
                            height: 26px;
                            font-size: 12px;
                            font-family: NanumSquareOTF_acEB;
                            line-height: 26px;
                            color: #ffffff;
                            text-align: center;
                            @include border-radius(4px);
                            background-color: var(--color-Web-Orange);
                        }

                        .proposal.disable {
                            background-color: #e6e6e6;
                        }

                        .price {
                            font-size: 18px;
                            font-family: NanumSquareOTF_acEB;
                            line-height: 24px;
                            color: var(--color-Web-Orange);
                        }
                    }

                    .p_class,
                    .p_name,
                    .inner {
                        padding: 0 5%;
                    }

                    .p_class {
                        padding: 5% 5% 0 5%;
                    }

                    .inner {
                        padding: 0 5% 5% 5%;
                    }
                }

                .search_result {
                    margin-bottom: 15px;
                    color: #747474;

                    p+p {
                        margin-top: 15px;
                    }
                }

                .btn_more {
                    margin: 50px auto 0 auto;
                    position: relative;
                    display: block;
                    width: 233px;
                    height: 52px;
                    line-height: 50px;
                    padding: 0 30px;
                    font-size: 17px;
                    font-family: NanumSquareOTF_acB;
                    color: #ffffff;
                    @include border-radius(27px);
                    background-color: var(--color-Web-Orange);
                }

                .slick-list {
                    .item {
                        box-shadow: none;
                    }
                }
            }

            .bg {
                padding: 1.5% 0 ;
                width: 100%;
                height: 330px;
                background: rgba(198,119,0, .1);

                .wrap_list {
                    margin-top: 0;

                    .l_head {
                        margin-bottom: 10px;
                    }

                    .item {
                        img {
                            height: 140px;
                        }
                    }
                }

                .slick-track {
                    margin: 0;
                }
            }
        }

        .slick-prev:before,
        .slick-next:before {
            position: relative;
            top: -35px;
            opacity: 1;
            font-size: 65px;
        }

        .slick-prev:before {
            position: relative;
            left: -50px;
        }
    }

    .mo {
        .banner {
            padding-top: 100px;
            height: 200px;
            background-color: rgba(128, 206, 183, 0.2);
            text-align: center;

            h2 {
                margin-bottom: 13px;
                font-size: 20px;
                font-family: NanumSquareOTF_acEB;
            }

            p {
                font-size: 14px;
                font-family: NanumSquareOTF_acB;
                line-height: 20px;
                color: #606060;
            }
        }

        .search_area,
        .wrap_list {
            margin: auto;
            width: 90%;
        }

        .search_area {
            margin-top: 8px;

            .s_head {
                margin-bottom: 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h2 {
                    font-size: 18px;
                    font-family: NanumSquareOTF_acEB;
                    color: #443018
                }

                button {
                    width: 120px;
                    height: 26px;
                    font-size: 12px;
                    font-family: NanumSquareOTF_acEB;
                    color: #ffffff;
                    @include border-radius(4px);
                    background-color: var(--color-Web-Orange);
                }
            }

            .wrap_search_form {
                margin-bottom: 15px;

                .search_form {
                    position: relative;

                    select {
                        position: absolute;
                        padding: 0 9px;
                        width: 95px;
                        height: 38px;
                        font-size: 12px;
                        font-family: NanumSquareOTF_acB;
                        color: #747474;
                        border: none;
                    }


                    input {
                        padding: 0 35px 0 100px;
                        width: 100%;
                        height: 38px;
                        font-size: 14px;
                        color: #443018;
                        @include border-radius(3px);
                        border: 2px solid #d8d8d8;
                    }

                    input::placeholder {
                        color: #959595;
                    }

                    button {
                        position: absolute;
                        top: 6px;
                        right: 8px;
                    }
                }

                .btn_search_add {
                    margin-top: 12px;
                    margin-left: auto;
                    display: block;
                    width: 120px;
                    height: 26px;
                    font-size: 12px;
                    font-family: NanumSquareOTF_acEB;
                    line-height: 18px;
                    color: var(--color-Web-Orange);
                    @include border-radius(4px);
                    border: solid 2px var(--color-Web-Orange);
                    background-color: #ffffff;
                }

                .btn_price {
                    margin-top: 12px;
                    position: absolute;
                    right: 5%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 85px;
                    height: 25px;
                    font-size: 12px;
                    font-family: NanumSquareOTF_acB;
                    color: #747474;
                    @include border-radius(3.5px);
                    border: 1px solid #d8d8d8;
                    background-color: #ffffff;

                    i {
                        margin-top: 4px;
                    }
                }
            }
        }

        .floating {
            padding: 0 12px;
            position: fixed;
            display: flex;
            align-items: center;
            width: 150px;
            height: 56px;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
            @include border-radius(28px);
            background-color: var(--color-Web-Orange);
            right: 20px;
            bottom: 60px;
            z-index: 1;

            p {
                margin-left: 9px;
                height: 20px;
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
                color: #ffffff;
            }
        }
    }
}