#AdminService {
  margin: auto;
  position: relative;
  width: 780px;
  min-height: calc(100vh - 200px);

  .category_tab {
    position: absolute;
    top: 41px;
    left: -150px;
    min-width: 120px;

    h2 {
      margin-bottom: 16px;
      font-size: 22px;
      font-family: NanumSquareOTF_acB;
    }

    ul {
      li {
        font-size: 14px;
        color: #343434;
        cursor: pointer;
      }

      li.active {
        font-family: NanumSquareOTF_acB;
        color: #ffa600;
      }

      li + li {
        margin-top: 15px;
      }
    }
  }

  table {
    th:nth-child(2),
    td:nth-child(2) {
      text-align: left;
    }
  }

  .btns {
    position: absolute;
    top: 40px;
    right: 0;
  }

  .btn_pin {
    width: 64px;
    height: 32px;
    color: #ffffff;
    background-color: var(--color-Web-Orange);
  }

  .btn_pin.on {
    background-color: #6c6c6c;
  }

  .btn_post {
    position: relative;
    top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 32px;
    font-size: 16px;
    color: #ffffff;
    background-color: var(--color-Alizarin-Crimson);
  }
}
