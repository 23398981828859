@import "src/include";
#TradeList {
    padding-bottom: 30px;

    .wrap_list {
        padding-bottom: 30px;

        ul {
            margin-bottom: 22px;
            height: 26px;
            display: flex;
            align-items: center;

            .wrap_title {
                font-size: 20px;
                font-family: NanumSquareOTF_acEB;
                color: #443018;
                opacity: 0.3;
            }

            .wrap_title+.wrap_title {
                margin-left: 17px;
            }

            .wrap_title.active {
                opacity: 1;
            }
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            column-gap: 16px;
            row-gap: 20px;

            .item {
                width: 152px;

                img {
                    margin-bottom: 8px;
                    min-width: 152px;
                    width: 100%;
                    min-height: 100px;
                    height: 100px;
                    object-fit: contain;
                    @include border-radius(4px);
                    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
                }

                .category {
                    margin-bottom: 4px;
                    padding: 0 8px;
                    font-size: 12px;
                    font-family: NanumSquareOTF_acEB;
                    line-height: 18px;
                    color: var(--color-Web-Orange);
                    @include text-ellipsis(1);
                }

                .name {
                    margin-bottom: 2px;
                    padding: 0 8px;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acEB;
                    color: #443018;
                    @include text-ellipsis(1);
                }

                .price {
                    padding: 0 8px;
                    font-size: 16px;
                    font-family: NanumSquareOTF_acEB;
                    color: #747474;
                }
            }

            .not_data {
                font-size: 14px;
                color: #747474;
            }
        }
    }

    .btn_more {
        margin: auto;
        display: block;
        width: 190px;
        height: 40px;
        font-size: 16px;
        font-family: NanumSquareOTF_acEB;
        color: #747474;
        text-align: center;
        @include border-radius(20px);
        border: solid 2px #959595;
        background-color: #ffffff;
    }
}