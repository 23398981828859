#Modal_table {
  z-index: 999;
  width: 1400px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  border-radius: 30px;
  box-shadow: 0 2px 4px 0 #d8d8d8;
  > strong {
    display: block;
    margin-bottom: 10px;
  }
  .close {
    position: absolute;
    right: 30px;
    top: 30px;
    background: transparent;
  }
  .more {
    background: transparent;
    color: #0078f1;
    border: 1px solid #0078f1;
    padding: 4px 12px;
    border-radius: 20px;
  }
  .tbl_box {
    max-height: none;
    .popup {
      position: absolute;
      top: 100%; /* 셀 바로 아래 */
      left: 50%; /* 셀의 가운데 */
      transform: translateX(-50%); /* 가운데 정렬 */
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 10px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 1000; /* 팝업을 다른 요소 위에 표시 */
      white-space: nowrap; /* 텍스트 줄바꿈 방지 */
      text-align: center; /* 텍스트 중앙 정렬 */
    }
    tbody {
      > tr {
        &:hover {
          cursor: pointer;
          background-color: rgba(107, 211, 255, 0.2);
        }
      }
    }
  }
  .grade {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 22px;
    border-radius: 8px;
  }
}
