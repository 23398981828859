.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 26px 0;

  & > div:first-of-type {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > button {
      position: relative;
      top: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 132px;
      height: 32px;
      font-size: 16px;
      color: #ffffff;
      background-color: var(--color-Web-Orange);
    }

    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  & > div:last-child {
    overflow-y: auto;
    max-height: 600px;
  }

  .actions-cell {
    & > div {
      display: flex;
      justify-content: center;
      gap: 8px;

      & > button {
        padding: 5px;
        color: white;

        &:first-child {
          background-color: #88f600;
        }
        &:last-child {
          background-color: red;
        }
      }
    }
  }

  // SelectBox custom modifications
  .select-div {
    & > button {
      background-color: transparent;
      color: black;
    }

    & > ul {
      color: #000;
      width: auto;
      z-index: 10;

      li {
        border: 1px solid black;
        background-color: white;
        white-space: nowrap;

        &:hover {
          background-color: #4593f5;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #4593f5;
    border-radius: 16px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #1e59a1;
  }

  thead tr {
    position: sticky;
    top: 0;
    background-color: #f9f9f9;
    z-index: 1;
  }
}
