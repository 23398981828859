.wrapper {
  display: flex;
  justify-content: center;
  margin: auto;
}
.rnd_wrapper {
  width: 1010px;
}

input[type="text"] {
  height: 42px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 14px;
  color: black;
  padding: 12px 0px 12px 10px;
  input::placeholder {
    color: #959595;
    padding: 12px 0 12px 10px;
  }
}

.rnd_plan {
  border-radius: 15px;
  box-shadow: 0 2px 3px 1px #f0f0f0;
  padding-left: 24px;
  color: #343434;
  padding-bottom: 40px;
}

.big_text {
  font-size: 18px;
  font-family: NanumSquareOTF_acEB;
}

.small_text {
  font-size: 14px;
  font-family: NanumSquareOTF_acB;
}

.extra_text {
  font-family: NanumSquareOTF_acB;
  font-size: 12px;
  color: #606060;
}

.blue_color_text {
  font-family: NanumSquareOTF_acEB;
  font-size: 14px;
  color: #4593f5;
}
.blue_bg_text {
  background-color: rgba(107, 211, 255, 0.2);
  width: 1010px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  p {
    font-family: NanumSquareOTF_acB;
    font-size: 16px;
    color: #343434;
  };
  div {
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
    }
    i {
      width: 24px;
      height: 17px;
      margin: 0 15px 0 6px;
    }
  }
}
.text_letter_spacing {
  letter-spacing: 20px;
}

.text_letter_spacing_10 {
  letter-spacing: 10px;
}

.height_100 {
  input {
    height: 100px;
  }
}
.plan_radio_btn {
  height: 30px;
  background: rgba(240, 240, 240, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px;
  border-radius: 14px;
  padding: 5px 15px 5px 9px;
  input[type="radio"] {
    width: 14px;
    height: 14px;
  }
  label {
    font-size: 12px;
    color: #606060;
    padding-left: 6px;
    display: inline-block;
  }
}

.plan_grid_items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  font-size: 14px;
  font-family: NanumSquareOTF_acEB;
  color: #343434;
  .plan_grid_item {
    display: flex;
    align-items: center;
    p {
      width: 110px;
      padding-right: 13px;
      span {
        color: #606060;
        font-size: 12px;
        padding: 1px 0 0 18px;
      }
    }
  }
  .right_align_item{
    width: 100%;
    grid-column: auto / span 2;
    justify-content: end;
    padding-right: 14px;
  }
}

textarea {
  width: 858px;
  border-radius: 4px;
  border: solid 1px #d8d8d8;
  resize: none;
  padding: 5px;
}

.head_text {
  display: flex;
  align-items: center;
}

.content_length {
  position: absolute;
  bottom: 10px;
  right: 41px;
  font-family: NanumSquareOTF_acR;
  font-size: 12px;
  color: #606060;
}
