@import "../../../include";

#CyberAccount {
    @import "Common.scss";

    .page_title {
        margin: auto;
        width: 302px;
        font-size: 24px;
        font-family: NanumSquareOTF_acEB;
        line-height: 32px;
    }

    .form {
        margin: 39px auto 16px auto;
        @include border-radius(3px);
    }
}