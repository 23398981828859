// ManageCompanyInfo.module.scss
.manageCompanyInfo {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  gap: 20px;

  .wrapper {
    width: calc(100% - 150px);

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      h1 {
        font-size: 24px;
        font-weight: bold;
      }
    }

    main {
      width: 100%;
    }

    .saveButton {
      width: 95px;
      font-size: 16px;
      color: #fff;
      background-color: #0984e3;
      padding: 8px 12px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: #066db5;
      }

      &:active {
        background-color: #055a99;
      }
    }
  }
}
