#ChartInput {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  .field {
    position: relative;
    padding: 16px 0;
    display: flex;
    column-gap: 14px;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    width: 980px;

    span {
      padding-right: 14px;
      display: flex;
      align-items: center;
      min-width: 70px;
      width: 70px;
      max-width: 70px;
      font-family: NanumSquareOTF_acB;
      border-right: 1px solid #ddd;
    }

    input,
    select {
      font-size: 14px;
    }

    input[type="text"] {
      padding: 10px;
      width: 100%;
      border: 1px solid #ddd;
    }

    .btn_search {
      width: 100px;
    }

    select {
      width: 135px;
      height: 38px;
    }

    p {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    .icon_badge_del {
      position: absolute;
      top: 23px;
      right: 100px;
      border: none;
    }

    .keyword_list {
      position: absolute;
      top: 53px;
      left: 84px;
      padding: 0 18px;
      width: 703px;
      max-height: 400px;
      background-color: #ffffff;
      border: 1px solid #d8d8d8;
      overflow-y: scroll;
      z-index: 9;

      li {
        padding: 10px 0;
        display: flex;
        align-items: center;
        column-gap: 10px;
        flex-wrap: wrap;
        min-height: 40px;
        border-bottom: 1px solid #d8d8d8;
        cursor: pointer;
      }
    }
  }

  .ctl_btns {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }

  .btn {
    display: block;
    width: 132px;
    height: 48px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    background-color: #4593f5;
  }

  .btn_excel {
    background-color: #96c719;
  }

  .btn_reset {
    background-color: var(--color-Alizarin-Crimson);
  }
}
