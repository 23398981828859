@import "src/include";
@import "src/mixin";

#TaskSearchWarp {
  max-width: 1280px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin: 35px auto 35px;

  .ip_search {
    .auto_calc_annual_fee {
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 565px;
      height: 190px;
      border-radius: 5px;
      background-color: #f6f5f9;
      box-shadow: 0 2px 3px 1px #f0f0f0;

      .txt {
        margin-left: 25px;
        margin-top: 15px;
        .desc {
          font-size: 22px;
          font-family: NanumSquareOTF_acEB;
          color: #343434;
          letter-spacing: 0.3px;

          .color_orange {
            font-family: NanumSquareOTF_acB;
            font-size: 40px;
            color: #ffa600;
            letter-spacing: 0.43px;
          }

          .color_purple {
            font-size: 25px;
            color: #807aff;
          }
        }
      }

      .search {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        margin: 11px 0 0 25px;
        font-family: NanumSquareOTF_acR;
        input {
          width: 389px;
          height: 50px;
          font-size: 14px;
          border: none;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          padding: 15px 15px 15px 18px;
        }

        input::placeholder {
          font-size: 14px;
          color: #aaaaaa;
        }

        .btn_search {
          width: 60px;
          height: 50px;
          font-size: 16px;
          color: #ffffff;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          background-color: #ffffff;
          background-position: center;
        }
      }
    }


    .search_form {
      position: relative;
      bottom: 7vw;
      display: flex;
      align-items: center;
      margin: auto;
      padding: 0 8px;
      width: 90%;
      min-height: 40px;
      height: 11vw;
      border: 2px solid var(--color-Web-Orange);
      @include border-radius(8px);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      z-index: 1;

      p {
        position: absolute;
        left: 10vw;
        font-size: 3.8vw;
        font-family: NanumSquareOTF_acB;
        color: #959595;

        span {
          margin-right: 8px;
          display: inline-block;
          font-family: NanumSquareOTF_acEB;
          color: var(--color-Web-Orange);
        }
      }

      input {
        width: 90%;
        font-size: 3.8vw;
        font-family: NanumSquareOTF_acB;
        color: #959595;
        border: none;
        background-color: transparent;
        z-index: 2;
      }

      input:focus {
        outline: none;
      }

      .icon_m_search {
        margin-right: 8px;
        min-width: 24px;
        width: 6vw;
        min-height: 24px;
        height: 6vw;
        background-size: contain;
      }
    }

    .use_notify {
      padding: 5.5vw 20px 0 20px;
      position: absolute;
      top: -8px;
      left: 0;
      display: flex;
      align-items: center;
      column-gap: 4px;
      width: 100%;
      height: 17vw;
      font-size: 14px;
      font-family: NanumSquareOTF_acEB;
      color: #959595;
      background: rgba(240, 240, 240, 0.5);
    }
  }

  .keyword_list {
    position: absolute !important;
    top: 100%;
    width: 88% !important;
    max-height: 445px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-left: solid 1px #e7e7e7;
    border-right: solid 1px #e7e7e7;
    border-bottom: solid 1px #e7e7e7;
    background-color: #ffffff;
    z-index: 10;
    border-radius: 5px;
    margin-top: 3px;

    li {
      margin-top: 3px;
      padding-bottom: 3px;
      width: 100%;
      text-align: left;
      background-color: #ffffff;
      cursor: pointer;

      div {
        margin: 0 15px;
        padding: 3px 0;
        display: flex;
        border-bottom: solid 1px #e7e7e7;

        h2,
        p {
          padding: 4px 0;
          display: flex;
          align-items: center;
          min-height: 32px;
        }

        h2 {
          padding-right: 8px;
          min-width: 82px;
          width: 82px;
          font-size: 14px;
          font-family: NanumSquareOTF_acB;
        }

        p {
          width: 446px;
          font-size: 12px;
        }
      }
    }

    li:first-child {
      padding-top: 7px;
      border-top: solid 1px #e7e7e7;
    }

    li:last-child {
      padding-bottom: 7px;
      border-bottom: solid 1px #e7e7e7;
    }

    .active:hover div {
      background-color: rgba(255, 166, 0, 0.1);
    }

    .active:hover h2 {
      color: var(--color-Web-Orange);
    }
  }
}
