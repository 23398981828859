#Chat {
    position: fixed;
    right: 200px;
    bottom: 50px;
    z-index: 999;

    #ChatIcon {
        position: relative;

        .icon_notify {
            position: absolute;
            top: 0;
            right: 0;
        }

        .icon_chat {
            cursor: pointer;
        }
    }

    #ChatRoom {
        width: 400px;
        border-radius: 5px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;

        .header {
            padding: 10px 22px 0 22px;
            display: flex;
            justify-content: space-between;
            height: 40px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            background-color: #343434;

            .title {

                h2 {
                    margin-bottom: 4px;
                    font-size: 22px;
                    font-family: NanumSquareOTF_acB;
                    color: #ffffff;
                }

                p {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    color: #ffffff;

                    .circle {
                        margin-right: 8px;
                        display: block;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: #07ec0a;
                    }
                }
            }

            .btns {
                .icon_minimize {
                    margin-right: 8px;
                }
            }

            button {
                color: #ffffff;
                background-color: transparent;
            }
        }


        .chat_message {
            padding: 10px 23px;
            height: 50vh;
            overflow-y: scroll;

            .history_day {
                margin: 7px 0 5px 0;
                font-size: 12px;
                line-height: 1.25;
                letter-spacing: -0.4px;
                text-align: center;
                color: rgba(0, 0, 0, 0.87);
            }

            .chat_log {
                margin-bottom: 5px;
                clear: both;

                .sender_info {
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;

                    .icon_user_profile {
                        margin-right: 8px;
                    }

                    p {
                        font-family: NanumSquareOTF_acEB;
                        color: #343434;
                    }
                }

                .message {
                    padding: 4px 11px;
                    display: inline-block;
                    max-width: 260px;
                    font-size: 14px;
                    color: #343434;
                    word-break: keep-all;
                    border-radius: 12.5px;
                    background-color: #f0f0f0;
                }

                .history_time {
                    padding-left: 11px;
                    text-align: left;
                }
            }

            .chat_log.my_chat {
                float: right;

                .message {
                    margin-left: auto;
                    color: #ffffff;
                    background-image: linear-gradient(to right, #1bb6db, #1e7af1);
                }

                .history_time {
                    text-align: right;
                }
            }

            .history_time {
                padding-top: 4px;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.87);
            }

            .chat_end {
                margin-top: 12px;
                font-size: 12px;
                text-align: center;
                color: rgba(255, 0, 0, 0.8);
            }
        }

        .chat_input {
            position: relative;
            padding-left:36px;
            display: flex;
            align-items: center;
            height: 57px;
            border-top: solid 1px #dcdcdc;

            input {
                margin-right: 12px;
                padding-top: 3px;
                width: 300px;
                height: 24px;
                font-size: 14px;
                border: none;
                resize: none;
            }

            input:focus {
                outline: none;
            }

            .icon_send {
                position: absolute;
                right: 21px
            }
        }
    }

    .none {
        opacity: 0;
    }

    .disable {
        opacity: 0.3;
        pointer-events: none;
    }
}
