#CommonAttachPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    padding: 35px 45px;
    width: 780px;
    height: 480px;
    border: 1px solid #d8d8d8;
    background-color: #fff;
    z-index: 9;

    .header {
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 22px;
            font-family: NanumSquareOTF_acB;
            color: #343434;
        }

        > div {
            display: flex;
            align-items: center;
            column-gap: 10px;
        }

        .btn_upload {
            width: 120px;
            line-height: 32px;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
            background-color: #96c719;
        }
    }

    th:nth-child(2),
    td:nth-of-type(2) {
        text-align: left;
    }

    .wrap_table {
        height: 350px;
        overflow-y: scroll;
    }
}