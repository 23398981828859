#CompetitorFinanceStatus {
  min-width: 400px;
  padding: 30px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 9999;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  .sub_title {
    margin-bottom: 20px;
    > p:nth-child(1) {
      font-size: 18px;
      letter-spacing: -0.5px;
      color: #0635e3;
      > span {
        font-weight: normal;
        font-size: 14px;
      }
    }
  }
  .tbl_box {
    max-height: 580px;
    overflow-y: auto;
    table {
      thead {
        background-color: transparent;
      }
      th {
        color: #333;
        border: 1px solid #333;
        padding: 4px 6px;
        height: auto;
      }
      td {
        color: #333;
        border: 1px solid #333;
        padding: 4px 6px;
        height: auto;
        &:not(.name) {
          text-align: right;
        }
      }
    }
  }
  .close {
    position: absolute;
    right: 30px;
    top: 30px;
  }
}
