.annual_mail_page {
  max-width: 540px;
  width: 100%;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  margin: 30px auto;

  .top {
    background: #fff7d2;
    position: relative;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    i {
      width: 180px;
      height: 180px;
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 20px;

      strong {
        color: #a8543d;
        font-size: 24px;
        margin-bottom: 14px;
        display: block;
      }

      p {
        color: #707070;
        font-size: 16px;
      }
    }

    .steps {
      position: absolute;
      right: 48px;
      top: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      >p {
        width: 24px;
        height: 24px;
        line-height: 24px;
        color: #acacac;
        background-color: #e7e7e7;
        text-align: center;
        border-radius: 24px;
        margin-left: 10px;
      }

      >p.on {
        color: #fff;
        background-color: #da8169;
      }
    }
  }

  .auth_form {
    display: flex;
    justify-content: center;
    padding: 30px 0;

    ul {
      >li {
        >input {
          line-height: 21px;
        }

        >strong {
          min-width: 80px;
          display: inline-block;
        }

        >button {
          padding: 4px 12px;
          margin-top: 10px;
          border: 1px solid #acacac;
          border-radius: 6px;
        }
      }
    }

    .chk_res {
      >ul {
        display: flex;
        align-items: center;
        justify-content: center;

        >li {
          padding: 20px;
          box-shadow: 0 3px 15px 0 rgba(79, 94, 247, 0.1);
          border-radius: 12px;
          text-align: center;

          >strong {
            font-size: 20px;
            display: flex;
            margin-bottom: 10px;
            text-align: left;
            align-items: center;

            i {
              margin-right: 4px;
            }
          }

          >p {
            font-size: 14px;
            color: #acacac;
            text-align: left;
          }

          input[type="radio"] {
            margin-top: 20px;
          }
        }

        >li.yes {
          border: solid 1px #05ac9e;
        }

        >li.no {
          border: solid 1px #ec0700;
          margin-left: 20px;
        }
      }
    }

    .etc_box {
      margin: 30px 68px 0 68px;

      .etc {
        padding: 20px;
        box-shadow: 0 3px 15px 0 rgba(79, 94, 247, 0.1);
        border-radius: 12px;
        text-align: center;
        border: solid 1px #c3c3c3;

        strong {
          font-size: 20px;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          text-align: left;

          i {
            margin-right: 4px;
          }
        }

        textarea {
          width: 100%;
        }

        input[type="radio"] {
          margin-top: 20px;
        }
      }
    }
  }

  .btns {
    text-align: center;
    padding-bottom: 20px;

    button {
      width: 120px;
      background-color: #00c6be;
      color: #fff;
      font-size: 18px;
      text-align: center;
      padding: 10px 0;
      border-radius: 10px;
    }
  }
}

#confirm_IsPaid {
  .auth_form {
    display: block;
  }

  .annual_mail_page .top .title strong {
    letter-spacing: -0.125rem;
    color: #4f5ef7;
  }

  .annual_mail_page .top {
    background-color: #e8edfd;
  }

  .annual_mail_page .top .steps>p.on {
    background-color: #4f5ef7;
  }
}

#confirm_IsGiveup {
  .auth_form {
    display: block;
  }

  .annual_mail_page .top .title strong {
    letter-spacing: -0.125rem;
    color: #ff6f6e;
  }

  .annual_mail_page .top {
    background-color: #ffdddc;
  }

  .annual_mail_page .top .steps>p.on {
    background-color: #ff6f6e;
  }
}
