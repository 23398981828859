#AnnualFeePerCost {
  position: fixed;
  width: 480px;
  min-height: 500px;
  max-height: 800px;
  overflow-y: auto;
  border: solid 1px #d8d8d8;
  background-color: #fff;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #343434;
  padding: 46px 35px;
  h2 {
    font-family: NanumSquareOTF_acB;
    font-size: 22px;
  }
  p {
    display: flex;
    align-items: center;
  }
  header {
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    p {
      font-family: NanumSquareOTF_acR;
      font-size: 12px;
      color: #606060;
      i {
        margin-right: 7px;
      }
    }
  }
  section {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    > div {
      display: flex;
      p {
        min-width: 100px;
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
      }
      > div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .input_wrapper {
          display: flex;
          align-items: center;
          border: 1px solid #d8d8d8;
          border-radius: 4px;
          input[type="text"] {
            border: none;
            width: 240px;
          }
        }
      }
    }
  }
  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px 0;
    margin-top: 20px;
    button {
      padding: 0 20px;
      height: 30px;
      border-radius: 4px;
      background-color: #4593f5;
      color: #fff;
    }
  }
  //.annual_fee_review_body {
  //  margin-top: 35px;
  //  > div {
  //    display: flex;
  //  }
  //  .review_manage_number {
  //    border-bottom: solid 1px #d8d8d8;
  //    padding: 10px 0;
  //    p:nth-child(1) {
  //      font-family: NanumSquareOTF_acEB;
  //      font-size: 14px;
  //      margin-right: 20px;
  //    }
  //    p:nth-child(2) {
  //      font-family: NanumSquareOTF_acR;
  //      font-size: 14px;
  //      letter-spacing: 0.3px;
  //    }
  //  }
  //  .annual_fee_list {
  //    margin-top: 11px;
  //    flex-direction: column;
  //    .title {
  //      font-family: NanumSquareOTF_acEB;
  //      font-size: 14px;
  //    }
  //    > div {
  //      display: grid;
  //      grid-template-columns: repeat(4, 1fr);
  //      gap: 8px;
  //      margin-top: 12.5px;
  //    }
  //    .annual_fee_item {
  //      display: flex;
  //      justify-content: space-between;
  //      align-items: center;
  //      width: 102px;
  //      height: 51px;
  //      border-radius: 8px;
  //      border: solid 1px #d8d8d8;
  //      padding: 8px 12px;
  //      p {
  //        font-family: NanumSquareOTF_acR;
  //        font-size: 14px;
  //        color: rgba(0, 0, 0, 0.87);
  //        display: inherit;
  //        align-items: inherit;
  //      }
  //    }
  //    .highlight {
  //      border: solid 1px #4593f5;
  //    }
  //  }
  //}
}
