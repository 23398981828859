#NameSearchPopup {
  position: fixed;
  width: 780px;
  height: 500px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > header {
    display: flex;
    justify-content: space-between;
    button {
      width: 100px;
      background-color: white;
    }
  }

  > .nation_list_wrapper {
    overflow-y: scroll;
    height: 400px;
    select {
      width: 200px;
      margin-left: 10px;
    }
    ul {
      display: flex;
      width: 100%;

      select {
        /* 추가로 필요한 스타일링 */

        margin-right: 10px; /* select 요소와 다음 요소 간격 조정 */
      }

      .search_wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: 1px solid #606060;
        border-radius: 5px;
        margin: 20px auto;
        width: 90%; /* .search_wrapper 요소의 너비를 조정 */
        input[type="text"] {
          width: 100%; /* input 요소를 100%로 설정하여 부모 요소 너비에 맞게 조정 */
          border: none;
        }

        i {
          cursor: pointer;
          width: 100px;
        }

        > p {
          display: flex;
          align-items: center;
          height: 40px;
        }
        span {
          min-width: 40px;
        }
      }
      .nation_list_second {
        padding-left: 20px;
        border: 1px solid #d8d8d8;
      }
      .nation_list_second:hover {
        background-color: rgb(255, 254, 196);
      }
    }
    div {
      button {
        background-color: white;
        display: flex;
        flex-direction: column;
        margin: 10px;
      }
    }
  }
}
