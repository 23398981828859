#PopupKeywordCondition {
    padding: 41px 62px 58px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 780px;
    min-height: 618px;
    border: 1px solid #d8d8d8;
    background-color: #fff;
    z-index: 1000;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;

        h2 {
            font-size: 22px;
            font-family: NanumSquareOTF_acB;
            color: #343434;
        }

        .btns {
            display: flex;
            align-items: center;
            column-gap: 20px;
        }

        .btn_setting {
            width: 120px;
            height: 32px;
            font-size: 16px;
            font-family: NanumSquareOTF_acB;
            color: #ffffff;
            border-radius: 4px;
            background-color: var(--color-Web-Orange);
        }
    }

    .desc {
        margin: 6px 0 11px 0;
        display: flex;
        align-items: center;
        column-gap: 7px;
        font-size: 12px;
        color: #606060;
    }

    .input {
        margin-bottom: 17px;
        padding-left: 24px;
        display: flex;
        align-items: center;
        column-gap: 6px;

        h2 {
            width: 92px;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;
        }

        input {
            padding: 0 11px;
            width: 370px;
            height: 32px;
            font-size: 14px;
            border-radius: 4px;
            border: 1px solid #d8d8d8;
        }

        input::placeholder {
            color: #959595;
        }
    }

    .add_keyword {
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;

        li {
            display: flex;
            justify-content: center;
            align-items: center;

            div {
                padding: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 24px;
                font-size: 14px;
                border-radius: 12px;
                border: 1px solid #ffa600;
                background-color: #fff;
            }
        }
    }

    .category_list {
        max-height: 50vh;
        overflow-x: hidden;
        overflow-y: scroll;

        li {
            .title {
                padding: 0 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 654px;
                height: 47px;
                border-bottom: 1px solid #d8d8d8;

                &.active {
                    background-color: #f0f0f0;
                    color: var(--color-Web-Orange);
                    border-bottom: none;
                }

                p {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-family: NanumSquareOTF_acB;

                    span {
                        display: block;
                        width: 30px;
                    }
                }
            }

            .check_list {
                padding: 11px 24px;
                display: flex;
                align-items: center;
                column-gap: 12px;
                row-gap: 10px;
                flex-wrap: wrap;
                min-height: 49px;
                border-bottom: 1px solid #d8d8d8;

                .checkbox {
                    width: 105px;
                    justify-content: left;
                }
            }
        }
    }
}