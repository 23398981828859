.marketInfoMy {
    position: relative;
    margin: auto;
    padding-top: 70px;
    width: 880px;

    &_menu {
        position: absolute;
        left: -170px;
        width: 170px;

        h2 {
            font-size: 22px;
            font-family: NanumSquareOTF_acEB;
            line-height: 30px;
        }

        ul {
            li {
                margin-top: 16px;
                font-size: 14px;
                font-family: NanumSquareOTF_acB;
                color: #343434;
                cursor: pointer;

                &.active {
                    color: #4593f5;
                }
            }
        }
    }
}