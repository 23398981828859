.patentETC {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
  }

  .btns {
    display: flex;
    gap: 10px;

    .btnUpload {
      background-color: #007bff;
      color: white;
      padding: 8px 16px;
      border-radius: 5px;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }

    .btnSave {
      background-color: #6c757d;
      color: white;
      padding: 8px 16px;
      border-radius: 5px;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: #5a6268;
      }
    }
  }

  .list {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    thead {
      background-color: #007bff;
      color: white;

      th {
        padding: 12px;
        text-align: center;
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: #f1f1f1;
        }

        td {
          padding: 10px;
          text-align: center;
        }

        .deleteButton {
          color: red;
          cursor: pointer;
          &:hover {
            color: darkred;
          }
        }
      }
    }
  }
}
