#ManageTradeMarkForm {
  width: 880px;
  > .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    div {
      display: flex;
      align-items: center;
      gap: 10px;
      button {
        background-color: #1474ff;
        padding: 5px 10px;
        color: #FFFFFF;
        border-radius: 5px;
      }
    }
  }
  input[type="text"] {
    height: 30px;
  }
  .keyword_table {
    button {
      padding: 5px 10px;
      margin-right: 10px;
      border-radius: 5px;
      color: #FFFFFF;
    }
    .btn_save {
      background-color: yellowgreen;
    }
    .btn_cancel {
      background-color: red;
    }
    .btn_edit {
      background-color: #4593F5;
    }
  }
}
