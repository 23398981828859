@import "src/include";

#MobilePCServiceAlert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;

    .popup {
        margin: auto;
        position: relative;
        top: 15%;
        width: 320px;
        @include border-radius(8px);
        background-color: #ffffff;
        overflow: hidden;

        .content {
            padding: 23px 0;
            background-color: #fcfcfc;
            text-align: center;

            .logo {
                width: 84px;
            }

            img {
                margin: auto;
                display: block;
            }

            .bg {
                position: absolute;
                bottom: 32%;
                z-index: 0;
            }

            .bg1 {
                left: 50%;
                transform: translateX(-50%);
            }

            .title {
                margin: 15px 0;
                font-size: 20px;
                font-family: NanumSquareOTF_acEB;
                color: var(--color-Niagara);
            }

            .desc1 {
                font-size: 14px;
                font-family: NanumSquareOTF_acB;
                color: var(--color-Scorpion);

                span {
                    font-size: 16px;
                    font-family: NanumSquareOTF_acEB;
                    color: var(--color-Malibu);
                }

                b {
                    color: var(--color-Tarawera);
                }
            }

            .desc2 {
                margin: 10px 0 15px 0;
                font-size: 16px;
                font-family: NanumSquareOTF_acEB;
                line-height: 22px;
                color: var(--color-Scorpion);
            }

            .notify {
                font-size: 13px;
                font-family: NanumSquareOTF_acB;
                color: var(--color-Scorpion);

                b {
                    font-family: NanumSquareOTF_acEB;
                    color: var(--color-Mine_Shaft);
                }
            }

            .btn_join {
                margin: 85px auto 10px auto;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 194px;
                height: 42px;
                font-size: 16px;
                font-family: NanumSquareOTF_acEB;
                color: #ffffff;
                border-radius: 20px;
                background-color: var(--color-Malibu);
                z-index: 1;
            }
        }

        .btns {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;

            > div {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 5px;
                width: 50%;
                height: 49px;

                * {
                    font-size: 12px;
                    font-family: NanumSquareOTF_acB;
                    color: #606060;
                }
            }


            .checkbox {
                column-gap: 5px;
            }
        }
    }

    .pc_popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
            width: 483px;
            height: 483px;
        }
        .btns {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;

            > div {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 5px;
                width: 50%;
                height: 49px;
                * {
                    font-size: 12px;
                    font-family: NanumSquareOTF_acB;
                    color: #606060;
                }
            }
            .checkbox {
                column-gap: 5px;
            }
        }
    }
}
