$gray-4: #d1d6db;
$grau-9: #333d4b;
$radius-10: 6px;

#DTop {
  font-family: "Pretendard_Regular";
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  max-width: 1400px;
  margin: 0 auto;

  .my {
    display: flex;
    align-items: center;
  }
  .tit {
    font-size: 32px;
    // margin: 0 24px;
  }
  .files {
    display: flex;
    align-items: center;
    > button {
      background-color: transparent;
      display: flex;
      align-items: center;
      border: 1px solid $gray-4;
      padding: 4px 12px;
      border-radius: $radius-10;
      margin-right: 10px;
      > i {
        margin-right: 4px;
      }
    }
  }
}
