@import "src/include";

#InventionDetail {
  margin: auto;
  padding-top: 40px;
  width: 880px;

  > .header {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    //height: 61px;

    .header_box {
      display: flex;
      flex-direction: column; /* 세로 정렬 */
      justify-content: center; /* 세로 정렬 */
      gap: 10px;
    }

    .title {
      //margin-bottom: 4px;
      font-size: 22px;
      width: fit-content;
    }

    .info {
      //margin-top: 10px;
      //position: relative;
      //float: left;
      display: flex;
      align-items: center;
      justify-content: start;

      .state {
        padding: 0 6px;
        min-width: 106px;
        line-height: 24px;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        @include border-radius(16px);
        background-color: #00ae02;
        //cursor: pointer;

        .icon_more {
          position: absolute;
          top: -4px;
          right: -24px;
          width: 24px;
          height: 24px;
        }
      }
      ul {
        position: absolute;
        top: 30px;
        background-color: #ffffff;
        z-index: 9;

        button {
          display: block;
          width: 120px;
          height: 32px;
          font-size: 12px;
          border: solid 1px #d8d8d8;
          background-color: #ffffff;
        }
        button + button {
          border-top: none;
        }

        button:hover {
          background-color: rgba(0, 149, 174, 0.2);
          transition-duration: 0.5s;
        }
      }
    }

    .btns {
      float: right;
      display: flex;
      position: relative;
      align-items: center;

      button {
        line-height: 32px;
        font-size: 16px;
        color: #ffffff;
      }
      button + button {
        margin-left: 8px;
      }
      .btn_edit {
        width: 64px;
        padding: 0px 12px;
        background-color: var(--color-Sky-Blue);

        &:hover {
          background-color: #0077e6; /* 호버 시 진한 초록색 */
        }
        border-radius: 6px;
      }
      .btn_save {
        width: 64px;
        padding: 0px 12px;
        background-color: #05ac9e;
        border-radius: 6px;
      }
      .btn_back {
        padding: 0px 12px;
        background-color: var(--color-Manatee);

        &:hover {
          background-color: #8b949e;
        }
        border-radius: 6px;
      }
      .btn_download {
        padding: 0px 12px;
        background-color: #a256e1;
        border-radius: 6px;

        &:hover {
          background-color: #813bb5; /* 호버 시 진한 보라색 */
        }
      }

      .btn_cancle {
        width: 64px;
        padding: 0px 12px;
        background-color: #96c719;
        border-radius: 6px;
      }
      .btn_add {
        padding: 0px 12px;
        background-color: #e92f2c;
        border-radius: 6px;
      }
      .btn_add:hover {
        background-color: #d32f2f; /* 호버 시 진한 빨간색 */
      }
      ul {
        position: absolute;
        right: 0;
        bottom: -68px;
        width: 120px;
      }
    }
  }

  .form {
    .field {
      .header {
        margin-top: 60.5px;
        margin-bottom: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 32px;

        .title {
          font-size: 22px;
        }
        .btn_add {
          width: 120px;
          line-height: 32px;
          font-size: 16px;
          color: #ffffff;
          background-color: #05ac9e;
        }
      }
      .rows {
        display: flex;
        flex-wrap: wrap;
        .row {
          display: flex;
          align-items: center;
          width: 283px;
          min-height: 45px;
          font-size: 14px;
          border-bottom: solid 1px #e7e7e7;
          .title {
            position: relative;
            width: 150px;
            line-height: 45px;
            font-family: NanumSquareOTF_acB;

            .icon_badge_del {
              position: absolute;
              top: 10px;
              right: 0px;
            }
          }

          .wrap {
            display: flex;
            align-items: center;

            > span {
              margin-right: 6px;
            }
          }
          button + button {
            margin-left: 6px;
          }

          input {
            padding: 0 8px;
            width: 150px;
            height: 30px;
            font-size: 14px;
            text-align: left;
            border: solid 1px rgba(0, 0, 0, 0.56);
            border-radius: 4px;
          }

          .long {
            margin-right: 0 !important;
            width: 480px !important;
          }
          .middle {
            margin-right: 0 !important;
            width: 215px !important;
          }
          .short {
            margin-right: 0 !important;
            width: 180px !important;
          }
          .very-short {
            margin-right: 0 !important;
            width: 145px !important;
          }
          select {
            padding-left: 8px;
            width: 150px;
            height: 30px;
            font-size: 14px;
          }
          p {
            max-width: 450px;
            font-size: 14px;
            word-break: break-word;
          }
        }
        .row + .row {
          margin-left: 15px;
        }
        .row.no_data {
          border: none;
        }
        .row.between {
          justify-content: space-between;
        }
        .row.annual {
          width: 305px !important;
        }
        .row.plus {
          width: 150px !important;
        }
        .row.colspan2 {
          width: 581px;
        }
        .row.colspan3 {
          width: 430px;
        }
        .row.colspan4 {
          width: 313px;
        }

        .row.colspan5 {
          width: 282px;
        }

        .blue-placeholder {
          input::placeholder {
            color: #007bff;
          }
        }

        .inventor-wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .inventor {
          display: flex;
          gap: 8px;
          font-size: 14px;
          h2 {
            margin-top: 3px;
          }
        }
        .inventor-row {
          display: flex;
          gap: 8px;
          font-size: 14px;
          flex-direction: column;
          .inventor-section {
            display: flex;
            align-items: center;
            gap: 8px;
            input {
              padding: 5px 10px;
              height: unset; /* 다른 CSS의 height 속성 무시 */
            }
          }
        }
        ul {
          position: absolute;
          background-color: #ffffff;

          li {
            button {
              width: 150px;
              height: 32px;
              border: 1px solid rgb(0, 149, 174);
              background-color: rgb(255, 255, 255);
              text-align: right;
              padding: 0px 8px;
            }
            button:hover {
              transition-duration: 0.5s;
              background-color: rgba(0, 149, 174, 0.2);
            }
          }
        }
      }
      .rows.publish {
        .row:nth-child(4n) {
          margin-left: 0;
        }
      }
      .rows.center {
        margin-left: 165px;
      }
      .rows.right {
        justify-content: flex-end;
      }
      .rows.inventor-add {
        padding: 8px 0px !important;
        gap: 8px;
      }
      .rows.invention-publish {
        margin: 0px 0px 12px 200px;
      }
      .field-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        > h2 {
          font-size: 22px;
        }
        .ntis-link {
          color: #000;
          text-decoration: none;
          position: relative;
          font-weight: bold;
          transition: color 0.3s ease;
        }

        .ntis-link:hover {
          color: #4593f5;
        }

        .ntis-link::after {
          content: "";
          position: absolute;
          width: 0;
          height: 2px;
          bottom: -2px;
          left: 0;
          background-color: #4593f5;
          transition: width 0.3s ease;
        }

        .ntis-link:hover::after {
          width: 100%;
        }
        margin: 20px 0px;
      }
      textarea {
        width: 100%;
        padding: 16px;
        font-size: 16px;
        line-height: 1.5;
        border: 1px solid rgba(0, 0, 0, 0.56);
        border-radius: 5px;
        resize: none;
      }
      &:has(.toggle-button) {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
      }
      .toggle-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 12px 12px 28px;
        gap: 20px;
        border: 1px solid #007bff;
        border-radius: 6px;
        font-size: 16px;
      }
      .toggle-button:hover {
        background-color: rgba(0, 123, 255, 0.15);
        cursor: pointer;
      }
    }
  }
}

#InventionDetail.isNew {
  .icon_download {
    display: none;
  }
}

.fieldName {
  width: 150px;
  height: 100%;
  text-align: center;
  font-size: 14px;
  padding: 5px 10px;
  border: solid 1px rgba(0, 0, 0, 0.56);
  border-radius: 4px;
}

.fieldBirtday {
  width: 130px;
  text-align: center;
  font-size: 14px;
  padding: 5px 10px;
  border: solid 1px rgba(0, 0, 0, 0.56);
  border-radius: 4px;
}

.fieldEmail {
  width: 40%;
  text-align: center;
  font-size: 14px;
  padding: 5px 10px;
  border: solid 1px rgba(0, 0, 0, 0.56);
  border-radius: 4px;
}

.fieldAddress {
  width: 60%;
  text-align: center;
  font-size: 14px;
  padding: 5px 10px;
  border: solid 1px rgba(0, 0, 0, 0.56);
  border-radius: 4px;
}
.selectSearch {
  height: 100%;
  width: 100px;
  color: black;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 24px;
  border: solid 1px rgba(0, 0, 0, 0.56);
  border-radius: 4px;
}

.btns {
  display: flex;
}

.share-input {
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  h2 {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  input {
    width: 80px;
    text-align: center;
    font-size: 14px;
    padding: 5px 10px;
    border: solid 1px rgba(0, 0, 0, 0.56);
    border-radius: 4px;
  }
  .tooltip {
    position: absolute;
    text-align: center;
    top: -50px;
    left: 0;
    width: 250px;
    background-color: #ffedba;
    border: 1px solid #f0c36d;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    color: #333;
    z-index: 1000;
  }
}
