.info_icon {
  margin: 0 0 0 10px;
  position: relative;
  .info_div {
    position: absolute;
    width: 800px;
    border-radius: 9px;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 999;
    p {
      color: white;
      font-family: NanumSquareOTF_acR;
      font-size: 14px;
      line-height: 30px;
      padding: 10px;
    }
  }
}

#BringingUpIcon {
  margin: 0 10px 0 5px;
  cursor: pointer;
}
