#PopupIPAutoAdd {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 880px;
    height: 550px;
    padding: 45px 50px 20px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;

    .steps {
        display: flex;

        .one {
            z-index: 3;
        }

        .two {
            position: relative;
            left: -21px;
            z-index: 2;
        }

        .three {
            position: relative;
            left: -41px;
            z-index: 1;

            .body {
                border-right: 2px solid #d8d8d8;
            }
        }

        .active {

            .body {
                border: none;
                background-color: #443018;

                p {
                    color: #ffffff;
                }
            }

            .tri {
                border-left: 19px solid #443018;
            }

            .tri:after {
                display: none;
            }
        }
    }

    .step {
        display: flex;
        align-items: center;

        .body {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 261px;
            height: 40px;
            border: 2px solid #d8d8d8;
            border-right: none;
            background-color: #f3f3f3;

            p {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: NanumSquareOTF_acB;

                span {
                    display: block;
                    width: 30px;
                    font-size: 18px;
                    font-family: NanumSquareOTF_acEB;
                }
            }
        }

        .tri {
            position: relative;
            width: 18px;
            height: 40px;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
        }

        .tri:after {
            content: '';
            display: block;
            position: absolute;
            top: -13px;
            right: 4px;
            width: 25px;
            height: 25px;
            background: #f3f3f3;
            border-right: 2px solid #d8d8d8;
            border-bottom: 2px solid #d8d8d8;
            -moz-transform:rotate(-45deg);
            -webkit-transform:rotate(-45deg);
          }
    }

    .title {
        margin: 21px 0 15px 0;
        font-size: 22px;
        font-family: NanumSquareOTF_acB;
        color: #343434;
    }

    .desc {
        margin-bottom: 16px;
        font-size: 12px;
        font-family: NanumSquareOTF_acB;
        color: #606060;

        span {
            color: rgba(255, 0, 0, 0.8);
        }
    }

    .table-box-wrap {
        .wrap_list {
            max-height: 250px;

            th:first-child,
            td:first-child {
                width: 15%;
            }
        }
    }

    .btns {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 15px;

        .btn {
            width: 120px;
            height: 32px;
            font-size: 16px;
            font-family: NanumSquareOTF_acEB;
        }

        .btn_prev {
            color: #606060;
            border: solid 1px #606060;
            background-color: #ffffff;
        }

        .btn_add {
            color: #ffffff;
            background-color: var(--color-Web-Orange);
        }
    }
}