#AnnualFeeLogin {
  width: 1280px;
  height: 80vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #343434;
    h2 {
      font-family: NanumSquareOTF_acB;
      font-size: 22px;
      margin-bottom: 48px;
    }
    .login_form {
      width: 540px;
      height: 204px;
      border: solid 1px #f0f0f0;
      padding-left: 45px;
      > div {
        margin-top: 25px;
      }
      p {
        font-family: NanumSquareOTF_acB;
        font-size: 14px;
        letter-spacing: -0.25px;
      }
      i {
        width: 12px;
        height: 16px;
      }
      input {
        width: 450px;
        height: 32px;
        border: none;
        border-bottom: 1px solid #f0f0f0;
        font-family: NanumSquareOTF_acR;
        font-size: 14px;
        color: #959595;
        padding: inherit;
        margin-top: 10px;
      }
    }
    button {
      width: 450px;
      height: 50px;
      font-family: NanumSquareOTF_acB;
      font-size: 16px;
      color: #fff;
      border-radius: 4px;
      background-color: #ffa627;
      margin-top: 24px;
    }
  }
}
