@import "src/include";

#ProductPopUp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 780px;
  height: 500px;
  padding: 43px 74px;
  border: solid 1px #d8d8d8;
  background-color: #ffffff;

  .header {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 22px;
    }
    button {
      padding: 5px 10px;
      font-size: 16px;
      text-align: center;
      color: #ffffff;
      margin-right: 12px;
    }
    .btn_save {
      background-color: #19c77c;
    }
    .btn_cancel {
      background-color: red;
    }
    .btn_edit {
      background-color: #96c719;
    }
  }
  .product_list {
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow-y: scroll;
    gap: 5px 0;
    li {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 0 10px;
      border-bottom: 1px solid #d8d8d8;
      padding: 5px 0;
      input {
        width: 24px;
        height: 24px;
      }
      p {
        width: 80%;
        @include text-ellipsis(1);
      }
    }
  }
}
