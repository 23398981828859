@import "src/include";
@import "src/mixin";

#SearchInterested {
    min-width: 663px;
    width: 663px;
    height: 190px;
    box-shadow: 0 2px 3px 1px #f0f0f0;
    background-color: #faf0e9;
    background-image: none;
    @include border-radius(5px);

    .search_txt {
        display: flex;
        flex-direction: column;
        font-family: NanumSquareOTF_acB;
        margin: 21px 0px 0px 35px;
        .page_title {
            font-size: 16px;
            color: #925717;
            line-height: 20px;
            letter-spacing: 0.3px;
        }
        .second_title {
            width: 140px;
            height: 20px;
            font-size: 14px;
            font-family: NanumSquareOTF_acEB;
            display: inline-block;
            margin-top: 10px;
            background-color: #e2860a;
            color: white;
            padding: 3px 9px 3px 10px;
            border-radius: 14px;
            text-align: center;
        }
        .semi_title {
            font-size: 25px;
            font-family: NanumSquareOTF_acEB;
            margin-top: 5px;
            color: #443018;
            letter-spacing: -0.3px;
            span {
                background-color: #ffedb7;
            }
        }
    }
    .search_form {
        position: relative;
        display: flex;
    }

    input {
        margin: 15px 0 24px 35px;
        padding: 16px 0 14px 14px;
        width: 485px;
        height: 50px;
        font-size: 14px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border: none;
        background-color: #ffffff;
    }

    input::placeholder {
        color: #aaaaaa;
    }

    button {
        width: 50px;
        height: 50px;
        margin: 15px 0 24px 0;
        font-size: 14px;
        background-color: #ffffff;
        text-align: center;
        background-position: center;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .keyword_list {
        position: absolute;
        top: 100%;
        max-width: 680px;
        width: 100%;
        max-height: 445px;
        overflow-x: hidden;
        overflow-y: scroll;
        border-left: solid 1px #e7e7e7;
        border-right: solid 1px #e7e7e7;
        border-bottom: solid 1px #e7e7e7;
        background-color: #ffffff;
        z-index: 9;

        li {
            margin-top: 3px;
            padding-bottom: 3px;
            width: 100%;
            text-align: left;
            background-color: #ffffff;
            cursor: pointer;
            div {
                margin: 0 15px;
                padding: 3px 0;
                display: flex;
                border-bottom: solid 1px #e7e7e7;

              h2, p {
                  padding: 4px 0;
                  display: flex;
                  align-items: center;
                  min-height: 32px;
              }

              h2 {
                  margin: 0;
                  padding-right: 8px;
                  min-width: 150px;
                  width: 150px;
                  font-size: 14px;
                  font-family: NanumSquareOTF_acB;
              }

              p {
                  width: 500px;
                  font-size: 12px;
              }
          }
      }

      li:first-child {
          padding-top: 7px;
          border-top: solid 1px #e7e7e7;
      }

      li:last-child {
          padding-bottom: 7px;
          border-bottom: solid 1px #e7e7e7;
      }

      .active:hover div {
          background-color: rgba(255, 166, 0, 0.1);
      }

      .active:hover h2 {
          color: var(--color-Web-Orange);
      }
    }
}
