@import "src/include";

#SearchResult {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 60px;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    overflow-y: scroll;
    z-index: 2;

    .header {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
        z-index: 2;

        h2 {
            font-size: 16px;
            font-family: NanumSquareOTF_acEB;
            color: #443018;
        }

        button {
            position: absolute;
            left: 16px;
            background-size: contain;
        }
    }

    .result {
        margin: auto;
        padding-top: 22px;
        width: 90%;

        .result_head {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .result_info {
                display: flex;
                align-items: center;

                h2 {
                    font-size: 18px;
                    font-family: NanumSquareOTF_acEB;
                    line-height: 26px;
                    color: #443018;
                }

                p {
                    margin-left: 8px;
                    font-size: 12px;
                    color: #606060;
                }
            }

            .btn_search_add {
                width: 150px;
                height: 26px;
                font-size: 12px;
                font-family: NanumSquareOTF_acEB;
                color: #ffffff;
                border-radius: 4px;
                background-color: var(--color-Web-Orange);
            }

            .btn_search_add.disable {
                background-color: #d8d8d8;
                pointer-events: none;
            }
        }

        .result_option {
            margin: 10px 0 18px 0;
            display: flex;
            align-items: center;

            p {
                font-size: 12px;
                color: #747474;
            }
        }
    }
}