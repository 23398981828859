#PopupFilterPrice {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 60px;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    z-index: 2;

    .header {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
        z-index: 2;

        h2 {
            font-size: 16px;
            font-family: NanumSquareOTF_acEB;
            color: #443018;
        }

        button {
            position: absolute;
            left: 16px;
            background-size: contain;
        }
    }

    .setting {

        .s_head {
            margin-bottom: 10px;
            padding: 0 5%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            background-color: #f0f0f0;

            .setting_title {
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
                color: #606060;
            }

            .btns {
                display: flex;
                align-items: center;
                column-gap: 16px;
            }

            .btn_reset {
                width: 65px;
                height: 25px;
                font-size: 12px;
                font-family: NanumSquareOTF_acB;
                color: #ffffff;
                border-radius: 21px;
                background-color: var(--color-Web-Orange);
            }
        }

        .price_range {
            margin-bottom: 14px;
            padding: 0 5%;
            font-size: 20px;
            font-family: NanumSquareOTF_acEB;
            line-height: 26px;
            color: var(--color-Web-Orange);
        }

        .wrap_range {
            margin: auto;
            width: 86%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                font-size: 12px;
                color: #606060;
            }

            p:first-child {
                position: relative;
                left: 6px;
            }
        }
    }

    .btn_search {
        margin: 23px auto 0 auto;
        display: block;
        width: 206px;
        height: 42px;
        font-size: 16px;
        font-family: NanumSquareOTF_acEB;
        color: #ffffff;
        border-radius: 21px;
        background-color: var(--color-Web-Orange);
    }

    @media screen and (min-width: 850px) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 30px;
        width: 540px;
        height: 300px;
        border: 1px solid var(--color-Web-Orange);

        .header {
            display: none;
        }

        .setting {
            .s_head {
                margin-bottom: 20px;
                padding: 0;
                background-color: transparent;

                .setting_title {
                    font-size: 22px;
                    color: #443018;
                }
            }
        }
    }
}