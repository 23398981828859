#AnnualFeeGiveUpAndReview {
  padding: 30px 50px;
  header {
    h2 {
      font-size: 26px;
    }
    > div {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-top: 20px;
      select {
        width: 80px;
      }
      label {
        display: flex;
        align-items: center;
        gap: 10px;
        input[type="checkbox"] {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  section {
    .tab_wrapper {
      margin-bottom: 20px;
    }
    .tab_button {
      width: 100%;
    }
  }
}
