#NationAddPopup {
    padding: 36px 29px 73px 36px;
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 780px;
    width: 80%;
    height: 480px;
    transform: translate(-50%, -50%);
    border: solid 1px #d8d8d8;
    background-color: #ffffff;

    .popup_header {
        overflow: hidden;
        margin-bottom: 50px;

        .title {
            float: left;
            font-size: 22px;
        }
        .btns {
            text-align: right;

            .btn_add {
                margin-right: 40px;
                width: 120px;
                height: 32px;
                font-size: 16px;
                color: #ffffff;
                text-align: center;
                background-color: var(--color-Web-Orange);
            }
            .icon_exit {
                position: relative;
                top: 5px;
            }
        }
    }

    .popup_content {
        padding: 0 37px;

        label {
            clear: both;
            overflow: hidden;
            margin-bottom: 35px;
            display: block;
            line-height: 44px;
            font-size: 14px;
            font-family: NanumSquareOTF_acB;

            span {
                margin-right: 5px;
                display: inline-block;
                width: 80px;
            }
            select {
                padding-left: 21px;
                width: 220px;
                height: 44px;
                font-size: 14px;
                border: 1px solid var(--color-Web-Orange);
            }
        }
    }

    .wrap_list {
        clear: both;
        overflow-y: scroll;
        height: 200px;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .wrap_list::-webkit-scrollbar {
        display: none;
    }
}
