#SideBar {
  width: 270px;
  .sidebar_items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: NanumSquareOTF_acB;
    .sidebar_item {
      display: flex;
      align-items: flex-start;
      margin-top: 30px;
      .sidebar_item_text_test {
        color: #4593f5;
        font-size: 16px;
        margin-left: 5px;
      }
      .sidebar_item_text {
        margin-left: 5px;
        color: #343434;
        .small_text {
          margin: 31px 0 0 5px;
          font-size: 14px;
          color: #606060;
        }
      }
    }
  }
}
