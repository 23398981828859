@import "src/include";
#docTradeSample {
    .pc {
        .sample {
            width: 1100px;
            margin: 0 auto;
            padding: 45px 0 0;

            &_tutorial-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                width: 880px;
                height: 110px;
                margin: 0 auto 44px;
                background-color: #f1f1f1;
                border-radius: 20px;
                padding: 10px 15px;

                &_icon {
                    position: relative;
                    width: 160px;
                    height: 100%;
                    .icon_document {
                        position: absolute;
                        display: block;
                        width: 54.6px;
                        height: 59.9px;
                        top: 50%;
                        left: 35px;
                        transform: translateY(-50%);
                        background-size: cover;
                    }
                }

                &_content {
                    position: relative;
                    width: calc(100% - 160px);
                    height: 100%;
                }

                &_text {
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }

                &_title {
                    font-size: 18px;
                    height: 24px;
                    line-height: 24px;
                    margin-bottom: 5px;
                    color: #343434;
                    font-family: NanumSquareOTF_acEB;
                }

                .sample_tutorial-box_info {
                    font-size: 14px;
                    color: #606060;
                    line-height: 18px;
                    @include text-ellipsis(3);
                }

                .sample_tutorial-box_link {
                    position: absolute;
                    width: 123px;
                    height: 24px;
                    line-height: 24px;
                    border-radius: 12px;
                    top: 13px;
                    right: 14px;
                    background-color: #6bd3ff;
                    font-size: 13px;
                    color: #fff;
                    padding: 0 10px;

                    &::after {
                        position: absolute;
                        content: "";
                        width: 18px;
                        height: 18px;
                        top: 50%;
                        right: 3px;
                        transform: translateY(-50%);
                        background: url("../../../assets/images/mobile/icon_more_arrow_right_white.svg")
                            no-repeat 50% 50%;
                        background-size: cover;
                    }
                }
            }
            &_list {
                width: 1100px;
                margin: 0 auto 60px;

                &_title-wrap {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    position: relative;
                    width: 880px;
                    height: 25px;
                    line-height: 25px;
                    margin: 0 auto 27px;
                }

                &_title {
                    font-family: NanumSquareOTF_acB;
                    font-size: 22px;
                    color: #343434;
                    padding-right: 20px;
                }

                &_menu {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    width: 280px;
                    font-size: 18px;
                    color: #343434;
                }
                .sample_list_menu-list {
                    width: calc(100% / 4);
                    text-align: center;

                    &.active {
                        .sample_list_menu-link {
                            font-family: NanumSquareOTF_acEB;
                            color: #ffa600;
                        }
                    }
                }

                .sample_list_menu-link {
                    font-family: NanumSquareOTF_acB;
                    font-size: 18px;
                    color: #343434;
                    background-color: #ffffff;

                    &:hover {
                        font-family: NanumSquareOTF_acEB;
                        color: #ffa600;
                    }
                }

                &_btn-section {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    height: 32px;
                }

                &_btn-list {
                    width: 93px;
                    height: 32px;
                    line-height: 32px;
                    border-radius: 4px;
                    text-align: center;
                    margin-right: 8px;

                    &.enroll {
                        width: 170px;
                        background-color: #e92f2c;

                        .sample_list_btn-link {
                            position: relative;
                            text-indent: 28px;

                            &::after {
                                position: absolute;
                                content: "";
                                width: 24px;
                                height: 24px;
                                top: 50%;
                                left: 19px;
                                transform: translateY(-50%);
                                background: url("../../../assets/images/common/icon-posting.svg")
                                    no-repeat 50% 50%;
                                background-size: cover;
                            }
                        }
                    }
                    &.manage {
                        background-color: #1d334e;
                    }
                    &:last-of-type {
                        margin-right: 0;
                    }
                    .sample_list_btn-link {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        color: #fff;
                        font-family: NanumSquareOTF_acB;
                    }
                }
                &_search-form {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 490px;
                    height: 32px;
                    margin: 0 auto 33px;
                    .sample_list_search-form_border-area {
                        width: 400px;
                        height: 100%;
                        border-radius: 4px;
                        border: 1px solid #d8d8d8;
                        overflow: hidden;
                        margin-right: 2px;
                    }
                    &_input-search {
                        padding: 0 15px;
                        width: 100%;
                        height: 100%;
                        font-size: 14px;
                        border: none;
                    }
                    &_input-search::placeholder {
                        color: #959595;
                    }
                    &_btn-submit {
                        position: relative;
                        width: 30px;
                        height: 30px;
                        text-indent: -9999px;
                        background-color: transparent;
                        &::after {
                            position: absolute;
                            content: "";
                            width: 24px;
                            height: 24px;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            background: url("../../../assets/images/common/icon-search.svg")
                                no-repeat 50% 50%;
                            background-size: cover;
                        }
                    }
                }
            }
            &_list-contents {
                &_list-wrap {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    width: 950px;
                    margin: 0 auto;
                }
            }
        }
    }
    .mo {
        .sample {
            width: 100vw;
            overflow: hidden;
            &_top {
                width: 100%;
                height: 122px;
                margin: 60px 0 32px;
                padding-top: 32px;
                text-align: center;
                background-image: linear-gradient(
                    to top,
                    rgba(129, 202, 233, 0.2),
                    rgba(128, 206, 183, 0.2)
                );
            }
            &_title {
                font-family: NanumSquareOTF_acEB;
                font-size: 20px;
                color: #1d334e;
                margin-bottom: 12px;
            }
            &_subtitle {
                font-family: NanumSquareOTF_acB;
                font-size: 14px;
                color: #606060;
                text-align: center;
            }
            &_search-form{
                width: 100%;
                padding: 0 20px;
                margin-bottom: 19px;
                &_title{
                    font-family: NanumSquareOTF_acEB;
                    font-size: 18px;
                    color: #1d334e;
                    margin-bottom: 12px;
                }
                &_border-area{
                    display: flex;
                    height: 38px;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid #d8d8d8;
                    border-radius: 3px;
                    overflow: hidden;
                }
                &_input-search{
                    width: calc(100% - 33px);
                    height: 100%;
                    border: none;
                    padding-left: 10px;
                    font-size: 14px;
                    &::placeholder{
                        font-family: NanumSquareOTF_acR;
                        font-size: 14px;
                        color: #959595;
                    }
                }
                &_btn-submit{
                    position: relative;
                    width: 33px;
                    height: 100%;
                    text-indent: -9999px;
                    background-color: transparent;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 24px;
                        height: 24px;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        background: url(../../../assets/images/common/icon-search.svg) no-repeat 50% 50%;
                        background-size: cover;
                    }
                }
            }
            &_list-contents{
                width: 100%;
                padding: 0 20px;
                margin-bottom: 60px;
                &_title-wrap{
                    display: flex;
                    height: 25px;
                    line-height: 25px;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 25px;
                }
                &_title{
                    width: calc(100% - 69px);
                    height: 100%;
                    font-family: NanumSquareOTF_acEB;
                    font-size: 18px;
                    color: #1d334e;
                }
                &_btn-wrap{
                    position: relative;
                    width: 69px;
                    height: 25px;
                }
                &_btn-list{
                    width: 100%;
                    height: 25px;
                }
                &_btn{
                    width: 100%;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    padding-left: 32px;
                    font-family: NanumSquareOTF_acB;
                    font-size: 12px;
                    color: #747474;
                    text-align: left;
                    border-radius: 3.5px;
                    border: 1px solid #d8d8d8;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 24px;
                        height: 24px;
                        top: 50%;
                        left: 7px;
                        transform: translateY(-50%);
                        background: url(../../../assets/images/common/icon-filter.svg) no-repeat 50% 50%;
                        background-size: cover;
                    }
                    &_select{
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        height: 0px;
                        border-radius: 3.5px;
                        border: 1px solid transparent;
                        overflow: hidden;
                        opacity: 0;
                        &.on{
                            height: 75px;
                            border: 1px solid #d8d8d8;
                            opacity: 1;
                            transition: all 150ms ease-in-out;
                        }
                    }
                    &_option{
                        width: 100%;
                        height: 25px;
                        border-bottom: 1px solid #d8d8d8;
                        &_button{
                            display: block;
                            width: 100%;
                            height: 25px;
                            background-color: #fff;
                            font-size: 12px;
                            color: #747474;
                            &.on{
                                color: #fff;
                                background-color: var(--color-Web-Orange);
                            }
                        }
                    }
                }
                &_list-wrap{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-bottom: 30px;
                }
            }
        }
    }
}
