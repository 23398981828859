@import "src/mixin";

#IntroServiceDetail {
  margin: auto;
  max-width: 1280px;
  @include mobile {
    padding-top: 60px;
  }

  .top_banner {
      padding: 30px 0 0 45px;
      position: relative;
      min-height: 180px;
      background-color: var(--color-Cornflower-Blue-opactity-02);

      @include mobile {
        padding: 30px 20px;
      }

      img {
        position: absolute;
        right: 0;
        bottom: 0;

        @include mobile {
          display: none;
        }
      }

      h2 {
          margin-bottom: 20px;
          font-size: 30px;
          font-family: NanumSquareOTF_acEB;
          color: var(--color-Cloud-Burst);

          span {
              margin-left: 8px;
              font-size: 20px;
              font-family: NanumSquareOTF_acB;
          }
      }

      p {
          font-size: 16px;
          font-family: NanumSquareOTF_acB;
          line-height: 24px;
          color: var(--color-Mine_Shaft);
      }
  }

  .index {
    padding-left: 28px;
    display: flex;
    align-items: center;
    height: 47.5px;
    font-size: 12px;
    font-family: NanumSquareOTF_acB;
    border-bottom: solid 1px var(--color-Gallery);
  }

  .head {
    position: relative;
    margin: 0 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72.5px;
    font-size: 22px;
    font-family: NanumSquareOTF_acB;
    color: var(--color-Mine_Shaft);

    input {
      padding: 0 50px 0 20px;
      width: 270px;
      height: 40px;
      font-size: 14px;
      font-family: NanumSquareOTF_acB;
      border: 2px solid var(--color-Cornflower-Blue);
      border-radius: 20px;

      @include mobile {
        padding: 0 5px;
        width: 120px;
        font-size: 12px;
      }
    }

    button {
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translateY(-50%);
    }
  }

  table {
    thead {
      background-color: var(--color-Gallery);
      th {
        color: var(--color-Mine_Shaft) !important;
      }

      @include mobile {
        th:first-child {
          font-size: 0;
        }
      };
    }

    button {
      display: none;
    }
  }

  #pagination {
    margin-top: 30px;
  }

  .btn_add {
    display: none;
  }

  #TaskTable {
    .interest {
      display: none;
    }
    .table_title {
      padding-left: 15px;
    }
  }
}