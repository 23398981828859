@import "src/include";

#CommunityContainer {
    margin: 18px auto 0 auto;
    width: 967px;

    .page_title {
        margin-bottom: 10px;
        padding-left: 15px;
        font-size: 22px;
        font-family: NanumSquareOTF_acEB;
        line-height: 30px;
        color: #443018;
    }

    .header {
        margin-bottom: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 24px;

        .inner {
            padding-left: 17px;
            padding-right: 44px;
            display: flex;
            align-items: center;
            column-gap: 15px;

            p {
                font-size: 18px;
                font-family: NanumSquareOTF_acB;
                color: #343434;
                cursor: pointer;
            }

            p.active {
                font-family: NanumSquareOTF_acEB;
                color: var(--color-Web-Orange) !important;
            }
        }
    }

    .btn_link {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 112px !important;
        font-size: 12px;
        background: url("../../assets/images/common/icon-more-black.svg") no-repeat right;
    }

    .post_table {
        width: 100%;
        @include border-radius(3px);
        box-shadow: 0 3px 4px 0 rgba(216, 216, 216, 0.15);
        border: solid 1px #dfe3e8;
        background-color: #ffffff;
        overflow: hidden;

        .thead {
            display: flex;
            align-items: center;
            height: 46px;
            background-color: #f0f0f0;

            p {
                font-size: 14px;
                font-family: NanumSquareOTF_acEB;
                color: #443018;
            }
        }

        .tbody {

            a {
                display: flex;
                align-items: center;
                height: 38px;
                font-size: 12px;
                color: #343434;

                p:nth-child(2) {
                    font-family: NanumSquareOTF_acB;
                }
            }
        }


        p:first-child,
        p:nth-child(2),
        p:last-child {
            width: 8%;
            text-align: center;
        }

        p:last-child {
            width: 14%;
        }

        p:nth-child(3) {
            padding-left: 15px;
            width: 70%;
        }
    }

    .post.pin {
        background-color: rgba(107, 211, 255, 0.1);
    }
}