#LegalStatus {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1px;
  background-color: #d7d7d7;
  .color_box {
    width: 40px;
    height: 20px;
  }
  .status_box {
    text-align: center;
    background-color: #fafafa;
    padding: 48px 20px;
    .name {
      display: inline-flex;
      align-items: center;
      margin-bottom: 10px;
      > strong {
        display: inline-block;
        margin-right: 30px;
      }
    }
    .numbers {
      color: #565656;
      font-family: "Pretendard_Regular";
    }
  }
}
